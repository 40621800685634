import { FunctionComponent, ReactNode, useState } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import Card from 'components/common/Card'
import { FormattedMessage, useIntl } from 'react-intl'
import useStyles, { Divider, VerifiedStatusContainer } from './styled'
import { ReactComponent as VerifiedIcon } from 'assets/check_outline.svg'
import { theme } from 'screens/App/theme'

const primaryColor = theme.palette.primary.main

const SkeletonGroup: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Card className={classes.overviewCard}>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </Card>
  )
}

interface Props {
  fullName?: string | null
  isVerified?: boolean
  loading?: boolean
  overviewItems?: {
    key: string
    label: string
    icon?: ReactNode
    onClick: () => void
  }[]
  canConfigureAsAgent?: boolean
  isAgent?: boolean
  referralCode?: string
}
const InvestorOverview: FunctionComponent<Props> = ({
  fullName,
  isVerified,
  loading,
  overviewItems,
  isAgent,
  referralCode,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const DEFAULT_COPY_LABEL = intl.formatMessage({ id: 'screen.profile.overview.copy' })
  const fullUrl = intl.formatMessage(
    { id: 'app.component.inviteInvestorsModal.url.full' },
    { origin: window.location.origin, referralCode },
  )

  const shortUrl = intl.formatMessage(
    { id: 'app.component.inviteInvestorsModal.url.path' },
    { referralCode },
  )

  const [copyTextLabel, setCopyTextLabel] = useState(DEFAULT_COPY_LABEL)

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(fullUrl)
    setCopyTextLabel(intl.formatMessage({ id: 'screen.profile.overview.copied' }))
    setTimeout(() => {
      setCopyTextLabel(DEFAULT_COPY_LABEL)
    }, 3000)
  }
  if (loading) return <SkeletonGroup />

  return (
    <Card className={classes.overviewCard}>
      <Grid className={classes.largeBottomPadding}>
        <Typography variant="body1">Investor</Typography>
        <Grid className={classes.nameLabel}>
          <Typography variant="h3" className={classes.white}>
            {fullName}
          </Typography>
        </Grid>
        {isVerified && (
          <VerifiedStatusContainer isVerified>
            <VerifiedIcon className={classes.verifiedIcon} />
            <Typography variant="body2">
              <FormattedMessage id="screen.profile.overview.verified" />
            </Typography>
          </VerifiedStatusContainer>
        )}
      </Grid>
      <Divider size="1px" backgroundColor="rgba(255, 255, 255, 0.08)" />
      {isAgent && (
        <>
          <Grid className={classes.largeBottomPadding}>
            <Typography variant="body1" color={primaryColor}>
              <FormattedMessage id="screen.profile.overview.agent.title" />
            </Typography>
            <Grid className={classes.nameLabel}>
              <Typography variant="body1" className={classes.white}>
                {shortUrl}
              </Typography>
            </Grid>
            <Typography
              onClick={handleCopyReferralCode}
              variant="body3"
              className={classes.cursorPointer}
              color={primaryColor}
            >
              {copyTextLabel}
            </Typography>
          </Grid>
          <Divider size="1px" backgroundColor="rgba(255, 255, 255, 0.08)" />
        </>
      )}
      <Grid container direction="column" spacing={4}>
        {overviewItems?.map(({ key, label, icon, onClick }) => (
          <Grid item key={key}>
            <Typography
              onClick={onClick}
              className={classes.cursorPointer}
              variant="body3"
              color={primaryColor}
            >
              {label}
              {icon}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

export default InvestorOverview
