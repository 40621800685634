import { Step, StepLabel, Stepper } from '@mui/material'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { WrapperStepper, StepperBody } from './styled'

interface Props {
  steps: string[]
  activeStep: number
  widthStepper?: string
  screenId?: string
}

const StepperCard: FunctionComponent<Props> = ({ steps, activeStep, widthStepper, screenId }) => {
  const formattedMessageId = `components.stepper.label${screenId ? '.' + screenId : ''}`
  return (
    <WrapperStepper>
      <StepperBody width={widthStepper}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <FormattedMessage id={`${formattedMessageId}.${label}`} />
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperBody>
    </WrapperStepper>
  )
}

export default StepperCard
