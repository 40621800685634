import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  date: {
    color: theme.typographyColor.primaryText,
  },
  icon: {
    '& path': {
      stroke: `${theme.typographyColor.secondaryText}`,
    },
  },
}))

export const Wrapper = styled.div`
  width: 100%;
`

export const HeaderWrapper = styled.div`
  width: 100%;
`

export const HeaderTitle = styled.div`
  width: 100%;
`
