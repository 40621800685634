import { Alert } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './styled'
import { ReactComponent as HelpIcon } from 'assets/help.svg'
import clsx from 'clsx'

interface Props {
  variant: 'standard' | 'filled' | 'outlined' | undefined
  severity: 'success' | 'error' | 'warning' | 'info' | undefined
  message?: string
  messageId?: string
  messageValues?: any
  children?: any
  onClickIcon?: () => void
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>
}

const InlineAlert: FunctionComponent<Props> = ({
  variant,
  severity,
  message,
  messageId,
  messageValues,
  children,
  onClickIcon,
  Icon,
}: Props) => {
  const classes = useStyles()
  const messageToDisplay = () => {
    if (!messageId) return message
    return <FormattedMessage id={messageId} values={messageValues} />
  }
  return (
    <Alert
      variant={variant}
      severity={severity}
      className={classes.inlineAlert}
      icon={
        Icon ? (
          <Icon
            className={clsx(classes.icon, { [classes.iconClickable]: Boolean(onClickIcon) })}
            onClick={onClickIcon}
          />
        ) : (
          <HelpIcon className={classes.icon + classes.iconClickable} onClick={onClickIcon} />
        )
      }
    >
      {messageToDisplay()}
      {children}
    </Alert>
  )
}
export default InlineAlert
