import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '770px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  cardContainer: {
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
      gap: 8,
    },
  },
  noWrapButton: {
    whiteSpace: 'nowrap',
  },
  planRows: {
    flexDirection: 'row',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
}))

export const BackNextButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(3)};
`

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${theme.spacing(2)};
`
