import { FunctionComponent, useContext } from 'react'
import { useStyles, Container } from './styled'
import { Transaction } from 'types'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'components/common/styled'
import { useMutation } from '@apollo/client'
import { CANCEL_DEPOSIT_REQUEST } from '../../queries'
import CancelDepositModalContent from './CancelDepositModalContent'
import { AlertContext } from 'hooks/AlertContext'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  investmentUuid: string | undefined
  deposit: Transaction | undefined
}

const CancelDepositModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  investmentUuid,
  deposit,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const { showSnackbar } = useContext(AlertContext)

  const [cancelDeposit, { loading }] = useMutation(CANCEL_DEPOSIT_REQUEST, {
    variables: {
      investmentUuid: investmentUuid,
      depositUuid: deposit?.uuid,
    },
  })

  const handleCancelWithdrawalRequest = async () => {
    try {
      const result = await cancelDeposit()
      if (result) {
        showSnackbar({
          messageId: 'screen.investmentDetail.tab4.dataGrid.cancelRequestModal.undoDepositSnackbar',
        })
      }
    } catch (e: unknown) {
      // TODO error handler
    } finally {
      handleCloseCallback()
    }
  }

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      handleActionButton={handleCancelWithdrawalRequest}
      actionMessageId="screen.investmentDetail.tab3.dataGrid.cancelRequestModal.button.cancelRequest"
      titleMessage=""
      width="auto"
      descriptionMessage=""
      fullScreen={isResponsive}
      disabledActionButton={loading}
    >
      <Container>
        <Typography variant="h2" className={classes.whiteText}>
          <FormattedMessage id="screen.investmentDetail.tab3.dataGrid.cancelRequestModal.title" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.investmentDetail.tab3.dataGrid.cancelRequestModal.description" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <CancelDepositModalContent amount={deposit?.amount} />
      </Container>
    </Dialog>
  )
}

export default CancelDepositModal
