import { FunctionComponent, ReactNode, useEffect } from 'react'
import Appbar from '../components/Appbar'
import { HomeLayoutChildrenContent } from './styled'
import { useQuery } from '@apollo/client'
import { GET_USER_PROFILE } from './queries'
import { useNavigate, useParams } from 'react-router-dom'
import { ProfileVerificationStatus } from 'global/types'
import { useLoading } from 'hooks/LoadingHook'

interface Props {
  children: ReactNode
}

const WelcomeLayout: FunctionComponent<Props> = ({ children }: Props) => {
  const { loading, data, refetch } = useQuery(GET_USER_PROFILE)
  const navigate = useNavigate()
  const { sumsub } = useParams()
  const { isLoading, stopLoading } = useLoading(true)

  if (sumsub) {
    refetch()
    navigate('/home')
  }

  useEffect(() => {
    if (
      data &&
      data?.profile?.application &&
      (data?.profile?.application?.status === ProfileVerificationStatus.pending ||
        data?.profile?.application?.status === ProfileVerificationStatus.approved)
    ) {
      navigate('/home')
    } else if (data?.profile?.application) {
      if (data?.profile?.application.levelName === 'KYB') {
        navigate('/sumsub/basic-kyb-level')
      } else if (data?.profile?.application.levelName === 'KYC') {
        navigate('/sumsub/basic-kyc-level')
      }
    }
    if (!loading) stopLoading()
  }, [data])

  return (
    <>
      {!isLoading && !loading && data && (
        <div>
          <Appbar />
          <HomeLayoutChildrenContent>{children}</HomeLayoutChildrenContent>
        </div>
      )}
    </>
  )
}

export default WelcomeLayout
