import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    minHeight: `calc(100vh - 80px)`,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  customAlertSubtitle: {
    color: theme.typographyColor.primaryText,
  },
  muiAlertButton: {
    height: 48,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  muiAlertSubtitle: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export const CenterContainer = styled.div`
  width: 100%;
  max-width: 968px;
  height: calc(100vh - 80px);
  color: white;
  margin-top: ${theme.spacing(3)};
`

export const TopTitleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 48px;
`

export const StyledAnchorTag = styled.a`
  color: ${theme.palette.primary.main};
`

export const InnerAlertContainer = styled.div`
  margin-bottom: ${theme.spacing(5)};
`
