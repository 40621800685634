import React, { FunctionComponent, ReactNode } from 'react'
import Appbar from './components/Appbar'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = ({ children }: Props) => {
  return (
    <>
      <Appbar />
      {children}
    </>
  )
}

export default Layout
