import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DepositRequestsTab } from 'global/types'

export interface DepositTab {
  status: DepositRequestsTab
  index: number
}

const initialState = { status: DepositRequestsTab.REQUESTED, index: 0 } as DepositTab

const depositTabStates: DepositTab[] = [
  { status: DepositRequestsTab.REQUESTED, index: 0 },
  { status: DepositRequestsTab.PENDING, index: 1 },
  { status: DepositRequestsTab.ACTIVE, index: 2 },
]

const depositRequestsSlice = createSlice({
  name: 'depositRequests',
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      const newTab = depositTabStates[action.payload]
      state.index = newTab.index
      state.status = newTab.status
    },
  },
})

export const { changeTab } = depositRequestsSlice.actions
export default depositRequestsSlice.reducer
