import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    '& .MuiInputBase-input': {
      order: 2,
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-root': {
      '& path': {
        stroke: theme.typographyColor.primaryText,
      },
    },
    '& MuiFormControl-root-MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      pointerEvents: 'none',
    },
  },
}))
