import { gql } from '@apollo/client'

export const GET_DATES = gql`
  query PartnersDates {
    dashboardDates {
      dashboardDates
    }
  }
`

export const GET_REFERRALS_MONTHLY_EARNINGS = gql`
  query ReferralsMonthlyEarnings($date: String!) {
    referralsMonthlyEarnings(date: $date) {
      monthlyEarningsList {
        invitedByName
        invitedByUuid
        netMonthlyEarnings
        referralName
        referralUuid
      }
    }
  }
`

export const DOWNLOAD_CSV = gql`
  mutation DownloadAgentsEarningsCSV($date: String!) {
    downloadAgentsEarningsCSV(date: $date)
  }
`
