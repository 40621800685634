import { gql } from '@apollo/client'

export const MARK_PROFILE_AS_SEEN = gql`
  mutation markProfileAsSeen {
    markProfileAsSeen {
      application {
        seenAt
      }
    }
  }
`

export const GET_INVESTMENTS = gql`
  query getInvestments {
    investments {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
    }
  }
`
export const GET_ADDRESS = gql`
  query Address {
    address {
      uuid
    }
  }
`
export const GET_BANK_ACCOUNT = gql`
  query BankAccount {
    wireBankAccount {
      uuid
    }
  }
`
