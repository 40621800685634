import { Typography } from '@mui/material'
import BankInformation from 'components/BankInformation'
import { FunctionComponent } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import useStyles, {
  BankInformationContainer,
  DescriptionWrapper,
  MainContainer,
  SecondDescriptionMainContainer,
  Divider,
} from './styled'
import { theme } from '../../App/theme'
import InlineAlert from 'components/Alert/InlineAlert'

interface Props {
  pendingCapitalToFund: number
  referenceNumber: number
  interestPeriodDescription: string
}

const Empty: FunctionComponent<Props> = ({
  pendingCapitalToFund,
  referenceNumber,
  interestPeriodDescription,
}: Props) => {
  const classes = useStyles()

  return (
    <MainContainer>
      <Typography variant="h4" color="white">
        <FormattedMessage id="screen.investmentDetails.empty.title" />
      </Typography>
      <DescriptionWrapper>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.investmentDetails.empty.information.01" />
        </Typography>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.investmentDetails.empty.information.02" />
        </Typography>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.investmentDetails.empty.information.03" />
        </Typography>
        <InlineAlert variant="standard" severity="warning" message={interestPeriodDescription} />
      </DescriptionWrapper>
      <Typography variant="body1" color={theme.typographyColor.secondaryText}>
        Pending capital to fund
      </Typography>
      <Typography variant="h2" color="white" className={classes.minimumTypographySpacing}>
        <FormattedNumber
          value={pendingCapitalToFund}
          style="currency"
          currency="USD"
          maximumFractionDigits={2}
        />
      </Typography>
      <Divider />
      <Typography variant="h4" color="white">
        <FormattedMessage id="screen.investmentDetail.bank.information" />
      </Typography>
      <SecondDescriptionMainContainer>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.investmentDetails.empty.description2" />
        </Typography>
      </SecondDescriptionMainContainer>
      <BankInformationContainer>
        <BankInformation wrapperProps={{ marginTop: '0px' }} referenceNumber={referenceNumber} />
      </BankInformationContainer>
    </MainContainer>
  )
}

export default Empty
