import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
} from '@mui/material'
import Card from 'components/common/Card'
import { FunctionComponent, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Plan } from 'types'
import { useStyles, CenterContainer, Divider, DividerDotted } from './styled'
import CheckStateOff from 'assets/state_off.svg'
import CheckStateOn from 'assets/state_on.svg'
import LoadingLayout from 'components/common/Loading'
import InlineAlert from 'components/Alert/InlineAlert'
import FundingWorksModal from './FundingWorksModal'
import { FundInvestmentTabs } from 'global/types'
import { ReactComponent as InfoIcon } from 'assets/info.svg'

interface Props {
  capitalToFund: string
  referenceNumber?: number
  investmentPlan?: Plan | null
  finishInvestment: (activeTab: FundInvestmentTabs) => void
  setPublicToken: (token: string) => void
  setAccountId: (token: string) => void
  isLoading?: boolean
  handleDisableNextStep: (enable: boolean) => void
  handleSelectedDepositType: (activeTab: FundInvestmentTabs) => void
}

const FundInvestment: FunctionComponent<Props> = ({
  investmentPlan,
  capitalToFund,
  referenceNumber,
  setPublicToken,
  setAccountId,
  finishInvestment,
  isLoading = false,
  handleDisableNextStep,
  handleSelectedDepositType,
}: Props) => {
  const classes = useStyles()
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [fundingWorksModal, setFundingWorksModal] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<FundInvestmentTabs>(
    FundInvestmentTabs.WIRE_TRANSFER,
  )

  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  // TODO: Enable link bank
  // const handleSelectPublicToken = (token: string, accountId: string) => {
  //   setBankSelected(token !== null)
  //   setPublicToken(token)
  //   setAccountId(accountId)
  // }

  const fundingWorksModalChangeStatus = (open: boolean) => () => setFundingWorksModal(open)

  const handleFinishInvestment = () => {
    finishInvestment(selectedTab)
  }

  const disableNextStep: boolean =
    !termsAccepted ||
    isLoading /* TODO: Enable Link bank || (selectedTab === FundInvestmentTabs.LINK_BANK && !bankSelected) */

  useEffect(() => {
    handleDisableNextStep(disableNextStep)
    if (!disableNextStep) {
      handleSelectedDepositType(selectedTab)
    }
  }, [selectedTab, isLoading, termsAccepted])

  return (
    <CenterContainer className={classes.centerContainer}>
      <FundingWorksModal
        modalOpen={fundingWorksModal}
        handleCloseCallback={fundingWorksModalChangeStatus(false)}
      />
      <Card className={classes.cardContainer}>
        <Typography variant="h3">
          <FormattedMessage id="screen.newInvestment.stepper3.title" />
        </Typography>
        <Stack
          divider={<Divider size="4px" />}
          gap={theme.spacing(2)}
          paddingBottom={theme.spacing(2)}
        >
          <Typography variant="body1">
            <FormattedMessage id="screen.newInvestment.stepper3.information.paragraph.01" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="screen.newInvestment.stepper3.information.paragraph.02" />
          </Typography>
          <Typography variant="body1" className={classes.description}>
            <FormattedMessage id="screen.newInvestment.stepper3.information.paragraph.03" />
          </Typography>
        </Stack>
        <InlineAlert
          variant="standard"
          severity="warning"
          Icon={InfoIcon}
          message={investmentPlan?.interestPeriodDescription}
        />
        <DividerDotted spacing={2} />
        <FormControlLabel
          className={classes.authorizeCheckboxContainer}
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              icon={<img src={CheckStateOff} />}
              checkedIcon={<img src={CheckStateOn} />}
            />
          }
          value="start"
          label={
            <Stack>
              <Typography variant="body3">
                <FormattedMessage id="screen.newInvestment.stepper3.information.understanding.01" />
              </Typography>
              <Typography variant="body3">
                <FormattedMessage id="screen.newInvestment.stepper3.information.understanding.02" />
              </Typography>
              <Typography variant="body3">
                <FormattedMessage id="screen.newInvestment.stepper3.information.understanding.03" />
              </Typography>
            </Stack>
          }
        />
        {!isResponsive && (
          <Button variant="contained" onClick={handleFinishInvestment} disabled={disableNextStep}>
            <FormattedMessage id="screen.newInvestment.stepper3.button" />
          </Button>
        )}
      </Card>
      {isLoading && <LoadingLayout />}
    </CenterContainer>
  )
}
export default FundInvestment
