import enTranslations from 'translations/en.json'
import esTranslations from 'translations/es.json'

export const DEFAULT_LANGUAGE = 'en'

export const appLanguages = ['en', 'es']

type JSONObject = { [x: string]: string }

export const formatTranslationMessages = (language: string, messages: JSONObject): JSONObject => {
  const defaultFormattedMessage =
    language !== DEFAULT_LANGUAGE ? formatTranslationMessages(DEFAULT_LANGUAGE, enTranslations) : {}

  const flattenFormattedMessages = (formattedMessages: JSONObject, key: string) => {
    const formattedMessage =
      !messages[key] && language !== DEFAULT_LANGUAGE ? defaultFormattedMessage[key] : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }
  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslations),
  es: formatTranslationMessages('es', esTranslations),
}
