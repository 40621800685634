import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  planContainer: {
    maxWidth: '31%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  title: {
    color: `${theme.typographyColor.primaryText}!important`,
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.typographyColor.secondaryText,
  },
  minimalAmount: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
  },
  characteristics: {
    color: theme.typographyColor.primaryText,
  },
  link: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '12px',
    cursor: 'pointer',
  },
  img: {
    marginLeft: theme.spacing(0.6),
    marginRight: theme.spacing(0.6),
    width: '12px',
    height: '12px',
  },
}))

export const PlanContainer = styled.div`
  display: grid;
  padding: ${theme.spacing(4, 2)};
  border-style: solid;
  border-color: ${theme.buttonColor.stroke};
  gap: ${theme.spacing(1)};
  column-gap: ${theme.spacing(1)};
  color: ${theme.typographyColor.primaryText};
`

export const CharacteristicsContainer = styled.div`
  display: flex;
  align-items: center;
`
