import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme } from 'screens/App/theme'

export interface TextAttributeWrapperProps {
  gap?: string
  justifyContent?: string
  alignItems?: string
  padding?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    borderBottom: `1px solid ${theme.buttonColor.stroke}`,
    gridAutoFlow: 'column',
    alignItems: 'stretch',
  },
  whiteText: {
    color: 'white !important',
    '& p': {
      color: 'white !important',
    },
  },
}))

export const PlanDescriptionItemWrapper = styled.div`
  padding: ${myTheme.spacing(3, 1)};
  ${({ theme: Theme }) => myTheme.breakpoints.down('md')} {
    padding: ${myTheme.spacing(1)};
  }
`

export const TextAttributeWrapper = styled.div`
  display: flex;
  align-items: ${({ alignItems }: TextAttributeWrapperProps) => alignItems || 'center'};
  justify-content: ${({ justifyContent }: TextAttributeWrapperProps) =>
    justifyContent || 'space-evenly'};
  gap: ${({ gap }: TextAttributeWrapperProps) => gap || myTheme.spacing(3)};
  padding: ${({ padding }: TextAttributeWrapperProps) => padding || myTheme.spacing(3, 1)};
  p: {
    color: white !important;
  }
`
export const MarkdownAttributeWrapper = styled.div`
  padding: ${({ padding }: TextAttributeWrapperProps) => padding || myTheme.spacing(3, 1)};
`
