import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  buttonWrapper: {
    alignItems: 'flex-start',
  },
  dropdownIconActive: {
    transform: 'rotate(180deg)',
  },
  dropdownIconInactive: {
    transform: 'rotate(0deg)',
  },
}))
