import { FunctionComponent, useState, useEffect } from 'react'
import { useStyles, Content, InvestmentData, TextContainer } from './styled'
import { GET_INVESTMENT } from 'screens/FinishNewInvestment/queries'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import ArrowLeft from 'assets/arrow_down_left.svg'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Investment } from 'types'

const WithdrawalRequestAppBar: FunctionComponent = () => {
  const { uuid } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()

  const [investment, setInvestment] = useState<Investment>()

  const { data } = useQuery(GET_INVESTMENT, {
    variables: {
      uuid,
    },
  })

  useEffect(() => {
    if (data?.investment) setInvestment(data?.investment)
  }, [data])

  const handleBackHome = () => {
    navigate(`/investment-detail/${investment?.uuid}`)
  }

  return (
    <Content>
      <img
        src={ArrowLeft}
        alt="arrow_right"
        className={classes.backIcon}
        onClick={handleBackHome}
      />
      <TextContainer>
        <Typography variant="body2" className={classes.white}>
          <FormattedMessage id="global.appbar.menu.withdrawalRequest.bar" />
        </Typography>
        <InvestmentData>
          <Typography variant="caption" className={classes.gray}>
            {investment?.profile?.application?.name}
            <FormattedMessage id="global.appbar.menu.investmentDetailAppBar.plan" />{' '}
            {investment?.plan?.name}
          </Typography>
        </InvestmentData>
      </TextContainer>
    </Content>
  )
}

export default WithdrawalRequestAppBar
