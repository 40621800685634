import { gql } from '@apollo/client'

export const CREATE_ACCESS_TOKEN = gql`
  mutation sumsubToken($userId: String!, $levelName: String!) {
    sumsubToken(userId: $userId, levelName: $levelName) {
      user {
        userId
      }
      sumsubToken
    }
  }
`
