import { FunctionComponent, useState, useEffect } from 'react'
import { Button, Dot, useStyles, DrawerHeader } from './styled'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  GET_DEPOSIT_REQUESTS_COUNT,
  GET_WITHDRAWAL_REQUESTS_COUNT,
  GET_COMPANY_PROFILE,
} from './queries'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { DepositTab } from 'screens/DepositRequests/reducer'
import { GlobalState } from 'redux/store'
import { useLocation, useNavigate } from 'react-router-dom'
import closeWhite from 'assets/close_white.svg'
import AddCompanyProfileModal from 'components/common/AddCompanyProfileModal'
import Snackbar from 'components/common/Snackbar'

interface Props {
  open: boolean
  handleCloseMenu: () => void
}

const COMPANY_PROFILE_ADDED_SNACKBAR_LABEL_ID = 'screen.companyProfile.snackbar.added.profile'

const TreasurerSideBar: FunctionComponent<Props> = ({ open, handleCloseMenu }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const [selectedScreen, setSelectedScreen] = useState(`/${location.pathname.split('/')[1]}`)
  // TODO Get and set real counters for investments, deposit requests and withdrawal requests
  const [counters, setCounters] = useState({
    investments: 0,
    depositRequests: 0,
    withdrawalRequests: 0,
  })
  const [openAddCompanyProfileModal, setOpenAddCompanyProfileModal] = useState(false)
  const [openAddedCompanyProfileSnackbar, setOpenAddedCompanyProfileSnackbar] = useState(false)

  const selectedTab: DepositTab = useSelector((state: GlobalState) => state.depositRequests)

  const classes = useStyles()
  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const { data: depositRequestCount, refetch: refetchDepositRequestCount } = useQuery(
    GET_DEPOSIT_REQUESTS_COUNT,
    {
      variables: {
        status: 'REQUESTED',
      },
    },
  )

  const { data: withdrawalRequestCount, refetch: refetchWithdrawalRequestCount } = useQuery(
    GET_WITHDRAWAL_REQUESTS_COUNT,
    {
      variables: {
        status: 'PENDING',
      },
    },
  )

  const { data: companyProfile, refetch: refetchProfileData } = useQuery(GET_COMPANY_PROFILE)

  const { name, tin, addressOne, addressTwo, city, state, postalCode, country, countryKey, phone } =
    companyProfile?.companyProfile || {}

  const handleOnCloseDrawer = () => {
    handleCloseMenu()
  }

  useEffect(() => {
    if (depositRequestCount)
      setCounters({
        investments: counters.investments,
        depositRequests: depositRequestCount?.depositsRequestCount,
        withdrawalRequests: counters.withdrawalRequests,
      })
  }, [depositRequestCount])

  useEffect(() => {
    if (withdrawalRequestCount)
      setCounters({
        investments: counters.investments,
        depositRequests: counters.depositRequests,
        withdrawalRequests: withdrawalRequestCount?.withdrawalsRequestCount,
      })
  }, [withdrawalRequestCount])

  useEffect(() => {
    if (selectedTab?.status === 'REQUESTED') {
      refetchData()
    }
  }, [selectedTab])

  const refetchData = async () => {
    try {
      refetchDepositRequestCount()
      refetchWithdrawalRequestCount()
    } catch {
      // TODO Error handler
    }
  }

  const handleButtonOnClick = (option: string) => () => {
    setSelectedScreen(option)
    navigation(option)
    handleCloseMenu()
  }

  const onSaveCompanyProfile = () => {
    refetchProfileData()
    setOpenAddedCompanyProfileSnackbar(true)
  }

  const handleOpenAddCompanyProfileModal = (open: boolean) => () =>
    setOpenAddCompanyProfileModal(open)

  const handleCloseAddedCompanyProfileSnackbar = () => setOpenAddedCompanyProfileSnackbar(false)

  return (
    <Drawer
      variant={hideInResponsive ? 'temporary' : 'persistent'}
      open={hideInResponsive ? open : true}
      onClose={handleOnCloseDrawer}
      className={classes.drawer}
    >
      {hideInResponsive && (
        <DrawerHeader>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}
            onClick={handleCloseMenu}
          >
            <Typography variant="h4" color="white" className={classes.buttonText}>
              Menu
            </Typography>
            <IconButton sx={{ px: 0 }}>
              <img src={closeWhite} alt="closeButton" />
            </IconButton>
          </Box>
        </DrawerHeader>
      )}
      <Divider />
      <Button
        onClick={handleButtonOnClick('/dashboard')}
        isSelected={selectedScreen === '/dashboard'}
      >
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.dashboard" />
        </Typography>
      </Button>
      <Button
        onClick={handleButtonOnClick('/investments')}
        isSelected={selectedScreen === '/investments'}
      >
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.investments" />
        </Typography>
        {counters?.investments > 0 && (
          <Dot>
            <Typography variant="body3">{counters?.investments}</Typography>
          </Dot>
        )}
      </Button>
      <Button
        onClick={handleButtonOnClick('/investors')}
        isSelected={selectedScreen === '/investors'}
      >
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="screen.treasurer.investors.title" />
        </Typography>
      </Button>
      <Button onClick={handleButtonOnClick('/agents')} isSelected={selectedScreen === '/agents'}>
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.agents" />
        </Typography>
      </Button>
      <Button
        onClick={handleButtonOnClick('/deposit-requests')}
        isSelected={selectedScreen === '/deposit-requests'}
      >
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.depositRequests" />
        </Typography>
        {counters?.depositRequests > 0 && (
          <Dot>
            <Typography variant="body3">{counters?.depositRequests}</Typography>
          </Dot>
        )}
      </Button>
      <Button
        onClick={handleButtonOnClick('/withdrawal-requests')}
        isSelected={selectedScreen === '/withdrawal-requests'}
      >
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.withdrawalRequests" />
        </Typography>
        {counters?.withdrawalRequests > 0 && (
          <Dot>
            <Typography variant="body3">{counters?.withdrawalRequests}</Typography>
          </Dot>
        )}
      </Button>
      <Button onClick={handleOpenAddCompanyProfileModal(true)}>
        <Typography variant="body3" className={classes.buttonText}>
          <FormattedMessage id="components.sideBar.treasurer.companyProfile" />
        </Typography>
      </Button>
      <AddCompanyProfileModal
        data={{
          name,
          tin,
          addressOne,
          addressTwo,
          city,
          state,
          postalCode,
          country,
          countryKey,
          phone,
        }}
        open={openAddCompanyProfileModal}
        onSave={onSaveCompanyProfile}
        handleCloseCallback={handleOpenAddCompanyProfileModal(false)}
      />
      <Snackbar
        messageId={COMPANY_PROFILE_ADDED_SNACKBAR_LABEL_ID}
        open={openAddedCompanyProfileSnackbar}
        onClose={handleCloseAddedCompanyProfileSnackbar}
      />
    </Drawer>
  )
}

export default TreasurerSideBar
