import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  overviewCard: {
    color: theme.typographyColor.secondaryText,
    backgroundColor: theme.extraBackgrounds.paper02,
  },
  white: {
    color: '#FFF !important',
  },
  largeBottomPadding: {
    paddingBottom: theme.spacing(3),
  },
  underline: {
    textDecoration: 'underline',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
  },
  nameLabel: {
    marginTop: theme.spacing(1),
    wordBreak: 'break-word',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

interface VerifiedStatusProps {
  isVerified: boolean
}

interface DividerProps {
  size?: string
  backgroundColor?: string
}

export const VerifiedStatusContainer = styled.div`
  display: flex;
  align-items: center;
  path {
    fill: ${({ isVerified }: VerifiedStatusProps) =>
      isVerified ? theme.palette.success.main : theme.palette.warning.main};
  }
  p {
    color: ${({ isVerified }: VerifiedStatusProps) =>
      isVerified ? theme.palette.success.main : theme.palette.warning.main};
  }
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(1)};
`

export const Divider = styled.div`
  width: 100%;
  height: ${({ size }: DividerProps) => size || theme.spacing(3)};
  background-color: ${({ backgroundColor }: DividerProps) => backgroundColor};
`

export default useStyles
