import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'screens/App/theme'
import { formatMoneyWithSymbolAndTwoDecimals } from 'utils/MoneyFormat'
import BankInformationGridItem from '../BankInformationGridItem'
import CopyInformationButton from '../CopyInformationButton'
import { getFormattedReferenceNumber } from 'utils/DataFormat'

interface Props {
  depositAmount?: number
  reference?: number
}

const LOCAL_WIRE_TRANSFER_INFO = {
  ROUTING_ABA_NUMBER: '021000021',
  ACCOUNT_NUMBER: '897703168',
}

const LocalWireTransferGrid = ({ depositAmount, reference }: Props) => {
  const intl = useIntl()
  const DEFAULT_COPY_LABEL = intl.formatMessage({
    id: 'screen.depositRequestInitializated.copy',
  })
  const COPIED_LABEL = intl.formatMessage({
    id: 'screen.depositRequestInitializated.copied',
  })

  return (
    <Grid container>
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.routingAbaNumber" />
          </Typography>
        }
        copyButton={
          <CopyInformationButton
            copiedLabel={COPIED_LABEL}
            copyLabel={DEFAULT_COPY_LABEL}
            textToCopy={LOCAL_WIRE_TRANSFER_INFO.ROUTING_ABA_NUMBER}
          />
        }
        value={
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            {LOCAL_WIRE_TRANSFER_INFO.ROUTING_ABA_NUMBER}
          </Typography>
        }
      />
      <BankInformationGridItem
        title={
          <>
            <Typography variant="body1" color={theme.typographyColor.secondaryText}>
              <FormattedMessage id="screen.depositRequestInitializated.referenceNumber" />
            </Typography>
            <Typography variant="body3" color={theme.typographyColor.warningText}>
              <FormattedMessage id="screen.depositRequestInitializated.referenceNumber.infoText" />
            </Typography>
          </>
        }
        copyButton={
          <CopyInformationButton
            copiedLabel={COPIED_LABEL}
            copyLabel={DEFAULT_COPY_LABEL}
            textToCopy={getFormattedReferenceNumber(reference || 0)}
          />
        }
        value={getFormattedReferenceNumber(reference || 0)}
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.accountNumber" />
          </Typography>
        }
        copyButton={
          <CopyInformationButton
            copiedLabel={COPIED_LABEL}
            copyLabel={DEFAULT_COPY_LABEL}
            textToCopy={LOCAL_WIRE_TRANSFER_INFO.ACCOUNT_NUMBER}
          />
        }
        value={LOCAL_WIRE_TRANSFER_INFO.ACCOUNT_NUMBER}
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.amount" />
          </Typography>
        }
        value={formatMoneyWithSymbolAndTwoDecimals(Number(depositAmount || 0))}
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.beneficiary" />
          </Typography>
        }
        value="Blue Dot Capitals LLC"
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.beneficiaryAddress" />
          </Typography>
        }
        value="2896 N HILLS DR NE. ATLANTA, GA 30305-3270"
      />
    </Grid>
  )
}

export default LocalWireTransferGrid
