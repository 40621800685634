import { useQuery } from '@apollo/client'
import { Button, Link, Typography, useMediaQuery } from '@mui/material'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import GreenCheck from 'assets/green_check.svg'
import Card from 'components/common/Card'
import { Divider } from 'components/common/styled'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { theme } from 'screens/App/theme'
import LocalWireTransferGrid from './LocalWireTransferGrid'
import { GET_DEPOSIT_INFORMATION } from './queries'
import { CardWrapper, CenterContainer, DividerDotted, IconWrapper, useStyles } from './styled'
import InternationalTransferGrid from './InternationalTransferGrid'
import ButtonWithArrowAnimation from 'components/common/ButtonWithArrowAnimation'

export interface DepositInformation {
  depositByUuid: {
    amount: number
    reference: number
  }
}

function DepositRequestInitializated() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { uuid } = useParams()
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const { data } = useQuery<DepositInformation>(GET_DEPOSIT_INFORMATION, {
    variables: { uuid },
  })

  const handleSupportContactButton = () => {
    window.open('mailto:support@bluedotcapitals.com', '_blank')
  }

  const handleBackToMyInvestmentsButton = () => {
    navigate('/home')
  }

  return (
    <CenterContainer>
      <Card personalizedColors className={classes.cardSpacing}>
        <CardWrapper>
          <IconWrapper>
            <img src={GreenCheck} alt="check" />
          </IconWrapper>
        </CardWrapper>
        <Typography variant="h1">
          <FormattedMessage id="screen.depositRequestInitializated.title" />
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.depositRequestInitializated.description" />
        </Typography>
        <DividerDotted spacing={3} />
        <Typography variant="h4">
          <FormattedMessage id="screen.depositRequestInitializated.subtitle" />
        </Typography>
        <Divider size={theme.spacing(1)} />
        <Typography variant="body1" color={theme.typographyColor.primaryText}>
          <FormattedMessage id="screen.depositRequestInitializated.advice" />
        </Typography>
        <Divider size={theme.spacing(1)} />
        <LocalWireTransferGrid
          depositAmount={data?.depositByUuid?.amount}
          reference={data?.depositByUuid?.reference}
        />
        <Divider size={theme.spacing(3)} />
        <Typography variant="h4">
          <FormattedMessage id="screen.depositRequestInitializated.international" />
        </Typography>
        <Divider size={theme.spacing(1)} />
        <InternationalTransferGrid />
        <Divider size={theme.spacing(3)} />
        <div>
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.needHelp" />
          </Typography>
          <Typography fontWeight={700} color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.contact" />
            &nbsp;
            <Link style={{ cursor: 'pointer' }} onClick={handleSupportContactButton}>
              <FormattedMessage id="constants.supportEmail" />
            </Link>
          </Typography>
        </div>
        <DividerDotted spacing={4} />
        <div>
          <ButtonWithArrowAnimation
            variant="contained"
            fullWidth={!matchesUpMd}
            onClick={handleBackToMyInvestmentsButton}
            marginRight
          >
            <FormattedMessage id="screen.depositRequestInitializated.backButton" />
          </ButtonWithArrowAnimation>
        </div>
      </Card>
    </CenterContainer>
  )
}

export default DepositRequestInitializated
