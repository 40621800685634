import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
  checkboxContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}))

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: ${theme.spacing(2)};
`

export const Alert = styled.div`
  width: 100%;
  padding: ${theme.spacing(2)};
  background-color: ${theme.extraBackgrounds.paperWarning};
`
