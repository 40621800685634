import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.extraBackgrounds.paper02,
    color: theme.typographyColor.secondaryText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '116px',
    padding: '0 !important',
  },
}))
