import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  overviewCard: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 'auto',
    color: theme.typographyColor.secondaryText,
    backgroundColor: theme.extraBackgrounds.paper02,
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}
    ${theme.spacing(1.5)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
    },
  },
  white: {
    color: '#FFF !important',
  },
  largeBottomPadding: {
    paddingBottom: theme.spacing(3),
  },
  underline: {
    textDecoration: 'underline',
  },
  chevron: {
    '& path': {
      stroke: `${theme.palette.primary.main}`,
    },
  },
}))

export const EndingBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};
`
