import { theme } from 'screens/App/theme'

export const colors = {
  success: theme.palette.success.main,
  error: theme.palette.error.main,
  warning: theme.palette.warning.main,
  variant01: theme.typographyColor.color01,
  variant02: theme.typographyColor.color02,
  variant03: theme.typographyColor.onHoverSecondaryButtonText as string,
}
