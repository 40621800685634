import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  goToMyInvestorsButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  copyButton: {
    marginTop: theme.spacing(3),
  },
  bottomDialog: {
    position: 'absolute',
    bottom: 0,
    top: 'auto',
    width: '100%',
    maxWidth: '100%',
    padding: 0,
    alignItems: 'start',
  },
}))

interface ModalContentWrapperProps {
  width?: string
}

export const ModalContentWrapper = styled.div<ModalContentWrapperProps>`
  max-width: 520px;
  width: ${(props) => props.width};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: ${theme.spacing(5)};
  background-color: ${theme.extraBackgrounds.paper03};
  color: ${theme.typographyColor.secondaryText};
  outline: none;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${theme.spacing(1)};
`

export const CloseIconWrapper = styled.img`
  cursor: pointer;
`

export const CircularIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 232, 140, 0.08);
`

export const Divider = styled.div`
  margin: 24px 0px;
  height: 1px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
