import { FunctionComponent, useState, SyntheticEvent, Fragment, useEffect } from 'react'
import { MenuItem, Tab, Tabs as MuiTabs, SelectChangeEvent, SvgIcon } from '@mui/material'
import useStyles, { FlexContainer, MainContainer, TabContentWrapper, TabsWrapper } from './styled'
import { IntlShape, useIntl } from 'react-intl'
import MonthlyDataDashboard from './MonthlyDataDashboard'
import { useLazyQuery } from '@apollo/client'
import {
  GET_CHART_TAB_DATA,
  GET_DASHBOARD_DATES,
  GET_DASHBOARD_NEXT_MONTH_DATA,
  GET_MONTHLY_DATA,
} from './queries'
import moment, { Moment } from 'moment'
import ScreenSkeleton from './ScreenSkeleton'
import { getFormattedData } from './utils'
import ActiveInvestments from './ActiveInvestments'
import SelectInput from 'components/common/Inputs/SelectInput'
import ChartView from './ChartView'
import { ReactComponent as ChevronDown } from 'assets/not_filled_chevron_down.svg'
import NextMonth from 'components/NextMonth'
import { isSameMonthAsCurrent } from 'utils/DateUtils'

enum Tabs {
  OVERVIEW = 0,
  CHART = 1,
}

const { OVERVIEW, CHART } = Tabs

const MENU_ITEM_HEIGHT = 54
const MENU_ITEMS_DISPLAYED = 7
const PADDING_TOP_MENU = 8
const TOTAL_MENU_ITEMS_HEIGHT = MENU_ITEM_HEIGHT * MENU_ITEMS_DISPLAYED + PADDING_TOP_MENU

const getTabProps = (tab: Tabs, intl: IntlShape) => ({
  id: `dashboard-tab-${tab}`,
  'aria-controls': `dashboard-tabpanel-${tab}`,
  label: intl.formatMessage({
    id: `screen.dashboard.tab.${tab === OVERVIEW ? 'overview' : 'chart'}`,
  }),
})

const LOWEST_DATE = moment('01-01-2000')

const Dashboard: FunctionComponent = () => {
  const intl = useIntl()
  const currentEndOfMonth = moment().endOf('month')
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(OVERVIEW)
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM'))
  const [selectedPeriod, setSelectedPeriod] = useState({
    name: 'all',
    startDate: LOWEST_DATE,
    endDate: currentEndOfMonth,
  })

  const [getDashboardDates, { data: datesData, loading: loadingDashboardDates }] = useLazyQuery(
    GET_DASHBOARD_DATES,
    {
      fetchPolicy: 'network-only',
    },
  )
  const [getMonthlyData, { data: monthlyData, loading: loadingMonthlyData }] = useLazyQuery(
    GET_MONTHLY_DATA,
    {
      fetchPolicy: 'network-only',
      variables: { date: selectedDate },
    },
  )
  const [getChartData, { data: chartTabData, loading: loadingChartData }] = useLazyQuery(
    GET_CHART_TAB_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        startDate: selectedPeriod.startDate.format('YYYY-MM'),
        endDate: selectedPeriod.endDate.format('YYYY-MM'),
      },
    },
  )
  const [getNextMonthData, { data: nextMonthData, loading: loadingNextMonthData }] = useLazyQuery(
    GET_DASHBOARD_NEXT_MONTH_DATA,
    {
      fetchPolicy: 'network-only',
    },
  )
  const dashboardChartData = chartTabData?.dashboardChartData
  const {
    liabilities,
    balance,
    balanceSheetAsset,
    grossMargin,
    grossMarginCummulative,
    withdrawalsTotalAmount,
    agentsEarnings,
    noLockup,
    percentageFromLastMonth,
  } = getFormattedData(monthlyData?.monthlyDashboardData)
  const { total, earnings, lockup } = liabilities || {}
  const loading =
    selectedTab !== CHART &&
    (loadingMonthlyData || loadingDashboardDates || loadingChartData || loadingNextMonthData)
  const datesOptions = datesData?.dashboardDates?.dashboardDates
  const scheduledWithdrawals = nextMonthData?.nextMonthDashboardData?.scheduledWithdrawals || 0

  const handleChangeTab = (_event: SyntheticEvent, tab: Tabs) => setSelectedTab(tab)

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setSelectedDate(event.target.value)
    getMonthlyData()
  }

  const handleChangePeriod = (name: string, startDate: Moment | null, endDate: Moment) => {
    setSelectedPeriod({
      name: name,
      startDate: startDate as Moment,
      endDate: endDate,
    })
  }

  const handleRefetchQueries = () => {
    getDashboardDates()
    getMonthlyData()
    getChartData()
    getNextMonthData()
  }

  const renderValueSelectInputMonth = (selected: string) => {
    return moment(selected).format('MMM[,] YYYY')
  }

  useEffect(() => {
    getDashboardDates()
    getMonthlyData()
    getChartData()
    getNextMonthData()
  }, [])

  return (
    <MainContainer>
      <TabsWrapper>
        <MuiTabs
          className={classes.tabs}
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label={intl.formatMessage({ id: 'screen.dashboard.title' })}
        >
          <Tab {...getTabProps(OVERVIEW, intl)} />
          <Tab {...getTabProps(CHART, intl)} />
        </MuiTabs>
        <TabContentWrapper>
          {loading && <ScreenSkeleton />}
          {!loading && (
            <Fragment>
              {selectedTab === OVERVIEW && (
                <FlexContainer className={classes.overviewContainer}>
                  {datesOptions && (
                    <FlexContainer width="130px">
                      <SelectInput
                        value={selectedDate}
                        renderValue={renderValueSelectInputMonth}
                        onChange={handleChangeSelect}
                        variant="date"
                        iconComponent={(props) => (
                          <SvgIcon className={classes.iconComponent} {...props}>
                            <ChevronDown />
                          </SvgIcon>
                        )}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: `${TOTAL_MENU_ITEMS_HEIGHT}px` } },
                        }}
                      >
                        {datesOptions.toReversed().map((date: string) => (
                          <MenuItem key={date} value={date}>
                            {moment(date).format('MMMM[,] YYYY')}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </FlexContainer>
                  )}
                  <FlexContainer className={classes.cardsContainer}>
                    <FlexContainer className={classes.cardsLeft}>
                      <MonthlyDataDashboard
                        agentsEarnings={agentsEarnings}
                        balance={balance}
                        balanceSheetAsset={balanceSheetAsset}
                        grossMargin={grossMargin}
                        grossMarginCummulative={grossMarginCummulative}
                        agentsMargin={null}
                        liabilities={liabilities}
                        withdrawalsTotalAmount={withdrawalsTotalAmount}
                        selectedDate={selectedDate}
                        refetchQueries={handleRefetchQueries}
                      />
                    </FlexContainer>
                    <FlexContainer className={classes.graphRight}>
                      <ActiveInvestments
                        selectedDate={selectedDate}
                        percentageFromLastMonth={percentageFromLastMonth || 0}
                        liquid={noLockup || 0}
                        totalActiveInvestments={total || 0}
                        lockup={lockup || 0}
                        earnings={earnings || 0}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  {isSameMonthAsCurrent(selectedDate) && (
                    <FlexContainer width="100%" height="100%" marginTop="40px">
                      <NextMonth amount={scheduledWithdrawals} />
                    </FlexContainer>
                  )}
                </FlexContainer>
              )}
              {selectedTab === CHART && (
                <ChartView
                  selectedPeriod={selectedPeriod}
                  onChangePeriod={handleChangePeriod}
                  lineData={dashboardChartData?.grossMargins || []}
                  barData1={dashboardChartData?.liabilities || []}
                  barData2={dashboardChartData?.balanceSheetAssets || []}
                  labels={
                    dashboardChartData?.labels.map((label: string) =>
                      moment(label).format('MMM[,] YYYY'),
                    ) || []
                  }
                />
              )}
            </Fragment>
          )}
        </TabContentWrapper>
      </TabsWrapper>
    </MainContainer>
  )
}

export default Dashboard
