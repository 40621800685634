import { FunctionComponent, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate, useParams } from 'react-router-dom'
import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'

const getRedirectParams = (referralCode?: string) => {
  const data = { screen_hint: 'signup', redirectUri: AUTH0_LOGIN_REDIRECT_URL }

  if (referralCode) {
    return { ...data, referralCode }
  }

  return data
}

const SignUp: FunctionComponent = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const navigate = useNavigate()
  const { code: referralCode } = useParams()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    } else {
      const params = getRedirectParams(referralCode)
      loginWithRedirect(params)
    }
  }, [isAuthenticated])

  if (isLoading) {
    return <div>Loading ...</div>
  }

  return <div />
}

export default SignUp
