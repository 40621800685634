import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

interface StepperBodyProps {
  width?: string
}

export const WrapperStepper = styled.div`
  padding-left: ${theme.spacing(5)};
  padding-right: ${theme.spacing(5)};
  padding-top: ${theme.spacing(3)};
  padding-bottom: ${theme.spacing(3)};
  background-color: ${theme.extraBackgrounds.paper03};
  width: 100%;
  min-width: 770px;
  margin-bottom: ${theme.spacing(3)};
`

export const StepperBody = styled.div<StepperBodyProps>`
  width: ${(props) => props.width || '100%'};
`
