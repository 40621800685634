import { FunctionComponent, useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  TextField,
} from '@mui/material'
import Card from 'components/common/Card'
import { FormattedMessage } from 'react-intl'
import { ButtonWrapper, useStyles } from './styled'
import ArrowRight from 'assets/arrow_right_black.svg'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'

interface Props {
  handleNextStep: () => void
  buttonDisabled: boolean
  onValidateReferralCode: (referralCode: string) => void
  validatedReferralCode?: string | null
  loadingValidation?: boolean
}

const ReferralCode: FunctionComponent<Props> = ({
  handleNextStep,
  buttonDisabled,
  onValidateReferralCode,
  validatedReferralCode,
  loadingValidation = false,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [referralCode, setReferralCode] = useState<string>(validatedReferralCode ?? '')

  const [isDisabledChangeReferralCode, setIsDisabledChangeReferralCode] = useState<boolean>(false)
  const onClickContinueButton = () => {
    handleNextStep()
  }

  const onClickValidateCode = () => {
    onValidateReferralCode(referralCode)
  }

  useEffect(() => {
    if (validatedReferralCode) {
      setReferralCode(validatedReferralCode)
      setIsDisabledChangeReferralCode(true)
    } else {
      setReferralCode('')
      setIsDisabledChangeReferralCode(false)
    }
  }, [validatedReferralCode])

  return (
    <Card className={classes.mainGridCard}>
      <Typography variant="h4">
        <FormattedMessage id="screen.newAgent.stepper.referralCode.title" />
      </Typography>
      <Typography variant="body3" className={classes.body3}>
        <FormattedMessage id="screen.newAgent.stepper.referralCode.description" />
      </Typography>
      <FormControl>
        <Grid container className={classes.flex}>
          <TextField
            className={classes.referralCodeInput}
            id="outlined-adornment-amount"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value.trim())}
            label="Referral code"
            autoFocus={!isDisabledChangeReferralCode}
            placeholder="Referral code"
            disabled={isDisabledChangeReferralCode}
          />
          {!isDisabledChangeReferralCode && (
            <Button
              disabled={referralCode.length < 3 || loadingValidation}
              variant="outlined"
              onClick={onClickValidateCode}
            >
              <FormattedMessage id="screen.newAgent.stepper.referralCode.button.validate" />
            </Button>
          )}
        </Grid>
      </FormControl>
      {!isMobile && (
        <ButtonWrapper className={classes.continueButton}>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            onClick={onClickContinueButton}
            endIcon={
              <img src={buttonDisabled ? ArrowRightDisabled : ArrowRight} alt="arrowRight" />
            }
            className={classes.continueButton}
          >
            <FormattedMessage id="screen.newAgent.stepper.referralCode.button.continue" />
          </Button>
        </ButtonWrapper>
      )}
    </Card>
  )
}

export default ReferralCode
