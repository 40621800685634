import { gql } from '@apollo/client'

export const GET_DEPOSIT_INFORMATION = gql`
  query GetDepositInformation($uuid: String!) {
    depositByUuid(uuid: $uuid) {
      amount
      reference
    }
  }
`
