import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_INVESTMENT } from './queries'
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Investment } from 'types'
import { ApolloError } from 'apollo-client'
import {
  changeCapitalToFund,
  changeContract,
  changeInvestmentPlan,
  changeInvestmentTab,
  changeInvestmentUuid,
  changeMemorandum,
} from './reducer'

const NewInvestmentDraftHandler = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data, loading, error } = useQuery(GET_INVESTMENT, {
    variables: { uuid },
  })

  const fillData = (data: { investment: Investment; loading: boolean; error?: ApolloError }) => {
    const { investment, loading, error } = data
    if (loading) return
    if (error) navigate('/new-investment')
    const { uuid, plan, initialAmount, contract, memorandum } = investment
    dispatch(changeInvestmentPlan(plan))
    dispatch(changeCapitalToFund(initialAmount?.toString()))
    dispatch(changeContract(contract))
    dispatch(changeMemorandum(memorandum))
    dispatch(changeInvestmentTab(1))
    dispatch(changeInvestmentUuid(uuid))
    navigate('/new-investment?event=refresh')
  }

  useEffect(() => {
    fillData({ investment: data?.investment, loading, error })
  }, [data, loading, error])

  if (loading) return <div>Loading...</div>
  return <Fragment />
}

export default NewInvestmentDraftHandler
