import { FunctionComponent, useContext } from 'react'
import { useStyles, Content, InvestmentData } from './styled'
import { GET_INVESTMENT } from 'screens/FinishNewInvestment/queries'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import ArrowLeft from 'assets/arrow_down_left.svg'
import { Typography, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import BadgeStatus from 'components/BadgeStatus'
import { ProfileContext } from 'hooks/ProfileContext'
import { GET_INVESTMENT_BY_UUID } from 'screens/TreasurerInvestmentDetails/queries'
import { Investment } from 'types'
import { generatePlanNameWithSuffix } from 'utils/GeneratePlanNameWithSuffix'
import { colorInvestmentStatus } from 'utils/StatusColors'

const InvestmentDetailAppBar: FunctionComponent = () => {
  const { uuid } = useParams()
  const { isTreasurer } = useContext(ProfileContext)
  const classes = useStyles()
  const navigate = useNavigate()

  const { data } = useQuery(isTreasurer ? GET_INVESTMENT_BY_UUID : GET_INVESTMENT, {
    variables: {
      uuid,
    },
  })

  const investment: Investment = data?.investment || data?.investmentByUuid

  const handleBackHome = () => {
    if (isTreasurer) {
      navigate('/investments')
    } else {
      navigate('/home')
    }
  }

  return (
    <Content>
      <img
        src={ArrowLeft}
        alt="arrow_right"
        className={classes.backIcon}
        onClick={handleBackHome}
      />
      <InvestmentData>
        <Typography variant="body2" className={classes.textEllipsis}>
          {investment?.profile?.application?.name}
          <FormattedMessage id="global.appbar.menu.investmentDetailAppBar.plan" />{' '}
          {generatePlanNameWithSuffix(
            investment?.plan.name.toLowerCase(),
            investment?.plan.suffixName,
          )}
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'block' }, px: 0.5 }} />
        <BadgeStatus
          status={investment?.status}
          color={colorInvestmentStatus[investment?.status]}
        />
      </InvestmentData>
    </Content>
  )
}

export default InvestmentDetailAppBar
