import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { theme as myTheme, theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    minWidth: '770px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
    },
  },
  planDescription: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
  },
  description: {
    color: theme.typographyColor.color02,
  },
  authorizeCheckboxContainer: {
    marginTop: myTheme.spacing(4),
    marginBottom: myTheme.spacing(1),
    color: theme.typographyColor.secondaryText,
    '& .MuiCheckbox-root': {
      alignSelf: 'start',
      padding: '0px 9px !important',
    },
  },
  cancellationTitle: {
    color: `${theme.typographyColor.primaryText} !important`,
  },
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    marginBottom: myTheme.spacing(4),
  },
}))

export const CenterContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Divider = styled.div<{ size: string }>`
  width: 100%;
  height: ${({ size }) => size || theme.spacing(3)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`

export const DividerDotted = styled.div<{ spacing: number }>`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.22);
  margin-top: ${({ spacing }) => theme.spacing(spacing)};
  margin-bottom: ${({ spacing }) => theme.spacing(spacing)};
`
