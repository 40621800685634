import { useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import { Investment } from 'types'
import { GET_INVESTORS_INVESTMENTS_BY_AGENT } from './queries'
import { ContentContainer } from './styled'
import DataGrid from 'components/common/DataGrid'
import { columnsInvestmentsInvestorsByAgents } from 'components/common/DataGrid/utils'

interface Props {
  agentUuid: string
}

const InvestmentsInvestors: FunctionComponent<Props> = ({ agentUuid }: Props) => {
  const { data, loading, error } = useQuery<
    { investorsInvestmentsByAgent: Investment[] },
    { agentUuid: string }
  >(GET_INVESTORS_INVESTMENTS_BY_AGENT, { variables: { agentUuid } })
  const columns = columnsInvestmentsInvestorsByAgents()

  return (
    <ContentContainer>
      <DataGrid
        columns={columns}
        rows={data?.investorsInvestmentsByAgent || []}
        autoHeight
        getRowId={(row) => row.uuid}
        isLoading={loading}
        hasError={Boolean(error)}
        errorMessageId="screen.agentDetails.tab.investorsInvestment.dataGrid.error"
        emptyMessageId="screen.agentDetails.tab.investorsInvestment.dataGrid.empty"
      />
    </ContentContainer>
  )
}

export default InvestmentsInvestors
