import moment from 'moment'

export const NEW_YORK_TIMEZONE = 'America/New_York'

export const DAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const getFirstDayNextMonth = (): string => {
  const now = moment()
  return now.date(1).add(1, 'M').format('MMMM, DD, YYYY')
}

export const isSameMonthAsCurrent = (date: string | Date): boolean =>
  moment(date).isSame(moment.now(), 'month')
