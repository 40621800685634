import { createTheme } from '@mui/material'
import { AcuminProBook } from './fonts'
declare module '@mui/material/styles' {
  interface Theme {
    extraBackgrounds: {
      paper01: React.CSSProperties['color']
      paper02: React.CSSProperties['color']
      paper03: React.CSSProperties['color']
      paper04: React.CSSProperties['color']
      paper05: React.CSSProperties['color']
      paper06: React.CSSProperties['color']
      paper07: React.CSSProperties['color']
      paperSuccess: React.CSSProperties['color']
      paperError: React.CSSProperties['color']
      paperWarning: React.CSSProperties['color']
      selectedItem: React.CSSProperties['color']
    }
    typographyColor: {
      primaryText: React.CSSProperties['color']
      secondaryText: React.CSSProperties['color']
      primaonActivePrimaryButtonTextryText: React.CSSProperties['color']
      onDisabledPrimaryButtonText: React.CSSProperties['color']
      onActiveSecondaryButtonText: React.CSSProperties['color']
      onDisabledSecondaryButtonText: React.CSSProperties['color']
      onHoverSecondaryButtonText: React.CSSProperties['color']
      color01: React.CSSProperties['color']
      color02: React.CSSProperties['color']
      color03: React.CSSProperties['color']
      warningText: React.CSSProperties['color']
    }
    buttonColor: {
      active: React.CSSProperties['color']
      stroke: React.CSSProperties['color']
      hover: React.CSSProperties['color']
      disable: React.CSSProperties['color']
    }
    doughnutChartColors: {
      yellow: React.CSSProperties['color']
      blue: React.CSSProperties['color']
      pink: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    extraBackgrounds: {
      paper01: React.CSSProperties['color']
      paper02: React.CSSProperties['color']
      paper03: React.CSSProperties['color']
      paper04: React.CSSProperties['color']
      paper05: React.CSSProperties['color']
      paper06: React.CSSProperties['color']
      paper07: React.CSSProperties['color']
      paperSuccess: React.CSSProperties['color']
      paperError: React.CSSProperties['color']
      paperWarning: React.CSSProperties['color']
      selectedItem: React.CSSProperties['color']
    }
    typographyColor: {
      primaryText: React.CSSProperties['color']
      secondaryText: React.CSSProperties['color']
      primaonActivePrimaryButtonTextryText: React.CSSProperties['color']
      onDisabledPrimaryButtonText: React.CSSProperties['color']
      onActiveSecondaryButtonText: React.CSSProperties['color']
      onDisabledSecondaryButtonText: React.CSSProperties['color']
      onHoverSecondaryButtonText: React.CSSProperties['color']
      color01: React.CSSProperties['color']
      color02: React.CSSProperties['color']
      color03: React.CSSProperties['color']
      warningText: React.CSSProperties['color']
    }
    buttonColor: {
      active: React.CSSProperties['color']
      stroke: React.CSSProperties['color']
      hover: React.CSSProperties['color']
      disable: React.CSSProperties['color']
    }
    doughnutChartColors: {
      yellow: React.CSSProperties['color']
      blue: React.CSSProperties['color']
      pink: React.CSSProperties['color']
    }
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    body3: TypographyStyleOptions | undefined
  }

  interface TypographyOptions {
    body3: TypographyStyleOptions | undefined
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
  }
}

export const theme = createTheme({
  extraBackgrounds: {
    paper01: '#111A25',
    paper02: '#1D2630',
    paper03: '#222A34',
    paper04: '#242C36',
    paper05: '#262F39',
    paper06: '#2B333D',
    paper07: '#435E7C',
    paperSuccess: '#45714E',
    paperError: '#5C252B',
    paperWarning: '#5C552A',
    selectedItem: '#2B3644',
  },
  typography: {
    fontFamily: 'AcuminProBook',
    h1: {
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '48px',
    },
    h2: {
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '38px',
    },
    h3: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '29px',
    },
    h4: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    body1: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
    },
    body2: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19px',
    },
    body3: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
    },
    button: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '19px',
      textTransform: 'inherit',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': AcuminProBook,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: '#9BA1A6',
          borderRadius: 0,
          padding: '16px 40px',
        },
        standardSuccess: {
          backgroundColor: '#45714E',
        },
        standardWarning: {
          backgroundColor: '#5C552A',
        },
        standardError: {
          backgroundColor: '#5C252B',
        },
        standardInfo: {
          backgroundColor: '#344255',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '16px 16px 12px',
        },
        outlined: {
          borderRadius: 'unset',
          transition: 'all 0.2s ease-in-out',
          '& path': {
            stroke: '#8EC3FF',
            transition: 'all 0.2s ease-in-out',
          },
          '&:hover': {
            borderColor: '#4A9EFF',
            color: '#4A9EFF',
            backgroundColor: 'transparent',
            transition: 'all 0.2s ease-in-out',
            '& path': {
              stroke: '#4A9EFF',
              clipPath: 'none',
              transition: 'all 0.2s ease-in-out; clip-path 1s ease-out-in;',
            },
          },
          ':disabled': {
            borderColor: '#46525C',
            color: '#46525C',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            borderRadius: '0px',
            '&:hover': {
              backgroundColor: '#4A9EFF',
            },
            '&:disabled': {
              backgroundColor: '#46525C',
            },
            '&:active': {
              backgroundColor: '#8EC3FF',
            },
            '&:stroke': {
              backgroundColor: '#313A42',
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#222A34',
          padding: '16px 40px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1D2630',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            padding: '40px',
          },
          '& .MuiTypography-body3': {
            color: '#9BA1A6',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          padding: '4px 8px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: '104px !important',
          right: '40px !important',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          padding: '12px 16px',
        },
        action: {
          paddingLeft: '0px',
          marginRight: '0px',
        },
        message: {
          maxWidth: '250px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '17px',
          color: '#111A25',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '40px',
          '& h2': {
            color: '#FFFFFF',
          },
          overflow: 'scroll',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#313A42',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#313A42',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8EC3FF',
          },
          '& .MuiInputBase-inputMultiline': {
            padding: '12px 16px',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            paddingLeft: '24px',
          },
          color: '#FFFFFF',
          padding: '12px 16px',
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            top: '12px',
            color: '#9BA1A6',
          },
          '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
            top: 0,
            color: '#8EC3FF',
          },
          '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink': {
            top: 0,
          },
          // '& .MuiInputBase-input ': {
          //   margin: '12px 0px',
          // },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          paddingLeft: '22px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: '#222A34',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-label.Mui-active': {
            color: '#FFFFFF',
          },
          '& .MuiStepLabel-labelContainer': {
            color: '#9BA1A6',
          },
          '& .MuiStepLabel-label.Mui-completed': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#9BA1A6',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '& .MuiStepConnector-line': {
            borderColor: '#9BA1A6',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#9BA1A6',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          '& path': {
            stroke: '#FFFFFF',
            fill: '#FFFFFF',
          },
        },
        outlined: {
          paddingTop: '12px',
          paddingBottom: '4px',
          '& .MuiMenuItem-root': {
            backgroundColor: '#222A34',
          },
          '& .MuiInputLabel-root': {
            color: 'green',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          color: '#FFFFFF',
          backgroundColor: '#222A34',
          '& .MuiMenuItem-root': {
            padding: '16px',
          },
          '& .Mui-selected': {
            color: '#8EC3FF',
            backgroundColor: '#2B3644',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
          color: '#FFFFFF',
          backgroundColor: '#435E7C',
          padding: '8px 16px',
          borderRadius: 0,
        },
        arrow: {
          color: '#435E7C',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#8EC3FF',
    },
    success: {
      main: '#92F38C',
    },
    error: {
      main: '#FF8E8E',
    },
    warning: {
      main: '#FFE88C',
    },
    background: {
      default: '#0A151E',
    },
  },
  typographyColor: {
    primaryText: '#FFFFFF',
    secondaryText: '#9BA1A6',
    primaonActivePrimaryButtonTextryText: '#111A25',
    onDisabledPrimaryButtonText: '#26303B',
    onActiveSecondaryButtonText: '#8EC3FF',
    onDisabledSecondaryButtonText: '#46525C',
    onHoverSecondaryButtonText: '#4A9EFF',
    color01: '#3F556E',
    color02: '#707055',
    color03: '#CCAD3252',
    warningText: '#FFE88C',
  },
  buttonColor: {
    active: '#8EC3FF',
    stroke: '#313A42',
    hover: '#4A9EFF',
    disable: '#46525C',
  },
  doughnutChartColors: {
    yellow: '#FFE88C',
    blue: '#8EC3FF',
    pink: '#FFA4E6',
  },
})
