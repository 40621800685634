import { gql } from '@apollo/client'

export const GET_ADDRESS = gql`
  query Address {
    address {
      addressOne
      addressTwo
      city
      country
      postalCode
      state
      uuid
    }
  }
`

export const GET_BANK_ACCOUNT = gql`
  query WireBankAccount {
    wireBankAccount {
      uuid
      country
      bankName
      countryKey
      accountNumber
      routingNumber
      preferredMethod
      beneficiary
      swiftCode
    }
  }
`
