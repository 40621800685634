import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
  headerTypography: {
    color: theme.typographyColor.primaryText,
    cursor: 'pointer',
  },
  copyButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  listContainer: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '100%',
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inviteInvestorContainer: {
    [theme.breakpoints.up('md')]: {
      width: '40%',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      padding: 16,
      width: '100%',
      flexDirection: 'row',
    },
  },
  mainGrid: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  row: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
}))

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`

export const CircularIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 232, 140, 0.08);
`

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`

export const ListContainer = styled.div`
  background-color: ${theme.extraBackgrounds.paper02};
  padding: 16px;
`

export const InviteInvestorContainer = styled.div`
  background-color: ${theme.extraBackgrounds.paper02};
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-height: 300px;
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

export default useStyles
