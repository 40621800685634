import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme as myTheme } from 'screens/App/theme'

interface ExtraHeaderProps {
  hasHeader?: boolean
}

export const useStyles = makeStyles((theme: Theme) => ({
  dataGrid: {
    border: 'none',
    backgroundColor: theme.extraBackgrounds.paper02,
    minHeight: '450px',
    '& .MuiDataGrid-root': {
      border: 'none !important',
    },
    '& .MuiDataGrid-main': {
      paddingLeft: `${theme.spacing(3)} !important`,
    },
    '& .MuiDataGrid-root--densityStandard': {
      border: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
      paddingLeft: `${theme.spacing(3)} !important`,
    },
    '& .MuiDataGrid-cellContent': {
      color: theme.typographyColor.primaryText,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: theme.typographyColor.secondaryText,
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-cell': {
      paddingTop: `${theme.spacing(3)} !important`,
      paddingBottom: `${theme.spacing(3)} !important`,
      borderBottom: `1px solid ${theme.extraBackgrounds.paper01} !important`,
    },
    '& .MuiDataGrid-footerContainer': {
      border: 'none',
      color: theme.typographyColor.primaryText,
    },
    '& .MuiTablePagination-displayedRows': {
      color: theme.typographyColor.primaryText,
    },
    '& .MuiTablePagination-selectLabel': {
      color: theme.typographyColor.primaryText,
    },
    '& .MuiInputBase-input.MuiSelect-select': {
      color: theme.typographyColor.primaryText,
    },
    '& .MuiIconButton-root': {
      color: theme.typographyColor.primaryText,
    },
    '& .MuiSelect-icon': {
      color: `${theme.typographyColor.primaryText} !important`,
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important',
      cursor: 'pointer',
    },
    '& .MuiChip-deleteIcon': {
      padding: 0,
      margin: 0,
    },
  },
  white: {
    color: theme.typographyColor.primaryText,
  },
  heightAuto: {
    height: 'auto !important',
  },
  helpIcon: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(1.25),
  },
}))

export const ExtraHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 79px;
  padding: ${myTheme.spacing(4, 2)};
`
export const HeaderContainer = styled.div<ExtraHeaderProps>`
  height: ${(props) => props.hasHeader && '79px'};
`
export const Image = styled.img`
  width: 16px;
  height: 16px;
  position: relative;
  top: 4px;
  left: 4px;
`
