import { Typography } from '@mui/material'
import Alert from 'components/Alert'
import OutlinedCard from 'components/cards/OutlinedCard'
import Card from 'components/common/Card'
import Layout from 'components/common/Layout'
import { useContext, useState } from 'react'
import { Container, Row, useStyles } from './styled'
import UserIcon from 'assets/user.svg'
import BriefcaseIcon from 'assets/briefcase.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import SvgThinArrow from 'assets/arrow_right_thin.svg'
import { AlertContext } from 'hooks/AlertContext'
import { useMutation } from '@apollo/client'
import { RESEND_VERIFICATION_EMAIL } from './queries'

function Welcome() {
  const classes = useStyles()
  const intl = useIntl()
  //TODO: Implement with authentication
  const { user } = useAuth0()
  const { showSnackbar } = useContext(AlertContext)
  const [isVerified] = useState(Boolean(user?.email_verified))
  const navigate = useNavigate()
  const [resendVerificationEmail, { loading }] = useMutation<{
    resendVerificationEmail: { success: boolean; message?: string }
  }>(RESEND_VERIFICATION_EMAIL)

  const goToSumsubBusinessInvestor = () => {
    navigate('/sumsub/basic-kyb-level')
  }

  const goToSumsubPersonalInvestor = () => {
    navigate('/sumsub/basic-kyc-level')
  }

  const onClickDisabledButton = () => {
    if (!isVerified)
      showSnackbar({
        messageId: 'screen.home.snackbar.notVerifiedEmail.disabled.error',
      })
  }

  const onClickResendVerificationEmail = async () => {
    try {
      await resendVerificationEmail()
    } catch (error) {
      console.error(error)
    }
    showSnackbar({
      messageId: 'screen.home.banner.notVerifiedEmail.sent',
    })
  }
  return (
    <Layout>
      {!isVerified && (
        <Alert
          variant="warning"
          title={intl.formatMessage({ id: 'screen.home.banner.notVerifiedEmail.title' })}
          subtitle={intl.formatMessage({ id: 'screen.home.banner.notVerifiedEmail.subtitle' })}
          withRightButton
          buttonLabel={intl.formatMessage({
            id: 'screen.home.banner.notVerifiedEmail.buttonLabel',
          })}
          buttonColor="primary"
          rightButtonEndIcon={SvgThinArrow}
          buttonAction={onClickResendVerificationEmail}
          buttonDisabled={loading}
        />
      )}
      <Container className={classes.container}>
        <Card className={classes.cardResponsive}>
          <Typography variant="h1" className={classes.titleResponsive}>
            <FormattedMessage id="screen.welcome.mainCard.title" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="screen.welcome.MainCard.subtitle" />
          </Typography>
          <Row className={classes.cardsContainerResponsive}>
            <OutlinedCard
              icon={UserIcon}
              title="screen.welcome.personalInvestorCard.title"
              subtitle="screen.welcome.personalInvestorCard.subtitle"
              buttonLabel="screen.welcome.personalInvestorCard.button"
              buttonDisabled={!isVerified}
              onClickDisabledButton={onClickDisabledButton}
              buttonAction={goToSumsubPersonalInvestor}
            />
            <OutlinedCard
              icon={BriefcaseIcon}
              title="screen.welcome.businessInvestorCard.title"
              subtitle="screen.welcome.businessInvestorCard.subtitle"
              buttonLabel="screen.welcome.businessInvestorCard.button"
              buttonDisabled={!isVerified}
              onClickDisabledButton={onClickDisabledButton}
              buttonAction={goToSumsubBusinessInvestor}
            />
          </Row>
        </Card>
      </Container>
    </Layout>
  )
}

export default Welcome
