import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    language: 'en',
    defaultLanguage: 'en',
  },
  reducers: {
    changeLanguage: (state) => {
      state.language = 'en'
    },
  },
})

const { actions } = appSlice

export { actions }

export default appSlice.reducer
