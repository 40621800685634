import { gql } from '@apollo/client'

export const GET_INVESTORS = gql`
  query Investors(
    $page: Int
    $limit: Int
    $name: String
    $orderBy: String
    $orderDirection: String
  ) {
    investors(
      page: $page
      limit: $limit
      name: $name
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      total
      investors {
        accountType
        endingBalance
        name
        status
        uuid
        applicantId
        createdAt
        __typename
      }
    }
  }
`
