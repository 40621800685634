import { gql } from '@apollo/client'

export const GET_INVESTMENT = gql`
  query Investment($uuid: String!) {
    investment(uuid: $uuid) {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
        closedDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
        depositFrequency
        interestPeriodDescription
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
          createdAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
    }
    depositRulesDates(investmentUuid: $uuid) {
      createdAt
      receivedAt
      targetDate
    }
  }
`

export const CREATE_DEPOSIT_REQUEST = gql`
  mutation CreateDepositRequest($amount: Float!, $investmentUuid: String!) {
    createDepositRequest(amount: $amount, investmentUuid: $investmentUuid) {
      uuid
    }
  }
`
