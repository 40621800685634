import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  overviewCard: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 'auto',
    color: theme.typographyColor.secondaryText,
    backgroundColor: theme.extraBackgrounds.paper02,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
    },
  },
  white: {
    color: '#FFF !important',
  },
  largeBottomPadding: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  underline: {
    textDecoration: 'underline',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  detailsOfTheInvestment: {
    marginBottom: theme.spacing(2),
  },
  chevron: {
    marginTop: theme.spacing(1),
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  chevronUp: {
    transform: 'rotate(180deg)',
  },
}))

export const EndingBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};
`

export const DetailsContainer = styled.div``

export const ViewDetailsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
