import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import Card from 'components/common/Card'
import { Profile } from 'global/types'
import { FunctionComponent } from 'react'
import { theme } from 'screens/App/theme'
import { Plan } from 'types'
import { BackNextButtonWrapper, Row, useStyles } from './styled'
import ArrowRight from 'assets/arrow_right_black.svg'
import { CenterContainer } from '../styled'
import { useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { InvestmentPlan } from '../InvestmentPlan'
import { FormattedMessage } from 'react-intl'
import { getArraysChunks } from 'utils/PlanChunks'

interface Props {
  profile?: Profile
  previousStep: () => void
  nextStep: () => void
  disabledButton?: boolean
}

const ConfirmNewAgent: FunctionComponent<Props> = ({
  profile,
  nextStep,
  previousStep,
  disabledButton = false,
}: Props) => {
  const newAgentStore = useSelector((state: GlobalState) => state.newAgent)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const getNextPlanName = (planUuid?: string): string | undefined => {
    if (!planUuid) return undefined
    const nextPlan = newAgentStore.linkedPlans.find(
      (linkedPlan) => linkedPlan.planUuid === planUuid,
    )
    if (!nextPlan) return undefined
    const nextPlanData = newAgentStore.plans.find((plan) => plan.uuid === nextPlan.nextPlanUuid)
    if (!nextPlanData) return undefined
    return `${nextPlanData.name} ${nextPlanData.suffixName ?? ''}`.trim()
  }

  const InfoContainer = ({ title, info }: { title: string; info: string | number | undefined }) => {
    return (
      <Grid item xs={4}>
        <Typography variant="body3">{title}</Typography>
        <Typography variant="body2">{info}</Typography>
      </Grid>
    )
  }

  return (
    <CenterContainer className={classes.centerContainer}>
      <Card className={classes.cardContainer}>
        <Typography variant="h4">
          <FormattedMessage id="screen.newAgent.confirmAgent.title" />
        </Typography>
        <Typography variant="h5">Profile</Typography>
        <Grid container xs={12} spacing={5}>
          <InfoContainer title="Name" info={profile?.application.name || 'Investor name'} />
          <InfoContainer title="Referral Code" info={newAgentStore.referralCode} />
          <InfoContainer
            title="Investor Type"
            info={profile?.application.levelName || 'Personal'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Plans</Typography>
          {getArraysChunks(newAgentStore.plans, 3).map((chunk) => (
            <Row key={crypto.randomUUID()} className={classes.planRows}>
              {chunk.map((plan: Plan) => (
                <InvestmentPlan
                  key={plan.uuid}
                  plan={plan}
                  nextPlanName={getNextPlanName(plan.uuid)}
                />
              ))}
            </Row>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage id="screen.newAgent.confirmAgent.footer.text" />
          </Typography>
        </Grid>
        {!isMobile && (
          <BackNextButtonWrapper>
            <Button variant="outlined" onClick={previousStep}>
              <FormattedMessage id="screen.newAgent.confirmAgent.button.back" />
            </Button>
            <Button
              variant="contained"
              onClick={nextStep}
              disabled={disabledButton}
              endIcon={<img src={ArrowRight} alt="Arrow Right" />}
            >
              <FormattedMessage id="screen.newAgent.confirmAgent.button.create" />
            </Button>
          </BackNextButtonWrapper>
        )}
      </Card>
    </CenterContainer>
  )
}

export default ConfirmNewAgent
