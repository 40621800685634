import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import {
  useStyles,
  MainContainer,
  CenterContainer,
  TopTitleButtonContainer,
  StepperContentContainer,
} from './styled'
import StepperCard from 'components/Stepper'
import Snackbar from '../../components/common/Snackbar'
import ReferralCode from './ReferralCode'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_AGENT,
  GET_REFERRAL_CODE,
  VALIDATE_REFERRAL_CODE,
  GET_PROFILE_BY_UUID,
} from './queries'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertContext } from 'hooks/AlertContext'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import {
  setReferralCode,
  STEPS,
  NewAgentSteps,
  changeStep,
  orderedSteps,
  resetStore,
} from './reducer'
import LoadingLayout from 'components/common/Loading'
import ResponsiveStepperCard from 'components/Stepper/ResponsiveStepper'
import PlansEdit from './PlansEdit'
import LinkPlans from './LinkPlans'
import ConfirmNewAgent from './ConfirmAgent'
import { AgentDetailsTab, Profile, ReferralCode as ReferralCodeType } from 'global/types'
import { FormattedMessage } from 'react-intl'

const {
  referralCode: REFERRAL_CODE,
  plans: PLANS,
  confirmation: CONFIRMATION,
  linkPlans: LINK_PLANS,
} = STEPS

const NewAgent = () => {
  const newAgentStore = useSelector((state: GlobalState) => state.newAgent)
  const activeStep = newAgentStore.activeStep

  const classes = useStyles()
  const { profileUuid = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showSnackbar } = useContext(AlertContext)
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const { data: dataReferralCode, loading: loadingReferralCode } = useQuery<
    {
      referralCode: ReferralCodeType
    },
    {
      profileUuid: string
    }
  >(GET_REFERRAL_CODE, {
    variables: {
      profileUuid,
    },
    fetchPolicy: 'no-cache',
  })

  const [validateReferralCode, { loading: loadingValidateCode }] = useMutation<
    {
      validateReferralCode: ReferralCodeType
    },
    {
      referralCode: string
      profileUuid: string
    }
  >(VALIDATE_REFERRAL_CODE)

  const { data: dataProfile } = useQuery<{ profileByUuid: Profile }, { uuid: string }>(
    GET_PROFILE_BY_UUID,
    {
      variables: { uuid: profileUuid },
      fetchPolicy: 'network-only',
    },
  )

  const loadingInitialData = loadingReferralCode

  const [createAgent, { loading: loadingCreateAgent }] = useMutation<
    { createAgent: Profile },
    {
      referralCode: string
      profileUuid: string
      plansUuid: string[]
      linkPlans: { planUuid: string; nextPlanUuid: string }[]
    }
  >(CREATE_AGENT)

  const [open, setOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [isEnabledNextStep, setIsEnabledNextStep] = useState<boolean>(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    setSnackbarMessage('')
  }

  const changeActiveStep = async (step: NewAgentSteps) => {
    dispatch(changeStep(step))
  }

  const handleActiveStep = (step: number) => {
    dispatch(changeStep(orderedSteps[step]))
  }

  const onClickButtons = (step: NewAgentSteps) => () => changeActiveStep(step)

  const onValidateReferralCode = async (referralCode: string) => {
    try {
      const data = await validateReferralCode({
        variables: {
          referralCode,
          profileUuid,
        },
      })
      const code = data.data?.validateReferralCode.code
      dispatch(setReferralCode(code))
    } catch (error) {
      console.error(error)
      showSnackbar({ messageText: 'Error validating referral code' })
    }
  }

  const onCreateAgent = async () => {
    try {
      if (!newAgentStore.referralCode) {
        showSnackbar({ messageText: 'Referral code is required' })
        return
      }
      if (!newAgentStore.plans.every((plan) => plan.uuid)) {
        showSnackbar({ messageText: 'There are plans not saved' })
        return
      }
      const plansUuid = newAgentStore.plans.map((plan) => plan.uuid) as string[]
      await createAgent({
        variables: {
          linkPlans: newAgentStore.linkedPlans,
          plansUuid,
          profileUuid,
          referralCode: newAgentStore.referralCode,
        },
      })
      dispatch(resetStore())
      navigate('/agents')
    } catch (error) {
      showSnackbar({ messageText: 'Error creating agent' })
      console.error(error)
    }
  }

  useEffect(() => {
    if (snackbarMessage.length) {
      openModal()
    }
  }, [snackbarMessage])

  useEffect(() => {
    switch (activeStep) {
      case REFERRAL_CODE:
        setIsEnabledNextStep(Boolean(newAgentStore.referralCode))
        break
      case PLANS:
        setIsEnabledNextStep(Boolean(newAgentStore.plans?.length))
        break
      case LINK_PLANS:
        setIsEnabledNextStep(true)
        break
      case CONFIRMATION:
        setIsEnabledNextStep(Boolean(newAgentStore.contractId && newAgentStore.memorandumId))
        break
    }
  }, [newAgentStore, activeStep])

  useEffect(() => {
    const refCode = dataReferralCode?.referralCode
    if (refCode) {
      if (refCode.isUpdateBlocked) {
        setSnackbarMessage('This referral has already been used, it cannot be updated')
        navigate(`/investor/${profileUuid}?tab=${AgentDetailsTab.INVESTORS_INVESTMENTS}`)
      }
      if (refCode.code) {
        dispatch(setReferralCode(refCode.code))
      } else {
        dispatch(setReferralCode(''))
      }
    }
  }, [dataReferralCode])

  useEffect(() => {
    dispatch(resetStore())
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <MainContainer>
      <Grid container className={classes.mainGrid} direction="column" alignItems="center">
        <Snackbar
          open={open}
          onClose={closeModal}
          messageText={snackbarMessage}
          messageId="screen.welcome.personalInvestorCard.newInvestmentButton.error"
        />
        <CenterContainer className={classes.centerContainer}>
          <TopTitleButtonContainer>
            <Typography variant="h2">
              <FormattedMessage id="screen.newAgent.stepper.title" />
            </Typography>
          </TopTitleButtonContainer>
          <StepperContentContainer>
            {isBigScreen && (
              <StepperCard
                steps={orderedSteps}
                activeStep={orderedSteps.indexOf(newAgentStore.activeStep)}
                screenId="newAgent"
                widthStepper="657px"
              />
            )}
            {activeStep === STEPS.referralCode ? (
              <ReferralCode
                handleNextStep={onClickButtons(STEPS.plans)}
                buttonDisabled={!isEnabledNextStep}
                onValidateReferralCode={onValidateReferralCode}
                validatedReferralCode={newAgentStore.referralCode}
                loadingValidation={loadingValidateCode}
              />
            ) : activeStep === STEPS.plans ? (
              <PlansEdit profileUuid={profileUuid} />
            ) : activeStep === STEPS.linkPlans ? (
              <LinkPlans
                nextStep={() => onClickButtons(STEPS.confirmation)()}
                previousStep={onClickButtons(STEPS.plans)}
              />
            ) : activeStep === STEPS.confirmation ? (
              <ConfirmNewAgent
                profile={dataProfile?.profileByUuid}
                nextStep={onCreateAgent}
                previousStep={onClickButtons(STEPS.linkPlans)}
                disabledButton={loadingCreateAgent}
              />
            ) : (
              <></>
            )}
          </StepperContentContainer>
        </CenterContainer>
        {loadingInitialData && <LoadingLayout />}
      </Grid>
      {!isBigScreen && (
        <ResponsiveStepperCard
          activeStep={orderedSteps.indexOf(newAgentStore.activeStep)}
          steps={orderedSteps}
          setActiveStep={handleActiveStep}
          disableNextButton={!isEnabledNextStep || loadingCreateAgent}
          handleFinishAction={onCreateAgent}
        />
      )}
    </MainContainer>
  )
}

export default NewAgent
