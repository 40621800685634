import { Button, Chip, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import BadgeStatus from 'components/BadgeStatus'
import Card from 'components/common/Card'
import Snackbar from 'components/common/Snackbar'
import { useState } from 'react'
import LearnMoreModal from 'screens/NewInvestment/LearnMoreModal'
import { Plan } from 'types'
import { LabWrapper } from './styled'
import AdapterMoment from '@date-io/moment'
import moment, { Moment } from 'moment'
import DateInput from 'components/common/Inputs/DateInput'

function Lab() {
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [dateValue, setDateValue] = useState<Moment | null>(moment())
  const investmentPlan: Plan = {
    uuid: '',
    name: 'Test plan',
    description:
      "The Test plan is a great way to get started,  you can withdraw your interest earnings every month. If you don't draw them, we will reinvest your interest earnings for the following month",
    interestRatePerMonth: 3.5,
    minimumInitialInvestment: 15000,
    maximumInvestment: 49999.99,
    principalLockupInMonths: 1,
    maturity: 12,
    withdrawalAllowed: false,
    earlyCancellationPenalty: 30,
    scheduledWithdrawalAllowed: true,
    earlyCancellationAllowed: true,
    initialProcessingFee: 0,
    suffixName: 'plan',
    shortDescription: 'Accelerate your cash growth while maintaining flexibility.',
    autoRenewal: false,
    autoRenewalPeriodInMonths: 0,
    renewalPeriodLockInMonths: 0,
    depositAllowed: false,
    earlyWithdrawalAllowed: false,
    earlyWithdrawalPenalty: 0,
    earningPenalty: 0,
    depositFrequency: 'NEXT_MONTH',
    deadlineDays: 0,
    deadlineTime: '',
    includeWeekends: false,
    interestType: 'COMPOUND',
    scheduledWithdrawalMandatory: false,
    scheduledWithdrawalPeriodInMonths: 0,
    contractId: '',
    memorandumId: '',
    interestPeriodDescription: '',
  }

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const updateDate = (newValue: Moment | null) => setDateValue(newValue)
  return (
    <Card>
      <h1>Lab</h1>
      <LabWrapper>
        <BadgeStatus status="Label" color="error" />
        <BadgeStatus status="Label" color="error" isTableStatus />
      </LabWrapper>
      <Snackbar
        messageId="screen.home.banner.profileInReview.title"
        open={open}
        onClose={closeModal}
      />
      <Button onClick={() => setOpen(true)}>Open Snackbar</Button>
      <Button onClick={() => setModal(true)}>Open Learn more modal</Button>
      <LearnMoreModal
        investmentPlan={investmentPlan}
        modalOpen={modal}
        handleCloseCallback={() => setModal(false)}
      />
      <Button onClick={openModal}>Open Snackbar</Button>
      <DateInput value={dateValue} onChange={updateDate} />
    </Card>
  )
}

export default Lab
