import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  backIcon: {
    transform: 'rotate(45deg)',
    cursor: 'pointer',
  },
}))

export const Content = styled.div`
  display: flex;
  gap: ${theme.spacing(2)};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing(0.5)};
`
