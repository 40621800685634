import { Typography } from '@mui/material'
import ArrowLeft from 'assets/arrow_down_left.svg'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Content, TitleWrapper, useStyles } from './styled'
import { theme } from 'screens/App/theme'
import { useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'

const NewDepositRequestSuccessAppBar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const newDepositRequestSuccessAppBar = useSelector(
    (state: GlobalState) => state.newDepositRequestSuccessAppBar,
  )

  const handleBackHome = () => {
    navigate('/home')
  }

  return (
    <Content>
      <img
        src={ArrowLeft}
        alt="arrow_right"
        className={classes.backIcon}
        onClick={handleBackHome}
      />
      <TitleWrapper>
        <Typography color={theme.typographyColor.primaryText} variant="body2">
          <FormattedMessage id="global.appbar.menu.newDeposit.bar.title" />
        </Typography>
        <Typography color={theme.typographyColor.secondaryText} variant="caption">
          <FormattedMessage
            id="screen.investmentDetail.planOverviewTitle"
            values={{
              name: newDepositRequestSuccessAppBar?.profileName,
              planName: newDepositRequestSuccessAppBar?.planName?.toLowerCase(),
            }}
          />
        </Typography>
      </TitleWrapper>
    </Content>
  )
}

export default NewDepositRequestSuccessAppBar
