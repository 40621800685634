import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: '100%',
    width: '60px',
    '& path': {
      stroke: `${theme.typographyColor.secondaryText}`,
    },
  },
}))

interface buttonProps {
  isSelected?: boolean
}

export const Wrapper = styled.div``

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(5)};
`
export const LeftContainer = styled.div`
  width: 40%;
  display: flex;
  gap: ${theme.spacing(3)};
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: ${theme.spacing(2)};
`

export const ChartContainer = styled.div`
  width: 100%;
  height: calc(100vh - 500px);
`

export const PeriodButton = styled.div<buttonProps>`
  padding: ${theme.spacing(1)};
  color: ${(props) => props.isSelected && theme.palette.primary.main};
  background-color: ${(props) => props.isSelected && theme.extraBackgrounds.selectedItem};
  border: 1px solid ${theme.buttonColor.stroke};
  cursor: pointer;
`
