import { Chip, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { BalanceWrapper, useStyles } from './styled'
import { ReactComponent as UpIcon } from 'assets/up.svg'
import { ReactComponent as DownIcon } from 'assets/down.svg'

interface Props {
  percentage: number
}

const BalanceChip: FunctionComponent<Props> = ({ percentage }: Props) => {
  const classes = useStyles()
  return (
    <BalanceWrapper className={classes.chips}>
      <Chip
        avatar={percentage > 0 ? <UpIcon /> : <DownIcon />}
        label={
          <Typography variant="body3" className={classes.percentage}>
            {percentage}%
          </Typography>
        }
        color={percentage > 0 ? 'success' : 'error'}
      />
    </BalanceWrapper>
  )
}

export default BalanceChip
