import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => ({
  textHighlight: {
    color: theme.typographyColor.primaryText,
  },
}))

export const SubtitleWrapper = styled.p`
  margin-top: 0;
  margin-bottom: 32px;
`
