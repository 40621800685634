import { gql } from '@apollo/client'

export const GET_DEPOSIT_REQUESTS_COUNT = gql`
  query DepositsRequestCount($status: DepositStatusInput!) {
    depositsRequestCount(status: $status)
  }
`
export const GET_WITHDRAWAL_REQUESTS_COUNT = gql`
  query WithdrawalsRequestCount($status: WithdrawalStatusInput!) {
    withdrawalsRequestCount(status: $status)
  }
`
export const GET_COMPANY_PROFILE = gql`
  query CompanyProfile {
    companyProfile {
      name
      tin
      phone
      addressOne
      addressTwo
      city
      state
      postalCode
      country
      countryKey
      phone
    }
  }
`
