import { FunctionComponent, useState } from 'react'
import Button from '@mui/material/Button'
import PlanDescriptionItem from 'components/PlanDescriptionItem'
import useStyles, { TableDetailsWrapper, TableDetailsWrapperProps } from './styled'
import { WireTransferButton } from 'global/types'
import { useIntl } from 'react-intl'

const { ABA_NUMBER, ACCOUNT_NUMBER, SWIFT_CODE, ACH_NUMBER, REFERENCE_NUMBER } = WireTransferButton

interface Props {
  wrapperProps?: TableDetailsWrapperProps
  referenceNumber?: number
}

const formatReferenceNumber = (referenceNumber: number) => `0000000${referenceNumber}`.slice(-7)

const BankInformation: FunctionComponent<Props> = ({ wrapperProps, referenceNumber }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const DEFAULT_COPY_LABEL = intl.formatMessage({
    id: 'screen.newInvestment.stepper3.wireTransfer.copy',
  })
  const copiedText = intl.formatMessage({
    id: 'screen.newInvestment.stepper3.wireTransfer.copied',
  })
  const [copyTextLabelAbba, setCopyTextLabelAbba] = useState(DEFAULT_COPY_LABEL)
  const [copyTextLabelAccount, setCopyTextLabelAccount] = useState(DEFAULT_COPY_LABEL)
  const [copyTextLabelSwift, setCopyTextLabelSwift] = useState(DEFAULT_COPY_LABEL)
  const [copyTextLabelAch, setCopyTextLabelAch] = useState(DEFAULT_COPY_LABEL)
  const [copyTextLabelReference, setCopyTextLabelReference] = useState(DEFAULT_COPY_LABEL)

  const handleCopy =
    (data: { copyLabelId?: string; copyValue?: string; button: WireTransferButton }) => () => {
      const { copyLabelId, button, copyValue } = data
      const textToCopy = copyValue || (intl.formatMessage({ id: copyLabelId }) as string)
      navigator.clipboard.writeText(textToCopy)
      if (button === WireTransferButton.ACCOUNT_NUMBER) {
        setCopyTextLabelAccount(copiedText)
        setTimeout(() => {
          setCopyTextLabelAccount(DEFAULT_COPY_LABEL)
        }, 3000)
      } else if (button === WireTransferButton.ABA_NUMBER) {
        setCopyTextLabelAbba(copiedText)
        setTimeout(() => {
          setCopyTextLabelAbba(DEFAULT_COPY_LABEL)
        }, 3000)
      } else if (button === WireTransferButton.SWIFT_CODE) {
        setCopyTextLabelSwift(copiedText)
        setTimeout(() => {
          setCopyTextLabelSwift(DEFAULT_COPY_LABEL)
        }, 3000)
      } else if (button === WireTransferButton.ACH_NUMBER) {
        setCopyTextLabelAch(copiedText)
        setTimeout(() => {
          setCopyTextLabelAch(DEFAULT_COPY_LABEL)
        }, 3000)
      } else if (button === WireTransferButton.REFERENCE_NUMBER) {
        setCopyTextLabelReference(copiedText)
        setTimeout(() => {
          setCopyTextLabelReference(DEFAULT_COPY_LABEL)
        }, 3000)
      }
    }

  return (
    <TableDetailsWrapper {...wrapperProps}>
      <PlanDescriptionItem
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeName="screen.newInvestment.stepper3.wireTransfer.beneficiary"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.beneficiary.value"
      />
      <PlanDescriptionItem
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeName="screen.newInvestment.stepper3.wireTransfer.bank"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.bank.value"
      />
      <PlanDescriptionItem
        centerValues
        attributeValueClassName={classes.abaTypography}
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeName="screen.newInvestment.stepper3.wireTransfer.accountNumber"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.accountNumber.value"
        valueChildren={
          <Button
            onClick={handleCopy({
              copyLabelId: 'screen.newInvestment.stepper3.wireTransfer.accountNumber.value',
              button: ACCOUNT_NUMBER,
            })}
            variant="outlined"
          >
            {copyTextLabelAccount}
          </Button>
        }
      />
      <PlanDescriptionItem
        centerValues
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeValueClassName={classes.abaTypography}
        attributeName="screen.newInvestment.stepper3.wireTransfer.routingNumber"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.routingNumber.value"
        valueChildren={
          <Button
            onClick={handleCopy({
              copyLabelId: 'screen.newInvestment.stepper3.wireTransfer.routingNumber.value',
              button: ACH_NUMBER,
            })}
            variant="outlined"
          >
            {copyTextLabelAch}
          </Button>
        }
      />
      <PlanDescriptionItem
        centerValues
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeValueClassName={classes.abaTypography}
        attributeName="screen.newInvestment.stepper3.wireTransfer.abaNumber"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.abaNumber.value"
        valueChildren={
          <Button
            onClick={handleCopy({
              copyLabelId: 'screen.newInvestment.stepper3.wireTransfer.abaNumber.value',
              button: ABA_NUMBER,
            })}
            variant="outlined"
          >
            {copyTextLabelAbba}
          </Button>
        }
      />
      <PlanDescriptionItem
        centerValues
        textAttributeWrapperProps={{
          justifyContent: 'flex-start',
        }}
        attributeValueClassName={classes.abaTypography}
        attributeName="screen.newInvestment.stepper3.wireTransfer.swiftCode"
        attributeValue=""
        attributeValueId="screen.newInvestment.stepper3.wireTransfer.swiftCode.value"
        valueChildren={
          <Button
            onClick={handleCopy({
              copyLabelId: 'screen.newInvestment.stepper3.wireTransfer.swiftCode.value',
              button: SWIFT_CODE,
            })}
            variant="outlined"
          >
            {copyTextLabelSwift}
          </Button>
        }
      />
      {Boolean(referenceNumber) && (
        <PlanDescriptionItem
          centerValues
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
          attributeValueClassName={classes.abaTypography}
          attributeName="screen.newInvestment.stepper3.reference"
          infoTextId="screen.depositRequestInitializated.referenceNumber.infoText"
          attributeValue={referenceNumber ? formatReferenceNumber(referenceNumber) : ''}
          attributeValueId=""
          valueChildren={
            <Button
              onClick={handleCopy({
                copyValue: referenceNumber ? formatReferenceNumber(referenceNumber) : '',
                button: REFERENCE_NUMBER,
              })}
              variant="outlined"
            >
              {copyTextLabelReference}
            </Button>
          }
        />
      )}
    </TableDetailsWrapper>
  )
}
export default BankInformation
