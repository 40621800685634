import { FunctionComponent, useContext } from 'react'
import { WithdrawalRequest } from 'types'
import Modal from 'components/common/Modal'
import { theme } from 'screens/App/theme'
import { Container, useStyles } from './styled'
import { FormattedMessage } from 'react-intl'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { Divider } from 'components/common/styled'
import ApplyWithdrawalsModalContent from './ApplyWithdrawalsModalContent'
import { useDispatch } from 'react-redux'
import { AlertContext } from 'hooks/AlertContext'
import { useMutation } from '@apollo/client'
import { APPLY_WITHDRAWALS } from 'screens/WithdrawalRequests/queries'
import { changeTab } from 'screens/WithdrawalRequests/reducer'
import { map } from 'lodash'
import { useLoading } from 'hooks/LoadingHook'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  withdrawals: WithdrawalRequest[] | []
}

const ApplyWithdrawalsModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  withdrawals,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [applyWithdrawals] = useMutation(APPLY_WITHDRAWALS, {
    variables: {
      withdrawalUuids: map(withdrawals, 'withdrawal.uuid'),
      approve: true,
    },
  })
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showSnackbar } = useContext(AlertContext)
  const { isLoading, startLoading, stopLoading } = useLoading()

  const handleApplyWithdrawals = async () => {
    try {
      startLoading()
      const result = await applyWithdrawals()

      if (result) {
        showSnackbar({
          messageId: 'screen.withdrawalRequests.tab01.applyWithdrawalModal.applySnackbar',
          messageValues: { withdrawals: withdrawals.length },
        })
        dispatch(changeTab(1))
      }
    } catch (e: unknown) {
      showSnackbar({
        messageId: 'screen.withdrawalRequests.tab01.applyWithdrawalModal.applySnackbar.error',
      })
    } finally {
      handleCloseCallback()
      stopLoading()
    }
  }

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      handleActionButton={handleApplyWithdrawals}
      actionMessageId="screen.withdrawalRequests.tab01.applyWithdrawalModal.action01"
      actionMessageValues={{ withdrawals: withdrawals.length }}
      titleMessage=""
      descriptionMessage=""
      fullScreen={isResponsive}
      disabledActionButton={isLoading}
    >
      <Container>
        <Typography variant="h2" className={classes.whiteText}>
          <FormattedMessage id="screen.withdrawalRequests.tab01.applyWithdrawalModal.title" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.withdrawalRequests.tab01.applyWithdrawalModal.description" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <ApplyWithdrawalsModalContent withdrawals={withdrawals} />
      </Container>
    </Dialog>
  )
}

export default ApplyWithdrawalsModal
