import {
  ScriptableTooltipContext,
  ChartData,
  ChartTypeRegistry,
  TooltipItem,
  ScriptableContext,
} from 'chart.js'
import { IntlShape } from 'react-intl'
import { theme } from 'screens/App/theme'
import { isSameMonthAsCurrent } from 'utils/DateUtils'

enum SLOPES {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

const OFFSET_POSITION_X = 240
const OFFSET_POSITION_Y = 100

const getBody = (bodyItem: { after: string[]; before: string[]; lines: string[] }) => bodyItem.lines

const getBackgroundColor = (color: string | CanvasPattern | CanvasGradient) => {
  const isString = typeof color === 'string'

  if (isString && color === 'rgba(0, 0, 0, 0)') {
    return theme.typographyColor.onHoverSecondaryButtonText
  }

  if (isString) return color

  return theme.typographyColor.color03
}

export const getCustomTooltip = (
  context: ScriptableTooltipContext<keyof ChartTypeRegistry>,
  intl: IntlShape,
) => {
  let tooltipEl = document.getElementById('chartjs-tooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.innerHTML = '<table></table>'
    document.body.appendChild(tooltipEl)
  }

  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const [date] = tooltipModel.title
    const bodyLines = tooltipModel.body.map(getBody)

    let innerHtml = '<thead>'

    titleLines.forEach((title: string) => {
      innerHtml += `<tr><th style="color:${theme.typographyColor.primaryText};text-align:left;font-size:14px;font-weight:400;">${title}</th></tr>`
    })
    innerHtml += '</thead><tbody>'

    bodyLines.forEach((body: string[], i: number) => {
      const areLiabilities = i === 1
      const rawBackgroundColor = tooltipModel.labelColors[i].backgroundColor
      const squaredColorElement = `<div style="background-color:${getBackgroundColor(
        rawBackgroundColor,
      )};width:8px;height:17px;"></div>`
      const [line] = body
      const [description, formatted] = line.split(':')
      const descriptionElement = `<span style="font-size:14px;color:${theme.typographyColor.secondaryText};">${description}: </span>`
      const formattedElement = `<span style="font-size:14px;color:${
        theme.typographyColor.primaryText
      };margin-left:auto;">${formatted.trim()}</span>`
      const estimatedLabelElement = `<span style="padding:4px;max-width:61px;background-color:${
        theme.extraBackgrounds.paperWarning
      };color:#FFFFFF;font-size:12px;">${intl.formatMessage({
        id: 'global.label.estimated',
      })}</span>`
      innerHtml += `<tr><td style="display:flex;align-items:center;margin-top:16px;gap:8px;">${squaredColorElement}${descriptionElement}${formattedElement}${
        areLiabilities && isSameMonthAsCurrent(date) ? estimatedLabelElement : ''
      }</td></tr>`
    })
    innerHtml += '</tbody>'

    const tableRoot = tooltipEl.querySelector('table')
    if (tableRoot) tableRoot.innerHTML = innerHtml
  }

  const position = context.chart.canvas.getBoundingClientRect()

  const mdBreakpointValue = theme.breakpoints.values.md
  const isMdUp = window.matchMedia(`( min-width: ${mdBreakpointValue}px )`).matches
  const minimumLeftPosition = isMdUp ? 309 : 0
  const canvasLeftXPosition = position.left
  const leftXWhereTooltipIsActivated = tooltipModel.caretX
  const originalTooltipPosition =
    canvasLeftXPosition - OFFSET_POSITION_X + leftXWhereTooltipIsActivated
  const xPositionConsideringMinimum = Math.max(originalTooltipPosition, minimumLeftPosition)

  tooltipEl.style.display = 'flex'
  tooltipEl.style.justifyContent = 'center'
  tooltipEl.style.width = '300px'
  tooltipEl.style.minHeight = '188px'
  tooltipEl.style.opacity = '1'
  tooltipEl.style.position = 'absolute'
  // tooltipEl.style.left = position.left - OFFSET_POSITION_X + tooltipModel.caretX + 'px'
  tooltipEl.style.left = xPositionConsideringMinimum + 'px'
  tooltipEl.style.top = position.top - OFFSET_POSITION_Y + tooltipModel.caretY + 'px'
  tooltipEl.style.font = 'Acumin Pro'
  tooltipEl.style.padding = '24px 16px'
  tooltipEl.style.pointerEvents = 'none'
  tooltipEl.style.backgroundColor = '#222A34'
  tooltipEl.style.borderRadius = '8px'
  tooltipEl.style.fontSize = '14px'
}

export const createDiagonalPattern = (color = 'black', slope?: string) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context) {
    const stroke = 2
    context.strokeStyle = color
    context.lineWidth = stroke

    const SLOPE = 1
    const SPACING = 16
    const LINE_WIDTH = 1

    const len = Math.hypot(1, SLOPE)
    const w = (canvas.width = 1 / len + SPACING + 0.5 || 0)
    const h = (canvas.height = SLOPE / len + SPACING * SLOPE + 0.5 || 0)

    context.strokeStyle = color
    context.lineWidth = LINE_WIDTH
    context.beginPath()

    if (slope === SLOPES.NEGATIVE) {
      context.moveTo(0, 0)
      context.lineTo(w, h)
      context.moveTo(0, -h)
      context.lineTo(w * 2, h)
      context.moveTo(-w, 0)
      context.lineTo(w, h * 2)
    } else {
      context.moveTo(0, h)
      context.lineTo(w * 2, -h)
      context.moveTo(w, 0)
      context.lineTo(-w, h * 2)
    }
    context.stroke()

    return context.createPattern(canvas, 'repeat')
  }

  return color
}

export const getOptions = (intl: IntlShape) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false,
      external: (context: ScriptableTooltipContext<keyof ChartTypeRegistry>) =>
        getCustomTooltip(context, intl),
      callbacks: {
        label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
          const label = tooltipItem.dataset.label
          const value = tooltipItem.raw as number

          return `${label}: ${intl.formatNumber(value, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}`
        },
      },
      interaction: {
        mode: 'index',
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        color: theme.typographyColor.secondaryText,
        callback: (tickValue: string | number) => {
          const value = tickValue as number

          if (value % 100000 === 0) {
            return `${value / 1000000}M`
          }
          if (value % 1000 === 0) {
            return `${value / 1000}K`
          }
          return `${value}`
        },
      },
      grid: {
        drawBorder: false,
        borderDash: [8, 4],
        color: 'rgba(255, 255, 255, 0.08)',
      },
    },
    x: {
      ticks: { color: theme.typographyColor.primaryText },
      stacked: true,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
})

export const getChartData = (args: {
  intl: IntlShape
  lineData: number[]
  barData1: number[]
  barData2: number[]
  labels: string[]
}) => {
  const { intl, lineData, barData1, barData2, labels } = args

  return {
    datasets: [
      {
        type: 'line',
        fill: false,
        label: intl.formatMessage({
          id: 'components.dashboard.chartView.chart.dataset.grossMargin',
        }),
        data: lineData,
        backgroundColor: theme.typographyColor.onHoverSecondaryButtonText,
        borderColor: theme.typographyColor.onHoverSecondaryButtonText,
        borderRadius: 8,
        pointRadius: 8,
        pointHoverRadius: 8,
        pointBorderColor: theme.typographyColor.onHoverSecondaryButtonText,
        pointBackgroundColor: (ctx: ScriptableContext<'line'>) => {
          const lastIndex = lineData.length - 1
          const dataIndex = ctx.dataIndex

          if (lastIndex === dataIndex) {
            return 'rgba(0, 0, 0, 0)'
          }
          return theme.typographyColor.onHoverSecondaryButtonText
        },
        pointBorderWidth: 4,
        segment: {
          borderDash: (ctx: ScriptableContext<'line'> & { p1DataIndex: number }) => {
            const lastIndex = lineData.length - 1
            const p1DataIndex = ctx.p1DataIndex

            if (lastIndex === p1DataIndex) {
              return [6, 6]
            }
            return [0, 0]
          },
        },
        order: 0,
      },
      {
        type: 'bar' as const,
        label: intl.formatMessage({
          id: 'components.dashboard.chartView.chart.dataset.liabilities',
        }),
        data: barData1,
        borderWidth: 1,
        borderColor: theme.typographyColor.color03,
        backgroundColor: (ctx: ScriptableContext<'bar'>) => {
          const color = theme.typographyColor.color03
          const lastIndex = barData1.length - 1
          const dataIndex = ctx.dataIndex

          if (lastIndex === dataIndex) {
            return createDiagonalPattern(color)
          }
          return color
        },
        borderRadius: 8,
        order: 1,
      },
      {
        type: 'bar' as const,
        label: intl.formatMessage({
          id: 'components.dashboard.chartView.chart.dataset.balanceSheetAsset',
        }),
        data: barData2,
        backgroundColor: theme.typographyColor.color01,
        borderRadius: 8,
        order: 2,
      },
    ],
    labels,
  } as ChartData<keyof ChartTypeRegistry, number[], string>
}
