import { FunctionComponent } from 'react'
import { SubtitleWrapper, useStyles } from './styled'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { DepositFrequency } from 'types'

interface Props {
  depositRulesDates?: {
    createdAt: string
    receivedAt: string
    targetDate: string
  }
  depositFrequency: DepositFrequency
}

const DepositFrequencyMap = {
  [DepositFrequency.NEXT_MONDAY]: 'Monday',
  [DepositFrequency.NEXT_MONTH]: 'month',
}

const SubtitleTimeRules: FunctionComponent<Props> = ({
  depositRulesDates,
  depositFrequency,
}: Props) => {
  const classes = useStyles()

  if (!depositRulesDates) return <></>

  return (
    <SubtitleWrapper>
      <Typography variant="body3" component="span" className={classes.textHighlight}>
        <FormattedMessage
          id="screen.DepositAmountStepper.paragraph.first"
          values={{
            before: moment(depositRulesDates?.receivedAt).format('dddd [at] h:mm A'),
            target: DepositFrequencyMap[depositFrequency],
          }}
        />
      </Typography>
      <Typography variant="body3" component="span">
        &nbsp;
        <FormattedMessage
          id="screen.DepositAmountStepper.paragraph.second"
          values={{
            day: moment(depositRulesDates.receivedAt).format('dddd'),
            time: moment(depositRulesDates.receivedAt).format('h:mm A'),
          }}
        />
      </Typography>
      <Typography variant="body3" component="span" className={classes.textHighlight}>
        &nbsp;
        <FormattedMessage
          id="screen.DepositAmountStepper.paragraph.third"
          values={{
            time: moment(depositRulesDates?.receivedAt).format('h:mm A'),
            target: DepositFrequencyMap[depositFrequency],
          }}
        />
      </Typography>
    </SubtitleWrapper>
  )
}

export default SubtitleTimeRules
