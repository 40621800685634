export const routes = [
  {
    role: 'Treasurer',
    route: '/home',
  },
  {
    role: 'Treasurer',
    route: '/deposit-requests',
  },
  {
    role: 'Investor',
    route: '/home',
  },
  {
    role: 'Investor',
    route: '/welcome',
  },
  {
    role: 'Investor',
    route: '/new-investment',
  },
  {
    role: 'Investor',
    route: '/finish-investment',
  },
  {
    role: 'Treasurer',
    route: '/investment-detail',
  },
  {
    role: 'Investor',
    route: '/investment-detail',
  },
  {
    role: 'Treasurer',
    route: '/investment-detail',
  },
  {
    role: 'Investor',
    route: '/withdrawal-request',
  },
  {
    role: 'Investor',
    route: '/lab',
  },
  {
    role: 'Investor',
    route: '/sumsub',
  },
  {
    role: 'Treasurer',
    route: '/withdrawal-requests',
  },
  {
    role: 'Treasurer',
    route: '/callback',
  },
  {
    role: 'Investor',
    route: '/callback',
  },
  {
    role: 'Investor',
    route: '/profile',
  },
  {
    role: 'Treasurer',
    route: '/investments',
  },
  {
    role: 'Treasurer',
    route: '/investor',
  },
  {
    role: 'Treasurer',
    route: '/dashboard',
  },
  {
    role: 'Agent',
    route: '/home',
  },
  {
    role: 'Agent',
    route: '/my-investors',
  },
  {
    role: 'Treasurer',
    route: '/partners',
  },
  {
    role: 'Investor',
    route: '/new-deposit-request',
  },
  {
    role: 'Investor',
    route: '/new-deposit-request/success',
  },
  {
    role: 'Investor',
    route: '/new-schedule-withdrawal',
  },
  {
    role: 'Treasurer',
    route: '/configure-agent',
  },
]
