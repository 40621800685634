import {
  Button,
  Grid,
  Typography,
  Tab,
  Tabs as MuiTabs,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  useStyles,
  MainContainer,
  OverviewContainer,
  InvestmentDetailContainer,
  TopTitleButtonContainer,
  TabsWrapper,
  TabContentWrapper,
  CopyContainer,
} from './styled'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GlobalState } from 'redux/store'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import Dialog from 'components/common/Dialog'
import { InvestmentDetailsTab } from 'global/types'
import { DetailTab, changeTab } from './reducer'
import { FunctionComponent, useEffect, useState } from 'react'
import InvestmentOverview from './InvestmentOverview'
import { useQuery } from '@apollo/client'
import { Investment } from 'types'
import { useParams } from 'react-router-dom'
import OverviewTab from './OverviewTab'
import StatementsTab from './StatementsTab'
import WithdrawalsTab from './WithdrawalsTab'
import DepositsTab from './DepositsTab'
import { filter } from 'lodash'
import Empty from './Empty'
import { GET_INVESTMENT_BY_UUID } from './queries'
import ScheduleWithdrawalsTab from './ScheduledWithdrawalsTab'

const TreasurerInvestmentDetails: FunctionComponent = () => {
  const { uuid } = useParams()
  const classes = useStyles()
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const DEFAULT_COPY_LABEL = intl.formatMessage({ id: 'screen.investmentDetail.modal.action01' })

  const selectedTab: DetailTab = useSelector((state: GlobalState) => state.investmentDetails)
  const [investment, setInvestment] = useState<Investment>()
  const [modalOpen, setModalOpen] = useState(false)
  const [copyTextLabel, setCopyTextLabel] = useState(DEFAULT_COPY_LABEL)
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const [isOpenInvestmentDetails, setIsOpenInvestmentDetails] = useState(false)

  const { data, refetch, loading } = useQuery(GET_INVESTMENT_BY_UUID, {
    fetchPolicy: 'network-only',
    variables: {
      uuid,
    },
  })

  const transactions = investment?.transactions
  const pendingCapitalToFund = investment?.transactions[0]?.amount || 0
  const referenceNumber = investment?.transactions[0]?.reference || 0
  const showEmpty =
    transactions &&
    transactions.length === 1 &&
    transactions[0].status !== 'ACTIVE' &&
    transactions[0].__typename === 'Deposit'

  useEffect(() => {
    if (!loading) {
      setInvestment(data?.investmentByUuid)
    }
  }, [data, loading])

  useEffect(() => {
    return () => {
      dispatch(changeTab(0))
    }
  }, [])

  const refetchData = async () => {
    try {
      await refetch()
    } catch {
      // TODO Error handler
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
  }

  const handleRedirectToWithdrawalRequest = () => {
    navigate(`/withdrawal-request/${investment?.uuid}`)
  }

  const handleNewDepositRequest = () => {
    // TODO: Remove this modal and make a navigate to the deposit-request screen
    setModalOpen(true)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(intl.formatMessage({ id: 'global.vars.treasuryMail' }) as string)
    const copiedText = intl.formatMessage({ id: 'screen.investmentDetail.modal.action02' })
    setCopyTextLabel(copiedText)
    setTimeout(() => {
      setCopyTextLabel(DEFAULT_COPY_LABEL)
    }, 3000)
  }

  const handleClickToggleInvestmentDetails = () => {
    setIsOpenInvestmentDetails(!isOpenInvestmentDetails)
  }

  const tabs = [
    {
      tab: InvestmentDetailsTab.OVERVIEW,
      content: <OverviewTab investment={data?.investmentByUuid} />,
    },
    {
      tab: InvestmentDetailsTab.STATEMENTS,
      content: <StatementsTab investment={data?.investmentByUuid} />,
    },
    // TODO: Content for this tabs
    // {
    //   tab: InvestmentDetailsTab.ALL_ACTIVITY,
    //   content: <div>{/* TODO: All Activity tab component */}</div>,
    // },
    {
      tab: InvestmentDetailsTab.DEPOSITS,
      content: (
        <div>
          <DepositsTab
            deposits={filter(data?.investmentByUuid?.transactions, { __typename: 'Deposit' })}
          />
        </div>
      ),
    },
    {
      tab: InvestmentDetailsTab.WITHDRAWALS,
      content: (
        <div>
          <WithdrawalsTab
            withdrawals={filter(data?.investmentByUuid?.transactions, { __typename: 'Withdrawal' })}
            investmentUuid={investment?.uuid}
            refetchInvestment={refetchData}
          />
        </div>
      ),
    },
    {
      tab: InvestmentDetailsTab.SCHEDULE_WITHDRAWALS,
      content: (
        <ScheduleWithdrawalsTab scheduledWithdrawals={investment?.scheduledWithdrawals || []} />
      ),
    },
    // {
    //   tab: InvestmentDetailsTab.INTEREST_EARNED,
    //   content: <div>{/* TODO: Interest Earned tab component */}</div>,
    // },
  ]

  return (
    <Grid container className={classes.mainGrid} direction="column" alignItems="center">
      <Dialog
        dialogOpen={modalOpen}
        handleCloseCallback={() => setModalOpen(false)}
        titleMessageId="screen.investmentDetail.action02.newDeposit"
        fullScreen={isResponsive}
      >
        <Typography variant="body1" textAlign="left">
          <FormattedMessage id="screen.investmentDetail.modal.description1" />
        </Typography>
        <Typography variant="body1" width="100%">
          <FormattedMessage id="screen.investmentDetail.modal.description2" />
        </Typography>
        <CopyContainer>
          <Typography variant="body2">
            <FormattedMessage id="global.vars.treasuryMail" />
          </Typography>
          <Button variant="text" onClick={handleCopy}>
            {copyTextLabel}
          </Button>
        </CopyContainer>
      </Dialog>
      <MainContainer>
        {isResponsive && isOpenInvestmentDetails && (
          <Typography variant="h3" paddingBottom={1}>
            <FormattedMessage id="screen.investmentDetail.title" />
          </Typography>
        )}
        <OverviewContainer>
          <InvestmentOverview
            isOpenInvestmentDetails={isOpenInvestmentDetails}
            onClickToggleInvestmentDetails={handleClickToggleInvestmentDetails}
            investment={investment}
            loading={loading}
          />
        </OverviewContainer>
        <InvestmentDetailContainer>
          {!isResponsive && (
            <TopTitleButtonContainer>
              <Typography variant="h3">
                <FormattedMessage id="screen.investmentDetail.title" />
              </Typography>
            </TopTitleButtonContainer>
          )}
          {showEmpty ? (
            <InvestmentDetailContainer>
              <Empty
                interestPeriodDescription={investment.plan.interestPeriodDescription}
                pendingCapitalToFund={pendingCapitalToFund}
                referenceNumber={referenceNumber}
              />
            </InvestmentDetailContainer>
          ) : (
            <>
              <Divider size={theme.spacing(3)} />
              <TabsWrapper>
                <MuiTabs
                  className={classes.tabs}
                  value={selectedTab.index}
                  onChange={handleChangeTab}
                  aria-label={intl.formatMessage({ id: 'screen.depositRequests.title' }) as string}
                  scrollButtons={false}
                  variant="scrollable"
                >
                  {tabs.map((tab, index) => (
                    <Tab
                      key={`${tab?.tab}${index}`}
                      label={intl.formatMessage({
                        id: `screen.investmentDetail.tab${index + 1}.tab`,
                      })}
                      id={`investment-details-tab-${tab?.tab}`}
                      aria-controls={`investment-details-tabpanel-${tab?.tab}`}
                    />
                  ))}
                </MuiTabs>
                <Divider size={theme.spacing(3)} />
                <TabContentWrapper>{tabs[selectedTab.index]?.content}</TabContentWrapper>
              </TabsWrapper>
            </>
          )}
        </InvestmentDetailContainer>
      </MainContainer>
    </Grid>
  )
}

export default TreasurerInvestmentDetails
