import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const Wrapper = styled.div`
  padding: ${theme.spacing(4, 5)};
  height: 100%;
  width: 100%;
  background-color: ${theme.extraBackgrounds.paper02};
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(2)};
  }
`
