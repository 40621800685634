import Router from 'router'
import { Provider, useSelector } from 'react-redux'
import store, { GlobalState } from 'redux/store'
import { translationMessages } from './i18n'
import LangLayout from 'components/common/LangLayout'
import { theme } from 'screens/App/theme'
import AlertContext from 'hooks/AlertContext'
import { CssBaseline, ThemeProvider } from '@mui/material'

function App() {
  const lang: string = useSelector((state: GlobalState) => state.app.language)

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <LangLayout messages={translationMessages} screenProps={lang}>
            <AlertContext>
              <Router />
            </AlertContext>
          </LangLayout>
        </Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
