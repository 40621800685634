import { Typography } from '@mui/material'
import Card from 'components/common/Card'
import { CardContainer, CardHeader } from 'components/common/Card/styled'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './styled'
import { TableDetailsWrapper } from '../styled'
import PlanDescriptionItem from 'components/PlanDescriptionItem'
import { useQuery } from '@apollo/client'
import { GET_BANK_ACCOUNT } from '../queries'

const BankAccountTab: FunctionComponent = () => {
  const classes = useStyles()

  const { data } = useQuery(GET_BANK_ACCOUNT)

  const mailto = () => {
    window.open('mailto:support@bluedotcapitals.com', '_blank')
  }

  return (
    <Card>
      <CardContainer>
        <CardHeader>
          <Typography variant="h4">
            <FormattedMessage id="screen.profile.tab1.tab" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="screen.profile.tab1.description"
              values={{
                email: <FormattedMessage id="constants.supportEmail" />,
                styled: (chunks) => (
                  <Typography
                    className={classes.link}
                    onClick={mailto}
                    variant="body2"
                    component="span"
                  >
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </CardHeader>
        <TableDetailsWrapper>
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab1.bankName"
            attributeValue={data?.wireBankAccount?.bankName}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab1.accountNumber"
            attributeValue={data?.wireBankAccount?.accountNumber}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab1.routingNumber"
            attributeValue={data?.wireBankAccount?.routingNumber}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab1.preferredMethod"
            attributeValue={
              data?.wireBankAccount?.preferredMethod === 'WIRE_TRANSFER' ? 'Wire Transfer' : 'ACH'
            }
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab1.beneficiary"
            attributeValue={data?.wireBankAccount?.beneficiary}
            attributeValueId=""
          />
          {data?.wireBankAccount?.countryKey === 'MX' && (
            <PlanDescriptionItem
              textAttributeWrapperProps={{
                justifyContent: 'flex-start',
              }}
              attributeName="screen.profile.tab1.swiftCode"
              attributeValue={data?.wireBankAccount?.swiftCode}
              attributeValueId=""
            />
          )}
        </TableDetailsWrapper>
      </CardContainer>
    </Card>
  )
}

export default BankAccountTab
