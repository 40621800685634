import { FunctionComponent, useState } from 'react'
import { DataGridExtraHeader, DataGridWrapper, useStyles } from './styled'
import { Investment, InvestmentStatus } from 'types'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import DataGrid from 'components/common/DataGrid'
import { useNavigate } from 'react-router-dom'
import { columnsHomeInvestments } from 'components/common/DataGrid/utils'

interface Props {
  investments: Investment[]
  isLoading?: boolean
  disableOnRowClick?: boolean
}

const PAGE_SIZE = 7

const InvestmentDataGrid: FunctionComponent<Props> = ({
  investments,
  isLoading = false,
  disableOnRowClick,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const columns = columnsHomeInvestments()
  const [page, setPage] = useState(0)

  const formattedInvestments = investments.map((investment, index) => ({
    ...investment,
    id: index + 1,
  }))

  const ExtraHeader = () => {
    return (
      <DataGridExtraHeader>
        <Typography variant="body3" className={classes.white}>
          <FormattedMessage id="component.investmentDataGrid.listOfInvestments" />
        </Typography>
      </DataGridExtraHeader>
    )
  }

  const handleSelectedRow = (row: Investment) => {
    if (disableOnRowClick) return
    if (row.status === InvestmentStatus.draft) {
      navigate(`/new-investment/${row?.uuid}`)
      return
    }
    navigate(`/investment-detail/${row?.uuid}`)
  }

  return (
    <DataGridWrapper>
      <DataGrid
        columns={columns}
        rows={formattedInvestments}
        isLoading={isLoading}
        disableColumnMenu
        extraHeader={<ExtraHeader />}
        onRowClick={(row) => handleSelectedRow(row?.row)}
        autoHeight
        pagination
        page={page}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        rowCount={investments.length}
        onPageChange={(newPage: number) => setPage(newPage)}
        activeSorting={true}
      />
    </DataGridWrapper>
  )
}

export default InvestmentDataGrid
