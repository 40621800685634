import React, { FunctionComponent } from 'react'
import { Snackbar as MuiSnackbar, Typography, useMediaQuery } from '@mui/material'
import closeIcon from 'assets/close.svg'
import { FormattedMessage } from 'react-intl'
import { theme } from 'screens/App/theme'

interface Props {
  open: boolean
  onClose: () => void
  messageId?: string // For formatted message
  messageText?: string //For plain text
  messageValues?: any
}

const Snackbar: FunctionComponent<Props> = ({
  messageId,
  messageText,
  messageValues,
  open,
  onClose,
}: Props) => {
  const isBreakpointUpSm = useMediaQuery(theme.breakpoints.up('sm'))

  const action = (
    <React.Fragment>
      <img src={closeIcon} alt="Close icon" onClick={onClose} />
    </React.Fragment>
  )
  const messageContent = (
    <Typography variant="body3">
      {messageText ? messageText : <FormattedMessage id={messageId} values={messageValues} />}
    </Typography>
  )
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: isBreakpointUpSm ? 'right' : 'center' }}
      open={open}
      message={messageContent}
      key={messageId ? messageId : messageText}
      action={action}
      autoHideDuration={6000}
      onClose={onClose}
    />
  )
}

export default Snackbar
