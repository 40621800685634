import { DEFAULT_LANGUAGE } from 'i18n'
import { FunctionComponent, ReactNode } from 'react'
import { IntlProvider } from 'react-intl'

interface Props {
  children: ReactNode
  messages: any
  screenProps?: string
}
const LangLayout: FunctionComponent<Props> = ({ children, messages, screenProps }: Props) => {
  const defaultLocale = screenProps || DEFAULT_LANGUAGE
  return (
    <IntlProvider
      key={defaultLocale}
      locale={defaultLocale}
      messages={messages[defaultLocale]}
      onError={() => {
        // TODO Handler Intl error
      }}
    >
      {children}
    </IntlProvider>
  )
}

export default LangLayout
