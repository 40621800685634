import { FunctionComponent, useContext } from 'react'
import { Button, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import useStyles, {
  HeaderWrapper,
  CircularIconContainer,
  Row,
  MainContainer,
  ListContainer,
  InviteInvestorContainer,
  ButtonContainer,
} from './styled'
import { useTheme } from '@mui/material'
import { ReactComponent as PartnershipIcon } from 'assets/partnership.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { AlertContext } from 'hooks/AlertContext'

type InvestmentsReferralsItem = {
  name: string
  activeInvestments: number
}
interface Props {
  investors: InvestmentsReferralsItem[]
}

const InvestorsList: FunctionComponent<Props> = ({ investors }: Props) => {
  const { user } = useAuth0()
  const { showSnackbar } = useContext(AlertContext)

  const classes = useStyles()
  const intl = useIntl()
  const referralCode = user?.user_metadata?.referralCode || ''
  const theme = useTheme()
  const url = intl.formatMessage(
    { id: 'app.component.inviteInvestorsModal.url.full' },
    { origin: window.location.origin, referralCode },
  )

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
    showSnackbar({
      messageId: 'app.component.inviteInvestorsModal.copyUrl',
    })
  }

  return (
    <MainContainer className={classes.mainGrid}>
      <ListContainer className={classes.listContainer}>
        {investors.map(({ name, activeInvestments }, index) => (
          <Row key={index}>
            <Typography variant="body1" color={theme.typographyColor.primaryText}>
              {name}
            </Typography>
            <Typography variant="body1" color={theme.typographyColor.secondaryText}>
              <FormattedMessage id="screen.agent.myInvestors.row" values={{ activeInvestments }} />
            </Typography>
          </Row>
        ))}
      </ListContainer>
      <InviteInvestorContainer className={classes.inviteInvestorContainer}>
        <HeaderWrapper>
          <CircularIconContainer className={classes.row}>
            <PartnershipIcon />
          </CircularIconContainer>
          <Typography
            color={theme.typographyColor.secondaryText}
            variant="body3"
            className={classes.row}
          >
            <FormattedMessage id="app.component.inviteInvestorsModal.shareText" />
          </Typography>
          <Typography variant="body1" color="white" className={classes.row}>
            {url}
          </Typography>
        </HeaderWrapper>
        <ButtonContainer>
          <Button className={classes.copyButton} fullWidth variant="outlined" onClick={handleCopy}>
            <FormattedMessage id="app.component.inviteInvestorsModal.copyLink.button" />
          </Button>
        </ButtonContainer>
      </InviteInvestorContainer>
    </MainContainer>
  )
}

export default InvestorsList
