import { FunctionComponent, ReactNode } from 'react'
import Appbar from '../components/Appbar'
import { HomeLayoutChildrenContent } from './styled'

interface Props {
  children: ReactNode
}

const HomeLayout: FunctionComponent<Props> = ({ children }: Props) => {
  return (
    <>
      <Appbar />
      <HomeLayoutChildrenContent>{children}</HomeLayoutChildrenContent>
    </>
  )
}

export default HomeLayout
