import { gql } from '@apollo/client'

export const CREATE_INVESTMENT = gql`
  mutation CreateInvestment(
    $investmentUuid: String
    $planUuid: String!
    $amount: Float!
    $contract: ContractInput!
    $memorandum: MemorandumInput!
    $bankAccount: BankAccountInput!
    $depositType: DepositTypeInput!
  ) {
    createInvestment(
      investmentUuid: $investmentUuid
      planUuid: $planUuid
      amount: $amount
      contract: $contract
      memorandum: $memorandum
      bankAccount: $bankAccount
      depositType: $depositType
    ) {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
        interestPeriodDescription
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
    }
  }
`

export const GET_DOCUSIGN_CONTRACT_URL = gql`
  mutation generateDocuSignUrl($planUuid: String!, $amount: Int!, $investmentUuid: String) {
    generateDocuSignUrl(planUuid: $planUuid, amount: $amount, investmentUuid: $investmentUuid) {
      envelopeId
      url
      signUrl
    }
  }
`

export const GET_DOCUSIGN_MEMORANDUM_URL = gql`
  mutation generateDocuSignUrl($planUuid: String!, $amount: Int!, $investmentUuid: String) {
    generateDocuSignUrl(
      planUuid: $planUuid
      amount: $amount
      isMemorandum: true
      investmentUuid: $investmentUuid
    ) {
      envelopeId
      url
      signUrl
    }
  }
`

export const GET_DOCUSIGN_STATUS = gql`
  query isDocusignDocumentCompleted($envelopeId: String!) {
    isDocusignDocumentCompleted(envelopeId: $envelopeId)
  }
`

export const SAVE_DRAFT_INVESTMENT = gql`
  mutation saveDraftInvestment(
    $investmentUuid: String
    $planUuid: String!
    $initialAmount: Float!
    $memorandum: MemorandumInput
    $contract: ContractInput
  ) {
    saveDraftInvestment(
      investmentUuid: $investmentUuid
      planUuid: $planUuid
      initialAmount: $initialAmount
      memorandum: $memorandum
      contract: $contract
    ) {
      uuid
    }
  }
`

export const GET_INVESTMENT = gql`
  query Investment($uuid: String!) {
    investment(uuid: $uuid) {
      uuid
      status
      isMonthlyWithdrawal
      initialAmount
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
        closedDate
      }
      contract {
        id
        url
      }
      memorandum {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
        interestPeriodDescription
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
          createdAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
    }
  }
`
