import React, { FunctionComponent, ReactNode } from 'react'
import Appbar from './components/Appbar'
import { Box } from '@mui/material'
import { AgentLayoutChildrenContent, useStyles } from './components/Appbar/styled'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <Appbar />
      <AgentLayoutChildrenContent height={'auto'}>{children}</AgentLayoutChildrenContent>
    </Box>
  )
}

export default Layout
