import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme as appTheme } from '../../../screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  drawer: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '310px',
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      boxSizing: 'border-box',
      backgroundColor: theme.extraBackgrounds.paper01,
    },
  },
}))

interface buttonProps {
  isSelected?: boolean
}

export const Container = styled.div`
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  height: calc(100vh - 80px);
  padding-top: 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
`

export const Button = styled.div<buttonProps>`
  width: 100%;
  height: 56px;
  line-height: 24px;
  padding: 16px 24px;
  margin-bottom: 4px;
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.isSelected && appTheme.extraBackgrounds.selectedItem};
  color: ${(props) =>
    props.isSelected ? appTheme.palette.primary.main : appTheme.typographyColor.secondaryText};

  :hover {
    background-color: ${appTheme.extraBackgrounds.selectedItem};
    color: ${appTheme.palette.primary.main};
  }
`

export const Dot = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 20px;
  background-color: #ff9999;
  text-align: center;
  line-height: 24px;
  color: ${appTheme.extraBackgrounds.paper01};
`
export const CloseIconWrapper = styled.img`
  cursor: pointer;
`

export const DrawerHeader = styled.div` 
  display: 'flex',
  alignItems: 'center',
  padding: ${appTheme.spacing(0, 1)},
  justifyContent: 'flex-end',
`
