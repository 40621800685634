import { FunctionComponent } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { MainContainer } from './styled'
import { theme } from 'screens/App/theme'

const emptyColor = theme.extraBackgrounds.paper01

ChartJS.register(ArcElement, Tooltip, Legend)

const setValueAsBoolean = (defaultValue: boolean, value?: boolean) =>
  typeof value === 'boolean' ? value : defaultValue

const getChartsOptions = (args: {
  responsive?: boolean
  maintainAspectRatio?: boolean
  cutout?: number
  plugins?: { tooltip?: { enabled?: boolean }; legend?: { display?: boolean } }
}) => {
  const { responsive, maintainAspectRatio, cutout, plugins } = args
  const { tooltip, legend } = plugins || {}
  const { enabled } = tooltip || {}
  const { display } = legend || {}

  return {
    responsive: setValueAsBoolean(true, responsive),
    maintainAspectRatio: setValueAsBoolean(false, maintainAspectRatio),
    plugins: {
      tooltip: {
        enabled: setValueAsBoolean(false, enabled),
      },
      legend: {
        display: setValueAsBoolean(false, display),
      },
    },
    cutout: cutout || 60,
  }
}

interface Props {
  data: number[]
  colors: string[]
  width?: string
  height?: string
  cutout?: number
  responsive?: boolean
  maintainAspectRatio?: boolean
  plugins?: { tooltip?: { enabled?: boolean }; legend?: { display?: boolean } }
}

const CustomDoughnutChart: FunctionComponent<Props> = ({
  data,
  colors,
  width,
  height,
  cutout,
  responsive,
  maintainAspectRatio,
  plugins,
}: Props) => {
  const chartsOptions = getChartsOptions({ responsive, maintainAspectRatio, plugins, cutout })
  const isEmptyData = data.every((value) => value === 0)
  const datasetsColors = isEmptyData ? [emptyColor, emptyColor] : colors
  const customData = {
    datasets: [
      {
        type: 'doughnut' as const,
        data: isEmptyData ? [100, 0] : data,
        backgroundColor: datasetsColors,
        borderColor: datasetsColors,
        borderWidth: 1,
      },
    ],
  }

  return (
    <MainContainer {...{ width, height }}>
      <Doughnut data={customData} options={chartsOptions} />
    </MainContainer>
  )
}

export default CustomDoughnutChart
