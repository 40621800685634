import {
  Button,
  Grid,
  Typography,
  Tab,
  Tabs as MuiTabs,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  useStyles,
  MainContainer,
  OverviewContainerColumn,
  InvestmentDetailContainer,
  TopTitleButtonContainer,
  ButtonsContainer,
  TabsWrapper,
  TabContentWrapper,
} from './styled'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GlobalState } from 'redux/store'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import { InvestmentDetailsTab } from 'global/types'
import { DetailTab, changeTab } from './reducer'
import { FunctionComponent, useEffect, useState, Fragment, useContext } from 'react'
import InvestmentOverview from './InvestmentOverview'
import { useMutation, useQuery } from '@apollo/client'
import { Investment, InvestmentStatus, Transaction, TransactionTypeNames, Withdrawal } from 'types'
import { useParams } from 'react-router-dom'
import OverviewTab from './OverviewTab'
import StatementsTab from './StatementsTab'
import WithdrawalsTab from './WithdrawalsTab'
import DepositsTab from './DepositsTab'
import { filter } from 'lodash'
import Empty from './Empty'
import FinishInvestmentModal from './FinishInvestment'
import { CLOSE_INVESTMENT, GET_INVESTMENT } from './queries'
import { AlertContext } from 'hooks/AlertContext'
import ScheduleWithdrawalsTab from './ScheduledWithdrawalsTab'
import { WithdrawalsMenuContainer } from './WithdrawalsMenu'
import DisabledButtonWrapperWithEvent from 'components/common/DisabledButtonWrapperWithEvent'

const snackbarMessagesIds = {
  investmentPlanDoesNotAllowDeposits: 'screen.investmentDetail.snackbar.error.noDepositAllowed',
  investmentPlanDoesNotAllowCloseInvestment:
    'screen.investmentDetail.snackbar.error.noCloseInvestmentAllowed',
  investmentPlanDoesNotAllowWithdrawals:
    'screen.investmentDetail.snackbar.error.noRegularWithdrawalAllowed',
  investmentPlanDoesNotAllowScheduleWithdrawals:
    'screen.investmentDetail.snackbar.error.noScheduledWithdrawalAllowed',
  investmentHasPendingDeposits: 'screen.investmentDetail.snackbar.error.activePendingDeposit',
}

const InvestmentDetail: FunctionComponent = () => {
  const { uuid = '' } = useParams()
  const classes = useStyles()
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showSnackbar } = useContext(AlertContext)

  const [hasPendingDeposits, setHasPendingDeposits] = useState(false)
  const [alreadyScheduledWithdrawal, setAlreadyScheduledWithdrawal] = useState(false)
  const selectedTab: DetailTab = useSelector((state: GlobalState) => state.investmentDetails)
  const [investment, setInvestment] = useState<Investment>()
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const { data, refetch, loading } = useQuery<{ investment: Investment }, { uuid: string }>(
    GET_INVESTMENT,
    {
      fetchPolicy: 'network-only',
      variables: {
        uuid,
      },
    },
  )

  const [closeInvestment, { loading: loadingCloseInvestment }] = useMutation<
    { closeInvestment: Investment },
    { investmentUuid: string }
  >(CLOSE_INVESTMENT)

  const pendingCapitalToFund = investment?.transactions[0]?.amount || 0
  const referenceNumber = investment?.transactions[0]?.reference || 0
  const transactions = investment?.transactions
  const showEmpty =
    transactions &&
    transactions.length === 1 &&
    transactions[0].status !== 'ACTIVE' &&
    transactions[0].__typename === 'Deposit'

  const disabledCloseInvestment =
    hasPendingDeposits || investment?.isInLockupPrincipal
      ? investment?.plan.earlyCancellationAllowed
      : false

  useEffect(() => {
    if (data?.investment) {
      const investmentData = data.investment as Investment
      setInvestment(investmentData)
      setAlreadyScheduledWithdrawal(
        Boolean(investmentData?.scheduledWithdrawals?.some((sw) => sw.status === 'ACTIVE')),
      )
      setHasPendingDeposits(
        investmentData?.transactions.some(
          (transaction) => transaction.status === 'PENDING' && transaction.__typename === 'Deposit',
        ),
      )
    }
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(changeTab(0))
    }
  }, [])

  const refetchData = async () => {
    try {
      await refetch()
    } catch {
      // TODO Error handler
    }
  }

  const handleFinishInvestment = async () => {
    if (!investment?.uuid) return
    try {
      await closeInvestment({
        variables: {
          investmentUuid: investment?.uuid,
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setOpenModal(false)
      refetchData()
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
  }

  const handleRedirectToWithdrawalRequest = () => {
    navigate(`/withdrawal-request/${uuid}`)
  }

  const handleRedirectToScheduleWithdrawal = () => {
    navigate(`/new-schedule-withdrawal/${uuid}`)
  }

  const handleNewDepositRequest = () => {
    navigate(`/new-deposit-request/${uuid}`)
  }

  const handleClickDisabledCloseInvestment = () => {
    if (hasPendingDeposits) {
      showSnackbar({ messageId: snackbarMessagesIds.investmentHasPendingDeposits })
      return
    }
    if (!investment?.isInLockupPrincipal) return
    if (investment?.plan.earlyCancellationAllowed) return
    showSnackbar({ messageId: snackbarMessagesIds.investmentPlanDoesNotAllowCloseInvestment })
  }

  const handleClickDisabledDeposit = () => {
    if (!investment?.plan.depositAllowed) {
      showSnackbar({ messageId: snackbarMessagesIds.investmentPlanDoesNotAllowDeposits })
    }
  }

  const handleOpenFinishInvestmentModal = () => {
    setOpenModal(true)
  }

  const tabs = [
    {
      tab: InvestmentDetailsTab.OVERVIEW,
      content: <OverviewTab investment={investment} />,
    },
    {
      tab: InvestmentDetailsTab.STATEMENTS,
      content: <StatementsTab investment={investment} />,
    },
    // TODO: Content for this tabs
    // {
    //   tab: InvestmentDetailsTab.ALL_ACTIVITY,
    //   content: <div>{/* TODO: All Activity tab component */}</div>,
    // },
    {
      tab: InvestmentDetailsTab.DEPOSITS,
      content: (
        <div>
          <DepositsTab
            deposits={
              (filter(investment?.transactions || [], {
                __typename: 'Deposit',
              }) as unknown as Transaction[]) || []
            }
            investmentUuid={investment?.uuid}
            refetchInvestment={refetchData}
          />
        </div>
      ),
    },
    {
      tab: InvestmentDetailsTab.WITHDRAWALS,
      content: (
        <div>
          <WithdrawalsTab
            withdrawals={
              (filter(investment?.transactions || [], {
                __typename: 'Withdrawal',
              }) as unknown as Array<Withdrawal>) || []
            }
            investmentUuid={investment?.uuid}
            refetchInvestment={refetchData}
          />
        </div>
      ),
    },
    {
      tab: InvestmentDetailsTab.SCHEDULE_WITHDRAWALS,
      content: (
        <ScheduleWithdrawalsTab
          investmentUuid={investment?.uuid}
          scheduledWithdrawals={investment?.scheduledWithdrawals || []}
          refetchInvestment={refetchData}
        />
      ),
    },
    // {
    //   tab: InvestmentDetailsTab.INTEREST_EARNED,
    //   content: <div>{/* TODO: Interest Earned tab component */}</div>,
    // },
  ]

  return (
    <Grid container className={classes.mainGrid} direction="column" alignItems="center">
      <MainContainer>
        {!isResponsive && (
          <OverviewContainerColumn>
            <InvestmentOverview investment={investment} loading={loading} />
          </OverviewContainerColumn>
        )}
        {showEmpty ? (
          <InvestmentDetailContainer>
            <TopTitleButtonContainer>
              <Typography variant="h3">
                <FormattedMessage id="screen.investmentDetail.title" />
              </Typography>
            </TopTitleButtonContainer>
            <Empty
              pendingCapitalToFund={pendingCapitalToFund}
              referenceNumber={referenceNumber}
              interestPeriodDescription={investment.plan.interestPeriodDescription}
            />
          </InvestmentDetailContainer>
        ) : (
          <InvestmentDetailContainer className={classes.investmentDetailContainer}>
            <TopTitleButtonContainer className={classes.topTitleContainer}>
              <Typography variant="h3">
                <FormattedMessage id="screen.investmentDetail.title" />
              </Typography>
              {!loading &&
              investment?.status !== InvestmentStatus.closed &&
              investment?.status !== InvestmentStatus.closing ? (
                <ButtonsContainer className={classes.buttonsContainer}>
                  <DisabledButtonWrapperWithEvent onClick={handleClickDisabledCloseInvestment}>
                    <Button
                      variant="outlined"
                      onClick={handleOpenFinishInvestmentModal}
                      disabled={disabledCloseInvestment}
                      className={classes.button}
                      fullWidth
                    >
                      <FormattedMessage id="screen.investmentDetail.action03.finishInvestment" />
                    </Button>
                  </DisabledButtonWrapperWithEvent>
                  <WithdrawalsMenuContainer
                    onClickRequestWithdrawal={handleRedirectToWithdrawalRequest}
                    onClickScheduleWithdrawal={handleRedirectToScheduleWithdrawal}
                    investment={investment}
                    activeScheduleWithdrawal={alreadyScheduledWithdrawal}
                  />
                  <DisabledButtonWrapperWithEvent onClick={handleClickDisabledDeposit}>
                    <Button
                      variant="contained"
                      onClick={handleNewDepositRequest}
                      className={classes.button}
                      disabled={!investment?.plan.depositAllowed}
                    >
                      <FormattedMessage id="screen.investmentDetail.action02.newDeposit" />
                    </Button>
                  </DisabledButtonWrapperWithEvent>
                </ButtonsContainer>
              ) : null}
            </TopTitleButtonContainer>
            {isResponsive && (
              <Fragment>
                <Divider size={theme.spacing(4)} />
                <InvestmentOverview investment={investment} loading={loading} />
              </Fragment>
            )}
            <Divider size={theme.spacing(3)} />
            <TabsWrapper>
              <MuiTabs
                className={classes.tabs}
                value={selectedTab.index}
                onChange={handleChangeTab}
                aria-label={intl.formatMessage({ id: 'screen.depositRequests.title' }) as string}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={`${tab?.tab}${index}`}
                    label={intl.formatMessage({
                      id: `screen.investmentDetail.tab${index + 1}.tab`,
                    })}
                    id={`investment-details-tab-${tab?.tab}`}
                    aria-controls={`investment-details-tabpanel-${tab?.tab}`}
                  />
                ))}
              </MuiTabs>
              <Divider size={theme.spacing(3)} />
              <TabContentWrapper>{tabs[selectedTab.index]?.content}</TabContentWrapper>
            </TabsWrapper>
          </InvestmentDetailContainer>
        )}
      </MainContainer>
      <FinishInvestmentModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleClickFinish={handleFinishInvestment}
        loading={loadingCloseInvestment}
        pendingWithdrawals={transactions?.filter(
          (transaction) =>
            transaction.__typename === TransactionTypeNames.withdrawal &&
            transaction.canBeCancelable &&
            transaction.status === 'PENDING',
        )}
      />
    </Grid>
  )
}

export default InvestmentDetail
