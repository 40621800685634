import { FunctionComponent } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import ChartCard from 'components/cards/ChartCard'
import { Moment } from 'moment'
import { ChartViewMainContainer } from '../styled'
import { useIntl } from 'react-intl'
import times from 'lodash/times'
import { getChartData, getOptions } from './utils'
import { useMediaQuery, useTheme } from '@mui/material'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

enum TitlesTypes {
  BALANCE_SHEET_ASSET,
  LIABILITIES,
  GROSS_MARGIN_CUMMULATIVE,
}

interface Props {
  lineData: number[]
  barData1: number[]
  barData2: number[]
  labels: string[]
  selectedPeriod: { name: string; startDate: Moment | null; endDate: Moment }
  onChangePeriod: (name: string, startDate: Moment | null, endDate: Moment) => void
}

const getTitleWidth = (value: number) => {
  if (value === TitlesTypes.BALANCE_SHEET_ASSET) return '35%'
  if (value === TitlesTypes.LIABILITIES) return '17%'
  if (value === TitlesTypes.GROSS_MARGIN_CUMMULATIVE) return '48%'
  return undefined
}

const getLeftContainerWidth = (setFirsthWidth: boolean, setSecondWidth: boolean) => {
  if (setFirsthWidth) return '55%'
  if (setSecondWidth) return '60%'
  return '40%'
}

const ChartView: FunctionComponent<Props> = ({
  lineData,
  barData1,
  barData2,
  labels,
  selectedPeriod,
  onChangePeriod,
}: Props) => {
  const theme = useTheme()
  const setFirsthWidth = useMediaQuery(theme.breakpoints.between(1179, 1459))
  const setSecondWidth = useMediaQuery(theme.breakpoints.down(1179))
  const intl = useIntl()
  const chartsOptions = getOptions(intl)
  const leftContainerWidth = getLeftContainerWidth(setFirsthWidth, setSecondWidth)
  const data = getChartData({
    lineData,
    barData1,
    barData2,
    intl,
    labels,
  })

  return (
    <ChartViewMainContainer>
      <ChartCard
        leftContainerProps={{
          width: leftContainerWidth,
        }}
        chooseOptionsToShow={{
          showAll: true,
          showAYear: true,
          showCurrentMonth: false,
          showThreeMonths: true,
          showSixMonths: true,
        }}
        titles={times(3, (value) => ({
          color: `variant0${value + 1}` as 'variant01' | 'variant02' | 'variant03',
          text: intl.formatMessage({
            id: `components.dashboard.chartView.chart.label${value + 1}`,
          }),
          width: getTitleWidth(value),
        }))}
        selectedPeriod={selectedPeriod}
        onChangePeriod={onChangePeriod}
        chart={<Chart type="line" data={data} options={chartsOptions} />}
        colorLineStyleProps={{ height: '12px', width: '24px' }}
      />
    </ChartViewMainContainer>
  )
}
export default ChartView
