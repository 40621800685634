import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    minHeight: 'calc(100vh - 80px)',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  centerContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
}))

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const InvestorsRequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6, 7)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing(4)};
`
export const CenterContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(0, 5)};
  width: 100%;
`

export default useStyles
