import { FunctionComponent, ReactElement } from 'react'
import {
  useStyles,
  Wrapper,
  TopContainer,
  ChartContainer,
  LeftContainer,
  RightContainer,
  PeriodButton,
  LeftContainerProps,
} from './styled'
import TitleAmount from 'components/TitleAmount'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import MultitypeChart from 'components/charts/MultitypeChart'
import { ColorLineProps } from 'components/TitleAmount/styled'

enum PeriodsOptions {
  CURRENT_MONTH = 'actualMonth',
  THREE_MONTHS = '3months',
  SIX_MONTHS = '6months',
  A_YEAR = '1year',
  ALL = 'all',
}

const { CURRENT_MONTH, THREE_MONTHS, SIX_MONTHS, A_YEAR, ALL } = PeriodsOptions

interface TitleData {
  color: 'success' | 'error' | 'warning' | 'variant01' | 'variant02' | 'variant03'
  text: string
  amount?: number
  initialAmount?: number
  width?: string
}

interface DataSet {
  backgroundColor: string
  data: number[]
  label: string
  title: string
}

interface Period {
  name: string
  startDate: Moment | null
  endDate: Moment
}

interface Props {
  chart?: ReactElement
  titles: TitleData[]
  selectedPeriod: Period
  chartContainerProps?: {
    width?: string
    height?: string
  }
  multiTypeChartProps?: {
    chartLabels: string[]
    chartDataLine: DataSet
    chartDataBar: DataSet
  }
  colorLineStyleProps?: ColorLineProps
  chooseOptionsToShow: {
    showThreeMonths: boolean
    showCurrentMonth: boolean
    showSixMonths: boolean
    showAYear: boolean
    showAll: boolean
  }
  leftContainerProps?: LeftContainerProps
  onChangePeriod: (name: string, startDate: Moment | null, endDate: Moment) => void
}

const LOWEST_DATE = moment('01-01-2000')

interface ChartComponentProps {
  chart?: ReactElement
  multiTypeChartProps?: {
    chartLabels: string[]
    chartDataLine: DataSet
    chartDataBar: DataSet
  }
}

const ChartComponent: FunctionComponent<ChartComponentProps> = ({
  chart,
  multiTypeChartProps,
}: ChartComponentProps) => {
  if (chart && !multiTypeChartProps) return chart
  if (multiTypeChartProps && !chart) {
    const { chartDataBar, chartDataLine, chartLabels } = multiTypeChartProps
    return <MultitypeChart labels={chartLabels} bar={chartDataBar} line={chartDataLine} />
  }
  return null
}

const ChartCard: FunctionComponent<Props> = ({
  chart,
  titles,
  selectedPeriod,
  chartContainerProps,
  multiTypeChartProps,
  colorLineStyleProps,
  chooseOptionsToShow,
  leftContainerProps,
  onChangePeriod,
}: Props) => {
  const classes = useStyles()
  const { width: leftContainerWidth } = leftContainerProps || {}
  const { width, height } = chartContainerProps || {}
  const currentEndOfMonth = moment().endOf('month').endOf('day')
  const { name: period } = selectedPeriod
  const { showAYear, showAll, showCurrentMonth, showSixMonths, showThreeMonths } =
    chooseOptionsToShow || {}

  return (
    <Wrapper>
      <TopContainer className={classes.topContainer}>
        <LeftContainer width={leftContainerWidth} className={classes.responsiveFullWidth}>
          {titles.map(({ text, color, amount, width }) => (
            <TitleAmount
              {...{ colorLineStyleProps, width }}
              titleColor="white"
              key={text}
              color={color}
              title={text}
              amount={amount}
            />
          ))}
        </LeftContainer>
        <RightContainer className={classes.rightContainer}>
          {showCurrentMonth && (
            <PeriodButton
              isSelected={period === CURRENT_MONTH}
              onClick={() =>
                onChangePeriod(CURRENT_MONTH, moment().startOf('month'), currentEndOfMonth)
              }
            >
              <Typography variant="body3">{moment().format('MMM YYYY')}</Typography>
            </PeriodButton>
          )}
          {showThreeMonths && (
            <PeriodButton
              isSelected={period === THREE_MONTHS}
              onClick={() =>
                onChangePeriod(
                  THREE_MONTHS,
                  moment().startOf('month').subtract(2, 'months'),
                  currentEndOfMonth,
                )
              }
            >
              <Typography variant="body3">
                <FormattedMessage id="screen.investmentDetail.tab1.3months" />
              </Typography>
            </PeriodButton>
          )}
          {showSixMonths && (
            <PeriodButton
              isSelected={period === SIX_MONTHS}
              onClick={() =>
                onChangePeriod(
                  SIX_MONTHS,
                  moment().startOf('month').subtract(5, 'months'),
                  currentEndOfMonth,
                )
              }
            >
              <Typography variant="body3">
                <FormattedMessage id="screen.investmentDetail.tab1.6months" />
              </Typography>
            </PeriodButton>
          )}
          {showAYear && (
            <PeriodButton
              isSelected={period === A_YEAR}
              onClick={() =>
                onChangePeriod(
                  A_YEAR,
                  moment().startOf('month').subtract(1, 'years'),
                  currentEndOfMonth,
                )
              }
            >
              <Typography variant="body3">
                <FormattedMessage id="screen.investmentDetail.tab1.1year" />
              </Typography>
            </PeriodButton>
          )}
          {showAll && (
            <PeriodButton
              isSelected={period === ALL}
              onClick={() => onChangePeriod(ALL, LOWEST_DATE, currentEndOfMonth)}
            >
              <Typography variant="body3">
                <FormattedMessage id="screen.investmentDetail.tab1.all" />
              </Typography>
            </PeriodButton>
          )}
        </RightContainer>
      </TopContainer>
      <ChartContainer {...{ width, height }}>
        <ChartComponent {...{ chart, multiTypeChartProps }} />
      </ChartContainer>
    </Wrapper>
  )
}

export default ChartCard
