import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  iconComponent: {
    top: 0,
  },
}))

interface FlexContainerProps {
  backgroundColor?: string
  flexDirection?: string
  alignItems?: string
  width?: string
  gap?: string
  height?: string
  marginTop?: string
  justifyContent?: string
  padding?: string
  borderBottom?: string
  border?: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(3)};
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    padding: ${theme.spacing(6, 7)};
  }
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const FlexContainer = styled.div`
  background-color: ${({ backgroundColor }: FlexContainerProps) => backgroundColor};
  display: flex;
  flex-direction: ${({ flexDirection }: FlexContainerProps) => flexDirection};
  align-items: ${({ alignItems }: FlexContainerProps) => alignItems};
  width: ${({ width }: FlexContainerProps) => width};
  gap: ${({ gap }: FlexContainerProps) => gap};
  height: ${({ height }: FlexContainerProps) => height};
  margin-top: ${({ marginTop }: FlexContainerProps) => marginTop};
  justify-content: ${({ justifyContent }: FlexContainerProps) => justifyContent};
  padding: ${({ padding }: FlexContainerProps) => padding};
  border-bottom: ${({ borderBottom }: FlexContainerProps) => borderBottom};
  border: ${({ border }: FlexContainerProps) => border};
`

export const DataGridContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`

export default useStyles
