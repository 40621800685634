import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import {
  useStyles,
  MainContainer,
  CenterContainer,
  TopTitleButtonContainer,
  StepperContentContainer,
} from './styled'
import { StepperScheduleWithdrawal, StepsScheduleWithdrawal } from 'global/types'
import { Investment } from 'types'
import StepperCard from 'components/Stepper'
import WithdrawalAmount from './ScheduledWithdrawalAmount'
import WithdrawalConfirmation from './ScheduledWithdrawalConfirmation'
import WithdrawalRequested from './ScheduledWithdrawalRequested'
import { useMutation, useQuery } from '@apollo/client'
import { GET_INVESTMENT } from 'screens/FinishNewInvestment/queries'
import ResponsiveStepperCard from 'components/Stepper/ResponsiveStepper'
import { inputToNumber } from 'utils/MoneyFormat'
import { CREATE_SCHEDULED_WITHDRAWAL_REQUEST, GET_NEXT_MONTH_ESTIMATED_EARNINGS } from './queries'
import { AlertContext } from 'hooks/AlertContext'

const { SCHEDULE_WITHDRAWAL_AMOUNT, SCHEDULE_WITHDRAWAL_CONFIRMATION } = StepperScheduleWithdrawal

const { AMOUNT_STEP, CONFIRMATION_STEP, REQUESTED_STEP } = StepsScheduleWithdrawal

const ScheduledWithdrawalRequest = () => {
  const classes = useStyles()
  const { uuid } = useParams()
  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const { showSnackbar } = useContext(AlertContext)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [disableNextStep, setDisableNextStep] = useState<boolean>(true)
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('0')
  const [investmentFromWithdrawal, setInvestmentFromWithdrawal] = useState<Investment | null>()

  const withdrawalRequestSteps = [SCHEDULE_WITHDRAWAL_AMOUNT, SCHEDULE_WITHDRAWAL_CONFIRMATION]

  const { data } = useQuery(GET_INVESTMENT, {
    variables: {
      uuid,
    },
  })

  const { data: nextMonthEstimatedEarnings } = useQuery<
    { nextMonthEstimatedEarnings: number },
    { investmentUuid: string }
  >(GET_NEXT_MONTH_ESTIMATED_EARNINGS, {
    variables: {
      investmentUuid: uuid || '',
    },
    skip: !uuid,
  })
  const estimatedEarnings = nextMonthEstimatedEarnings?.nextMonthEstimatedEarnings || 0

  const [createScheduledWithdrawalRequest, { loading }] = useMutation<
    { createScheduledWithdrawal: Investment },
    { investmentUuid: string; amount: number }
  >(CREATE_SCHEDULED_WITHDRAWAL_REQUEST, {
    variables: {
      amount: inputToNumber(withdrawalAmount),
      investmentUuid: uuid || '',
    },
  })

  const handleCreateWithdrawalRequest = async () => {
    try {
      const response = await createScheduledWithdrawalRequest()
      const investment = response.data?.createScheduledWithdrawal
      if (investment) {
        setInvestmentFromWithdrawal(investment)
        setActiveStep(REQUESTED_STEP)
      }
    } catch (e: unknown) {
      setInvestmentFromWithdrawal(null)
      setActiveStep(REQUESTED_STEP)
      showSnackbar({
        messageId: 'global.snackbar.error.generalError',
      })
    }
  }

  const handleChangeWithdrawalAmount = (amount: string) => {
    setWithdrawalAmount(amount)
  }

  const changeActiveStep = async (step: number) => {
    if (activeStep === CONFIRMATION_STEP) {
      setActiveStep(step)
      setDisableNextStep(true)
    }
    setActiveStep(step)
  }

  useEffect(() => {
    if (
      activeStep === AMOUNT_STEP &&
      withdrawalAmount !== '0' &&
      inputToNumber(withdrawalAmount) > 100
    ) {
      setDisableNextStep(false)
    } else if (activeStep === CONFIRMATION_STEP) {
      setDisableNextStep(false)
    } else {
      setDisableNextStep(true)
    }

    if (activeStep === CONFIRMATION_STEP) {
      setDisableNextStep(false)
    }
  }, [activeStep, withdrawalAmount])

  return (
    <MainContainer>
      <Grid container className={classes.mainGrid} direction="column" alignItems="center">
        <CenterContainer className={classes.centerContainer}>
          <TopTitleButtonContainer>
            <Typography variant={hideInResponsive ? 'h2' : 'h3'}>
              <FormattedMessage id="screen.scheduleWithdrawalRequest.title" />
            </Typography>
          </TopTitleButtonContainer>
          <StepperContentContainer>
            {hideInResponsive && (
              <StepperCard
                steps={withdrawalRequestSteps}
                activeStep={activeStep}
                widthStepper="423px"
              />
            )}
            {activeStep === AMOUNT_STEP && (
              <WithdrawalAmount
                maximumAmount={estimatedEarnings}
                amount={withdrawalAmount}
                investment={data?.investment}
                handleChangeWithdrawalAmount={handleChangeWithdrawalAmount}
                handleChangeStep={setActiveStep}
              />
            )}
            {activeStep === CONFIRMATION_STEP && (
              <WithdrawalConfirmation
                investment={data?.investment}
                amount={withdrawalAmount}
                maximumAmount={estimatedEarnings}
                handleChangeStep={setActiveStep}
                handleConfirmWithdrawal={handleCreateWithdrawalRequest}
              />
            )}
            {activeStep === REQUESTED_STEP && investmentFromWithdrawal && (
              <WithdrawalRequested investment={investmentFromWithdrawal} />
            )}
          </StepperContentContainer>
        </CenterContainer>
      </Grid>
      {isResponsive && (
        <ResponsiveStepperCard
          steps={withdrawalRequestSteps}
          activeStep={activeStep}
          setActiveStep={changeActiveStep}
          disableNextButton={disableNextStep || loading}
          handleFinishAction={() => handleCreateWithdrawalRequest()}
        />
      )}
    </MainContainer>
  )
}

export default ScheduledWithdrawalRequest
