import { Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'screens/App/theme'
import BankInformationGridItem from '../BankInformationGridItem'
import CopyInformationButton from '../CopyInformationButton'

const INTERNATIONAL_WIRE_TRANSFER_INFO = {
  SWIFT_BIC_CODE: 'CHASUS33',
}

const InternationalTransferGrid = () => {
  const intl = useIntl()
  const DEFAULT_COPY_LABEL = intl.formatMessage({
    id: 'screen.depositRequestInitializated.copy',
  })
  const COPIED_LABEL = intl.formatMessage({
    id: 'screen.depositRequestInitializated.copied',
  })

  return (
    <Grid container>
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.swiftBicCode" />
          </Typography>
        }
        copyButton={
          <CopyInformationButton
            copiedLabel={COPIED_LABEL}
            copyLabel={DEFAULT_COPY_LABEL}
            textToCopy={INTERNATIONAL_WIRE_TRANSFER_INFO.SWIFT_BIC_CODE.toString()}
          />
        }
        value={
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            {INTERNATIONAL_WIRE_TRANSFER_INFO.SWIFT_BIC_CODE}
          </Typography>
        }
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.bank" />
          </Typography>
        }
        value={
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Chase
          </Typography>
        }
      />
      <BankInformationGridItem
        title={
          <Typography variant="body1" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.depositRequestInitializated.bankAddress" />
          </Typography>
        }
        value={
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            4500 BONITA RD, BONITA, CA, 91902
          </Typography>
        }
      />
    </Grid>
  )
}

export default InternationalTransferGrid
