import { gql } from '@apollo/client'

export const QUERY_ME = gql`
  query me {
    me {
      userId
      name
      lastName
      email
      roles
    }
  }
`
