import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    '& .MuiPaper-root': {
      width: '200px',
      justifyContent: 'flex-end',
      backgroundColor: theme.extraBackgrounds.paper05,
      color: theme.typographyColor.primaryText,
      '& li': {
        justifyContent: 'flex-end',
        flexDirection: 'row',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        '& p': {
          display: 'flex',
          alignItems: 'center',
        },
        '& img': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
  toolbar: {
    '& .MuiToolbar-root': {
      padding: 0,
      maxHeight: theme.spacing(6),
      minHeight: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
      maxHeight: theme.spacing(10),
      minHeight: theme.spacing(10),
    },
  },
  emailLabel: {
    color: theme.typographyColor.primaryText,
  },
  typeLabel: {
    color: theme.typographyColor.secondaryText,
  },
  menuIcon: {
    width: 24,
    height: 24,
  },
  textUserContainer: {
    marginRight: theme.spacing(1),
  },
  logoBlueDot: {
    maxHeight: 48,
    marginRight: theme.spacing(2.5),
  },
  leftArrowIcon: {
    maxHeight: 48,
    marginRight: theme.spacing(2.5),
    position: 'relative',
    top: 4.5,
  },
  hideInResponsive: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  referContainerResponsive: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}))

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`

export const LeftAppbar = styled.div``

export const LeftMenu = styled.div`
  display: flex;
  align-content: flex-end;
  flex: 2;
  align-items: center;
`

export const RightAppbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
`

export const ReferBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing(5)};
`

export const MenuIcon = styled.img`
  width: 20;
  height: 20;
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
`

export const UserImageBackground = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  border-radius: 50%;
  background-color: #2d353e;
  cursor: pointer;
`

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const Tab = styled.div`
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.typographyColor.primaryText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

export const TabSelectedAddon = styled.div`
  width: 18px;
  height: 4px;
  margin-top: ${theme.spacing(0.5)};
  background-color: ${theme.palette.primary.main};
`

export const RotatedImage = styled.img`
  transform: rotate(225deg);
`

export const BlueDotLogoIcon = styled.img``

export const GoBackIcon = styled.img`
  transform: rotate(45deg);
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;
`
