import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
  noTabs: {
    marginTop: theme.spacing(5),
  },
  white: {
    color: theme.typographyColor.primaryText,
  },
}))

export const WithdrawalRequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(3)};
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    padding: ${theme.spacing(6, 7)};
    width: calc(100vw - 240px);
  }
  height: 100%;
  overflow-y: auto;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`

export const DataGridHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
`

export const DataGridActionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${theme.spacing(1)};
`
