import { FunctionComponent, useContext } from 'react'
import { useStyles } from './styled'
import { FormattedMessage } from 'react-intl'
import InvestmentDataGrid from 'components/InvestmentDataGrid'
import { Typography, Card, CardContent, Skeleton } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_REFERRALS_INVESTMENTS } from '../queries'
import { ProfileContext } from 'hooks/ProfileContext'

export const MyInvestorsInvestmentsTab: FunctionComponent = () => {
  const { dataQueryMe } = useContext(ProfileContext)
  const userId = dataQueryMe?.me?.userId
  const { data, loading } = useQuery(GET_REFERRALS_INVESTMENTS, { variables: { userId } })
  const referralInvestments = data?.referralsInvestmentsByUserId?.investments || []
  const classes = useStyles()

  if (loading) {
    return (
      <Skeleton variant="rectangular" width="100%" height={600}>
        <CardContent className={classes.card}>
          <InvestmentDataGrid investments={referralInvestments} isLoading={loading} />
        </CardContent>
      </Skeleton>
    )
  }

  return (
    <Card elevation={0} square>
      <CardContent className={classes.card}>
        {referralInvestments.length > 0 ? (
          <InvestmentDataGrid
            disableOnRowClick
            investments={referralInvestments}
            isLoading={loading}
          />
        ) : (
          <Typography variant="body1">
            <FormattedMessage id="components.cards.investmentCard.empty" />
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default MyInvestorsInvestmentsTab
