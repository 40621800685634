import { FunctionComponent } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import { FlexContainer } from '../styled'
import { theme } from 'screens/App/theme'
import times from 'lodash/times'

const paperBackground = theme.extraBackgrounds.paper02

interface SkeletonGroupProps {
  numberSkeletons?: number
  height?: string | number
}

const SkeletonGroup: FunctionComponent<SkeletonGroupProps> = ({
  numberSkeletons,
  height,
}: SkeletonGroupProps) => (
  <Box
    sx={{
      height: 'max-content',
      backgroundColor: paperBackground,
      width: '100%',
    }}
  >
    {[...Array(numberSkeletons || 5)].map((_, index) => (
      <Skeleton variant="rectangular" key={index} sx={{ my: 4, mx: 1 }} height={height || 80} />
    ))}
  </Box>
)

const ScreenSkeleton: FunctionComponent = () => (
  <Grid sx={{ mt: 2 }}>
    <Skeleton
      variant="rectangular"
      sx={{ backgroundColor: paperBackground }}
      width={120}
      height={40}
    />
    <FlexContainer
      alignItems="center"
      flexDirection="column"
      width="100%"
      gap="8px"
      marginTop="8px"
    >
      <FlexContainer width="100%" gap="16px">
        <FlexContainer width="70%" flexDirection="column">
          {times(3, (value) => (
            <FlexContainer key={`cards-group-skeleton-${value}`} width="100%">
              <Skeleton
                variant="rectangular"
                sx={{ mt: 2, mr: 1, backgroundColor: paperBackground }}
                height={102}
                width="50%"
              />
              <Skeleton
                variant="rectangular"
                sx={{ mt: 2, ml: 1, backgroundColor: paperBackground }}
                height={102}
                width="50%"
              />
            </FlexContainer>
          ))}
        </FlexContainer>
        <FlexContainer width="30%" marginTop="16px">
          <SkeletonGroup numberSkeletons={1} height={392} />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width="100%" marginTop="40px" flexDirection="column">
        <Skeleton
          variant="rectangular"
          sx={{ backgroundColor: paperBackground }}
          width={120}
          height={40}
        />
        <Skeleton
          width="100%"
          height={137}
          variant="rectangular"
          sx={{ mt: 2, backgroundColor: paperBackground }}
        />
      </FlexContainer>
    </FlexContainer>
  </Grid>
)

export default ScreenSkeleton
