import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme } from 'screens/App/theme'

interface modalContentProps {
  width?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    borderBottom: `1px solid ${theme.buttonColor.stroke}`,
    gridAutoFlow: 'column',
    alignItems: 'stretch',
  },
  whiteText: {
    color: 'white !important',
    '& p': {
      color: 'white !important',
    },
  },
}))

export const ModalContentWrapper = styled.div<modalContentProps>`
  width: ${(props) => props.width || '816px'};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: ${myTheme.spacing(5)};
  background-color: ${myTheme.extraBackgrounds.paper03};
  color: ${myTheme.typographyColor.secondaryText};
  outline: none;
`

export const PlanDescriptionItemWrapper = styled.div`
  padding: ${myTheme.spacing(3, 1)};
`

export const TextAttributeWrapper = styled.div`
  padding: ${myTheme.spacing(3, 1)};
  p: {
    color: white !important;
  }
`
export const TableDetailsWrapper = styled.div`
  margin-top: ${myTheme.spacing(4)};
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(2)};
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${myTheme.spacing(1)};
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  padding-top: 32px;
`

export const CloseIconWrapper = styled.img`
  cursor: pointer;
`
