import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  cardSpacing: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
  },
  backIcon: {
    '& path': {
      stroke: theme.typographyColor.primaonActivePrimaryButtonTextryText,
    },
  },
}))

export const CenterContainer = styled.div`
  color: white;
  max-width: 750px;
  margin: ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)};
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin: ${theme.spacing(8)} auto ${theme.spacing(8)} auto;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`

export const IconWrapper = styled.div`
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(4)};
  background-color: rgba(146, 243, 140, 0.16);
  border-radius: 41px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
`

export const DividerDotted = styled.div<{ spacing: number }>`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.22);
  margin-top: ${({ spacing }) => theme.spacing(spacing)};
  margin-bottom: ${({ spacing }) => theme.spacing(spacing)};
`
