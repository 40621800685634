export const AGENTS_MARGINS_CARD_TITLE_ID =
  'components.monthlyDataDashboard.agents.margins.card.title'
export const BALANCE_SHEET_ASSET_CARD_TITLE_ID =
  'components.monthlyDataDashboard.balance.sheet.asset.card.title'
export const GLOBAL_LABEL_EDIT_ID = 'global.label.edit'
export const LIABILITIES_CARD_TITLE_ID = 'components.monthlyDataDashboard.liabilities.title'
export const GROSS_MARGIN_CUMMULATIVE_CARD_TITLE_ID =
  'components.monthlyDataDashboard.gross.margin.cumulative.card.title'
export const GROSS_MARGIN_THIS_MONTH_CARD_TITLE_ID =
  'components.monthlyDataDashboard.gross.margin.this.month.card.title'
export const WITHDRAWALS_CARD_TITLE_ID = 'components.monthlyDataDashboard.withdrawals.title'
export const WITHDRAWALS_INFO_ICON_TOOLTIP_ID =
  'components.monthlyDataDashboard.withdrawals.tooltip'
export const WITHDRAWALS_CARD_SUBTITLE_PRINCIPAL_ID =
  'components.monthlyDataDashboard.withdrawals.subtitle.principal'
export const WITHDRAWALS_CARD_SUBTITLE_INTEREST_ID =
  'components.monthlyDataDashboard.withdrawals.subtitle.interest'
export const BALANCE_IN_TRANSIT_CARD_TITLE_ID =
  'components.monthlyDataDashboard.balance.in.transit.card.title'
export const DEPOSIT_CARD_TITLE_ID = 'components.monthlyDataDashboard.deposit.title'
export const ADD_BALANCE_LABEL_ID =
  'components.monthlyDataDashboard.balance.sheet.asset.button.label'
export const HYPHEN = '-'
