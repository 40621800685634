import { gql } from '@apollo/client'

export const DOWNLOAD_1099_PDF = gql`
  mutation ($profileUuid: String!) {
    download1099Pdf(profileUuid: $profileUuid)
  }
`

export const GET_ADDRESS_BY_INVESTOR = gql`
  query AddressByInvestor($profileUuid: String!) {
    addressByInvestor(profileUuid: $profileUuid) {
      countryKey
    }
  }
`
