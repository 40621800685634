import { StepperNewInvestment } from 'global/types'
import { createSlice } from '@reduxjs/toolkit'
import { Contract, Plan } from 'types'
import moment from 'moment'

const LOCALSTATE = 'newInvestment'

interface NewInvestmentStore {
  uuid?: string
  activeStep: number
  investmentPlanSelected: Plan | null
  capitalToFund: string
  contract: Contract | null
  memorandum: Contract | null
  isWaitingContractSign: boolean
  expiresAt: number
}
const newInvestmentLocalStorage = localStorage.getItem(LOCALSTATE)

const defaultState: NewInvestmentStore = {
  uuid: undefined,
  activeStep: 0,
  investmentPlanSelected: null,
  capitalToFund: '',
  contract: null,
  memorandum: null,
  isWaitingContractSign: false,
  expiresAt: moment().add('25', 'm').utc().unix(),
}

const getInitialState = (): NewInvestmentStore => {
  if (
    newInvestmentLocalStorage &&
    JSON.parse(newInvestmentLocalStorage).expiresAt > moment(moment.now()).unix()
  ) {
    return JSON.parse(newInvestmentLocalStorage)
  } else {
    localStorage.setItem(LOCALSTATE, JSON.stringify(defaultState))
    return defaultState
  }
}

const initialState: NewInvestmentStore = getInitialState()

const newInvestmentSlice = createSlice({
  name: 'newInvestment',
  initialState,
  reducers: {
    changeInvestmentUuid(state, action) {
      state.uuid = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeInvestmentTab(state, action) {
      state.activeStep = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeInvestmentPlan(state, action) {
      state.investmentPlanSelected = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeCapitalToFund(state, action) {
      state.capitalToFund = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeIsWaitingContractSign(state, action) {
      state.isWaitingContractSign = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeContract(state, action) {
      state.contract = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    changeMemorandum(state, action) {
      state.memorandum = action.payload
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
    },
    clearNewInvestment(state) {
      state = defaultState
      localStorage.setItem(LOCALSTATE, JSON.stringify(state))
      return state
    },
  },
})

export const {
  changeInvestmentUuid,
  changeInvestmentTab,
  changeInvestmentPlan,
  changeCapitalToFund,
  changeContract,
  changeMemorandum,
  clearNewInvestment,
} = newInvestmentSlice.actions
export default newInvestmentSlice.reducer
