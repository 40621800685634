import { Grid, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { theme } from 'screens/App/theme'
import { useStyles, LeftMenu, RotatedImage } from '../styled'
import ArrowIcon from 'assets/arrow_up_right.svg'

interface Props {
  fullName?: string
}

const InvestorDetailAppBar: FunctionComponent<Props> = ({ fullName }: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <LeftMenu>
      <Grid container alignItems="center">
        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
          className={classes.leftArrowIcon}
        >
          <RotatedImage src={ArrowIcon} alt="left arrow icon" />
        </Link>
        <Typography variant="body2" color={theme.typographyColor.primaryText}>
          {fullName}
        </Typography>
      </Grid>
    </LeftMenu>
  )
}
export default InvestorDetailAppBar
