import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
  noTabs: {
    marginTop: theme.spacing(5),
  },
  white: {
    color: theme.typographyColor.primaryText,
  },
  icon: {
    cursor: 'pointer',
    '& path': {
      stroke: `${theme.typographyColor.primaryText}`,
    },
  },
  iconComponent: {
    top: 0,
  },
  estimatedLabel: {
    backgroundColor: theme.extraBackgrounds.paperWarning,
    padding: theme.spacing(0.5),
    maxWidth: 61,
  },
  rowLabel: {
    minWidth: 'fit-content',
  },
  flexContainer: {
    gap: '16px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  cardsContainer: {
    gap: '16px',
    marginTop: '8px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  cardsLeft: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  graphRight: {
    [theme.breakpoints.up('md')]: {
      width: '30%',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  overviewContainer: {
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  miniCard: {
    width: '100%',
    alignItems: 'center',
    gap: '12px',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  miniCardExtraContent: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      gap: '24px',
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  },
}))

interface FlexContainerProps {
  backgroundColor?: string
  flexDirection?: string
  alignItems?: string
  width?: string
  gap?: string
  height?: string
  marginTop?: string
  justifyContent?: string
  padding?: string
  borderBottom?: string
  border?: string
}

interface DotProps {
  backgroundColor?: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(3)};
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    padding: ${theme.spacing(6, 7)};
    width: calc(100vw - 240px);
  }
  height: 100%;
  overflow-y: auto;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`

export const FlexContainer = styled.div`
  background-color: ${({ backgroundColor }: FlexContainerProps) => backgroundColor};
  display: flex;
  flex-direction: ${({ flexDirection }: FlexContainerProps) => flexDirection};
  align-items: ${({ alignItems }: FlexContainerProps) => alignItems};
  width: ${({ width }: FlexContainerProps) => width};
  gap: ${({ gap }: FlexContainerProps) => gap};
  height: ${({ height }: FlexContainerProps) => height};
  margin-top: ${({ marginTop }: FlexContainerProps) => marginTop};
  justify-content: ${({ justifyContent }: FlexContainerProps) => justifyContent};
  padding: ${({ padding }: FlexContainerProps) => padding};
  border-bottom: ${({ borderBottom }: FlexContainerProps) => borderBottom};
  border: ${({ border }: FlexContainerProps) => border};
`

export const InfoIconImage = styled.img`
  position: relative;
  top: 2px;
  left: 4px;
`
export const Image = styled.img``

export const Dot = styled.div`
  min-width: 12px;
  width: 12px;
  height: 12px;
  background-color: ${({ backgroundColor }: DotProps) => backgroundColor};
  border-radius: 50%;
`
export const ChartViewMainContainer = styled.div`
  margin-top: 32px;
`
export default useStyles
