import { FunctionComponent, useEffect, useState } from 'react'
import { Button, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material'
import Card from 'components/common/Card'
import { FormattedMessage } from 'react-intl'
import {
  CenterContainer,
  IconWrpapper,
  SignContainer,
  InfoContainer,
  SignButtonWrapper,
  BackNextButtonWrapper,
  useStyles,
  TextContainer,
} from './style'
import File from 'assets/file_grey.svg'
import ArrowRight from 'assets/arrow_right_black.svg'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import ExternalLink from 'assets/external_link_blue.svg'
import DocSign from 'assets/doc_signed.svg'
import BadgeStatus from 'components/BadgeStatus'
import { StepNumber } from 'global/types'
import { useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { useLazyQuery } from '@apollo/client'
import { GET_DOCUSIGN_STATUS } from '../queries'
import { useLocation } from 'react-router-dom'

enum BadgeColorStatus {
  warning = 'warning',
  success = 'success',
}

enum LabelBadge {
  pending = 'Pending',
  signed = 'Signed',
}

type DocumentInfo = {
  name: string
  status: string
  badgeColor: BadgeColorStatus
}

const { NONE, SECOND } = StepNumber

interface Props {
  handleStepNumber: (value: number) => () => void
  handleDisableNextStep: (enable: boolean) => void
  generateContractUrl: () => void
  generateMemorandumUrl: () => void
}

const ContractSign: FunctionComponent<Props> = ({
  handleStepNumber,
  handleDisableNextStep,
  generateContractUrl,
  generateMemorandumUrl,
}) => {
  const newInvestmentStore = useSelector((state: GlobalState) => state.newInvestment)

  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const { warning, success } = BadgeColorStatus
  const { pending, signed } = LabelBadge
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [contractInfo, setContractInfo] = useState<DocumentInfo>({
    name: newInvestmentStore.contract?.id || '',
    status: pending,
    badgeColor: warning,
  })
  const [memorandumInfo, setMemorandumInfo] = useState<DocumentInfo>({
    name: newInvestmentStore.memorandum?.id || '',
    status: pending,
    badgeColor: warning,
  })
  const {
    name: _nameContract,
    status: statusContract,
    badgeColor: badgeColorContract,
  } = contractInfo
  const {
    name: _nameMemorandum,
    status: statusMemorandum,
    badgeColor: badgeColorMemorandum,
  } = memorandumInfo

  const { search } = useLocation()

  const onClickSignContractButton = () => {
    generateContractUrl()
  }

  const onClickSignMemorandumButton = () => {
    generateMemorandumUrl()
  }

  const [fetchDocusignContractStatus, { loading: loadingContract }] = useLazyQuery(
    GET_DOCUSIGN_STATUS,
    {
      variables: {
        envelopeId: newInvestmentStore.contract?.id || '',
      },
    },
  )

  const [fetchDocusignMemorandumStatus, { loading: loadingMemorandum }] = useLazyQuery(
    GET_DOCUSIGN_STATUS,
    {
      variables: {
        envelopeId: newInvestmentStore.memorandum?.id || '',
      },
    },
  )

  useEffect(() => {
    if (badgeColorContract === success && badgeColorMemorandum === success) {
      setButtonDisabled(false)
      handleDisableNextStep(false)
    } else {
      handleDisableNextStep(true)
    }
  }, [badgeColorContract, badgeColorMemorandum])

  const getDocusignContractStatus = async () => {
    try {
      if (newInvestmentStore.contract?.id && search.includes('event')) {
        const response = await fetchDocusignContractStatus()
        if (response.data?.isDocusignDocumentCompleted) {
          setContractInfo({
            name: newInvestmentStore.contract?.id || '',
            status: signed,
            badgeColor: success,
          })
        }
      }
    } catch (e) {
      // TODO: Handle error
      console.log(e)
    }
  }

  const getDocusignMemorandumStatus = async () => {
    try {
      if (newInvestmentStore.memorandum?.id && search.includes('event')) {
        const response = await fetchDocusignMemorandumStatus()
        if (response.data?.isDocusignDocumentCompleted) {
          setMemorandumInfo({
            name: newInvestmentStore.memorandum?.id || '',
            status: signed,
            badgeColor: success,
          })
        }
      }
    } catch (e) {
      // TODO: Handle error
      console.log(e)
    }
  }

  useEffect(() => {
    getDocusignContractStatus()
    getDocusignMemorandumStatus()
  }, [])

  return (
    <CenterContainer className={classes.centerContainer}>
      <Card className={classes.cardContainer}>
        <Typography variant="h4" className={classes.marginBottom}>
          <FormattedMessage id="components.cards.contractsSigning.title" />
        </Typography>
        <Typography variant="body3" className={classes.body3}>
          <FormattedMessage id="components.cards.contractsSigning.description" />
        </Typography>
        <SignContainer>
          <InfoContainer>
            <IconWrpapper>
              <img className={classes.icon} src={File} alt="file" />
              {badgeColorContract === success && (
                <img className={classes.docSignedIcon} src={DocSign} alt="success" />
              )}
            </IconWrpapper>
            <TextContainer>
              <Typography variant="body2">
                <FormattedMessage id="screen.newInvestment.contractSing.contract" />
              </Typography>
              <BadgeStatus status={statusContract} color={badgeColorContract} />
            </TextContainer>
          </InfoContainer>
          <SignButtonWrapper>
            {loadingContract && <CircularProgress />}
            {contractInfo.status !== signed && !loadingContract && (
              <Button
                className={classes.noWrapButton}
                variant="outlined"
                onClick={onClickSignContractButton}
                fullWidth={isResponsive}
                endIcon={<img src={ExternalLink} alt="arrowRight" />}
              >
                <FormattedMessage id="screen.newInvestment.contractSing.signContractButton" />
              </Button>
            )}
          </SignButtonWrapper>
        </SignContainer>
        <SignContainer>
          <InfoContainer>
            <IconWrpapper>
              <img className={classes.icon} src={File} alt="file" />
              {badgeColorMemorandum === success && (
                <img className={classes.docSignedIcon} src={DocSign} alt="success" />
              )}
            </IconWrpapper>
            <TextContainer>
              <Typography variant="body2">
                <FormattedMessage id="screen.newInvestment.contractSing.privatePlaceMemorandum" />
              </Typography>
              <BadgeStatus status={statusMemorandum} color={badgeColorMemorandum} />
            </TextContainer>
          </InfoContainer>
          <SignButtonWrapper>
            {loadingMemorandum && <CircularProgress />}
            {memorandumInfo.status !== signed && !loadingMemorandum && (
              <Button
                className={classes.noWrapButton}
                variant="outlined"
                onClick={onClickSignMemorandumButton}
                fullWidth={isResponsive}
                endIcon={<img src={ExternalLink} alt="arrowRight" />}
              >
                <FormattedMessage id="screen.newInvestment.contractSing.signMemorandumButton" />
              </Button>
            )}
          </SignButtonWrapper>
        </SignContainer>
        {!isResponsive && (
          <BackNextButtonWrapper>
            <Button variant="outlined" onClick={handleStepNumber(NONE)}>
              <FormattedMessage id="screen.newInvestment.contractSing.backButton" />
            </Button>
            <Button
              variant="contained"
              disabled={buttonDisabled}
              onClick={handleStepNumber(SECOND)}
              endIcon={
                buttonDisabled ? (
                  <img src={ArrowRight} alt="Arrow Right Disabled" />
                ) : (
                  <img src={ArrowRightDisabled} alt="Arrow Right" />
                )
              }
            >
              <FormattedMessage id="screen.newinvestment.buttonContinue" />
            </Button>
          </BackNextButtonWrapper>
        )}
      </Card>
    </CenterContainer>
  )
}

export default ContractSign
