import { FunctionComponent } from 'react'
import { useStyles, TotalRequestedContainer } from '../styled'
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'

interface Props {
  amount?: number
}

const FinalizeScheduledWithdrawalModalContent: FunctionComponent<Props> = ({
  amount = 0,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <div>
      <TotalRequestedContainer>
        <Typography variant="body3" className={classes.grayText}>
          <FormattedMessage id="screen.investmentDetail.tab5.dataGrid.cancelRequestMoodal.totalRequested" />
        </Typography>
        <Divider size={theme.spacing(1)} />
        <Typography variant="h4" className={classes.whiteText}>
          {intl.formatNumber(amount, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </TotalRequestedContainer>
    </div>
  )
}

export default FinalizeScheduledWithdrawalModalContent
