import { Tabs as MuiTabs, Tab } from '@mui/material'
import { AgentDetailsTab } from 'global/types'
import { FunctionComponent, useState } from 'react'
import { Investment } from 'types'
import { Divider, MainContainer, TabContentWrapper, TabsWrapper, useStyles } from './styled'
import { theme } from 'screens/App/theme'
import InvestmentDataGrid from 'components/InvestmentDataGrid'
import { PlanTab } from './PlanTab'
import InvestorsByAgent from './InvestorsByAgent'
import InvestmentsInvestors from './InvestmentsInvestors'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface Props {
  investments: Investment[]
  agentUuid: string
}

const { INVESTMENTS, INVESTORS, INVESTORS_INVESTMENTS, PLANS } = AgentDetailsTab

const AgentDetailsTabs: FunctionComponent<Props> = ({ investments, agentUuid }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const initialTab = (searchParams.get('tab') as AgentDetailsTab) || INVESTMENTS
  const [selectedTab, setSelectedTab] = useState<AgentDetailsTab>(initialTab)
  const tabs = {
    [INVESTMENTS]: <InvestmentDataGrid investments={investments} />,
    [INVESTORS]: <InvestorsByAgent agentUuid={agentUuid} />,
    [INVESTORS_INVESTMENTS]: <InvestmentsInvestors agentUuid={agentUuid} />,
    [PLANS]: <PlanTab agentUuid={agentUuid} />,
  }

  return (
    <MainContainer>
      <TabsWrapper>
        <MuiTabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(_event, newValue) => setSelectedTab(newValue)}
        >
          {Object.keys(tabs).map((tab) => (
            <Tab
              key={tab}
              label={intl.formatMessage({
                id: `screen.agentDetails.tab.${tab.toLowerCase()}.title`,
              })}
              value={tab}
            />
          ))}
        </MuiTabs>
      </TabsWrapper>
      <Divider size={theme.spacing(3)} />
      <TabContentWrapper>{tabs[selectedTab]}</TabContentWrapper>
    </MainContainer>
  )
}

export default AgentDetailsTabs
