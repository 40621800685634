import { FunctionComponent, useContext } from 'react'
import { useStyles, Container } from './styled'
import { Deposit } from 'types'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'components/common/styled'
import { useMutation } from '@apollo/client'
import { APPROVE_OR_UNDO_DEPOSIT } from '../../queries'
import UndoAcknowledgeDepositsModalContent from './UndoDepositsModalContent'
import { useDispatch } from 'react-redux'
import { AlertContext } from 'hooks/AlertContext'
import { changeTab } from '../../reducer'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  deposit: Deposit
}

const UndoDepositsModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  deposit,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [undoDeposits, { loading }] = useMutation(APPROVE_OR_UNDO_DEPOSIT, {
    variables: {
      depositUuid: deposit.deposit.uuid,
      approve: false,
      approvedDate: null,
    },
  })

  const dispatch = useDispatch()
  const classes = useStyles()
  const { showSnackbar } = useContext(AlertContext)

  const handleUndoDeposits = async () => {
    try {
      const result = await undoDeposits()

      if (result) {
        showSnackbar({
          messageId: 'screen.depositRequests.tab03.undoDepositModal.undoDepositsSnackbar',
        })
        dispatch(changeTab(0))
      }
    } catch (e: unknown) {
      // TODO error handler
    } finally {
      handleCloseCallback()
    }
  }

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      handleActionButton={handleUndoDeposits}
      actionMessageId="screen.depositRequests.tab03.undoDepositModal.action01"
      titleMessage=""
      descriptionMessage=""
      fullScreen={isResponsive}
      width="600px"
      disabledActionButton={loading}
    >
      <Container>
        <Typography variant="h2" className={classes.whiteText}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.title" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.description" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <UndoAcknowledgeDepositsModalContent deposit={deposit} />
      </Container>
    </Dialog>
  )
}

export default UndoDepositsModal
