import { gql } from '@apollo/client'

export const GET_USER_PROFILE = gql`
  query GetProfile {
    profile {
      uuid
      tin
      application {
        name
        applicantId
        levelName
        status
        seenAt
      }
    }
  }
`
