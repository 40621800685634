import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    alignItems: 'center',
    '& .MuiChip-filledSuccess': {
      backgroundColor: 'rgba(146, 243, 140, 0.12)',
      '& .MuiChip-label': {
        color: theme.palette.success.main,
        padding: '0px',
      },
    },
    '& .MuiChip-filledWarning': {
      backgroundColor: 'rgba(255, 232, 140, 0.12)',
      '& .MuiChip-label': {
        color: theme.palette.warning.main,
        padding: '0px',
      },
    },
    '& .MuiChip-filledError': {
      backgroundColor: 'rgba(255, 142, 142, 0.12)',
      '& .MuiChip-label': {
        color: theme.palette.error.main,
        padding: '0px',
      },
    },
    '& .MuiChip-filledInfo': {
      backgroundColor: '#435E7C',
      '& .MuiChip-label': {
        color: theme.typographyColor.primaryText,
        padding: '0px',
      },
    },
    '& .MuiChip-label': {
      color: theme.typographyColor.primaryText,
      padding: '0px',
    },
    '& .MuiChip-avatar': {
      marginRight: theme.spacing(1),
    },
  },
  largeBadge: {
    '& .MuiChip-root': {
      width: '130px',
    },
  },
  isClickeable: {
    '& .MuiChip-root': {
      cursor: 'pointer',
    },
  },
}))

export const BadgeWrapper = styled.div`
  display: flex;
`
