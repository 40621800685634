import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    backgroundColor: theme.extraBackgrounds.paper04,
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  clearIcon: {
    cursor: 'pointer',
  },
  hidden: {
    visibility: 'hidden',
  },
}))
