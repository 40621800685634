import { theme } from 'screens/App/theme'
import styled from 'styled-components'

interface CardProps {
  width?: string
  flexDirection?: string
  alignItems?: string
  marginTop?: string
  gap?: string
}

export const MainContainer = styled.div`
  padding: 24px;
  margin-top: ${({ marginTop }: CardProps) => marginTop};
  display: flex;
  flex-direction: ${({ flexDirection }: CardProps) => flexDirection};
  align-items: ${({ alignItems }: CardProps) => alignItems};
  width: ${({ width }: CardProps) => width};
  background-color: ${theme.extraBackgrounds.paper02};
  height: 100%;
  gap: ${({ gap }: CardProps) => gap};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 24px 8px;
  }
`

export const InfoIconImage = styled.img`
  margin-left: 4px;
`
