import { Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import ArrowRight from 'assets/arrow_right_black.svg'
import { FormattedMessage } from 'react-intl'
import { useStyles, NotFoundWrapper, LogoWrapper, ButtonWrapper, TextWrapper } from './styled'
import Card from 'components/common/Card'
import { Link, useNavigate } from 'react-router-dom'
import brand from 'assets/brand.svg'
import { ProfileContext } from 'hooks/ProfileContext'

function NotFound() {
  const navigate = useNavigate()
  const classes = useStyles()

  const { isTreasurer } = useContext(ProfileContext)

  const redirectToHome = () => {
    const path = isTreasurer ? '/dashboard' : '/home'
    navigate(path)
  }

  return (
    <NotFoundWrapper>
      <Card>
        <LogoWrapper>
          <Link to="/home" className={classes.logoBlueDot}>
            <img src={brand} alt="bluedot logo" />
          </Link>
        </LogoWrapper>
        <TextWrapper>
          <Typography variant="h1">
            <FormattedMessage id="screen.notfound.title" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="screen.notfound.subtitle" />
          </Typography>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            variant="contained"
            endIcon={<img src={ArrowRight} alt="arrow_right" />}
            onClick={redirectToHome}
          >
            <FormattedMessage id="screen.notfound.button" />
          </Button>
        </ButtonWrapper>
      </Card>
    </NotFoundWrapper>
  )
}

export default NotFound
