import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import React, { FunctionComponent, useState, useContext, Fragment } from 'react'
import {
  LeftAppbar,
  LeftMenu,
  RightAppbar,
  UserContainer,
  UserImageBackground,
  UserInfoContainer,
  useStyles,
  Tab,
  TabSelectedAddon,
  GoBackIcon,
  MenuIcon,
  LeftAppbarProps,
} from './styled'
import brand from 'assets/brand.svg'
import userLogo from 'assets/user.svg'
import LogoutIcon from 'assets/log_out.svg'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FormattedMessage } from 'react-intl'
import { ProfileContext } from 'hooks/ProfileContext'
import InvestmentDetailAppBar from './InvestmentDetailAppBar'
import WithdrawalRequestAppBar from './WithdrawalRequestAppBar'
import InvestorDetailAppBar from './InvestorDetailAppBar'
import NewDepositRequestAppBar from './NewDepositRequestAppBar'
import ArrowIcon from 'assets/arrow_down_left.svg'
import MenuBarsIcon from 'assets/menu.svg'
import NewDepositRequestSuccessAppBar from './NewDepositRequestSuccessAppBar'
import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'
import ScheduleWithdrawalRequestAppBar from './ScheduleWithdrawalRequestAppBar'

interface Props {
  fullName?: string
  showGoBackIcon?: boolean
  showScreenTitle?: boolean
  screenTitleId?: string
  leftAppbarStyleProps?: LeftAppbarProps
  goBackAction?: () => void
  openMenu?: () => void
  showMenuButton?: boolean
}

const Appbar: FunctionComponent<Props> = ({
  fullName,
  showGoBackIcon,
  showScreenTitle,
  screenTitleId,
  leftAppbarStyleProps,
  goBackAction,
  openMenu,
  showMenuButton,
}: Props) => {
  const classes = useStyles()
  const { user, logout } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))
  const showInResponsive = useMediaQuery(theme.breakpoints.down('md'))

  // TODO: Change this for authenticated user
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isInvestmentDetail = location?.pathname.includes('investment-detail')
  const isNewDepositRequest =
    location.pathname.includes('new-deposit-request') &&
    !location.pathname.includes('new-deposit-request/success')
  const isNewDepositRequestSuccess = location.pathname.includes('new-deposit-request/success')
  const isWithdrawalRequest =
    location?.pathname.includes('withdrawal-request') &&
    !location.pathname.includes('withdrawal-requests')
  const isScheduleWithdrawal = location?.pathname.includes('new-schedule-withdrawal')
  const isInvestorDetail = location?.pathname.includes('investor')
  const isInvestors = location.pathname.includes('investors')

  const { isTreasurer, isInvestor } = useContext(ProfileContext)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    localStorage.clear()
    logout({
      returnTo: AUTH0_LOGIN_REDIRECT_URL,
    })
  }

  const handleNavigate = (url: string) => () => {
    setAnchorEl(null)
    navigate(url)
  }

  return (
    <AppBar position="sticky" elevation={0} className={classes.toolbar}>
      <Toolbar>
        {showInResponsive && showMenuButton && (
          <IconButton sx={{ pl: 0, pr: 4 }}>
            <MenuIcon onClick={openMenu} src={MenuBarsIcon} />
          </IconButton>
        )}
        <LeftAppbar {...leftAppbarStyleProps}>
          {showGoBackIcon ? (
            <GoBackIcon onClick={goBackAction} src={ArrowIcon} alt="go back arrow icon" />
          ) : (
            <Fragment>
              {isInvestmentDetail && <InvestmentDetailAppBar />}
              {isNewDepositRequestSuccess && <NewDepositRequestSuccessAppBar />}
              {isNewDepositRequest && <NewDepositRequestAppBar />}
              {!isTreasurer && isWithdrawalRequest && <WithdrawalRequestAppBar />}
              {!isTreasurer && isScheduleWithdrawal && <ScheduleWithdrawalRequestAppBar />}
              {((!isInvestmentDetail &&
                !isWithdrawalRequest &&
                !isScheduleWithdrawal &&
                !isInvestorDetail &&
                !isNewDepositRequestSuccess &&
                !isNewDepositRequest) ||
                isInvestors) && (
                <LeftMenu>
                  <Link to="/home" className={classes.logoBlueDot}>
                    <img src={brand} alt="bluedot logo" />
                  </Link>
                  {!isTreasurer && hideInResponsive && (
                    <Tab onClick={handleNavigate('/home')}>
                      <Typography variant="body3">
                        <FormattedMessage id="global.appbar.menu.tab.myInvestments" />
                      </Typography>
                      {location?.pathname === '/home' && <TabSelectedAddon />}
                    </Tab>
                  )}
                </LeftMenu>
              )}
              {isInvestorDetail && isTreasurer && !isInvestors && (
                <InvestorDetailAppBar fullName={fullName} />
              )}
            </Fragment>
          )}
          {showScreenTitle && screenTitleId && (
            <Typography variant="body2" color="white">
              <FormattedMessage id={screenTitleId} />
            </Typography>
          )}
        </LeftAppbar>
        <RightAppbar>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenu}
            sx={{ p: 0 }}
          >
            {!isTreasurer &&
              !isInvestmentDetail &&
              !hideInResponsive &&
              !isNewDepositRequest &&
              !isNewDepositRequestSuccess &&
              !isWithdrawalRequest &&
              !isScheduleWithdrawal && (
                <Tab onClick={handleNavigate('/home')}>
                  <Typography variant="body3">
                    <FormattedMessage id="global.appbar.menu.tab.myInvestments" />
                  </Typography>
                  {location?.pathname === '/home' && <TabSelectedAddon />}
                </Tab>
              )}
            <UserContainer>
              {hideInResponsive && (
                <UserInfoContainer className={classes.textUserContainer}>
                  <Typography className={classes.emailLabel} variant="body1">
                    {user?.email}
                  </Typography>
                  <Typography className={classes.typeLabel} variant="caption">
                    {isTreasurer && <FormattedMessage id="global.appbar.menu.treasurer" />}
                    {isInvestor && <FormattedMessage id="global.appbar.menu.investor" />}
                  </Typography>
                </UserInfoContainer>
              )}
              <UserImageBackground>
                <img className={classes.menuIcon} src={userLogo} alt="User icon" />
              </UserImageBackground>
            </UserContainer>
          </IconButton>
          <Menu
            elevation={0}
            className={classes.menu}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!isTreasurer && (
              <MenuItem onClick={handleNavigate('/profile')}>
                <Typography variant="body2">
                  <FormattedMessage id="global.appbar.menu.profile" />
                </Typography>
              </MenuItem>
            )}
            {/* TODO: Uncomment this when implemented */}
            {/* <MenuItem
              onClick={() => {
                // TODO How it works action
              }}
            >
              <Typography variant="body2">
                <FormattedMessage id="global.appbar.menu.howitworks" />
              </Typography>
            </MenuItem> */}
            <MenuItem onClick={handleLogout}>
              <Typography variant="body2">
                <FormattedMessage id="global.appbar.menu.logout" />
                <img src={LogoutIcon} />
              </Typography>
            </MenuItem>
          </Menu>
        </RightAppbar>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar
