import { FunctionComponent, ElementType, useEffect, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ProfileContext } from 'hooks/ProfileContext'
import { routes } from './routes'
import find from 'lodash/find'
import reduce from 'lodash/reduce'

interface Props {
  component: FunctionComponent
  layout: ElementType
}

const PrivateRoute = ({ component: Component, layout: Layout }: Props) => {
  const { isLoading, isAuthenticated } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()

  const { profileData, dataQueryMe, isTreasurer, hasFinishedLoading } = useContext(ProfileContext)

  useEffect(() => {
    if (!isLoading && !isAuthenticated) navigate('/login')
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    if (hasFinishedLoading && !isTreasurer && !profileData?.profile?.application) {
      navigate('/welcome')
    }
  }, [profileData, hasFinishedLoading, isTreasurer])

  useEffect(() => {
    if (hasFinishedLoading && ['/home', '/welcome'].includes(location?.pathname) && isTreasurer) {
      navigate('/dashboard')
    }
  }, [dataQueryMe, hasFinishedLoading, isTreasurer])

  useEffect(() => {
    const completePathname = location?.pathname
    const onlyRoute = completePathname.substring(
      completePathname.indexOf('/'),
      completePathname.lastIndexOf('/'),
    )

    if (onlyRoute && dataQueryMe?.me?.roles) {
      validateRoleRoute(onlyRoute, dataQueryMe?.me?.roles)
    }
  }, [location, dataQueryMe])

  const validateRoleRoute = (path: string, userRoles: Array<string>) => {
    const hasAccess = reduce(
      userRoles,
      (hasAccess, role) => hasAccess || !!find(routes, { role, route: path }),
      false,
    )

    if (!hasAccess) navigate('/not-found')
  }

  return (
    <>
      {isAuthenticated && !isLoading && profileData && (
        <>
          {isAuthenticated && !isLoading && (
            <Layout>{isAuthenticated && !isLoading && <Component />}</Layout>
          )}
        </>
      )}
    </>
  )
}

export default PrivateRoute
