import { Typography } from '@mui/material'
import InlineAlert from 'components/Alert/InlineAlert'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'screens/App/theme'
import { ReactComponent as ClockIcon } from 'assets/clock.svg'
import { useStyles } from './styled'
import { DAYS } from 'utils/DateUtils'

interface Props {
  createdBefore?: string
  startGeneratingInterest?: string
}

const TimeLeftInlineAlert = ({ createdBefore, startGeneratingInterest }: Props) => {
  const intl = useIntl()
  const classes = useStyles()

  const [finalizeTransactionText, setFinalizeTransactionText] = useState('')

  const setFinalizeTransactionTextValue = () => {
    if (!createdBefore) return
    const now = moment()
    const createdBeforeTime = moment(createdBefore)
    const difference = moment.duration(createdBeforeTime.diff(now))
    const daysFormatted = intl.formatMessage(
      { id: 'screen.DepositAmountStepper.days' },
      { days: difference.days() },
    )
    const hoursFormatted = intl.formatMessage(
      { id: 'screen.DepositAmountStepper.hours' },
      { hours: difference.hours() },
    )
    const minutesFormatted = intl.formatMessage(
      { id: 'screen.DepositAmountStepper.minutes' },
      { minutes: difference.minutes() },
    )
    setFinalizeTransactionText(`${daysFormatted}${hoursFormatted}${minutesFormatted}`)
  }

  useEffect(() => {
    setFinalizeTransactionTextValue()
    const interval = setInterval(setFinalizeTransactionTextValue, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [createdBefore])

  let messageIdToDisplayTheDate = 'screen.DepositAmountStepper.infoWarning.paragraph.third.new'
  const currentDateInNewYork = moment().tz('America/New_York')
  const currentDayNY = currentDateInNewYork.day()
  const currentHourNY = currentDateInNewYork.hour()
  const daysWithNewMessageId = [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY]
  const isWednesdayAndBefore12PM = currentDayNY === DAYS.WEDNESDAY && currentHourNY < 12
  const needsToDisplayNewMessageId =
    daysWithNewMessageId.includes(currentDayNY) || isWednesdayAndBefore12PM
  if (needsToDisplayNewMessageId) {
    messageIdToDisplayTheDate = 'screen.DepositAmountStepper.infoWarning.paragraph.third'
  }

  return (
    <InlineAlert Icon={ClockIcon} severity="info" variant="standard">
      <Typography variant="body3" className={theme.typographyColor.primaryText}>
        <FormattedMessage id="screen.DepositAmountStepper.infoWarning.paragraph.first" />
      </Typography>
      <Typography variant="body3" className={classes.textWarningHighlight}>
        {finalizeTransactionText}
      </Typography>
      <Typography variant="body3" className={theme.typographyColor.primaryText}>
        <FormattedMessage
          id={messageIdToDisplayTheDate}
          values={{
            date: startGeneratingInterest
              ? moment(startGeneratingInterest).tz('America/New_York').format('dddd, MMMM D')
              : '-',
          }}
        />
      </Typography>
    </InlineAlert>
  )
}

export default TimeLeftInlineAlert
