import { ClassNameMap } from '@mui/material'
import clsx from 'clsx'
import { FunctionComponent, ReactNode } from 'react'
import { CardContainer, useStyles } from './styled'

interface Props {
  children?: ReactNode
  className?: string | undefined
  props?: any
  personalizedColors?: boolean
}
const Card: FunctionComponent<Props> = ({
  children,
  props,
  className,
  personalizedColors = false,
}: Props) => {
  const classes = useStyles()
  const defaultClasses = personalizedColors ? '' : classes.defaultTypography
  return (
    <CardContainer className={clsx(classes.card, className, defaultClasses)} {...props}>
      {children}
    </CardContainer>
  )
}

export default Card
