import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    minHeight: 'calc(100vh - 80px)',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  centerContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(3),
  },
  investmentPlan: {
    paddingTop: theme.spacing(6),
  },
  wrapperContent: {
    marginBottom: '80px',
  },
}))

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const TopTitleButtonContainer = styled.div`
  width: 100%;
  max-width: 940px;
  margin-bottom: ${theme.spacing(4)};
`

export const StepperContentContainer = styled.div`
  width: 100%;
  max-width: 940px;
`

export const CenterContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
