import { Button, Grid, Tab, Tabs as MuiTabs } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { GlobalState } from 'redux/store'
import { DetailTab, changeTab } from './reducer'
import { useNavigate } from 'react-router-dom'
import {
  useStyles,
  CenterContainer,
  TabsWrapper,
  TabContentWrapper,
  TabsButtonWrapper,
} from './styled'
import { AgentMyInvestmentTab } from 'global/types'
import { theme } from 'screens/App/theme'
import { Divider } from 'components/common/styled'
import MyInvestmentsTab from './MyInvestmentsTab'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import MyInvestorsInvestmentsTab from './MyInvestorsInvestmentsTab'
import { clearNewInvestment } from 'screens/NewInvestment/reducer'

const { MY_INVESTMENTS, MY_INVESTORS_INVESTMENTS } = AgentMyInvestmentTab

const AgentInvestments: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedTab: DetailTab = useSelector((state: GlobalState) => state.agentMyInvestments)

  useEffect(() => {
    return () => {
      dispatch(changeTab(0))
    }
  }, [])

  const tabs = [
    {
      tab: MY_INVESTMENTS,
      content: <MyInvestmentsTab />,
    },
    {
      tab: MY_INVESTORS_INVESTMENTS,
      content: <MyInvestorsInvestmentsTab />,
    },
  ]

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
  }

  const onClickNewInvestmentButton = () => {
    dispatch(clearNewInvestment())
    navigate('/new-investment')
  }

  return (
    <Grid container className={classes.mainGrid} direction="column" alignItems="center">
      <CenterContainer>
        <TabsButtonWrapper>
          <TabsWrapper>
            <MuiTabs
              className={classes.tabs}
              value={selectedTab.index}
              onChange={handleChangeTab}
              aria-label={intl.formatMessage({ id: 'screen.depositRequests.title' }) as string}
            >
              {tabs.map(({ tab }, index) => (
                <Tab
                  key={`${tab}${index}`}
                  label={intl.formatMessage({
                    id: `screen.agent.myInvestments.tab${index + 1}.tab`,
                  })}
                  id={`agent-my-investments-tab-${tab}`}
                  aria-controls={`agent-my-investments-tabpanel-${tab}`}
                />
              ))}
            </MuiTabs>
          </TabsWrapper>
          <Button variant="contained" onClick={onClickNewInvestmentButton}>
            <FormattedMessage id="screen.welcome.personalInvestorCard.newInvestmentButton" />
            <SvgThinArrow className={classes.buttonIcon} />
          </Button>
        </TabsButtonWrapper>
        <Divider size={theme.spacing(3)} />
        <TabContentWrapper>{tabs[selectedTab.index]?.content}</TabContentWrapper>
      </CenterContainer>
    </Grid>
  )
}

export default AgentInvestments
