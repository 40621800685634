import { useAuth0 } from '@auth0/auth0-react'
import AuthProfile from 'hooks/ProfileContext'
import { FunctionComponent, useEffect } from 'react'
import { getInvitationCode, getRedirectParams } from './utils'

interface Props {
  children: JSX.Element
}

const AuthWrapper: FunctionComponent<Props> = ({ children }: Props) => {
  const { isLoading, user, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const code = getInvitationCode()
      const params = getRedirectParams(code)
      loginWithRedirect(params)
    }
  }, [isLoading, isAuthenticated])

  if (isLoading || !user) {
    return <div>loading...</div>
  }

  return <AuthProfile>{children}</AuthProfile>
}

export default AuthWrapper
