import { useLazyQuery } from '@apollo/client'
import { Grid, Tabs as MuiTabs, Tab, Typography, useMediaQuery } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { columnsWithdrawalRequestsTreasurer } from 'components/common/DataGrid/utils'
import SearchInput from 'components/common/Inputs/SearchInput'
import { WithdrawalRequestsTab } from 'global/types'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import PendingTab from 'screens/WithdrawalRequests/PendingTab'
import CompletedTab, {
  GetWithdrawalRequestsData,
  GetWithdrawalRequestsVariables,
} from './CompletedTab'
import { GET_WITHDRAWAL_REQUESTS } from './queries'
import { changeTab, WithdrawalTab } from './reducer'
import {
  HeaderWrapper,
  SearchBarWrapper,
  TabContentWrapper,
  TabsWrapper,
  useStyles,
  WithdrawalRequestWrapper,
} from './styled'
import { Theme } from '@mui/material/styles'
import ScheduledWithdrawals from './ScheduledWithdrawals'
import { ScheduledWithdrawalStatus } from 'types'

const getAriaAndId = (tab: WithdrawalRequestsTab) => {
  return {
    id: `withdrawals-tab-${tab}`,
    'aria-controls': `withdrawals-tabpanel-${tab}`,
  }
}
function WithdrawalRequests() {
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const classes = useStyles()
  const selectedTab: WithdrawalTab = useSelector((state: GlobalState) => state.withdrawalRequests)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const intl = useIntl()

  const [page, setPage] = useState<number>(0)
  const [limit, setPageSize] = useState(10)

  const [getWithdrawals, { loading, data, error }] = useLazyQuery<
    GetWithdrawalRequestsData,
    GetWithdrawalRequestsVariables
  >(GET_WITHDRAWAL_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    variables: { status: null },
  })
  const withdrawalsDebouncer = useCallback(_.debounce(getWithdrawals, 1000), [getWithdrawals])
  const columns = columnsWithdrawalRequestsTreasurer(false, true)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
  }

  const onChangeSearchValue = (newSearch: string) => {
    setSearch(newSearch)
    setPage(0)
    if (newSearch.length < 1) return
    withdrawalsDebouncer({
      variables: { search: newSearch },
    })
  }

  let withdrawals = data?.withdrawalRequests.withdrawalsRequest ?? []
  let rowCount = data?.withdrawalRequests.total ?? 0
  if (error || loading) {
    withdrawals = []
    rowCount = 0
  }

  const Tabs = {
    [WithdrawalRequestsTab.PENDING]: <PendingTab />,
    [WithdrawalRequestsTab.COMPLETED]: <CompletedTab />,
    [WithdrawalRequestsTab.ACTIVE_SCHEDULED]: (
      <ScheduledWithdrawals status={ScheduledWithdrawalStatus.ACTIVE} />
    ),
    [WithdrawalRequestsTab.INACTIVE_SCHEDULED]: (
      <ScheduledWithdrawals status={ScheduledWithdrawalStatus.FINALIZED} />
    ),
  }

  return (
    <WithdrawalRequestWrapper>
      <HeaderWrapper>
        <Grid
          container
          rowGap={5}
          alignItems={{ xs: 'initial', md: 'center' }}
          justifyContent={{ xs: 'initial', md: 'space-between' }}
          paddingBottom={{ xs: 2, md: 2.5 }}
        >
          <Grid item xs={12} md="auto">
            <Typography className={classes.white} sx={{ typography: { xs: 'h3', md: 'h2' } }}>
              <FormattedMessage id="screen.withdrawalRequests.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchBarWrapper>
              <SearchInput
                fullWidth={!isBreakpointMdUp}
                value={search}
                onChangeValue={onChangeSearchValue}
              />
            </SearchBarWrapper>
          </Grid>
        </Grid>
      </HeaderWrapper>
      {search.length > 1 ? (
        <TabsWrapper className={classes.noTabs}>
          <DataGrid
            columns={columns}
            rows={withdrawals}
            rowCount={rowCount}
            page={page}
            rowsPerPageOptions={[10]}
            pageSize={limit}
            pagination
            onPageChange={(newPage: number) => setPage(newPage)}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            emptyMessageId="screen.withdrawalRequests.search.empty"
            errorMessageId="screen.withdrawalRequests.tab.error"
            checkboxSelection={false}
            isLoading={loading}
            hasError={Boolean(error)}
            autoHeight
          />
        </TabsWrapper>
      ) : (
        <TabsWrapper>
          <MuiTabs
            className={classes.tabs}
            value={selectedTab.index}
            onChange={handleChange}
            aria-label={intl.formatMessage({ id: 'screen.withdrawalRequests.title' }) as string}
          >
            <Tab
              label={intl.formatMessage({ id: 'screen.withdrawalRequests.tab01.tab' })}
              {...getAriaAndId(WithdrawalRequestsTab.PENDING)}
            />
            <Tab
              label={intl.formatMessage({ id: 'screen.withdrawalRequests.tab02.tab' })}
              {...getAriaAndId(WithdrawalRequestsTab.COMPLETED)}
            />
            <Tab
              label={intl.formatMessage({ id: 'screen.withdrawalRequests.tab03.tab' })}
              {...getAriaAndId(WithdrawalRequestsTab.ACTIVE_SCHEDULED)}
            />
            <Tab
              label={intl.formatMessage({ id: 'screen.withdrawalRequests.tab04.tab' })}
              {...getAriaAndId(WithdrawalRequestsTab.INACTIVE_SCHEDULED)}
            />
          </MuiTabs>
          <TabContentWrapper>{Tabs[selectedTab.status]}</TabContentWrapper>
        </TabsWrapper>
      )}
    </WithdrawalRequestWrapper>
  )
}
export default WithdrawalRequests
