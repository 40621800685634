import { FunctionComponent, ReactNode } from 'react'
import { Button, Modal as MuiModal, Typography } from '@mui/material'
import {
  ActionWrapper,
  CloseIconWrapper,
  HeaderWrapper,
  ModalContentWrapper,
  RightSideWrapper,
} from './styled'
import { FormattedMessage } from 'react-intl'
import CloseIcon from 'assets/close_white.svg'
import { IntlValuesObject } from 'global/types'

interface Props {
  modalOpen: boolean
  titleMessage?: string
  titleMessageId?: string
  descriptionMessage?: string
  descriptionMessageId?: string
  actionMessageId?: string
  actionMessageValues?: IntlValuesObject
  handleCloseCallback: (event?: any, reason?: 'escapeKeyDown' | 'backdropClick') => void
  handleActionButton?: () => void
  children?: React.ReactNode
  width?: string
  valuesTitle?: IntlValuesObject
  valuesDescription?: IntlValuesObject
  endIconActionButton?: ReactNode
  disabledActionButton?: boolean
  disableEscapeKeyDown?: boolean
}

const Modal: FunctionComponent<Props> = ({
  modalOpen,
  titleMessage,
  titleMessageId,
  descriptionMessage,
  descriptionMessageId,
  actionMessageId,
  actionMessageValues,
  handleCloseCallback,
  handleActionButton,
  children,
  width,
  valuesTitle,
  valuesDescription,
  endIconActionButton,
  disabledActionButton = false,
  disableEscapeKeyDown = false,
}: Props) => {
  const getFormattedMessage = (messageId?: string, message?: string, values?: IntlValuesObject) => {
    if (messageId) {
      return <FormattedMessage id={messageId} values={values} />
    }
    return message
  }

  return (
    <MuiModal
      open={modalOpen}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={handleCloseCallback}
      aria-labelledby="learn-more-modal-title"
      aria-describedby="learn-more-modal-description"
    >
      <ModalContentWrapper width={width}>
        <HeaderWrapper>
          <RightSideWrapper>
            <CloseIconWrapper src={CloseIcon} onClick={handleCloseCallback} />
          </RightSideWrapper>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            {getFormattedMessage(titleMessageId, titleMessage, valuesTitle)}
          </Typography>
          <Typography id="modal-modal-description" variant="body1">
            {getFormattedMessage(descriptionMessageId, descriptionMessage, valuesDescription)}
          </Typography>
        </HeaderWrapper>
        {children}
        {actionMessageId && handleActionButton && (
          <ActionWrapper>
            <Button
              variant="outlined"
              onClick={handleActionButton}
              endIcon={endIconActionButton}
              disabled={disabledActionButton}
            >
              <FormattedMessage id={actionMessageId} values={actionMessageValues} />
            </Button>
          </ActionWrapper>
        )}
      </ModalContentWrapper>
    </MuiModal>
  )
}

export default Modal
