import { Grid, Typography } from '@mui/material'
import { FunctionComponent, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import {
  MarkdownAttributeWrapper,
  PlanDescriptionItemWrapper,
  TextAttributeWrapper,
  TextAttributeWrapperProps,
  useStyles,
} from './styled'
import clsx from 'clsx'
import { theme } from 'screens/App/theme'

interface Props {
  attributeName: string
  attributeValue: string
  attributeValueId?: string
  infoTextId?: string
  isMarkdown?: boolean
  valueChildren?: ReactNode | ReactNode[]
  centerValues?: boolean
  attributeValueClassName?: string
  textAttributeWrapperProps?: TextAttributeWrapperProps
}

const PlanDescriptionItem: FunctionComponent<Props> = ({
  attributeName,
  attributeValue,
  attributeValueId,
  infoTextId,
  isMarkdown = false,
  valueChildren,
  centerValues = false,
  attributeValueClassName,
  textAttributeWrapperProps,
}: Props) => {
  const classes = useStyles()

  const renderAttributeValue = isMarkdown ? (
    <MarkdownAttributeWrapper padding={textAttributeWrapperProps?.padding}>
      <ReactMarkdown className={classes.whiteText}>{attributeValue}</ReactMarkdown>
    </MarkdownAttributeWrapper>
  ) : (
    <TextAttributeWrapper {...textAttributeWrapperProps}>
      <Typography variant="body1" className={clsx(classes.whiteText, attributeValueClassName)}>
        {attributeValueId ? (
          <FormattedMessage id={attributeValueId} values={{ value: attributeValue }} />
        ) : (
          attributeValue
        )}
      </Typography>
      {valueChildren}
    </TextAttributeWrapper>
  )

  return (
    <Grid container alignItems={centerValues ? 'center' : ''} className={classes.gridContainer}>
      <Grid item xs={5} alignItems={centerValues ? 'center' : ''}>
        <PlanDescriptionItemWrapper>
          <Typography variant="body1">
            <FormattedMessage id={attributeName} />
          </Typography>
          {Boolean(infoTextId) && (
            <Typography variant="body3" color={theme.typographyColor.warningText}>
              <FormattedMessage id={infoTextId} />
            </Typography>
          )}
        </PlanDescriptionItemWrapper>
      </Grid>
      <Grid item xs={7}>
        {renderAttributeValue}
      </Grid>
    </Grid>
  )
}

export default PlanDescriptionItem
