import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const Divider = styled.div`
  margin: 24px 0px;
  height: 1px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  padding-top: ${theme.spacing(5)};
  justify-content: right;
`

export const BackNextButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(3)};
`

export const Row = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: ${theme.spacing(2)};
`
