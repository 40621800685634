import { useState } from 'react'

export const useTemporalLabel = (firstLabel: string, secondLabel: string, timeInMs = 3000) => {
  const [label, setLabel] = useState(firstLabel)

  const changeToSecondLabelJustTimeAssigned = () => {
    setLabel(secondLabel)
    setTimeout(() => {
      setLabel(firstLabel)
    }, timeInMs)
  }

  return [label, changeToSecondLabelJustTimeAssigned] as const
}
