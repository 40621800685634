import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme } from 'screens/App/theme'

interface dialogContentProps {
  width?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      alignItems: 'start',
    },
    '& .MuiDialog-paper': {
      backgroundColor: 'transparent',
    },
    '& .MuiDialog-container': {
      width: '100%',
    },
  },
  dialogContent: {
    padding: theme.spacing(5),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(2.5, 2),
      justifyContent: 'flex-start',
    },
  },
  actionWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  gridContainer: {
    borderBottom: `1px solid ${theme.buttonColor.stroke}`,
    gridAutoFlow: 'column',
    alignItems: 'stretch',
  },
  whiteText: {
    color: 'white !important',
    '& p': {
      color: 'white !important',
    },
  },
}))

export const DialogContentWrapper = styled.div<dialogContentProps>`
  width: ${(props) => props.width || '816px'};
  max-width: ${(props) => props.width || '816px'};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${myTheme.extraBackgrounds.paper03};
  color: ${myTheme.typographyColor.secondaryText};
  outline: none;
`

export const PlanDescriptionItemWrapper = styled.div`
  padding: ${myTheme.spacing(3, 1)};
`

export const TextAttributeWrapper = styled.div`
  padding: ${myTheme.spacing(3, 1)};
  p: {
    color: white !important;
  }
`
export const TableDetailsWrapper = styled.div`
  margin-top: ${myTheme.spacing(4)};
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(2)};
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${myTheme.spacing(1)};
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  padding-top: 32px;
`

export const CloseIconWrapper = styled.img`
  cursor: pointer;
`
