import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    '& button': {
      height: '100%',
    },
  },
}))
