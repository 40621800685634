import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme as appTheme, theme } from '../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
}))

export const Container = styled.div`
  height: calc(100vh - 80px);
  background-color: ${appTheme.extraBackgrounds.paper01};
`
export const ButtonWrapper = styled.div`
  align-self: flex-start;
  width: auto;
  padding-top: ${theme.spacing(5)};
`

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: ${theme.spacing(11)};
`
