import { FunctionComponent, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  useStyles,
  BalanceContainer,
  BalanceItem,
  ButtonWrapper,
  DepositAcountItem,
  DepositAcountItemTitle,
  DepositAcountItemContent,
} from './styled'
import { Investment } from 'types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import Money from 'assets/money.svg'
import { StepsWithdrawalRequest } from 'global/types'
import Card from 'components/common/Card'
import { inputToNumber } from 'utils/MoneyFormat'
import { ReactComponent as OffIcon } from 'assets/state_off.svg'
import { ReactComponent as OnIcon } from 'assets/state_on.svg'

interface Props {
  investment: Investment
  amount: string
  maximumAmount: number
  handleChangeWithdrawalAmount: (value: string) => void
  handleChangeStep: (value: number) => void
}

const { CONFIRMATION_STEP } = StepsWithdrawalRequest

const WithdrawalAmount: FunctionComponent<Props> = ({
  investment,
  handleChangeWithdrawalAmount,
  handleChangeStep,
  amount,
  maximumAmount,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [selectedAllMoney, setSelectedAllMoney] = useState<boolean>(false)
  const [disabledInput, setDisabledInput] = useState<boolean>(false)

  const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedAmount = inputToNumber(event.target.value)
    const newAmount = new Intl.NumberFormat('en-US', { currency: 'USD' })
    if (formattedAmount >= maximumAmount) {
      const formattedInvestmentAmount = newAmount.format(maximumAmount)
      handleChangeWithdrawalAmount(formattedInvestmentAmount)
    } else {
      const formattedInvestmentAmount = newAmount.format(formattedAmount)
      handleChangeWithdrawalAmount(formattedInvestmentAmount)
    }
  }

  const handleNextStep = () => {
    handleChangeStep(CONFIRMATION_STEP)
  }

  useEffect(() => {
    if (selectedAllMoney) {
      const formattedMaximumAmount = intl.formatNumber(maximumAmount, {
        currency: 'USD',
      })
      handleChangeWithdrawalAmount(formattedMaximumAmount)
      setDisabledInput(true)
    } else {
      setDisabledInput(false)
    }
  }, [selectedAllMoney])

  return (
    <Card className={classes.mainGridCard}>
      <Typography variant="h4">
        <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.title" />
      </Typography>
      <Divider size={theme.spacing(1)} />
      <Typography variant="body3" className={classes.body3}>
        <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.description" />
      </Typography>
      <Divider size={theme.spacing(5)} />
      <BalanceContainer>
        <BalanceItem>
          <Typography variant="body3" className={classes.body3}>
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.currentInvestmentBalance" />
          </Typography>
          <Typography variant="h4">
            {intl.formatNumber(investment?.balance?.total || 0, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </BalanceItem>
        <BalanceItem>
          <Typography variant="body3" className={classes.body3}>
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.estimatedBalanceAfterWithdrawal" />
          </Typography>
          <Typography variant="h4">
            {intl.formatNumber(maximumAmount, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </BalanceItem>
      </BalanceContainer>
      <Divider size={theme.spacing(5)} />
      <FormControl className={classes.amountInput}>
        {amount.length > 0 && (
          <InputLabel htmlFor="outlined-adornment-amount">
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.title" />
          </InputLabel>
        )}
        <OutlinedInput
          id="outlined-adornment-amount"
          label={
            amount.length > 0
              ? intl.formatMessage({ id: 'screen.scheduleWithdrawalRequest.step01.title' })
              : ''
          }
          value={amount}
          onChange={handleInputAmount}
          startAdornment={<img src={Money} alt="money" />}
          placeholder={intl.formatMessage({ id: 'screen.scheduleWithdrawalRequest.step01.title' })}
          disabled={disabledInput}
          className={classes.inputLabel}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedAllMoney}
              onChange={() => setSelectedAllMoney((prev) => !prev)}
              icon={<OffIcon />}
              checkedIcon={<OnIcon />}
            />
          }
          value="start"
          label={
            <Typography variant="body3" color={theme.typographyColor.secondaryText}>
              <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.checkbox" />
            </Typography>
          }
        />
      </FormControl>
      {investment?.profile?.bankAccounts.length > 0 && (
        <div>
          <Divider size={theme.spacing(5)} />
          <DepositAcountItem>
            <DepositAcountItemTitle>
              <Typography variant="body3" className={classes.gray}>
                <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.depositAccount" />
              </Typography>
            </DepositAcountItemTitle>
            <DepositAcountItemContent>
              <img
                className={classes.bankImage}
                src={investment?.profile?.bankAccounts[0]?.bank?.logo}
                alt="bank-logo"
              />
              <Typography variant="body3">
                {investment?.profile?.bankAccounts[0]?.bank?.name}
              </Typography>
            </DepositAcountItemContent>
          </DepositAcountItem>
        </div>
      )}
      <Divider size={theme.spacing(5)} />
      {!isResponsive && (
        <ButtonWrapper className={classes.continueButton}>
          <Button
            disabled={inputToNumber(amount) <= 0}
            variant="contained"
            onClick={handleNextStep}
          >
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step01.continue" />
          </Button>
        </ButtonWrapper>
      )}
    </Card>
  )
}

export default WithdrawalAmount
