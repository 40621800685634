import { DOWNLOAD_CSV, GET_DATES, GET_REFERRALS_MONTHLY_EARNINGS } from '../queries'
import { useLazyQuery, useMutation } from '@apollo/client'

interface Params {
  selectedDate: string
}

const useRequestsHandlers = ({ selectedDate }: Params) => {
  const [getDates, { data: datesData, loading: loadingDates }] = useLazyQuery(GET_DATES, {
    fetchPolicy: 'network-only',
  })
  const [getNetMonthlyEarnings, { data: earningsData, loading: loadingEarnings }] = useLazyQuery(
    GET_REFERRALS_MONTHLY_EARNINGS,
    {
      variables: {
        date: selectedDate,
      },
      fetchPolicy: 'network-only',
    },
  )
  const [downloadCSV, { loading: loadingDownloadCsv }] = useMutation(DOWNLOAD_CSV, {
    variables: {
      date: selectedDate,
    },
    fetchPolicy: 'network-only',
  })

  return {
    getDates,
    getNetMonthlyEarnings,
    downloadCSV,
    datesData,
    loadingDates,
    earningsData,
    loadingEarnings,
    loadingDownloadCsv,
  }
}

export default useRequestsHandlers
