import { Button, Link, Typography } from '@mui/material'
import {
  CharacteristicsContainer,
  RelativeContainer,
  LabelIconContainer,
  PlanContainer,
  TriangularLabel,
  useStyles,
  ButtonContainer,
} from './styled'
import CheckOutline from 'assets/check_outline.svg'
import { FunctionComponent, useContext, useState } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Plan } from 'types'
import LearnMoreModal from '../LearnMoreModal'
import { ReactComponent as PartnershipIcon } from 'assets/partnership.svg'
import { formatMoney } from 'utils/MoneyFormat'
import { AlertContext } from 'hooks/AlertContext'

interface Props {
  plan: Plan
  setButtonDisabled: (value: boolean) => void
  amount: number
  selectInvestmentPlan: (item: Plan | null) => void
  isSelected: boolean
}

export const InvestmentPlan: FunctionComponent<Props> = ({
  plan,
  setButtonDisabled,
  amount,
  selectInvestmentPlan,
  isSelected,
}) => {
  const classes = useStyles()
  const { showSnackbar } = useContext(AlertContext)

  const { formatMessage } = useIntl()
  const {
    name,
    interestRatePerMonth,
    minimumInitialInvestment,
    principalLockupInMonths,
    maximumInvestment,
    initialProcessingFee,
    shortDescription,
  } = plan

  const [investmentPlanModalOpen, setInvestmentPlanModalOpen] = useState(false)

  const checkIfAllowed = () =>
    (maximumInvestment && Number(maximumInvestment) < amount) ||
    Number(minimumInitialInvestment) > amount

  const showSnackBar = () => {
    const formattedAmount = formatMoney(Number(minimumInitialInvestment))
    const messageId =
      Number(minimumInitialInvestment) > amount
        ? 'screen.newInvestment.investmentPlan.snackbarMessage.minimumAmount'
        : 'screen.newInvestment.investmentPlan.snackbarMessage.exceededAmount'
    const message = formatMessage({ id: messageId }, { name: name, minimalAmount: formattedAmount })
    showSnackbar({ messageText: message })
  }

  const validateAmount = () => {
    const isDisabled = checkIfAllowed()
    if (isDisabled) {
      showSnackBar()
      selectInvestmentPlan(null)
      setButtonDisabled(true)
    } else {
      selectInvestmentPlan(plan)
      setButtonDisabled(false)
    }
  }

  const onClickSelectPlan = () => {
    validateAmount()
  }

  const onClickDisabledPlan = () => {
    const isDisabled = checkIfAllowed()
    if (isDisabled) {
      showSnackBar()
    }
  }

  const openModal = () => {
    setInvestmentPlanModalOpen(true)
  }

  const closeModal = () => {
    setInvestmentPlanModalOpen(false)
  }

  return (
    <PlanContainer isSelected={isSelected} className={classes.planContainer}>
      {/* TODO: Agent: Check if the icon is needed
      {forAgents && (
        <RelativeContainer>
          <TriangularLabel>
            <RelativeContainer>
              <LabelIconContainer>
                <PartnershipIcon />
              </LabelIconContainer>
            </RelativeContainer>
          </TriangularLabel>
        </RelativeContainer>
      )} */}
      <Typography variant="body1" className={classes.title}>
        {name}
      </Typography>
      <Typography variant="caption" className={classes.description}>
        {shortDescription}
      </Typography>
      <Typography variant="caption" className={classes.minimalAmount}>
        <FormattedMessage id="screen.newInvestment.investmentPlan.minimalAmountLabel" />
        <FormattedNumber
          value={Number(minimumInitialInvestment)}
          style="currency"
          currency="USD"
          maximumFractionDigits={0}
        />
      </Typography>
      <CharacteristicsContainer>
        <img className={classes.img} src={CheckOutline} alt="checkoutline" />
        <Typography variant="caption" className={classes.characteristics}>
          <FormattedMessage
            id="screen.newInvestment.investmentPlan.interestRatePerMonthLabel"
            values={{ interestRatePerMonth: interestRatePerMonth }}
          />
        </Typography>
      </CharacteristicsContainer>
      <CharacteristicsContainer>
        <img className={classes.img} src={CheckOutline} alt="checkoutline" />
        <Typography variant="caption" className={classes.characteristics}>
          <FormattedMessage
            id={
              Number(principalLockupInMonths) <= 1
                ? 'screen.newInvestment.investmentPlan.principalLockupInMonthLabel'
                : 'screen.newInvestment.investmentPlan.principalLockupInMonthsLabel'
            }
            values={{ month: principalLockupInMonths }}
          />
        </Typography>
      </CharacteristicsContainer>
      {initialProcessingFee > 0 && (
        <CharacteristicsContainer>
          <img className={classes.img} src={CheckOutline} alt="checkoutline" />
          <Typography variant="caption" className={classes.characteristics}>
            <FormattedMessage
              id="screen.newInvestment.investmentPlan.processingFee"
              values={{ processingFee: initialProcessingFee }}
            />
          </Typography>
        </CharacteristicsContainer>
      )}
      <Link onClick={openModal} className={classes.link}>
        <FormattedMessage id="components.cards.investmentCard.link" />
      </Link>
      <ButtonContainer onClick={onClickDisabledPlan}>
        <Button
          disabled={checkIfAllowed()}
          variant="outlined"
          onClick={onClickSelectPlan}
          fullWidth
        >
          {isSelected ? (
            <FormattedMessage id="components.cards.investmentCard.ButtonSelected" />
          ) : (
            <FormattedMessage id="components.cards.investmentCard.Button" />
          )}
        </Button>
      </ButtonContainer>
      <LearnMoreModal
        modalOpen={investmentPlanModalOpen}
        handleCloseCallback={closeModal}
        investmentPlan={plan}
      />
    </PlanContainer>
  )
}
