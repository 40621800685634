import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import Appbar from '../components/Appbar'
import useStyles, { TreasurerLayoutChildrenContent } from './styled'
import TreasurerSideBar from '../components/SideBar/Treasurer'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'

const styleProps = {
  display: 'flex',
  alignContent: 'flex-end',
  flex: 2,
  alignItems: 'center',
  gap: '16px',
}

interface Props {
  children: ReactNode
  showSideBar?: boolean
}

const TreasurerLayout: FunctionComponent<Props> = ({ children, showSideBar = true }: Props) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const [openSideBar, setOpenSideBar] = useState(false)
  const isPartnersScreen = location?.pathname?.includes('partners')
  const isAgentCreationScreen = location?.pathname?.includes('configure-agent')
  const layoutHeight = isPartnersScreen ? 'auto' : undefined
  const handleGoBack = () => {
    isPartnersScreen ? navigate('/dashboard') : undefined
    isAgentCreationScreen ? navigate(-1) : undefined
  }

  const handleOpenMenu = () => setOpenSideBar(true)
  const handleCloseMenu = () => setOpenSideBar(false)

  useEffect(() => {
    if (!isResponsive) {
      setOpenSideBar(true)
    }
  }, [isResponsive])

  const screenTitleId = isPartnersScreen
    ? 'app.layout.treasurer.layout.screen.title'
    : isAgentCreationScreen
    ? 'app.layout.treasurer.layout.screen.configureAgent.title'
    : ''

  return (
    <Box className={classes.box}>
      <Appbar
        showGoBackIcon={isPartnersScreen || isAgentCreationScreen}
        showScreenTitle={isPartnersScreen || isAgentCreationScreen}
        screenTitleId={screenTitleId}
        goBackAction={handleGoBack}
        leftAppbarStyleProps={isPartnersScreen || isAgentCreationScreen ? styleProps : undefined}
        openMenu={handleOpenMenu}
        showMenuButton={!openSideBar}
      />
      <TreasurerLayoutChildrenContent height={layoutHeight}>
        {showSideBar && <TreasurerSideBar open={openSideBar} handleCloseMenu={handleCloseMenu} />}
        {children}
      </TreasurerLayoutChildrenContent>
    </Box>
  )
}

export default TreasurerLayout
