import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'
import moment from 'moment'
import { useCallback, useEffect, useRef } from 'react'

let sessionCheckInterval: NodeJS.Timer

const SECOND = 1000
const SESSION_LIFE_TIME_IN_MINUTES = 30

export const useSessionInactivityLifetime = () => {
  const { logout } = useAuth0()
  const dateRef = useRef<string>(moment().add(SESSION_LIFE_TIME_IN_MINUTES, 'minutes').format())

  const removeSessionFromLocalStorageAndLogout = () => {
    logout({
      returnTo: AUTH0_LOGIN_REDIRECT_URL,
    })
  }

  const checkSessionLifetime = () => {
    if (dateRef.current && moment(dateRef.current).isBefore(moment())) {
      removeSessionFromLocalStorageAndLogout()
    }
  }

  const handleUserActivity = useCallback(() => {
    const newDate = moment().add(SESSION_LIFE_TIME_IN_MINUTES, 'minutes')
    dateRef.current = newDate.format()
  }, [])

  useEffect(() => {
    checkSessionLifetime()
    handleUserActivity()
    sessionCheckInterval = setInterval(checkSessionLifetime, SECOND)
    document.addEventListener('touchstart', handleUserActivity)
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)
    document.addEventListener('click', handleUserActivity)

    return () => {
      clearInterval(sessionCheckInterval)
      document.removeEventListener('touchstart', handleUserActivity)
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
      document.removeEventListener('click', handleUserActivity)
    }
  }, [])
}
