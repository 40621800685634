import ProfileOverviewCard from 'components/cards/ProfileOverviewCard'
import { ProfileContainer, ProfileTabContainer, TabContentWrapper } from './styled'
import { Tabs as MuiTabs, Tab } from '@mui/material'
import { ProfileTabs } from 'global/types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import AddressTab from './AddressTab'
import BankAccountTab from './BankAccountTab'

function Profile() {
  const intl = useIntl()

  const [selectedTab, setSelectedTab] = useState(ProfileTabs.BANK_ACCOUNT)

  const handleChaneTab = (event: React.SyntheticEvent, newValue: ProfileTabs) => {
    setSelectedTab(newValue)
  }

  const tabs = [
    {
      tab: ProfileTabs.BANK_ACCOUNT,
      content: <BankAccountTab />,
    },
    {
      tab: ProfileTabs.ADDRESS,
      content: <AddressTab />,
    },
  ]
  return (
    <ProfileContainer>
      <ProfileOverviewCard />
      <ProfileTabContainer>
        <MuiTabs value={selectedTab} onChange={handleChaneTab}>
          {tabs.map((tab, index) => (
            <Tab
              key={`${tab?.tab}${index}`}
              label={intl.formatMessage({ id: `screen.profile.tab${index + 1}.tab` })}
              id={`profile-tab-${tab?.tab}`}
              aria-controls={`profile-tabpanel-${tab?.tab}`}
            />
          ))}
        </MuiTabs>
        <TabContentWrapper>{tabs[selectedTab].content}</TabContentWrapper>
      </ProfileTabContainer>
    </ProfileContainer>
  )
}

export default Profile
