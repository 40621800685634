import { Grid, Typography } from '@mui/material'
import { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import useStyles, { HeaderWrapper, MainContainer, CenterContainer } from './styled'
import { useQuery } from '@apollo/client'
import ScreenSkeleton from './ScreenSkeleton'
import InvestorsList from './Investors'
import { GET_REFERRALS_COUNT_INVESTMENTS } from './queries'
import { ProfileContext } from 'hooks/ProfileContext'

const AgentInvestors: FunctionComponent = () => {
  const classes = useStyles()

  const { dataQueryMe } = useContext(ProfileContext)

  const { loading, data } = useQuery(GET_REFERRALS_COUNT_INVESTMENTS, {
    variables: {
      userId: dataQueryMe?.me?.userId,
    },
  })

  return (
    <MainContainer>
      <Grid container className={classes.mainGrid} direction="column" alignItems="center">
        <CenterContainer className={classes.centerContainer}>
          <HeaderWrapper>
            <Typography variant="h2">
              <FormattedMessage id="screen.agent.myInvestors.title" />
            </Typography>
          </HeaderWrapper>
          {loading ? (
            <ScreenSkeleton />
          ) : (
            <InvestorsList investors={data?.referralsCountInvestmentsByUserId || []} />
          )}
        </CenterContainer>
      </Grid>
    </MainContainer>
  )
}

export default AgentInvestors
