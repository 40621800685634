import { FunctionComponent, useContext } from 'react'
import { useStyles, Container } from './styled'
import { ScheduledWithdrawal } from 'types'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'components/common/styled'
import { useMutation } from '@apollo/client'
import { FINALIZE_SCHEDULED_WITHDRAWAL } from '../../queries'
import FinalizeScheduledWithdrawalModalContent from './FinalizeScheduledWithdrawalModalContent'
import { AlertContext } from 'hooks/AlertContext'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  scheduledWithdrawal?: ScheduledWithdrawal
  investmentUuid?: string
}

const FinalizeScheduledWithdrawalModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  investmentUuid,
  scheduledWithdrawal,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const { showSnackbar } = useContext(AlertContext)

  const [finalizeScheduledWithdrawal, { loading }] = useMutation<
    { finalizeScheduledWithdrawal: { uuid: string } },
    {
      investmentUuid?: string
      scheduledWithdrawalUuid?: string
    }
  >(FINALIZE_SCHEDULED_WITHDRAWAL, {
    variables: {
      investmentUuid: investmentUuid,
      scheduledWithdrawalUuid: scheduledWithdrawal?.uuid,
    },
  })

  const handleCancelWithdrawalRequest = async () => {
    try {
      const result = await finalizeScheduledWithdrawal()
      if (result.data?.finalizeScheduledWithdrawal.uuid) {
        showSnackbar({
          messageId:
            'screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.success.snackbar',
        })
      }
    } catch (e: unknown) {
      showSnackbar({
        messageId:
          'screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.error.snackbar',
      })
    } finally {
      handleCloseCallback()
    }
  }

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      handleActionButton={handleCancelWithdrawalRequest}
      actionMessageId="screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.button.finalize"
      titleMessageId="screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.title"
      descriptionMessage=""
      fullScreen={isResponsive}
      disabledActionButton={loading}
    >
      <Container>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.description01" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.investmentDetail.scheduledWithdrawals.dataGrid.finalizeModal.description02" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <FinalizeScheduledWithdrawalModalContent amount={scheduledWithdrawal?.amount} />
      </Container>
    </Dialog>
  )
}

export default FinalizeScheduledWithdrawalModal
