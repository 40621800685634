import { FunctionComponent } from 'react'
import { ContentContainer } from './styled'
import DataGrid from 'components/common/DataGrid'
import { columnInvestorsByAgents } from 'components/common/DataGrid/utils'
import { useQuery } from '@apollo/client'
import { Profile } from 'global/types'
import { GET_REFERRALS_BY_AGENT } from './queries'

interface Props {
  agentUuid: string
}

const InvestorsByAgent: FunctionComponent<Props> = ({ agentUuid }: Props) => {
  const { data, loading, error } = useQuery<{ referralsByAgent: Profile[] }, { agentUuid: string }>(
    GET_REFERRALS_BY_AGENT,
    { variables: { agentUuid } },
  )

  const columns = columnInvestorsByAgents()

  return (
    <ContentContainer>
      <DataGrid
        columns={columns}
        rows={data?.referralsByAgent || []}
        autoHeight
        getRowId={(row) => row.uuid}
        isLoading={loading}
        errorMessageId="screen.agentDetails.tab.investors.dataGrid.error"
        hasError={Boolean(error)}
      />
    </ContentContainer>
  )
}

export default InvestorsByAgent
