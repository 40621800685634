import { gql } from '@apollo/client'

export const GET_DEPOSITS_REQUESTS = gql`
  query DepositsRequest(
    $status: DepositStatusInput = null
    $page: Int = 0
    $limit: Int = 10
    $orderBy: DepositsRequestListOrderBy = depositDate
    $orderDirection: OrderDirection = desc
    $search: String = ""
  ) {
    depositsRequest(
      status: $status
      page: $page
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
    ) {
      total
      depositsRequest {
        id
        fullName
        status
        investment {
          uuid
          status
          isMonthlyWithdrawal
          profile {
            uuid
          }
          duration {
            endDate
            startDate
          }
          contract {
            id
            url
          }
          plan {
            uuid
            name
            depositFrequency
            includeWeekends
          }
          transactions {
            ... on Deposit {
              uuid
              status
              stripeId
              amount
              acknowledgeAt
              approvedAt
            }
            ... on Earning {
              uuid
              amount
              approvedAt
            }
          }
        }
        deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          createdAt
          approvedAt
          reference
        }
      }
    }
  }
`

export const APPROVE_OR_UNDO_DEPOSIT = gql`
  mutation ApproveDeposit($depositUuid: String!, $approve: Boolean!, $approvedDate: String) {
    approveDeposit(depositUuid: $depositUuid, approve: $approve, approvedDate: $approvedDate) {
      success
      message
      transactionUuid
    }
  }
`

export const ACKNOWLEDGE_OR_UNDO_DEPOSIT = gql`
  mutation AcknowledgeDeposit($depositUuids: [String!]!, $acknowledge: Boolean!) {
    acknowledgeDeposit(depositUuids: $depositUuids, acknowledge: $acknowledge) {
      success
      message
      transactionUuid
    }
  }
`

export const GET_DEPOSIT_RULES_DATES = gql`
  query DepositRulesDates($investmentUuid: String!) {
    depositRulesDates(investmentUuid: $investmentUuid) {
      createdAt
      receivedAt
      targetDate
    }
  }
`
