import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGridCard: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  planRows: {
    flexDirection: 'row',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(2),
  },
  investmentPlan: {
    paddingTop: theme.spacing(6),
  },
  moneyInput: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  continueButton: {
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${theme.spacing(2)};
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  padding-top: ${theme.spacing(5)};
  justify-content: right;
`
