import { Typography } from '@mui/material'
import Card from 'components/common/Card'
import { CardContainer, CardHeader } from 'components/common/Card/styled'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './styled'
import { TableDetailsWrapper } from '../styled'
import PlanDescriptionItem from 'components/PlanDescriptionItem'
import { useQuery } from '@apollo/client'
import { GET_ADDRESS } from '../queries'

const AddressTab: FunctionComponent = () => {
  const classes = useStyles()

  const { data } = useQuery(GET_ADDRESS)

  const mailto = () => {
    window.open('mailto:support@bluedotcapitals.com', '_blank')
  }

  return (
    <Card>
      <CardContainer>
        <CardHeader>
          <Typography variant="h4">
            <FormattedMessage id="screen.profile.tab2.tab" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="screen.profile.tab2.description"
              values={{
                email: <FormattedMessage id="constants.supportEmail" />,
                styled: (chunks) => (
                  <Typography
                    className={classes.link}
                    onClick={mailto}
                    variant="body2"
                    component="span"
                  >
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </CardHeader>
        <TableDetailsWrapper>
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab2.streetName"
            attributeValue={data?.address?.addressOne}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab2.city"
            attributeValue={data?.address?.city}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab2.state"
            attributeValue={data?.address?.state}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab2.postalCode"
            attributeValue={data?.address?.postalCode}
            attributeValueId=""
          />
          <PlanDescriptionItem
            textAttributeWrapperProps={{
              justifyContent: 'flex-start',
            }}
            attributeName="screen.profile.tab2.country"
            attributeValue={data?.address?.country}
            attributeValueId=""
          />
        </TableDetailsWrapper>
      </CardContainer>
    </Card>
  )
}

export default AddressTab
