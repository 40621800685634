import { Skeleton, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import clsx from 'clsx'
import BalanceChip from 'components/BalanceChip'
import Card from 'components/common/Card'
import moment from 'moment'
import { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InterestType, Investment } from 'types'
import { GetFormattedDate } from 'utils/DataFormat'
import {
  EndingBalanceContainer,
  useStyles,
  DetailsContainer,
  ViewDetailsButtonContainer,
} from './styled'
import { ReactComponent as Chevron } from 'assets/not_filled_chevron_down.svg'
import { generatePlanNameWithSuffix } from 'utils/GeneratePlanNameWithSuffix'
import BadgeStatus from 'components/BadgeStatus'

interface Props {
  investment?: Investment
  loading?: boolean
}

const hasActiveScheduledWithdrawal = (investment?: Investment) => {
  return investment?.scheduledWithdrawals?.some(
    (scheduledWithdrawal) => scheduledWithdrawal.status === 'ACTIVE',
  )
}

const InvestmentOverview: FunctionComponent<Props> = ({ investment, loading = false }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [showDetails, setShowDetails] = useState<boolean>(false)

  if (loading) {
    return (
      <Card className={classes.overviewCard}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Card>
    )
  }

  const handleViewDetails = () => {
    setShowDetails(!showDetails)
  }

  return (
    <Card className={classes.overviewCard}>
      {isResponsive && (
        <Typography variant="h4" className={classes.detailsOfTheInvestment}>
          <FormattedMessage id="screen.investmentDetail.detailsOfTheInvestment" />
        </Typography>
      )}
      <Typography variant="body1" className={clsx(classes.white, classes.largeBottomPadding)}>
        <FormattedMessage
          id="screen.investmentDetail.planOverviewTitle"
          values={{
            name: investment?.profile.application.name,
            planName: generatePlanNameWithSuffix(
              investment?.plan.name.toLowerCase(),
              investment?.plan.suffixName,
            ),
          }}
        />
      </Typography>
      <Typography variant="body3">
        <FormattedMessage
          id="screen.investmentDetail.endingBalance"
          values={{ date: moment(investment?.balance?.cutoffDate).format('M/D/YYYY') }}
        />
      </Typography>
      <EndingBalanceContainer>
        <Typography className={clsx(classes.white, classes.largeBottomPadding)} variant="h3">
          {intl.formatNumber(investment?.balance?.total || 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
        {investment?.balance &&
          investment?.balance?.earningPercentage !== 0 &&
          investment.plan.interestType !== InterestType.SIMPLE && (
            <BalanceChip percentage={Number(investment?.balance?.earningPercentage.toFixed(2))} />
          )}
      </EndingBalanceContainer>
      {!showDetails && (
        <DetailsContainer>
          <Typography variant="body3">
            <FormattedMessage id="screen.investmentDetail.investor" />
          </Typography>
          <Typography
            className={clsx(classes.white, classes.largeBottomPadding, classes.underline)}
            variant="body2"
          >
            {investment?.profile.application.name}
          </Typography>

          <Typography variant="body3">
            <FormattedMessage id="screen.investmentDetail.plan" />
          </Typography>
          <Typography
            className={clsx(classes.white, classes.largeBottomPadding, classes.underline)}
            variant="body2"
          >
            {investment?.plan.name}
          </Typography>

          <Typography variant="body3">
            <FormattedMessage id="screen.investmentDetail.currentRateLabel" />
          </Typography>
          <Typography className={clsx(classes.white, classes.largeBottomPadding)} variant="body2">
            <FormattedMessage
              id="screen.investmentDetail.currentRateValue"
              values={{ percentage: investment?.plan.interestRatePerMonth }}
            />
          </Typography>

          <Typography variant="body3">
            <FormattedMessage id="screen.investmentDetail.principalLockup" />
          </Typography>
          <Typography className={clsx(classes.white, classes.largeBottomPadding)} variant="body2">
            {GetFormattedDate(
              moment(investment?.duration.startDate)
                .add(investment?.plan?.principalLockupInMonths, 'months')
                .format() || moment().toString(),
            )}
          </Typography>

          <Typography variant="body3">
            <FormattedMessage id="screen.investmentDetail.scheduledWithdrawals" />
          </Typography>
          <BadgeStatus
            status={hasActiveScheduledWithdrawal(investment) ? 'ACTIVE' : 'INACTIVE'}
            color={hasActiveScheduledWithdrawal(investment) ? 'info' : 'default'}
          />
        </DetailsContainer>
      )}
      {isResponsive && (
        <ViewDetailsButtonContainer>
          <Button variant="text" onClick={handleViewDetails} className={classes.button}>
            <FormattedMessage
              id={
                showDetails
                  ? 'screen.investmentDetail.viewDetails'
                  : 'screen.investmentDetail.hideDetails'
              }
            />
          </Button>
          {showDetails ? (
            <Chevron className={classes.chevron} />
          ) : (
            <Chevron className={clsx(classes.chevron, classes.chevronUp)} />
          )}
        </ViewDetailsButtonContainer>
      )}
    </Card>
  )
}

export default InvestmentOverview
