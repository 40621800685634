import { FunctionComponent, useState, useEffect } from 'react'
import SelectInput from 'components/common/Inputs/SelectInput'
import useStyles, { FlexContainer, MainContainer, DataGridContainer } from './styled'
import { SvgIcon, SelectChangeEvent, MenuItem, Button } from '@mui/material'
import moment from 'moment'
import { ReactComponent as ChevronDown } from 'assets/not_filled_chevron_down.svg'
import DataGrid from 'components/common/DataGrid'
import { ReactComponent as DownloadIcon } from 'assets/download_black.svg'
import { FormattedMessage } from 'react-intl'
import { getPartnersScreenColumns } from 'components/common/DataGrid/utils'
import useRequestsHandlers from './Hooks/requestsHandler'
import Snackbar from 'components/common/Snackbar'
import ScreenSkeleton from './ScreenSkeleton'

export const Partners: FunctionComponent = () => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM'))
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const {
    datesData,
    earningsData,
    loadingDates,
    loadingEarnings,
    loadingDownloadCsv,
    downloadCSV,
    getDates,
    getNetMonthlyEarnings,
  } = useRequestsHandlers({ selectedDate })
  const datesOptions = datesData?.dashboardDates?.dashboardDates
  const columns = getPartnersScreenColumns()
  const rows = earningsData?.referralsMonthlyEarnings?.monthlyEarningsList || []
  const loadingScreen = loadingDates || loadingEarnings

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setSelectedDate(event.target.value)
  }

  const handleClickDownloadCSVButton = async () => {
    try {
      const response = await downloadCSV()
      window.open(response.data.downloadAgentsEarningsCSV, '_blank')
    } catch (error) {
      console.log('Error when downloading csv: ', error)
      setOpenSnackbar(true)
    }
  }

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  useEffect(() => {
    getDates()
    getNetMonthlyEarnings()
  }, [])

  return (
    <MainContainer>
      {loadingScreen ? (
        <ScreenSkeleton />
      ) : (
        <FlexContainer width="100%" flexDirection="column">
          <FlexContainer width="100%" alignItems="center" justifyContent="space-between">
            {datesOptions && (
              <FlexContainer width="130px">
                <SelectInput
                  value={selectedDate}
                  onChange={handleChangeSelect}
                  variant="date"
                  iconComponent={(props) => (
                    <SvgIcon className={classes.iconComponent} {...props}>
                      <ChevronDown />
                    </SvgIcon>
                  )}
                >
                  {datesOptions.map((date: string) => (
                    <MenuItem key={date} value={date}>
                      {moment(date).format('MMM[,] YYYY')}
                    </MenuItem>
                  ))}
                </SelectInput>
              </FlexContainer>
            )}
            <Button
              disabled={loadingScreen || loadingDownloadCsv}
              variant="contained"
              onClick={handleClickDownloadCSVButton}
            >
              <FlexContainer width="100%" alignItems="center" gap="8px">
                <FormattedMessage
                  id="screen.partners.download.csv.button"
                  values={{ downloading: loadingDownloadCsv }}
                />
                <SvgIcon>
                  <DownloadIcon />
                </SvgIcon>
              </FlexContainer>
            </Button>
          </FlexContainer>
          <DataGridContainer>
            <DataGrid
              sx={{ p: '72px 24px 24px 0px' }}
              columns={columns}
              autoHeight
              rows={rows}
              getRowId={({ referralUuid }) => referralUuid}
            />
          </DataGridContainer>
        </FlexContainer>
      )}
      <Snackbar
        messageId="global.error.generalError"
        open={openSnackbar}
        onClose={handleCloseSnackbar}
      />
    </MainContainer>
  )
}

export default Partners
