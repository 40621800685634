import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const ProfileContainer = styled.div`
  padding: ${theme.spacing(5)};
  padding-right: ${theme.spacing(10)};
  display: flex;
  gap: ${theme.spacing(4)}px;
  flex-grow: 1;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
  }
`

export const ProfileTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacing(3)};
  flex: 1;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    margin-left: 0;
  }
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
  margin-top: ${theme.spacing(3)};
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(4)};
`
