import { gql } from '@apollo/client'

export const OVERALL_INVESTMENT_LIST = gql`
  query OverallInvestments(
    $name: String
    $page: Int
    $limit: Int
    $orderBy: String
    $orderDirection: String
    $status: String
  ) {
    overallInvestments(
      name: $name
      page: $page
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
      status: $status
    ) {
      investments {
        investment_uuid
        profile_applicantName
        profile_applicantLevel
        plan_name
        investment_status
        investment_balance
        investment_startDate
        investment_endDate
      }
      total
    }
  }
`
