import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentMyInvestmentTab } from 'global/types'

export interface DetailTab {
  status: AgentMyInvestmentTab
  index: number
}

const initialState = { status: AgentMyInvestmentTab.MY_INVESTMENTS, index: 0 } as DetailTab

const detailsTabStates: DetailTab[] = [
  { status: AgentMyInvestmentTab.MY_INVESTMENTS, index: 0 },
  { status: AgentMyInvestmentTab.MY_INVESTORS_INVESTMENTS, index: 1 },
]

const agentMyInvestmentsSlice = createSlice({
  name: 'agentMyInvestments',
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      const newTab = detailsTabStates[action.payload]
      state.index = newTab.index
      state.status = newTab.status
    },
  },
})

export const { changeTab } = agentMyInvestmentsSlice.actions
export default agentMyInvestmentsSlice.reducer
