import { FunctionComponent } from 'react'
import { useStyles, Wrapper, GridContainer } from './styled'
import { Transaction } from 'types'
import { columnsInvestmentWithdrawals } from 'components/common/DataGrid/utils'
import DataGrid from 'components/common/DataGrid'

interface Props {
  withdrawals: Transaction[]
  investmentUuid: string | undefined
  refetchInvestment: () => void
}

const WithdrawalsTab: FunctionComponent<Props> = ({
  withdrawals,
  investmentUuid,
  refetchInvestment,
}: Props) => {
  const classes = useStyles()

  const columns = columnsInvestmentWithdrawals()

  return (
    <Wrapper>
      <GridContainer>
        <DataGrid
          pagination
          className={classes.dataGrid}
          columns={columns}
          rows={withdrawals}
          emptyMessageId="screen.depositRequests.search.empty"
          errorMessageId="screen.depositRequests.tab.error"
          initialState={{
            sorting: {
              sortModel: [{ field: 'approvedAt', sort: 'desc' }],
            },
            pagination: { page: 0, pageSize: 10 },
          }}
          autoHeight
          getRowId={(row) => row?.uuid}
          activeSorting={true}
          replaceDefaultPropsFromDataGrid={{
            rowCount: undefined,
            page: undefined,
            pageSize: undefined,
          }}
        />
      </GridContainer>
    </Wrapper>
  )
}

export default WithdrawalsTab
