import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'

export const useStyles = makeStyles((theme: Theme) => ({
  internalCard: {
    padding: theme.spacing(5, 3, 5, 3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.buttonColor.stroke,
    gap: theme.spacing(1),
    columnGap: theme.spacing(1),
    color: theme.typographyColor.primaryText,
  },
  cardTitle: {
    color: theme.typographyColor.primaryText,
  },
  cardSubtitle: {
    flex: 'auto',
    color: theme.typographyColor.secondaryText,
    justifySelf: 'flex-start',
  },
  bodyWrapper: {
    marginBottom: theme.spacing(3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export const OutlinedCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-self: stretch;
  flex-direction: column;
  justify-content: space-between;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CardIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #2d353e;
`
export const ImageIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const ButtonIcon = styled.img`
  margin-left: 10px;
  width: 16px;
  height: 16px;
`
