import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '770px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  cardContainer: {
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
      gap: 8,
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(3),
  },
  marginBottom: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  formControl: {
    width: '100%',
  },
  minimalAmount: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
  },
  shortDescription: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(1),
  },
  downgradePlan: {
    color: theme.typographyColor.secondaryText,
    paddingTop: theme.spacing(3),
  },
}))

export const CenterContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BackNextButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(3)};
`

export const Divider = styled.div`
  border: 1px solid ${theme.typographyColor.secondaryText}10;
  margin: ${theme.spacing(3, 0, 3, 0)};
`
