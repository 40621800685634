import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from 'screens/App/theme'

interface RowProps {
  grayBackground?: boolean
  borderBottom?: boolean
}

export const useStyles = makeStyles((theme: Theme) => ({
  mainGridCard: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  gray: {
    color: theme.typographyColor.secondaryText,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  backIcon: {
    '& path': {
      stroke: theme.typographyColor.primaonActivePrimaryButtonTextryText,
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(2),
  },
}))

export const TopTitleButtonContainer = styled.div`
  margin-bottom: ${theme.spacing(4)};
`

export const BalanceContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
`

export const BalanceItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(0.5)};
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
`

export const WithdrawalRow = styled.div<RowProps>`
  width: 100%;
  padding: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.grayBackground && theme.extraBackgrounds.paper06};
  border-bottom: ${(props) => props.borderBottom && `1px solid ${theme.extraBackgrounds.paper06}`};
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  padding-top: ${theme.spacing(5)};
  justify-content: right;
  gap: ${theme.spacing(3)};
`
