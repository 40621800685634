import { Box, Skeleton, SxProps, Theme, Typography } from '@mui/material'
import {
  DataGrid as DataGridMui,
  GridCallbackDetails,
  GridColDef,
  GridRowParams,
  GridSelectionModel,
  GridToolbarContainer,
  GridFeatureMode,
  GridInitialState,
  GridRowId,
  GridValidRowModel,
} from '@mui/x-data-grid'
import { GridSlotsComponent, GridSlotsComponentsProps, GridSortModel } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import clsx from 'clsx'
import { DataGridWrapper } from 'components/InvestmentDataGrid/styled'
import { CSSProperties, FunctionComponent, MutableRefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { GridDataRow } from 'types'
import Checkbox from '../Checkbox'
import { ExtraHeader, HeaderContainer, useStyles } from './styled'

interface Props {
  apiRef?: MutableRefObject<GridApiPro | null>
  columns: GridColDef[]
  rows: GridDataRow[]
  isLoading?: boolean
  disableColumnFilter?: boolean
  disableColumnMenu?: boolean
  headerHeight?: number
  rowHeight?: number
  rowCount?: number
  rowsPerPageOptions?: number[]
  page?: number
  pageSize?: number
  pagination?: true | undefined
  paginationMode?: GridFeatureMode
  onPageChange?: (page: number) => void
  onPageSizeChange?: (page: number) => void
  checkboxSelection?: boolean
  checkboxDisabled?: boolean
  onRowClick?: (params: GridRowParams) => void
  onSelectionModelChange?: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails,
  ) => void
  extraHeader?: JSX.Element | JSX.Element[]
  emptyMessageId?: string
  hasError?: boolean
  errorMessageId?: string
  autoHeight?: boolean
  className?: string
  initialState?: GridInitialState
  getRowId?: (row: GridValidRowModel) => GridRowId
  componentsProps?: GridSlotsComponentsProps
  components?: Partial<GridSlotsComponent>
  style?: CSSProperties
  sx?: SxProps<Theme>
  activeSorting?: boolean
  sortingMode?: GridFeatureMode
  onSortModelChange?: (sortModel: GridSortModel) => void
  replaceDefaultPropsFromDataGrid?: Partial<Parameters<typeof DataGridMui>[0]>
}

const DataGrid: FunctionComponent<Props> = ({
  apiRef,
  columns,
  rows = [],
  isLoading = false,
  headerHeight = 40,
  rowHeight = 80,
  rowCount = 5,
  page = 0,
  pageSize = 10,
  pagination = undefined,
  paginationMode = 'client',
  rowsPerPageOptions = [10, 20, 50],
  onPageChange,
  onPageSizeChange,
  checkboxSelection = false,
  checkboxDisabled = false,
  onRowClick,
  onSelectionModelChange,
  extraHeader,
  emptyMessageId = 'component.dataGrid.default.empty',
  hasError = false,
  errorMessageId = 'component.dataGrid.default.error',
  autoHeight = false,
  className,
  initialState,
  getRowId,
  componentsProps,
  components,
  style,
  sx,
  activeSorting = false,
  sortingMode = 'client',
  onSortModelChange,
  replaceDefaultPropsFromDataGrid,
}: Props) => {
  const classes = useStyles()

  const DataGridSkeleton = () => {
    return (
      <Box sx={{ height: 'max-content' }}>
        {[...Array(5)].map((_, index) => (
          <Skeleton variant="rectangular" key={index} sx={{ my: 4, mx: 1 }} height={80} />
        ))}
      </Box>
    )
  }

  const DataGridExtraHeader = () => {
    if (hasError || !rows.length) {
      return (
        <ExtraHeader>
          <Typography variant="body3" className={classes.white}>
            <FormattedMessage id={hasError ? errorMessageId : emptyMessageId} />
          </Typography>
        </ExtraHeader>
      )
    }
    if (extraHeader) {
      return <ExtraHeader>{extraHeader}</ExtraHeader>
    }
    return (
      <HeaderContainer hasHeader={extraHeader}>
        {/* TODO: Remove when filters are implemented */}
        {/* <GridToolbarFilterButton /> */}
      </HeaderContainer>
    )
  }

  if (isLoading) {
    return (
      <DataGridWrapper>
        <ExtraHeader>{extraHeader}</ExtraHeader>
        <DataGridSkeleton />
      </DataGridWrapper>
    )
  }

  return (
    <DataGridWrapper
      className={clsx({
        [classes.heightAuto]: !rows.length,
      })}
    >
      {!rows.length && <DataGridExtraHeader />}
      {rows.length > 0 && (
        <DataGridMui
          sx={sx}
          ref={apiRef as MutableRefObject<HTMLDivElement | null>}
          style={style || { border: 'none' }}
          className={clsx(classes.dataGrid, className)}
          disableSelectionOnClick
          columns={
            activeSorting ? columns : columns.map((column) => ({ ...column, sortable: false }))
          }
          rows={rows}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          pagination={pagination}
          paginationMode={paginationMode}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          autoHeight={autoHeight}
          disableColumnMenu
          hideFooter={!pagination}
          checkboxSelection={checkboxSelection}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          onRowClick={onRowClick}
          onSelectionModelChange={onSelectionModelChange}
          localeText={{
            toolbarFilters: '',
          }}
          componentsProps={{
            baseCheckbox: {
              disabled: checkboxDisabled,
            },
            ...componentsProps,
          }}
          components={{
            BaseCheckbox: Checkbox,
            Toolbar: () => (
              <GridToolbarContainer>
                <DataGridExtraHeader />
              </GridToolbarContainer>
            ),
            ...components,
          }}
          initialState={initialState}
          getRowId={getRowId}
          sortingMode={sortingMode === 'server' && Boolean(onSortModelChange) ? 'server' : 'client'}
          onSortModelChange={onSortModelChange}
          {...replaceDefaultPropsFromDataGrid}
        />
      )}
    </DataGridWrapper>
  )
}

export default DataGrid
