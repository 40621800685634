import React, { FunctionComponent, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = ({ children }: Props) => {
  return <>{children}</>
}

export default Layout
