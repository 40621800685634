import Card from 'components/common/Card'
import { FunctionComponent, useRef } from 'react'
import {
  CardWrapper,
  CenterContainer,
  ContactWrapper,
  DescriptionWrapper,
  IconWrapper,
  useStyles,
  ActionWrapper,
  BankInformationContainer,
  PlanDescriptionContainer,
  PlanDescriptionItem,
} from './styled'
import GreenCheck from 'assets/green_check.svg'
import { Button, Skeleton, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_INVESTMENT } from './queries'
import { useQuery } from '@apollo/client'
import { Investment, InvestmentStatus } from 'types'
import { theme } from 'screens/App/theme'
import { Divider } from 'components/common/styled'
import BankInformation from 'components/BankInformation'
import BadgeStatus, { BadgeStatusColor } from 'components/BadgeStatus'
import { colorInvestmentStatus } from 'utils/StatusColors'
import InlineAlert from 'components/Alert/InlineAlert'

const FinishNewInvestment: FunctionComponent = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { uuid } = useParams()
  const investment = useRef<Investment | null>(null)
  const { loading, data } = useQuery(GET_INVESTMENT, {
    variables: {
      uuid,
    },
  })

  investment.current = data?.investment
  const mailto = () => {
    window.open('mailto:support@bluedotcapitals.com', '_blank')
  }

  const goToHome = () => {
    navigate('/home')
  }

  if (loading) {
    return (
      <CenterContainer className={classes.centerContainer}>
        <Card className={classes.cardContainer}>
          <CardWrapper>
            <Skeleton variant="circular">
              <IconWrapper />
            </Skeleton>
          </CardWrapper>
          <Skeleton width="50%">
            <Typography variant="body2">
              <FormattedMessage
                id="screen.finishInvestment.invoiceNumber"
                values={{ number: investment?.current?.uuid }}
              />
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="h1">
              <FormattedMessage id="screen.finishInvestment.investmentDone" />
            </Typography>
          </Skeleton>
          <Skeleton width="100%" height={60}></Skeleton>
          <ContactWrapper>
            <Skeleton width="100%" height={80}></Skeleton>
          </ContactWrapper>
          <Skeleton>
            <ActionWrapper>
              <Button>
                <FormattedMessage id="screen.finishInvestment.button" />
              </Button>
            </ActionWrapper>
          </Skeleton>
        </Card>
      </CenterContainer>
    )
  }

  return (
    <CenterContainer className={classes.centerContainer}>
      <Card className={classes.cardContainer}>
        <CardWrapper>
          <IconWrapper>
            <img src={GreenCheck} alt="check" />
          </IconWrapper>
          <Typography variant="h1">
            <FormattedMessage id="screen.finishInvestment.investmentDone" />
          </Typography>
        </CardWrapper>
        <Divider size={theme.spacing(3)} />
        <Typography variant="h3">
          <FormattedMessage id="screen.finishInvestment.investmentSummary" />
        </Typography>
        <PlanDescriptionContainer className={classes.planDescription}>
          <PlanDescriptionItem>
            <Typography variant="body2">
              <FormattedMessage id="screen.newInvestment.stepper3.capitalToFund" />
            </Typography>
            <Typography variant="h2">
              {investment.current?.transactions[0].amount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </PlanDescriptionItem>
          <PlanDescriptionItem>
            <Typography variant="body2">
              <FormattedMessage id="screen.newInvestment.stepper3.interestRate" />
            </Typography>
            <Typography variant="h4">
              <FormattedMessage
                id="components.modals.stepper3.interestRateValue"
                values={{ value: investment.current?.plan.interestRatePerMonth }}
              />
              <Typography variant="body3" className={classes.body3}>
                <FormattedMessage id="components.modals.stepper3.interestRateValuePerMonth" />
              </Typography>
            </Typography>
          </PlanDescriptionItem>
          <PlanDescriptionItem>
            <Typography variant="body2">
              <FormattedMessage id="screen.newInvestment.stepper3.plan" />
            </Typography>
            <Typography variant="h4">{investment.current?.plan.name}</Typography>
          </PlanDescriptionItem>

          <PlanDescriptionItem>
            <Typography variant="body2">
              <FormattedMessage id="screen.newInvestment.stepper3.principalLockup" />
            </Typography>
            <Typography variant="h4">
              <FormattedMessage
                id={`screen.newInvestment.stepper3.month${
                  Number(investment.current?.plan?.principalLockupInMonths) > 1
                    ? 'Plural'
                    : 'Singular'
                }`}
                values={{ value: investment.current?.plan?.principalLockupInMonths }}
              />
            </Typography>
          </PlanDescriptionItem>
          <PlanDescriptionItem>
            <Typography variant="body2">
              <FormattedMessage id="screen.newInvestment.stepper3.status" />
            </Typography>
            <BadgeStatus
              status={investment.current?.status || ''}
              color={
                investment.current?.status
                  ? colorInvestmentStatus[investment.current?.status as InvestmentStatus]
                  : BadgeStatusColor.DEFAULT
              }
            />
          </PlanDescriptionItem>
        </PlanDescriptionContainer>
        <Typography variant="h4" color="white">
          <FormattedMessage id="screen.finishInvestment.bank.information" />
        </Typography>
        <BankInformationContainer>
          <BankInformation referenceNumber={investment.current?.transactions[0].reference} />
        </BankInformationContainer>
        <InlineAlert
          variant="standard"
          severity="warning"
          message="Please make sure to include the Reference number in the description of your transfer"
        />
        <ActionWrapper className={classes.actionWrapper}>
          <Button variant="outlined" color="primary" onClick={goToHome}>
            <FormattedMessage id="screen.finishInvestment.button" />
          </Button>
          <Stack>
            <Typography variant="body2">
              <FormattedMessage id="screen.finishInvestment.needHelp" />
            </Typography>
            <Typography variant="body2" className={classes.link} onClick={mailto}>
              <FormattedMessage id="screen.finishInvestment.contactSupport" />
            </Typography>
          </Stack>
        </ActionWrapper>
      </Card>
    </CenterContainer>
  )
}

export default FinishNewInvestment
