import styled from 'styled-components'

interface MainContainerProps {
  width?: string
  height?: string
}

export const MainContainer = styled.div`
  width: ${({ width }: MainContainerProps) => width};
  height: ${({ height }: MainContainerProps) => height};
`
