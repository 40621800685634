import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  useStyles,
  MainContainer,
  CenterContainer,
  TopTitleButtonContainer,
  StepperContentContainer,
} from './styled'
import StepperCard from 'components/Stepper'
import ContractSign from 'screens/NewInvestment/ContractSign'
import Snackbar from '../../components/common/Snackbar'
import InvestmentAmount from './InvestmentAmount'
import { FundInvestmentTabs, StepNumber, StepperNewInvestment } from 'global/types'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_PLANS } from './InvestmentPlan/queries'
import { Contract, DepositType, DocusignUrlResponse, Plan } from 'types'
import FundInvestment from './FundInvestment'
import { CREATE_INVESTMENT, SAVE_DRAFT_INVESTMENT } from './queries'
import { useNavigate } from 'react-router-dom'
import { useLoading } from 'hooks/LoadingHook'
import { AlertContext } from 'hooks/AlertContext'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import {
  changeCapitalToFund,
  changeContract,
  changeMemorandum,
  changeInvestmentPlan,
  changeInvestmentTab,
  clearNewInvestment,
  changeInvestmentUuid,
} from './reducer'
import { GET_DOCUSIGN_CONTRACT_URL, GET_DOCUSIGN_MEMORANDUM_URL } from './queries'
import LoadingLayout from 'components/common/Loading'
import ResponsiveStepperCard from 'components/Stepper/ResponsiveStepper'
import * as Sentry from '@sentry/react'

const { NONE, FIRST } = StepNumber
const { INVESTMENT_PLAN, CONTRACTS_SIGNING, INVESTMENT_FUNDING } = StepperNewInvestment

const NewInvestment = () => {
  const newInvestmentStore = useSelector((state: GlobalState) => state.newInvestment)
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showSnackbar } = useContext(AlertContext)
  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))

  const { data } = useQuery(GET_ALL_PLANS)
  const plans: Plan[] = data?.plans || []
  const [open, setOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [activeStep, setActiveStep] = useState<number>(newInvestmentStore.activeStep)
  const [investmentPlanSelected, setInvestmentPlanSelected] = useState<Plan | null>(
    newInvestmentStore.investmentPlanSelected,
  )
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    newInvestmentStore.capitalToFund.length == 0 ||
      newInvestmentStore.investmentPlanSelected === null,
  )
  const [capitalToFund, setCapitalToFund] = useState<string>(newInvestmentStore.capitalToFund)
  const [publicToken, setPublicToken] = useState<string | null>(null)
  const [accountId, setAccountId] = useState<string | null>(null)
  const [contract, setContract] = useState<Contract | null>(newInvestmentStore.contract)
  const [memorandum, setMemorandum] = useState<Contract | null>(newInvestmentStore.memorandum)
  const [createInvestment] = useMutation(CREATE_INVESTMENT)
  const [disableNextStep, setDisableNextStep] = useState<boolean>(false)
  const [enableSecondStep, setEnableSecondStep] = useState<boolean>(false)
  const [enableThirdStep, setEnableThirdStep] = useState<boolean>(false)
  const [depositType, setDepositType] = useState<FundInvestmentTabs>(
    FundInvestmentTabs.WIRE_TRANSFER,
  )
  const { isLoading, startLoading, stopLoading } = useLoading(false)
  const investmentSteps = [INVESTMENT_PLAN, CONTRACTS_SIGNING, INVESTMENT_FUNDING]

  const [generateDocusignContractUrl] = useMutation(GET_DOCUSIGN_CONTRACT_URL, {
    variables: {
      investmentUuid: newInvestmentStore.uuid,
      planUuid: investmentPlanSelected?.uuid,
      amount: parseFloat(capitalToFund.replaceAll(',', '')),
    },
  })
  const [generateDocusignMemorandumUrl] = useMutation(GET_DOCUSIGN_MEMORANDUM_URL, {
    variables: {
      investmentUuid: newInvestmentStore.uuid,
      planUuid: investmentPlanSelected?.uuid,
      amount: parseFloat(capitalToFund.replaceAll(',', '')),
    },
  })

  const [saveDraftInvestment] = useMutation(SAVE_DRAFT_INVESTMENT, {
    variables: {
      investmentUuid: newInvestmentStore.uuid,
      planUuid: investmentPlanSelected?.uuid,
      initialAmount: parseFloat(capitalToFund.replaceAll(',', '')),
      memorandum: {
        id: memorandum?.id || '',
        url: memorandum?.url || '',
      },
      contract: {
        id: contract?.id || '',
        url: contract?.url || '',
      },
    },
  })

  const openModal = () => {
    setOpen(true)
    setButtonDisabled(true)
  }

  const closeModal = () => {
    setOpen(false)
    setSnackbarMessage('')
  }

  const generateContractUrl = async () => {
    try {
      startLoading()
      const response = await generateDocusignContractUrl()
      const docusignResponse: DocusignUrlResponse = response.data
        .generateDocuSignUrl as DocusignUrlResponse
      const contract: Contract = {
        id: docusignResponse.envelopeId,
        url: docusignResponse.url,
        signUrl: docusignResponse.signUrl,
      }
      const savedDraft = await saveDraftInvestment({
        variables: {
          investmentUuid: newInvestmentStore.uuid,
          planUuid: investmentPlanSelected?.uuid,
          initialAmount: parseFloat(capitalToFund.replaceAll(',', '')),
          memorandum: {
            id: memorandum?.id || '',
            url: memorandum?.url || '',
          },
          contract: {
            id: contract.id,
            url: contract.url,
          },
        },
      })
      const draftInvestment = savedDraft.data.saveDraftInvestment
      dispatch(changeInvestmentUuid(draftInvestment.uuid))
      dispatch(changeContract(contract))
      setContract(contract)
      window.location.href = docusignResponse.signUrl
    } catch (e: unknown) {
      Sentry.captureException(e)
      // TODO: Change messageId
      showSnackbar({
        messageId: 'screen.welcome.personalInvestorCard.newInvestmentButton.error',
      })
    } finally {
      stopLoading()
    }
  }

  const generateMemorandumUrl = async () => {
    try {
      startLoading()
      const response = await generateDocusignMemorandumUrl()
      const docusignResponse: DocusignUrlResponse = response.data
        .generateDocuSignUrl as DocusignUrlResponse
      const memorandum: Contract = {
        id: docusignResponse.envelopeId,
        url: docusignResponse.url,
        signUrl: docusignResponse.signUrl,
      }
      const savedDraft = await saveDraftInvestment({
        variables: {
          investmentUuid: newInvestmentStore.uuid,
          planUuid: investmentPlanSelected?.uuid,
          initialAmount: parseFloat(capitalToFund.replaceAll(',', '')),
          memorandum: {
            id: memorandum.id,
            url: memorandum.url,
          },
          contract: {
            id: contract?.id || '',
            url: contract?.url || '',
          },
        },
      })
      const draftInvestment = savedDraft.data.saveDraftInvestment
      dispatch(changeInvestmentUuid(draftInvestment.uuid))
      dispatch(changeMemorandum(memorandum))
      setMemorandum(memorandum)
      window.location.href = docusignResponse.signUrl
    } catch (e: unknown) {
      Sentry.captureException(e)
      // TODO: Change messageId
      showSnackbar({
        messageId: 'screen.welcome.personalInvestorCard.newInvestmentButton.error',
      })
    } finally {
      stopLoading()
    }
  }

  const changeActiveStep = async (step: number) => {
    dispatch(changeInvestmentTab(step))
    setActiveStep(step)
  }

  const updateCapitalToFund = (capital: string) => {
    dispatch(changeCapitalToFund(capital))
    setCapitalToFund(capital)
  }

  const updateInvestmentPlanSelected = (plan: Plan | null) => {
    dispatch(changeInvestmentPlan(plan))
    setInvestmentPlanSelected(plan)
  }
  const onClickButtons = (step: number) => () => changeActiveStep(step)

  const finishInvestment = async (depositType: FundInvestmentTabs) => {
    try {
      startLoading()
      const response = await createInvestment({
        variables: {
          investmentUuid: newInvestmentStore.uuid,
          planUuid: investmentPlanSelected?.uuid,
          amount: parseFloat(capitalToFund.replaceAll(',', '')),
          contract: {
            id: contract?.id || '',
            url: contract?.url || '',
          },
          memorandum: {
            id: memorandum?.id || '',
            url: memorandum?.url || '',
          },
          bankAccount: {
            token: publicToken || '',
            accountId: accountId || '',
          },
          depositType:
            depositType === FundInvestmentTabs.WIRE_TRANSFER
              ? DepositType.WIRE_TRANSFER
              : DepositType.STRIPE,
        },
      })
      dispatch(clearNewInvestment())
      navigate(`/finish-investment/${response?.data?.createInvestment?.uuid}?type=${depositType}`)
    } catch (e: unknown) {
      showSnackbar({
        messageId: 'screen.welcome.personalInvestorCard.newInvestmentButton.error',
      })
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    if (snackbarMessage.length) {
      openModal()
      setButtonDisabled(true)
    }
  }, [snackbarMessage])

  useEffect(() => {
    if (activeStep === 0) {
      setDisableNextStep(capitalToFund === '0' || !investmentPlanSelected)
    } else if (activeStep === 1) {
      setDisableNextStep(enableSecondStep)
    } else {
      setDisableNextStep(enableThirdStep)
    }
  }, [activeStep, capitalToFund, investmentPlanSelected, enableSecondStep, enableThirdStep])

  return (
    <MainContainer>
      <Grid container className={classes.mainGrid} direction="column" alignItems="center">
        <Snackbar
          open={open}
          onClose={closeModal}
          messageText={snackbarMessage}
          messageId="screen.welcome.personalInvestorCard.newInvestmentButton.error"
        />
        <CenterContainer className={classes.centerContainer}>
          <TopTitleButtonContainer>
            <Typography variant="h2">
              <FormattedMessage id="screen.welcome.personalInvestorCard.newInvestmentButton" />
            </Typography>
          </TopTitleButtonContainer>
          <StepperContentContainer>
            {hideInResponsive && (
              <StepperCard steps={investmentSteps} activeStep={activeStep} widthStepper="657px" />
            )}
            {activeStep === NONE ? (
              <InvestmentAmount
                handleStepNumber={changeActiveStep}
                isButtonDisabled={setButtonDisabled}
                buttonDisabled={buttonDisabled}
                plans={plans}
                selectInvestmentPlan={updateInvestmentPlanSelected}
                investmentPlanSelected={investmentPlanSelected}
                setCapitalToFund={updateCapitalToFund}
                capitalToFund={capitalToFund}
                isDisabledChangeAmount={Boolean(newInvestmentStore.uuid)}
              />
            ) : activeStep === FIRST ? (
              <ContractSign
                handleStepNumber={onClickButtons}
                handleDisableNextStep={setEnableSecondStep}
                generateContractUrl={generateContractUrl}
                generateMemorandumUrl={generateMemorandumUrl}
              />
            ) : (
              <FundInvestment
                investmentPlan={investmentPlanSelected}
                capitalToFund={capitalToFund}
                setPublicToken={setPublicToken}
                setAccountId={setAccountId}
                finishInvestment={finishInvestment}
                isLoading={isLoading}
                handleDisableNextStep={setEnableThirdStep}
                handleSelectedDepositType={setDepositType}
              />
            )}
          </StepperContentContainer>
        </CenterContainer>
        {isLoading && <LoadingLayout />}
      </Grid>
      {!hideInResponsive && (
        <ResponsiveStepperCard
          activeStep={activeStep}
          steps={investmentSteps}
          setActiveStep={changeActiveStep}
          disableNextButton={disableNextStep}
          handleFinishAction={() => finishInvestment(depositType)}
        />
      )}
    </MainContainer>
  )
}

export default NewInvestment
