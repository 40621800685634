import { FunctionComponent } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import PlanDescriptionItem from 'components/PlanDescriptionItem'
import { TableDetailsWrapper } from './styled'
import { Plan } from 'types'
import { formatMoney } from 'utils/MoneyFormat'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  investmentPlan: Plan
}

const LearnMoreModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  investmentPlan,
}: Props) => {
  const titleMessage = investmentPlan.suffixName
    ? investmentPlan.name + ' ' + investmentPlan.suffixName
    : investmentPlan.name

  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      actionMessageId="components.button.close"
      handleActionButton={handleCloseCallback}
      titleMessage={titleMessage}
      descriptionMessage={investmentPlan.description}
      fullScreen={isResponsive}
    >
      <TableDetailsWrapper>
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.interestRate"
          attributeValue={investmentPlan.interestRatePerMonth.toString()}
          attributeValueId="components.modals.investmentModal.interestRateValue"
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.minimunInvestment"
          attributeValue={formatMoney(Number(investmentPlan.minimumInitialInvestment))}
          attributeValueId="components.modals.investmentModal.minimunInvestmentValue"
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.principalLockup"
          attributeValue={investmentPlan.principalLockupInMonths.toString()}
          attributeValueId={`components.modals.investmentModal.principalLockupValue${
            Number(investmentPlan.principalLockupInMonths) > 1 ? 'Plural' : 'Singular'
          }`}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.renewalLockup"
          attributeValue={investmentPlan.renewalPeriodLockInMonths.toString()}
          attributeValueId={`components.modals.investmentModal.principalLockupValue${
            Number(investmentPlan.principalLockupInMonths) > 1 ? 'Plural' : 'Singular'
          }`}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.withdrawals"
          attributeValue={investmentPlan.withdrawalAllowed ? 'Yes, optional' : 'N/A'}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.scheduledWithdrawals"
          attributeValue={investmentPlan.scheduledWithdrawalAllowed ? 'Yes, optional' : 'N/A'}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.autoRenewal"
          attributeValue={investmentPlan.autoRenewal ? 'Yes' : 'No'}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="components.modals.investmentModal.earlyWithdrawalsAllowed"
          attributeValue={
            investmentPlan.earlyWithdrawalAllowed
              ? `Yes, with ${investmentPlan.earlyWithdrawalPenalty}% penalty`
              : 'N/A'
          }
          attributeValueId={
            investmentPlan.earlyWithdrawalAllowed
              ? ''
              : 'components.modals.investmentModal.notApplicable'
          }
          isMarkdown={true}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
            padding: '0px 8px',
          }}
        />
        <PlanDescriptionItem
          attributeName="Contract ID"
          attributeValue={investmentPlan.contractId}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
        <PlanDescriptionItem
          attributeName="Memorandum ID"
          attributeValue={investmentPlan.memorandumId}
          textAttributeWrapperProps={{
            justifyContent: 'flex-start',
          }}
        />
      </TableDetailsWrapper>
    </Dialog>
  )
}

export default LearnMoreModal
