import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    minHeight: 'calc(100vh - 80px)',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  topTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonsContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
  },
  investmentDetailContainer: {
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      width: '100%',
      overflowX: 'auto',
    },
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '190px',
    },
  },
}))

export const MainContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  color: ${theme.typographyColor.primaryText};
  width: 100%;
  gap: 16px;
  display: flex;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    padding-bottom: ${theme.spacing(3)};
  }
`

export const OverviewContainerColumn = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
`

export const InvestmentDetailContainer = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
`

export const TopTitleButtonContainer = styled.div`
  width: 100%;
  line-height: 48px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing(3)};
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`
