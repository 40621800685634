import { useAuth0 } from '@auth0/auth0-react'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LoginCallback: FunctionComponent = () => {
  const navigate = useNavigate()
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()

  const getAccessToken = async () => {
    try {
      await getAccessTokenSilently({
        ignoreCache: true,
        redirect_uri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL,
      })
      navigate('/welcome')
    } catch (error) {
      loginWithRedirect({
        redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL,
      })
    }
  }
  useEffect(() => {
    getAccessToken()
  }, [getAccessToken])

  return <div />
}

export default LoginCallback
