import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import React, { ChangeEvent, FunctionComponent } from 'react'
import { theme } from 'screens/App/theme'
import { DepositFrequency, InterestType, Plan } from 'types'
import { TextFieldContainer, useStyles } from './styled'
import CheckStateOff from 'assets/state_off.svg'
import CheckStateOn from 'assets/state_on.svg'
import { ButtonWrapper, Divider } from '../styled'
import { FormattedMessage } from 'react-intl'

interface Props {
  plan?: Plan
  callToActionMessageId: string
  handleSave?: (plan: Plan) => void
  loadingSave?: boolean
}

const validateMoney = (data: {
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  min?: number
  max?: number
}) => {
  const { event, min, max } = data
  const value = event.target.value
  const isValidNumber = numberRegex.test(value)
  if (!value) return ''
  if (value.length > 1 && !isValidNumber) {
    event.target.value = value.replace(/[^0-9.,]/g, '')
    return '0'
  }
  const validRange = validateRange({
    value,
    min,
    max,
  })
  const formattedValue = moneyFormatted(validRange)
  event.target.value = formattedValue
  return formattedValue
}

const toNumber = (value: string | number) => {
  if (typeof value === 'number') return value
  return parseFloat(value.replace(/[^0-9.]/g, ''))
}

const validateRange = (data: { value: string; min?: number; max?: number }) => {
  const { value, min, max } = data
  const numberValue = toNumber(value)
  if (min && numberValue < min) return min.toString()
  if (max && numberValue > max) return max.toString()
  return value
}

const depositFrequencyLabel = {
  [DepositFrequency.NEXT_MONTH]: 'Next Month',
  [DepositFrequency.NEXT_MONDAY]: 'Next Monday',
}

const interestTypeLabel = {
  [InterestType.COMPOUND]: 'Compound',
  [InterestType.SIMPLE]: 'Simple',
}

const addCentsCommas = (value: string) => {
  const hasPeriod = value.includes('.')
  const [integer, decimal] = value.split('.')
  const formattedNumber = integer
    .replace(/[^0-9]/g, '')
    .replace(/^0+(?=\d)/, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return hasPeriod ? formattedNumber + '.' + decimal.slice(0, 2) : formattedNumber
}
const numberRegex = /^[+-]?([0-9,]+([.][0-9]*)?|[.][0-9]+)$/
const integerRegex = /^[0-9]*$/

const moneyFormatted = (value: string) => {
  if (!value) return ''
  if (value === '0') return '0'
  if (value === '.') return '0.'
  if (!numberRegex.test(value)) return '0'
  if (value.startsWith('.')) return '0' + value
  return addCentsCommas(value)
}

const percentageFormatted = (value: string) => {
  if (!value) return ''
  if (value === '0') return '0'
  if (value === '.') return '0.'
  if (!numberRegex.test(value)) return '0'
  if (value.startsWith('.')) return '0' + value
  return value.replace(/^0+(?=\d)/, '')
}

const integerFormatted = (value: string) => {
  if (!value) return ''
  if (value === '0') return '0'
  if (!integerRegex.test(value)) return '0'
  return value.replace(/^0+(?=\d)/, '')
}

const numberProperties = [
  'minimumInitialInvestment',
  'interestRatePerMonth',
  'principalLockupInMonths',
  'renewalPeriodLockInMonths',
  'autoRenewalPeriodInMonths',
  'maxAutoRenewalPeriod',
  'earlyWithdrawalPenalty',
  'earningPenalty',
  'initialProcessingFee',
  'earlyCancellationPenalty',
  'deadlineDays',
] as const

interface ContainerProps {
  children: JSX.Element | JSX.Element[]
}

const Container: FunctionComponent<ContainerProps> = ({ children }: ContainerProps) => (
  <Grid container xs={12} spacing={2} paddingTop={2}>
    {children}
  </Grid>
)

interface ItemProps {
  children?: JSX.Element | JSX.Element[]
  xs?: number
}
const Item: FunctionComponent<ItemProps> = ({ children, xs = 6 }: ItemProps) => (
  <Grid item xs={xs}>
    {children}
  </Grid>
)

const validateInput = (data: {
  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  type: 'PERCENTAGE' | 'INTEGER' | 'MONEY'
  min?: number
  max?: number
}) => {
  const { e, type, min, max } = data
  if (type === 'MONEY') {
    const validRange = validateMoney({ event: e, min, max })
    e.target.value = validRange
    return validRange
  }
  if (type === 'PERCENTAGE') {
    const validRange = validateRange({ value: e.target.value, min: 0, max: 100 })
    const formattedValue = percentageFormatted(validRange)
    e.target.value = formattedValue
    return validRange
  }
  if (type === 'INTEGER') {
    const validRange = validateRange({ value: e.target.value, min, max })
    const formattedValue = integerFormatted(validRange)
    e.target.value = formattedValue
    return validRange
  }
}

const PlanForm: FunctionComponent<Props> = ({
  plan,
  handleSave,
  loadingSave = false,
  callToActionMessageId,
}: Props) => {
  const classes = useStyles()

  const canUpsert = (plan: Plan) => {
    return [
      plan.name,
      plan.description,
      plan.shortDescription,
      toNumber(plan.minimumInitialInvestment) > 0,
      toNumber(plan.principalLockupInMonths) >= 0,
      toNumber(plan.interestRatePerMonth) > 0,
      (plan.scheduledWithdrawalMandatory && toNumber(plan.scheduledWithdrawalPeriodInMonths) > 0) ||
        !plan.scheduledWithdrawalMandatory,
      (plan.autoRenewal && toNumber(plan.autoRenewalPeriodInMonths) > 0) || !plan.autoRenewal,
      plan.contractId,
      plan.memorandumId,
    ].every(Boolean)
  }
  if (!plan) return <></>
  return (
    <Grid>
      <Formik
        initialValues={plan}
        onSubmit={(plan) => {
          const planCopy: Plan = { ...plan }
          numberProperties.forEach((property) => {
            const formattedValue = plan[property]?.toString().replace(/[^0-9.]/g, '')
            if (!formattedValue) return
            planCopy[property] = parseFloat(formattedValue)
          })
          delete planCopy.nextPlanUuid
          delete planCopy.previousPlanUuid
          handleSave?.(planCopy)
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Divider />
            <Grid container xs={12}>
              <Typography variant="h5">Plan Details</Typography>
              <Container>
                <Item xs={8}>
                  <TextFieldContainer>
                    <TextField
                      label="Name"
                      fullWidth
                      variant="outlined"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </TextFieldContainer>
                </Item>
                <Item xs={4}>
                  <TextFieldContainer>
                    <TextField
                      label="Suffix"
                      fullWidth
                      variant="outlined"
                      name="suffixName"
                      placeholder="(Optional) plan, term, etc."
                      onChange={handleChange}
                      value={values.suffixName}
                    />
                  </TextFieldContainer>
                </Item>
                <Item xs={12}>
                  <TextFieldContainer>
                    <TextField
                      label="Description"
                      fullWidth
                      variant="outlined"
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                      multiline
                    />
                    <></>
                  </TextFieldContainer>
                </Item>
                <Item xs={12}>
                  <TextFieldContainer>
                    <TextField
                      label="Short Description"
                      fullWidth
                      variant="outlined"
                      name="shortDescription"
                      onChange={handleChange}
                      value={values.shortDescription}
                    />
                  </TextFieldContainer>
                </Item>
              </Container>

              <Divider />
              <Typography variant="h5">Initial configurations</Typography>
              <Container>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Minimal Initial Amount"
                      fullWidth
                      variant="outlined"
                      name="minimumInitialInvestment"
                      onChange={(event) => {
                        validateInput({ e: event, type: 'MONEY', min: 0 })
                        handleChange(event)
                      }}
                      value={values.minimumInitialInvestment}
                      InputProps={{
                        startAdornment: '$',
                      }}
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Initial Lockup Period"
                      fullWidth
                      variant="outlined"
                      name="principalLockupInMonths"
                      type="number"
                      inputProps={{ min: 0 }}
                      onChange={(e) => {
                        validateInput({ e, type: 'INTEGER', min: 0 })
                        handleChange(e)
                      }}
                      value={values.principalLockupInMonths}
                      InputProps={{
                        endAdornment: 'month(s)',
                      }}
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Initial Processing Fee"
                      fullWidth
                      variant="outlined"
                      name="initialProcessingFee"
                      type="number"
                      onChange={(e) => {
                        validateInput({ e, type: 'PERCENTAGE', min: 0 })
                        handleChange(e)
                      }}
                      value={values.initialProcessingFee}
                      InputProps={{
                        endAdornment: '%',
                        inputProps: { min: 0, max: 100 },
                      }}
                    />
                  </TextFieldContainer>
                </Item>
              </Container>
              <Divider />
              <Typography variant="h5">Interest configurations</Typography>
              <Container>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Interest Rate"
                      fullWidth
                      variant="outlined"
                      name="interestRatePerMonth"
                      type="number"
                      onChange={(e) => {
                        validateInput({ e, type: 'PERCENTAGE', min: 0, max: 100 })
                        handleChange(e)
                      }}
                      InputProps={{
                        endAdornment: '%',
                        inputProps: { min: 0, max: 100 },
                      }}
                      value={values.interestRatePerMonth}
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Interest type</InputLabel>
                      <Select
                        label="Interest type"
                        fullWidth
                        variant="outlined"
                        name="interestType"
                        onChange={handleChange}
                        value={values.interestType}
                        inputProps={{
                          className: classes.selectInput,
                        }}
                        className={classes.depositDateInputLabel}
                      >
                        {Object.entries(interestTypeLabel).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText />
                    </FormControl>
                  </TextFieldContainer>
                </Item>
              </Container>

              <Divider />
              <Typography variant="h5">Deposit configurations</Typography>
              <Container>
                <Item xs={12}>
                  <TextFieldContainer>
                    <TextField
                      label="Interest period description"
                      fullWidth
                      variant="outlined"
                      name="interestPeriodDescription"
                      onChange={handleChange}
                      value={values.interestPeriodDescription}
                      multiline
                    />
                    <></>
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Deadline days"
                      fullWidth
                      variant="outlined"
                      name="deadlineDays"
                      onChange={(e) => {
                        validateInput({ e, type: 'INTEGER', min: 0 })
                        handleChange(e)
                      }}
                      InputProps={{
                        endAdornment: 'day(s)',
                      }}
                      value={values.deadlineDays}
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <TextField
                      label="Deadline Time"
                      fullWidth
                      variant="outlined"
                      type="time"
                      name="deadlineTime"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: 'EST',
                      }}
                      onChange={handleChange}
                      value={values.deadlineTime}
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Deposit target date</InputLabel>
                      <Select
                        label="Deposit target date"
                        fullWidth
                        variant="outlined"
                        name="depositFrequency"
                        onChange={handleChange}
                        value={values.depositFrequency}
                        inputProps={{
                          className: classes.selectInput,
                        }}
                        className={classes.depositDateInputLabel}
                      >
                        {Object.entries(depositFrequencyLabel).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText />
                    </FormControl>
                  </TextFieldContainer>
                </Item>
                <Item />
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="includeWeekends"
                          checked={values.includeWeekends}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Include non-working days
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="depositAllowed"
                          checked={values.depositAllowed}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Allow additional deposits
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
              </Container>
              <Divider />
              <Typography variant="h5">Withdrawal configurations</Typography>
              <Grid container xs={12} spacing={2}>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="withdrawalAllowed"
                          checked={values.withdrawalAllowed}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Allow withdrawals
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="earlyWithdrawalAllowed"
                          checked={values.earlyWithdrawalAllowed}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Allow early withdrawals
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                {values.withdrawalAllowed || values.earlyWithdrawalAllowed ? (
                  <Item>
                    <TextFieldContainer>
                      <TextField
                        label="Overdraft Penalty"
                        variant="outlined"
                        name="earningPenalty"
                        type="number"
                        fullWidth
                        onChange={(e) => {
                          validateInput({ e, type: 'PERCENTAGE', min: 0, max: 100 })
                          handleChange(e)
                        }}
                        value={values.earningPenalty}
                        InputProps={{
                          endAdornment: '%',
                          inputProps: { min: 0, max: 100 },
                        }}
                      />
                    </TextFieldContainer>
                  </Item>
                ) : (
                  <></>
                )}
                {values.earlyWithdrawalAllowed && (
                  <Item>
                    <TextFieldContainer>
                      <TextField
                        label="Early withdrawal Fee"
                        variant="outlined"
                        name="earlyWithdrawalPenalty"
                        type="number"
                        fullWidth
                        onChange={(e) => {
                          validateInput({ e, type: 'PERCENTAGE', min: 0, max: 100 })
                          handleChange(e)
                        }}
                        value={values.earlyWithdrawalPenalty}
                        InputProps={{
                          endAdornment: '%',
                          inputProps: { min: 0, max: 100 },
                        }}
                      />
                    </TextFieldContainer>
                  </Item>
                )}
                {values.withdrawalAllowed && !values.earlyWithdrawalAllowed && <Item />}
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="scheduledWithdrawalAllowed"
                          checked={values.scheduledWithdrawalAllowed}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Allow Scheduled Withdrawal
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="scheduledWithdrawalMandatory"
                          checked={values.scheduledWithdrawalMandatory}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Mandatory Scheduled Withdrawal
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                {values.scheduledWithdrawalMandatory && (
                  <Item>
                    <TextFieldContainer>
                      <TextField
                        label="Mandatory Scheduled Withdrawal Period"
                        fullWidth
                        variant="outlined"
                        name="scheduledWithdrawalPeriodInMonths"
                        type="number"
                        onChange={(e) => {
                          validateInput({ e, type: 'INTEGER', min: 0 })
                          handleChange(e)
                        }}
                        value={values.scheduledWithdrawalPeriodInMonths}
                        InputProps={{
                          endAdornment: 'month(s)',
                        }}
                      />
                    </TextFieldContainer>
                  </Item>
                )}
              </Grid>
              <Divider />
              <Typography variant="h5">Renewal configurations</Typography>
              <Grid container xs={12} spacing={2}>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="autoRenewal"
                          checked={values.autoRenewal}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Automatic Renewal
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                <Item />
                {values.autoRenewal && (
                  <>
                    <Item>
                      <TextFieldContainer>
                        <TextField
                          label="Auto Renewal Period"
                          fullWidth
                          variant="outlined"
                          name="autoRenewalPeriodInMonths"
                          type="number"
                          onChange={(e) => {
                            validateInput({ e, type: 'INTEGER', min: 0 })
                            handleChange(e)
                          }}
                          value={values.autoRenewalPeriodInMonths}
                          InputProps={{
                            endAdornment: 'month(s)',
                          }}
                        />
                      </TextFieldContainer>
                    </Item>
                    <Item>
                      <TextFieldContainer>
                        <TextField
                          label="Max auto renewal"
                          fullWidth
                          variant="outlined"
                          name="maxAutoRenewalPeriod"
                          type="number"
                          onChange={(e) => {
                            validateInput({ e, type: 'INTEGER', min: 0 })
                            handleChange(e)
                          }}
                          value={values.maxAutoRenewalPeriod}
                        />
                      </TextFieldContainer>
                    </Item>
                    <Item>
                      <TextFieldContainer>
                        <TextField
                          label="Renewal Lockup Period"
                          fullWidth
                          variant="outlined"
                          name="renewalPeriodLockInMonths"
                          onChange={(e) => {
                            validateInput({ e, type: 'INTEGER', min: 0 })
                            handleChange(e)
                          }}
                          value={values.renewalPeriodLockInMonths}
                          InputProps={{
                            endAdornment: 'month(s)',
                          }}
                        />
                      </TextFieldContainer>
                    </Item>
                  </>
                )}
              </Grid>
              <Divider />
              <Typography variant="h5">Cancellation configurations</Typography>
              <Grid container xs={12} spacing={2}>
                <Item>
                  <TextFieldContainer>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      control={
                        <Checkbox
                          name="earlyCancellationAllowed"
                          checked={values.earlyCancellationAllowed}
                          onChange={handleChange}
                          icon={<img src={CheckStateOff} alt="Unchecked" />}
                          checkedIcon={<img src={CheckStateOn} alt="Checked" />}
                        />
                      }
                      label={
                        <Typography variant="body3" color={theme.typographyColor.secondaryText}>
                          Allow early cancellation
                        </Typography>
                      }
                    />
                  </TextFieldContainer>
                </Item>
                {values.earlyCancellationAllowed && (
                  <Item>
                    <TextFieldContainer>
                      <TextField
                        label="Early cancellation fee"
                        fullWidth
                        variant="outlined"
                        name="earlyCancellationPenalty"
                        type="number"
                        onChange={(e) => {
                          validateInput({ e, type: 'PERCENTAGE', min: 0, max: 100 })
                          handleChange(e)
                        }}
                        value={values.earlyCancellationPenalty}
                        InputProps={{
                          endAdornment: '%',
                          inputProps: { min: 0, max: 100 },
                        }}
                      />
                    </TextFieldContainer>
                  </Item>
                )}
              </Grid>
              <Divider />
              <Typography variant="h5">Documents</Typography>
              <TextFieldContainer>
                <TextField
                  label="Contract ID"
                  fullWidth
                  variant="outlined"
                  name="contractId"
                  onChange={handleChange}
                  value={values.contractId}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="Memorandum ID"
                  fullWidth
                  variant="outlined"
                  name="memorandumId"
                  onChange={handleChange}
                  value={values.memorandumId}
                />
              </TextFieldContainer>
              {Boolean(handleSave) && (
                <ButtonWrapper>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    disabled={loadingSave || !canUpsert(values)}
                  >
                    <FormattedMessage
                      id={
                        loadingSave
                          ? 'screen.newAgent.configurePlans.upsert.modal.button.loading'
                          : callToActionMessageId
                      }
                    />
                  </Button>
                </ButtonWrapper>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  )
}
export default PlanForm
