import moment from 'moment-timezone'

const TIMEZONE = 'America/New_York'

export const getMonths = (data: { startDate?: string; closedDate?: string }) => {
  const { closedDate, startDate } = data
  if (!startDate) return []
  const availableMonths: string[] = []
  const startDateAux = moment.tz(startDate, TIMEZONE)
  const today = moment.tz(TIMEZONE)

  const cutoffDate = closedDate ? moment.tz(closedDate, TIMEZONE) : today

  while (startDateAux.isSameOrBefore(cutoffDate, 'month')) {
    availableMonths.push(startDateAux.utc().format())
    startDateAux.add(1, 'months')
  }
  return availableMonths
}
