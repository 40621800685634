import { Button } from '@mui/material'
import { useTemporalLabel } from 'hooks/TemporalLabel'
import { FormattedMessage } from 'react-intl'

interface Props {
  copyLabel: string
  copiedLabel: string
  textToCopy: string
}

const CopyInformationButton = ({ copiedLabel, copyLabel, textToCopy }: Props) => {
  const [label, activeTemporalLabelChange] = useTemporalLabel(copyLabel, copiedLabel)

  const handleClickCopyButton = () => {
    navigator.clipboard.writeText(textToCopy)
    activeTemporalLabelChange()
  }

  return (
    <Button variant="outlined" onClick={handleClickCopyButton}>
      <FormattedMessage id={label} />
    </Button>
  )
}

export default CopyInformationButton
