import { FunctionComponent, useState } from 'react'
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import useStyles, {
  ActionWrapper,
  TextFieldContainer,
  getTextFieldSx,
  ErrorContainer,
} from './styled'
import { FormattedMessage } from 'react-intl'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useMutation } from '@apollo/client'
import { SAVE_COMPANY_PROFILE } from './queries'
import localesLangsEn from 'data/countries/langs/en.json'
import { getNames, registerLocale } from 'data/countries'
import Dialog from '../Dialog'

registerLocale(localesLangsEn)

type Data = {
  tin: string
  name: string
  addressOne: string
  addressTwo: string | null
  city: string
  state: string
  postalCode: string
  country: string
  countryKey: string
  phone: string
} | null

type Variables = {
  name: string
  tin: string
  addressOne: string
  city: string
  country: string
  countryKey: string
  postalCode: string
  state: string
  addressTwo?: string
  phone: string
}

const getButtonLabelId = (loading: boolean, data?: Data) => {
  if (loading) return 'components.addCompanyProfileModal.buttonLabel.saving'
  if (data) return 'components.addCompanyProfileModal.buttonLabel.edit'
  return 'components.addCompanyProfileModal.buttonLabel.save'
}

const getTitleId = (data?: Data) =>
  `components.addCompanyProfileModal.title.${data ? 'edit' : 'save'}`

const EndIcon: FunctionComponent = () => <img src={ArrowRightDisabled} alt="arrow_right_black" />

interface Props {
  data?: Data
  open: boolean
  onSave?: () => void
  handleCloseCallback?: () => void
}

const AddCompanyProfileModal: FunctionComponent<Props> = ({
  data,
  open,
  onSave,
  handleCloseCallback,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const { name, addressOne, addressTwo, city, countryKey, postalCode, state, tin, phone } =
    data || {}
  const [updateCompanyProfile, { loading }] = useMutation(SAVE_COMPANY_PROFILE)
  const [error, setError] = useState(false)
  const titleId = getTitleId(data)
  const buttonLabelId = getButtonLabelId(loading, data)
  const countriesData = getNames('en', { select: 'official' })
  const countries = Object.entries(countriesData).map(([key, value]) => ({ key, name: value }))

  const handleClose = () => {
    setError(false)
    if (handleCloseCallback) handleCloseCallback()
  }

  return (
    <Dialog
      dialogOpen={open}
      handleCloseCallback={handleClose}
      fullScreen={isResponsive}
      titleMessageId={titleId}
    >
      {
        <Formik
          initialValues={{
            name: name || '',
            tin: tin || '',
            addressOne: addressOne || '',
            addressTwo: addressTwo || '',
            city: city || '',
            country: countryKey || '',
            postalCode: postalCode || '',
            state: state || '',
            phone: phone || '',
          }}
          onSubmit={async function (values: FormikValues): Promise<void | Promise<unknown>> {
            try {
              const { name, tin, addressOne, addressTwo, city, country, postalCode, state, phone } =
                values
              const variables: Variables = {
                name,
                tin,
                addressOne,
                city,
                country: countries.find(({ key }) => key === country)?.name || '',
                countryKey: country,
                postalCode,
                state,
                phone,
              }

              if (addressTwo.length) variables.addressTwo = addressTwo

              await updateCompanyProfile({ variables })

              if (onSave) onSave()
              handleClose()

              return
            } catch (error) {
              setError(true)
              console.log('Error when saving address: ', error)
            }
          }}
        >
          {({ values, dirty, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextFieldContainer>
                <TextField
                  label="Name"
                  fullWidth
                  variant="outlined"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  sx={getTextFieldSx(values.name)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="TIN"
                  fullWidth
                  variant="outlined"
                  name="tin"
                  onChange={handleChange}
                  value={values.tin}
                  sx={getTextFieldSx(values.tin)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="Phone"
                  fullWidth
                  variant="outlined"
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  sx={getTextFieldSx(values.phone)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="Address 1"
                  fullWidth
                  variant="outlined"
                  name="addressOne"
                  onChange={handleChange}
                  value={values.addressOne}
                  sx={getTextFieldSx(values.addressOne)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="Address 2"
                  fullWidth
                  variant="outlined"
                  name="addressTwo"
                  onChange={handleChange}
                  value={values.addressTwo}
                  sx={getTextFieldSx(values.addressTwo)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="City"
                  fullWidth
                  variant="outlined"
                  name="city"
                  onChange={handleChange}
                  value={values.city}
                  sx={getTextFieldSx(values.city)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="State"
                  fullWidth
                  variant="outlined"
                  name="state"
                  onChange={handleChange}
                  value={values.state}
                  sx={getTextFieldSx(values.state)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <TextField
                  label="Postal Code"
                  fullWidth
                  variant="outlined"
                  name="postalCode"
                  onChange={handleChange}
                  value={values.postalCode}
                  sx={getTextFieldSx(values.postalCode)}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.countryInputLabel}>Country</InputLabel>
                  <Select
                    label="Country"
                    fullWidth
                    variant="outlined"
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                    sx={getTextFieldSx(values.country)}
                  >
                    {countries.map(({ key, name }, index) => (
                      <MenuItem value={key} key={`${key}-${index}`}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TextFieldContainer>
              {error && (
                <ErrorContainer>
                  <FormattedMessage id="global.error.generalError" />
                </ErrorContainer>
              )}
              <ActionWrapper>
                <Button
                  disabled={!dirty || loading}
                  type="submit"
                  variant="contained"
                  fullWidth={isResponsive}
                  endIcon={<EndIcon />}
                >
                  <FormattedMessage id={buttonLabelId} />
                </Button>
              </ActionWrapper>
            </form>
          )}
        </Formik>
      }
    </Dialog>
  )
}

export default AddCompanyProfileModal
