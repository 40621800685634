import { AlertTitle, Typography, Button } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { FunctionComponent, ReactNode } from 'react'
import { Container, useStyles } from './styled'
import clsx from 'clsx'
import ArrowRightError from 'assets/arrow_right_error.svg'
import { FormattedMessage } from 'react-intl'
import { IntlValuesObject } from 'global/types'

interface Props {
  variant: 'success' | 'error' | 'warning'
  icon?: boolean | ReactNode
  title: string
  subtitle?: string
  withRightButton?: boolean
  buttonLabel?: string
  buttonDisabled?: boolean
  buttonColor?: 'error' | 'warning' | 'success' | 'info' | 'primary' | 'secondary'
  rightButtonEndIcon?: string
  valuesTitle?: IntlValuesObject
  valuesSubtitle?: IntlValuesObject
  subtitleClassName?: string
  subtitleContainerClassName?: string
  buttonClassName?: string
  buttonAction?: () => void
  onClose?: () => void
}

const Alert: FunctionComponent<Props> = ({
  variant,
  icon = false,
  title,
  subtitle,
  withRightButton,
  buttonLabel,
  buttonDisabled,
  buttonColor = 'error',
  rightButtonEndIcon,
  valuesTitle,
  valuesSubtitle,
  subtitleClassName,
  subtitleContainerClassName,
  buttonClassName,
  buttonAction,
  onClose,
}: Props) => {
  const classes = useStyles()

  return (
    <Container
      className={clsx({
        [classes.alerts]: true,
        [classes.closeIcon]: !withRightButton,
      })}
    >
      <MuiAlert
        severity={variant}
        icon={icon}
        onClose={onClose}
        className={clsx({
          [classes.withRightButton]: withRightButton,
        })}
      >
        <div
          className={
            subtitleContainerClassName ||
            clsx({
              [classes.subtitle]: variant === 'success',
            })
          }
        >
          <AlertTitle>
            <Typography variant="h4">
              <FormattedMessage id={title} values={valuesTitle} />
            </Typography>
          </AlertTitle>
          <Typography
            variant="body2"
            className={subtitleClassName || clsx({ [classes.subtitle]: variant === 'success' })}
          >
            <FormattedMessage id={subtitle} values={valuesSubtitle} />
          </Typography>
        </div>
        {withRightButton && (
          <Button
            className={buttonClassName}
            variant="outlined"
            endIcon={<img src={rightButtonEndIcon || ArrowRightError} alt="arrow_right" />}
            color={buttonColor}
            onClick={buttonAction}
            disabled={buttonDisabled}
          >
            <FormattedMessage id={buttonLabel} />
          </Button>
        )}
      </MuiAlert>
    </Container>
  )
}

export default Alert
