import { createSlice } from '@reduxjs/toolkit'
import { Plan } from 'types'

export const STEPS = {
  referralCode: 'referralCode',
  plans: 'plans',
  linkPlans: 'linkPlans',
  confirmation: 'confirmation',
} as const

type ObjectValues<T> = T[keyof T]

export type NewAgentSteps = ObjectValues<typeof STEPS>

export const orderedSteps = [STEPS.referralCode, STEPS.plans, STEPS.linkPlans, STEPS.confirmation]

interface NewAgentStore {
  activeStep: NewAgentSteps
  referralCode?: string
  plans: Plan[]
  contractId: string
  memorandumId: string
  linkedPlans: {
    planUuid: string
    nextPlanUuid: string
  }[]
}

const initialState: NewAgentStore = {
  activeStep: STEPS.referralCode,
  referralCode: undefined,
  plans: [],
  contractId: '',
  memorandumId: '',
  linkedPlans: [],
}

const newAgentSlice = createSlice({
  name: 'newInvestment',
  initialState,
  reducers: {
    setReferralCode(state, action) {
      state.referralCode = action.payload
    },
    previousStep(state) {
      state.activeStep = orderedSteps[orderedSteps.indexOf(state.activeStep) - 1]
    },
    nextStep(state) {
      state.activeStep = orderedSteps[orderedSteps.indexOf(state.activeStep) + 1]
    },
    changeStep(state, action) {
      state.activeStep = action.payload
    },
    addPlan(state, action) {
      if (action.payload instanceof Array) {
        const newPlansUuids = action.payload.map((plan) => plan.uuid)
        const notAddedPlans = state.plans.filter((plan) => !newPlansUuids.includes(plan.uuid))
        state.plans = [...notAddedPlans, ...action.payload]
      } else {
        const notAddedPlans = state.plans.filter((plan) => plan.uuid !== action.payload.uuid)
        state.plans = [...notAddedPlans, action.payload]
      }
      const linkedPlans = [] as NewAgentStore['linkedPlans']
      state.plans.forEach((plan) => {
        if (plan.nextPlanUuid && plan.uuid) {
          linkedPlans.push({ planUuid: plan.uuid, nextPlanUuid: plan.nextPlanUuid })
        }
      })
      state.linkedPlans = linkedPlans
    },
    setContractId(state, action) {
      state.contractId = action.payload
    },
    setMemorandumId(state, action) {
      state.memorandumId = action.payload
    },
    addLinkedPlan(state, action) {
      state.linkedPlans = action.payload
    },
    resetStore() {
      return initialState
    },
  },
})

export const {
  setReferralCode,
  addPlan,
  nextStep,
  setContractId,
  setMemorandumId,
  previousStep,
  changeStep,
  addLinkedPlan,
  resetStore,
} = newAgentSlice.actions
export default newAgentSlice.reducer
