import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '770px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  cardContainer: {
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
      gap: 8,
    },
  },
  signButtonContainer: {
    width: '70%',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(2),
      marginRight: 0,
      minHeight: theme.spacing(20),
    },
  },
  infoContainer: {
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(3),
  },
  icon: {
    margin: '16px 16px 12px 16px',
  },
  documentName: {
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: theme.spacing(0.5),
    },
  },
  docSignedIcon: {
    position: 'absolute',
    zIndex: '2',
    right: '10px',
    top: '9px',
  },
  marginBottom: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '48px',
    },
  },
  noWrapButton: {
    whiteSpace: 'nowrap',
  },
}))

export const CenterContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const SignContainer = styled.div`
  background-color: ${theme.extraBackgrounds.paper02};
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
  display: flex;
  flex-direction: row;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`

export const IconWrpapper = styled.div`
  background-color: ${theme.extraBackgrounds.paper06};
  margin: 15px;
  max-height: 56px;
  width: auto;
  position: sticky;
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  min-width: fit-content;
  gap: ${theme.spacing(0.5)};
  align-items: center;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    gap: ${theme.spacing(1)};
  }
`

export const SignButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: ${theme.spacing(1)};
  align-items: center;
  flex-direction: row;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`

export const BackNextButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(3)};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${theme.spacing(0.5)};
`
