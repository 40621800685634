import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    alignItems: 'center',
    height: 'fit-content',
    minHeight: `calc(100vh - 80px)`,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    '& path': {
      stroke: `${theme.extraBackgrounds.paper01}`,
    },
  },
}))

export const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 968px;
  overflow-y: auto;
  color: white;
  margin-top: ${theme.spacing(3)};
`

export const TabsButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-height: ${theme.breakpoints.values.md}px) {
    flex-direction: column-reverse;
  }
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`
