import { CircularProgress } from '@mui/material'
import { FunctionComponent } from 'react'
import { LoadingLayoutWrapper, LoadingWrapper } from './styled'

const LoadingLayout: FunctionComponent = () => {
  return (
    <LoadingLayoutWrapper>
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    </LoadingLayoutWrapper>
  )
}

export default LoadingLayout
