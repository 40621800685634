import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from 'screens/App/theme'

interface RowProps {
  grayBackground?: boolean
  borderBottom?: boolean
}

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper02,
    height: '100%',
    maxWidth: '721px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  gray: {
    color: theme.typographyColor.secondaryText,
  },
  greenCheck: {
    marginTop: theme.spacing(1),
  },
  bankImage: {
    height: '24px',
    width: '24px',
    borderRadius: '40px',
    marginRight: theme.spacing(1.5),
  },
  backIcon: {
    '& path': {
      stroke: theme.typographyColor.primaonActivePrimaryButtonTextryText,
    },
  },
}))

export const IconWrapper = styled.div`
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(4)};
  background-color: rgba(146, 243, 140, 0.16);
  border-radius: 41px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
`

export const DashedDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed ${theme.extraBackgrounds.paper06};
`

export const DepositBankContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  gap: ${theme.spacing(8)};
`

export const BankDataContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${theme.spacing(1)};
`
