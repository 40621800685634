import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { Moment } from 'moment'
import { FunctionComponent, useState } from 'react'
import AdapterMoment from '@date-io/moment'
import { TextField } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './styled'
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg'

interface Props {
  value: Moment | null
  onChange: (newDate: Moment | null) => void
  labelMessageId?: string
  inputFormat?: string
  disabled?: boolean
  fullWidth?: boolean
  shouldDisableDate?: (date: Moment) => boolean
}

const DateInput: FunctionComponent<Props> = ({
  value,
  onChange,
  labelMessageId = 'component.dateInput.defaultLabel',
  inputFormat = 'dddd, MMMM DD, YYYY',
  disabled = false,
  fullWidth = false,
  shouldDisableDate,
}) => {
  const classes = useStyles()

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)

  const removeKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        className={classes.datePicker}
        label={<FormattedMessage id={labelMessageId} />}
        inputFormat={inputFormat}
        value={value}
        open={isOpenDatePicker}
        disabled={disabled}
        onChange={onChange}
        onClose={() => setIsOpenDatePicker(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={fullWidth}
            onKeyDown={removeKeyDown}
            onClick={() => setIsOpenDatePicker(true)}
          />
        )}
        shouldDisableDate={shouldDisableDate}
      />
    </LocalizationProvider>
  )
}

export default DateInput
