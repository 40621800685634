import moment from 'moment'

const REFERENCE_LENGTH = 7

export const GetFormattedDate = (date: string) => {
  return moment(date).format('MMM DD, YYYY')
}

export const getSumsubUrl = (applicantId?: string | null) =>
  `https://cockpit.sumsub.com/checkus#/applicant/${applicantId}/basicInfo?clientId=bluedotcapitals.com_48890`

export const getFormattedReferenceNumber = (referenceNumber: number) => {
  const referenceString = referenceNumber.toString()
  const referenceLength = referenceString.length
  const howManyZeroesNeedToAdd = REFERENCE_LENGTH - referenceLength
  return `${'0'.repeat(howManyZeroesNeedToAdd)}${referenceString}`
}
