import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  backIcon: {
    transform: 'rotate(45deg)',
    cursor: 'pointer',
  },
  textEllipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const Content = styled.div`
  display: flex;
  gap: ${theme.spacing(2)};
`

export const InvestmentData = styled.div`
  display: flex;
  color: ${theme.typographyColor.primaryText};
  gap: ${theme.spacing(0.5)};
  margin-top: ${theme.spacing(0.75)};
  align-items: center;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    align-items: start;
    margin-top: 0;
    width: 100%;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
  }
`
