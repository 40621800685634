export interface Config {
  NODE_ENV?: string
  REACT_APP_AUTH0_PROVIDER_DOMAIN: string
  REACT_APP_AUTH0_PROVIDER_CLIENT_ID: string
  REACT_APP_AUTH0_LOGIN_REDIRECT_URL: string
  REACT_APP_BLUEDOT_GRAPHQL_URL: string
  REACT_APP_PUBLIC_PLAID_TOKEN: string
  REACT_APP_SENTRY_DSN?: string
}

const config: Config = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV || '',
  REACT_APP_AUTH0_PROVIDER_DOMAIN: process.env.REACT_APP_AUTH0_PROVIDER_DOMAIN || '',
  REACT_APP_AUTH0_PROVIDER_CLIENT_ID: process.env.REACT_APP_AUTH0_PROVIDER_CLIENT_ID || '',
  REACT_APP_AUTH0_LOGIN_REDIRECT_URL: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL || '',
  REACT_APP_BLUEDOT_GRAPHQL_URL: process.env.REACT_APP_BLUEDOT_GRAPHQL_URL || '',
  REACT_APP_PUBLIC_PLAID_TOKEN: process.env.REACT_APP_PUBLIC_PLAID_TOKEN || '',
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
}

export default config
