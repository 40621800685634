import { FunctionComponent } from 'react'
import { useStyles, RowWithBorder } from '../styled'
import { Deposit, DepositFrequency } from 'types'
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import moment, { Moment } from 'moment'
import DateInput from 'components/common/Inputs/DateInput'
import { getFormattedReferenceNumber } from 'utils/DataFormat'
import { DAYS } from 'utils/DateUtils'

interface Props {
  deposit: Deposit
  activationDate?: Moment
  onChangeActivationDate: (date: Moment) => void
}

const DepositRulesValidation: Record<
  DepositFrequency,
  (date: string, includeWeekends: boolean) => boolean
> = {
  [DepositFrequency.NEXT_MONDAY]: (date: string) => moment(date).day() === DAYS.MONDAY,
  [DepositFrequency.NEXT_MONTH]: (date: string, includeWeekends: boolean) => {
    const currentDate = moment(date)
    const dayOfMonth = currentDate.date()
    if (dayOfMonth > 3) return false
    if (includeWeekends) return dayOfMonth === 1

    const dayOfWeek = currentDate.day()
    const isWeekend = dayOfWeek === DAYS.SATURDAY || dayOfWeek === DAYS.SUNDAY
    if (dayOfMonth === 1) return !isWeekend
    if (!isWeekend && dayOfWeek === DAYS.MONDAY) return true
    return false
  },
}

const ApproveDepositsModalContent: FunctionComponent<Props> = ({
  deposit,
  activationDate,
  onChangeActivationDate,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()

  const shouldDisableDate = (date: moment.Moment) => {
    const depositFrequency = deposit.investment.plan.depositFrequency
    const includeNonWorkingDays = deposit.investment.plan.includeWeekends
    const isValidDepositFrequency = DepositRulesValidation[depositFrequency](
      date.format('YYYY-MM-DD'),
      includeNonWorkingDays,
    )
    const isBeforeDepositRequestDate = date.isBefore(moment(deposit.deposit.createdAt))

    return !isValidDepositFrequency || isBeforeDepositRequestDate
  }

  const handleActivationDateInput = (newDate: Moment | null) => {
    if (!newDate) return
    onChangeActivationDate(newDate)
  }

  return (
    <div>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.reference" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {getFormattedReferenceNumber(deposit.deposit?.reference || 0)}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.dateRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {moment(deposit.deposit.createdAt).format('MMM DD[,] YYYY')}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.investorRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {deposit.fullName}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.planRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {deposit.investment?.plan?.name}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.amountRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {intl.formatNumber(deposit.deposit?.amount, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </RowWithBorder>
      <Divider size={theme.spacing(4)} />
      <Typography variant="body1" color={theme.typographyColor.primaryText}>
        <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.dateInstructions" />
      </Typography>
      <Divider size={theme.spacing(2)} />
      <DateInput
        fullWidth
        labelMessageId="screen.depositRequests.tab01.acknowledDepositModal.dateInputLabel"
        onChange={handleActivationDateInput}
        value={activationDate || null}
        shouldDisableDate={shouldDisableDate}
      />
      <Typography
        sx={{ px: 2, py: 0.5 }}
        variant="caption"
        color={theme.typographyColor.secondaryText}
      >
        <FormattedMessage
          id={`screen.depositRequests.tab01.acknowledDepositModal.dateHint.${deposit.investment.plan.depositFrequency}`}
        />
      </Typography>
      <Divider size={theme.spacing(4)} />
      <Typography variant="body1" className={classes.grayText}>
        <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.investorText" />
      </Typography>
    </div>
  )
}

export default ApproveDepositsModalContent
