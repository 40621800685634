import { Button, Link, Typography } from '@mui/material'
import { CharacteristicsContainer, PlanContainer, useStyles } from './styled'
import CheckOutline from 'assets/check_outline.svg'
import { FunctionComponent, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Plan } from 'types'
import LearnMoreModal from '../LearnMoreModal'

interface Props {
  plan: Plan
  nextPlanName?: string
  callToActionId?: string
  onClickCallToAction?: (plan: Plan) => void
}

export const InvestmentPlan: FunctionComponent<Props> = ({
  plan,
  callToActionId,
  onClickCallToAction,
  nextPlanName,
}) => {
  const classes = useStyles()
  const {
    name,
    interestRatePerMonth,
    minimumInitialInvestment,
    principalLockupInMonths,
    initialProcessingFee: processingFee,
    shortDescription,
  } = plan

  const [investmentPlanModalOpen, setInvestmentPlanModalOpen] = useState(false)

  const onClickSelectPlan = () => {
    onClickCallToAction?.(plan)
  }

  const openModal = () => {
    setInvestmentPlanModalOpen(true)
  }

  const closeModal = () => {
    setInvestmentPlanModalOpen(false)
  }

  return (
    <PlanContainer className={classes.planContainer}>
      <Typography variant="body1" className={classes.title}>
        {name}
      </Typography>
      <Typography variant="caption" className={classes.description}>
        {shortDescription}
      </Typography>
      <Typography variant="caption" className={classes.minimalAmount}>
        <FormattedMessage id="screen.newInvestment.investmentPlan.minimalAmountLabel" />
        <FormattedNumber
          value={Number(minimumInitialInvestment)}
          style="currency"
          currency="USD"
          maximumFractionDigits={0}
        />
      </Typography>
      <CharacteristicsContainer>
        <img className={classes.img} src={CheckOutline} alt="checkoutline" />
        <Typography variant="caption" className={classes.characteristics}>
          <FormattedMessage
            id="screen.newInvestment.investmentPlan.interestRatePerMonthLabel"
            values={{ interestRatePerMonth: interestRatePerMonth }}
          />
        </Typography>
      </CharacteristicsContainer>
      <CharacteristicsContainer>
        <img className={classes.img} src={CheckOutline} alt="checkoutline" />
        <Typography variant="caption" className={classes.characteristics}>
          <FormattedMessage
            id={
              Number(principalLockupInMonths) <= 1
                ? 'screen.newInvestment.investmentPlan.principalLockupInMonthLabel'
                : 'screen.newInvestment.investmentPlan.principalLockupInMonthsLabel'
            }
            values={{ month: principalLockupInMonths }}
          />
        </Typography>
      </CharacteristicsContainer>
      {processingFee > 0 && (
        <CharacteristicsContainer>
          <img className={classes.img} src={CheckOutline} alt="checkoutline" />
          <Typography variant="caption" className={classes.characteristics}>
            <FormattedMessage
              id="screen.newInvestment.investmentPlan.processingFee"
              values={{ processingFee }}
            />
          </Typography>
        </CharacteristicsContainer>
      )}
      {nextPlanName && (
        <CharacteristicsContainer>
          <img className={classes.img} src={CheckOutline} alt="checkoutline" />
          <Typography variant="caption" className={classes.characteristics}>
            <FormattedMessage
              id="screen.newInvestment.investmentPlan.upgradePlan"
              values={{ plan: nextPlanName }}
            />
          </Typography>
        </CharacteristicsContainer>
      )}
      <Link onClick={openModal} className={classes.link}>
        <FormattedMessage id="components.cards.investmentCard.link" />
      </Link>
      {callToActionId && Boolean(onClickCallToAction) && (
        <>
          <Button variant="outlined" onClick={onClickSelectPlan} fullWidth>
            <FormattedMessage id={callToActionId} />
          </Button>
        </>
      )}
      <LearnMoreModal
        modalOpen={investmentPlanModalOpen}
        handleCloseCallback={closeModal}
        investmentPlan={plan}
      />
    </PlanContainer>
  )
}
