import { FunctionComponent } from 'react'
import { Typography } from '@mui/material'
import useStyles, { AddressInfoContainer, ContentContainer, MainContainer } from './styled'
import { FormattedMessage } from 'react-intl'

const AddMissingInfoView: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <MainContainer>
      <Typography variant="body1" color="white">
        <FormattedMessage id="screen.home.bank.statement.address.modal.description.subtitle.bank" />
      </Typography>
      <ContentContainer>
        <Typography variant="body2">
          <FormattedMessage id="screen.home.bank.statement.address.modal.description.info.bank" />
        </Typography>
      </ContentContainer>
      <AddressInfoContainer>
        <Typography variant="body1" color="white">
          <FormattedMessage id="screen.home.bank.statement.address.modal.description.subtitle.address" />
        </Typography>
        <ContentContainer>
          <Typography variant="body2">
            <FormattedMessage id="screen.home.bank.statement.address.modal.description.info.address" />
          </Typography>
        </ContentContainer>
        <ContentContainer>
          <Typography className={classes.italic} variant="body3" color="white">
            <FormattedMessage id="constants.address" />
          </Typography>
        </ContentContainer>
      </AddressInfoContainer>
    </MainContainer>
  )
}

export default AddMissingInfoView
