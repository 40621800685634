import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import {
  Button,
  Modal as MuiModal,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useStyles, {
  ActionWrapper,
  CloseIconWrapper,
  ErrorContainer,
  HeaderWrapper,
  listSubHeaderSx,
  ModalContentWrapper,
  RightSideWrapper,
  TextFieldContainer,
  textFieldSx,
} from './styled'
import { FormattedMessage } from 'react-intl'
import CloseIcon from 'assets/close_white.svg'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { getNames, registerLocale } from 'data/countries'
import localesLangsEn from 'data/countries/langs/en.json'
import { useQuery, useMutation } from '@apollo/client'
import { GET_MOST_USED_COUNTRIES, SAVE_BANK_ACCOUNT } from './queries'
import { Data, getTitleId, getButtonLabelId, getMostUsedCountriesOptions, Variables } from './utils'
import Dialog from '../Dialog'

const NUMBERS_ONLY_REGEXP = /^\d+$/
const LETTERS_AND_NUMBERS_ONLY_REGEXP = /^[a-z0-9]+$/i
const SUBHEADER = 'subheader'
const ACCOUNT_NUMBER = 'accountNumber'
const ROUTING_NUMBER = 'routingNumber'
const SWIFT_CODE = 'swiftCode'
const MX = 'MX'

registerLocale(localesLangsEn)

const EndIcon: FunctionComponent = () => <img src={ArrowRightDisabled} alt="arrow_right_black" />

interface Props {
  data?: Data
  open: boolean
  width?: string
  onSave?: () => void
  handleCloseCallback?: () => void
}

const AddBankAccountModal: FunctionComponent<Props> = ({
  data,
  open,
  width,
  onSave,
  handleCloseCallback,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const { profileUuid, bankAccount } = data || {}
  const {
    accountNumber,
    bankName,
    countryKey,
    routingNumber,
    preferredMethod,
    swiftCode,
    beneficiary,
  } = bankAccount || {}
  const countriesData = getNames('en', { select: 'official' })
  const countries = Object.entries(countriesData).map(([key, value]) => ({ key, name: value }))
  const [saveBankAccount, { loading: loadingSaveBankAccount }] = useMutation(SAVE_BANK_ACCOUNT)
  const { data: dataMostUsedCountres } = useQuery(GET_MOST_USED_COUNTRIES)
  const { mostUsedCountries } = dataMostUsedCountres || {}
  const [showSwiftCodeTextField, setShowSwiftCodeTextField] = useState(false)
  const [error, setError] = useState(false)
  const titleId = getTitleId(data)
  const buttonLabelId = getButtonLabelId(loadingSaveBankAccount, data)
  const mostUsedOptions = getMostUsedCountriesOptions(countries, mostUsedCountries || [])

  const handleClose = () => {
    setError(false)
    if (handleCloseCallback) handleCloseCallback()
  }

  useEffect(() => {
    if (swiftCode) setShowSwiftCodeTextField(true)
  }, [swiftCode])

  return (
    <Dialog
      dialogOpen={open}
      handleCloseCallback={handleClose}
      fullScreen={isResponsive}
      titleMessageId={titleId}
    >
      {
        <Formik
          initialValues={{
            accountNumber: accountNumber || '',
            bank: bankName || '',
            routingNumber: routingNumber || '',
            preferredMethod: preferredMethod || '',
            beneficiary: beneficiary || '',
            country: countryKey || '',
            swiftCode: swiftCode || '',
          }}
          onSubmit={async function (values: FormikValues): Promise<void | Promise<unknown>> {
            try {
              const {
                accountNumber,
                bank,
                routingNumber,
                preferredMethod,
                country,
                swiftCode,
                beneficiary,
              } = values
              const variables: Variables = {
                profileUuid,
                accountNumber,
                bankName: bank,
                country: countries.find(({ key }) => key === country)?.name || '',
                countryKey: country,
                routingNumber,
                preferredMethod,
                beneficiary,
                swiftCode,
              }

              if (swiftCode) variables.swiftCode = swiftCode

              await saveBankAccount({ variables })

              if (onSave) onSave()
              handleClose()

              return
            } catch (error) {
              setError(true)
              console.log('Error when saving bank account data: ', error)
            }
          }}
        >
          {({ values, dirty, handleChange, handleSubmit, setFieldValue }) => {
            const handleChangeSwiftCode = (e: ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
              const value = e.target.value
              const matched = LETTERS_AND_NUMBERS_ONLY_REGEXP.test(value)

              if (matched || value.length === 0) setFieldValue(SWIFT_CODE, value)
            }

            const handleChangeNumericInput = (e: ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
              const value = e.target.value
              const name = e.target.name
              const matched = NUMBERS_ONLY_REGEXP.test(value)

              if (
                (matched || value.length === 0) &&
                (name === ACCOUNT_NUMBER || name === ROUTING_NUMBER)
              ) {
                setFieldValue(name, value)
              }
            }

            const handleChangeCountry = (event: SelectChangeEvent) => {
              const value = event.target.value
              if (value !== MX) setFieldValue(SWIFT_CODE, '')
              setShowSwiftCodeTextField(value === MX)
              handleChange(event)
            }

            return (
              <form onSubmit={handleSubmit}>
                <TextFieldContainer>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.countryInputLabel}>Country</InputLabel>
                    <Select
                      label="Country"
                      fullWidth
                      variant="outlined"
                      name="country"
                      onChange={handleChangeCountry}
                      value={values.country}
                      sx={textFieldSx}
                    >
                      {[...mostUsedOptions, { key: SUBHEADER, name: '' }, ...countries].map(
                        ({ key, name }, index) => {
                          if (key === SUBHEADER) {
                            return <ListSubheader key={key} sx={listSubHeaderSx} />
                          }

                          return (
                            <MenuItem value={key} key={`${key}-${index}`}>
                              {name}
                            </MenuItem>
                          )
                        },
                      )}
                    </Select>
                  </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                  <TextField
                    label="Bank"
                    fullWidth
                    variant="outlined"
                    name="bank"
                    onChange={handleChange}
                    value={values.bank}
                    sx={textFieldSx}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <TextField
                    label="Account number"
                    fullWidth
                    variant="outlined"
                    name="accountNumber"
                    onChange={handleChangeNumericInput}
                    value={values.accountNumber}
                    sx={textFieldSx}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <TextField
                    label="Routing number"
                    fullWidth
                    variant="outlined"
                    name="routingNumber"
                    onChange={handleChangeNumericInput}
                    value={values.routingNumber}
                    sx={textFieldSx}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.countryInputLabel}>Preferred method</InputLabel>
                    <Select
                      label="Preferred method"
                      fullWidth
                      variant="outlined"
                      name="preferredMethod"
                      onChange={handleChange}
                      value={values.preferredMethod}
                      sx={textFieldSx}
                    >
                      <MenuItem value={'ACH'}>ACH</MenuItem>
                      <MenuItem value={'WIRE_TRANSFER'}>Wire transfer</MenuItem>
                    </Select>
                  </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                  <TextField
                    label="Beneficiary"
                    fullWidth
                    variant="outlined"
                    name="beneficiary"
                    onChange={handleChange}
                    value={values.beneficiary}
                    sx={textFieldSx}
                  />
                </TextFieldContainer>
                {showSwiftCodeTextField && (
                  <TextFieldContainer>
                    <TextField
                      type="text"
                      label="SWIFT Code"
                      fullWidth
                      variant="outlined"
                      name="swiftCode"
                      onChange={handleChangeSwiftCode}
                      value={values.swiftCode}
                      sx={textFieldSx}
                      inputProps={{ maxLength: 12 }}
                    />
                  </TextFieldContainer>
                )}
                {error && (
                  <ErrorContainer>
                    <FormattedMessage id="global.error.generalError" />
                  </ErrorContainer>
                )}
                <ActionWrapper>
                  <Button
                    disabled={!dirty || loadingSaveBankAccount}
                    type="submit"
                    variant="contained"
                    fullWidth={isResponsive}
                    endIcon={<EndIcon />}
                  >
                    <FormattedMessage id={buttonLabelId} />
                  </Button>
                </ActionWrapper>
              </form>
            )
          }}
        </Formik>
      }
    </Dialog>
  )
}

export default AddBankAccountModal
