import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useStyles, Container } from './styled'
import { Deposit } from 'types'
import { Theme } from '@mui/material/styles'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { APPROVE_OR_UNDO_DEPOSIT } from '../../queries'
import ApproveDepositsModalContent from './ApproveDepositsModalContent'
import { AlertContext } from 'hooks/AlertContext'
import { changeTab } from '../../reducer'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  deposit: Deposit
  defaultInputValueDate?: string
}

const ApproveDepositsModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  deposit,
  defaultInputValueDate,
}: Props) => {
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const [activationDate, setActivationDate] = useState(
    defaultInputValueDate ? moment(defaultInputValueDate).tz('America/New_York') : undefined,
  )

  const [approveDeposits, { loading }] = useMutation(APPROVE_OR_UNDO_DEPOSIT)

  const dispatch = useDispatch()
  const classes = useStyles()
  const { showSnackbar } = useContext(AlertContext)
  const navigate = useNavigate()

  const handleApproveDepositButton = async () => {
    if (!activationDate) return
    try {
      const result = await approveDeposits({
        variables: {
          depositUuid: deposit.deposit.uuid,
          approve: true,
          approvedDate: activationDate.format('YYYY-MM-DD'),
        },
      })

      if (result) {
        const isDepositActivated = activationDate.isBefore(moment())
        showSnackbar({
          messageId: isDepositActivated
            ? 'screen.depositRequests.tab01.acknowledDepositModal.acknowledgeDepositsSnackbar.active'
            : 'screen.depositRequests.tab01.acknowledDepositModal.acknowledgeDepositsSnackbar',
        })
        dispatch(changeTab(isDepositActivated ? 2 : 1))
      }
    } catch (e: unknown) {
      // TODO error handler
    } finally {
      handleCloseCallback()
    }
  }

  const handleGoInvestmentDetailButton = () => {
    navigate(`/investment-detail/${deposit.investment?.uuid}`)
  }

  useEffect(() => {
    setActivationDate(moment(defaultInputValueDate).tz('America/New_York'))
  }, [modalOpen])

  useEffect(() => {
    if (!_.isEmpty(defaultInputValueDate)) {
      setActivationDate(moment(defaultInputValueDate).tz('America/New_York'))
    }
  }, [defaultInputValueDate])

  return (
    <Dialog
      fullScreen={!isBreakpointMdUp}
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      width="600px"
    >
      <Container>
        <Typography variant="h2" className={classes.whiteText}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.title" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.description" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <ApproveDepositsModalContent
          deposit={deposit}
          activationDate={activationDate}
          onChangeActivationDate={setActivationDate}
        />
      </Container>
      <Divider size={theme.spacing(3)} />
      <Grid
        container
        justifyContent={{ xs: 'initial', sm: 'space-between' }}
        alignItems={{ xs: 'initial', sm: 'center' }}
        rowSpacing={{ xs: 3, sm: 0 }}
      >
        <Grid item xs={12} sm="auto" order={{ sm: 1 }}>
          <Button
            variant="outlined"
            fullWidth
            disabled={loading}
            onClick={handleApproveDepositButton}
            endIcon={<SvgThinArrow />}
          >
            <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.action01" />
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto" order={{ sm: 0 }}>
          <Button
            style={{ padding: '12px 15px' }}
            fullWidth
            onClick={handleGoInvestmentDetailButton}
          >
            <FormattedMessage id="screen.depositRequests.tab01.acknowledDepositModal.action02" />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ApproveDepositsModal
