import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, Dispatch, SetStateAction } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
  headerTypography: {
    color: theme.typographyColor.primaryText,
    cursor: 'pointer',
  },
}))

export const getComponentsProps = (args: {
  panelAnchorEl: HTMLSpanElement | null
  apiRef?: MutableRefObject<GridApiPro | null>
  setPanelAnchorEl: Dispatch<SetStateAction<HTMLSpanElement | null>>
}) => {
  const { panelAnchorEl, apiRef, setPanelAnchorEl } = args

  return {
    panel: {
      anchorEl: panelAnchorEl,
      placement: 'bottom-end',
      sx: {
        '& .MuiInputBase-formControl': {
          color: theme.typographyColor.secondaryText,
        },
        '& .MuiTypography-root': {
          color: theme.typographyColor.primaryText,
          fontSize: 16,
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: theme.typographyColor.secondaryText,
        },
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          color: theme.typographyColor.primaryText,
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
          color: theme.typographyColor.primaryText,
        },
        '& .MuiSvgIcon-root.MuiNativeSelect-icon': {
          color: theme.typographyColor.primaryText,
        },
        '& .MuiNativeSelect-select.MuiInputBase-input.MuiInput-input': {
          '& option': {
            backgroundColor: theme.extraBackgrounds.paper02,
            color: theme.typographyColor.primaryText,
          },
        },
        '& .MuiInputBase-root.MuiInput-root:before': {
          borderBottom: `1px solid ${theme.typographyColor.onDisabledSecondaryButtonText}`,
        },
        '& .MuiInputBase-root.MuiInput-root:after': {
          borderBottom: `1px solid ${theme.typographyColor.primaryText}`,
        },
      },
    },
    filterPanel: {
      sx: {
        backgroundColor: theme.extraBackgrounds.paper02,
      },
    },
    columnsPanel: {
      sx: {
        backgroundColor: theme.extraBackgrounds.paper02,
      },
    },
    toolbar: {
      apiRef,
      setPanelAnchorEl,
    },
  }
}

export const InvestorsRequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    padding: ${theme.spacing(3)};
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    padding: ${theme.spacing(6, 7)};
    width: calc(100vw - 240px);
  }
  height: 100%;
  overflow-y: auto;
`
export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`
export const DataGridHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
`

export const DataGridActionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${theme.spacing(1)};
`

export const ExtraHeaderContainer = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ColumnFilterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${theme.spacing(3)};
`

export default useStyles
