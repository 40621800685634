import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme, theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  stepsWrapper: {
    flexDirection: 'row',
    gap: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(5),
    },
  },
  stepItemHeader: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'normal',
      gap: theme.spacing(3),
      marginBottom: 0,
    },
  },
  stepDivider: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '40px',
      height: '40px',
      transform: 'rotate(90deg)',
      position: 'absolute',
      left: '4px',
      marginTop: theme.spacing(7),
    },
  },
  white: {
    color: 'white !important',
  },
  stepActive: {
    borderColor: `${theme.buttonColor.hover} !important`,
  },
  noBorder: {
    border: 'none !important',
  },
  bottomText: {
    paddingTop: `${theme.spacing(4)}`,
    color: `${theme.typographyColor.primaryText}`,
  },
}))

export const StepsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${myTheme.spacing(4)};
  border-bottom: 1px dashed ${myTheme.typographyColor.secondaryText};
  padding-bottom: ${myTheme.spacing(5)};
`

export const StepItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const StepItemHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0px;
`

export const StepCircle = styled.div`
  max-width: 56px;
  max-height: 56px;
  flex: 1;
  border-radius: 50%;
  background-color: ${theme.extraBackgrounds.paper06};
  border: 1px solid ${theme.extraBackgrounds.paper06};
  padding: 16px;
`
export const StepDivider = styled.div`
  flex: 99;
  border-top: 1px dashed ${theme.typographyColor.secondaryText};
  width: 100%;
  height: 1px;
  align-self: center;
`

export const StepItemBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(1)};
  padding-right: ${myTheme.spacing(5)};
  .MuiTypography-body1 {
    color: ${myTheme.typographyColor.primaryText};
  }
`
