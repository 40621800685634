import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    '& .MuiChip-root': {
      padding: theme.spacing(0.5, 1),
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      gap: theme.spacing(0.5),
    },
    '& .MuiChip-avatar': {
      margin: '0 !important',
      width: '16px !important',
      height: '16px !important',
      '& path': {
        stroke: theme.extraBackgrounds.paper01,
      },
    },
    '& .MuiChip-filledSuccess': {
      backgroundColor: theme.palette.success.main,
      '& .MuiChip-label': {
        padding: '0px',
      },
    },
    '& .MuiChip-filledWarning': {
      backgroundColor: theme.palette.warning.main,
      '& .MuiChip-label': {
        padding: '0px',
      },
    },
    '& .MuiChip-filledError': {
      backgroundColor: theme.palette.error.main,
      '& .MuiChip-label': {
        padding: '0px',
      },
    },
    '& .MuiChip-filledInfo': {
      backgroundColor: theme.palette.info.main,
      '& .MuiChip-label': {
        padding: '0px',
      },
    },
  },
  percentage: {
    color: theme.extraBackgrounds.paper01,
    padding: '0px',
  },
}))

export const BalanceWrapper = styled.div`
  display: flex;
`
