import { useState } from 'react'

export const useLoading = (initialLoading = false) => {
  const [isLoading, setLoading] = useState(initialLoading)

  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  return { isLoading, startLoading, stopLoading }
}
