import { Grid, Tooltip, Typography } from '@mui/material'
import { FunctionComponent, ReactElement } from 'react'
import { MainContainer, InfoIconImage } from './styled'
import InfoIcon from 'assets/info-gray.svg'

interface Props {
  alignItems?: string
  flexDirection?: string
  width?: string
  title?: string | ReactElement
  children?: ReactElement
  tooltipText?: string | ReactElement
  tooltip?: boolean
  marginTop?: string
  gap?: string
}

const DashboardCard: FunctionComponent<Props> = ({
  alignItems,
  flexDirection,
  width,
  title,
  children,
  tooltipText,
  marginTop,
  gap,
}: Props) => {
  return (
    <MainContainer {...{ width, alignItems, flexDirection, marginTop, gap }}>
      {(title || tooltipText) && (
        <Grid container alignItems="center">
          {title && <Typography variant="body3">{title}</Typography>}
          {tooltipText && (
            <Tooltip arrow title={tooltipText}>
              <InfoIconImage src={InfoIcon} alt="info.svg" />
            </Tooltip>
          )}
        </Grid>
      )}
      {children}
    </MainContainer>
  )
}

export default DashboardCard
