import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled, { css } from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  planContainer: {
    maxWidth: '31%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  title: {
    color: `${theme.typographyColor.primaryText}!important`,
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.typographyColor.secondaryText,
  },
  minimalAmount: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
  },
  characteristics: {
    color: theme.typographyColor.primaryText,
  },
  link: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '12px',
    cursor: 'pointer',
  },
  img: {
    marginLeft: theme.spacing(0.6),
    marginRight: theme.spacing(0.6),
    width: '12px',
    height: '12px',
  },
}))

interface PlanContainerProps {
  readonly isSelected: boolean
}

export const PlanContainer = styled.div<PlanContainerProps>`
  display: grid;
  padding: ${theme.spacing(4, 2)};
  border-style: solid;
  border-color: ${theme.buttonColor.stroke};
  gap: ${theme.spacing(1)};
  column-gap: ${theme.spacing(1)};
  color: ${theme.typographyColor.primaryText};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${theme.extraBackgrounds.selectedItem};
    `}
`

export const CharacteristicsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const RelativeContainer = styled.div`
  width: 100%;
  position: relative;
`

export const TriangularLabel = styled.div`
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid rgba(255, 232, 140, 0.08);
  transform: rotate(45deg);
  position: absolute;
  left: 178px;
  top: -40px;
`

export const LabelIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: -15px;
  transform: rotate(-45deg);
`

export const ButtonContainer = styled.div``
