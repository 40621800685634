import { Typography } from '@mui/material'
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import useStyles, { ExtraHeaderContainer, ColumnFilterSection } from '../styled'
import { GridPreferencePanelsValue } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

interface ExtraHeaderProps {
  apiRef?: MutableRefObject<GridApiPro | null>
  setPanelAnchorEl: Dispatch<SetStateAction<HTMLSpanElement | null>>
}

const ExtraHeader: FunctionComponent<ExtraHeaderProps> = ({
  apiRef,
  setPanelAnchorEl,
}: ExtraHeaderProps) => {
  const ref = useRef<HTMLSpanElement | null>(null)
  const classes = useStyles()

  const handleShowFilterPanel = () => {
    if (ref.current) setPanelAnchorEl(ref.current)
    apiRef?.current?.showPreferences(GridPreferencePanelsValue.filters)
  }

  const handleShowColumnMenu = () => {
    if (ref.current) setPanelAnchorEl(ref.current)
    apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns)
  }

  return (
    <ExtraHeaderContainer>
      <Typography variant="body3" className={classes.white}>
        <FormattedMessage id="component.investmentDataGrid.listOfInvestors" />
      </Typography>
      {/*TODO: Show these options when are ready from back */}
      {/* <ColumnFilterSection>
        <Typography
          variant="body3"
          className={classes.headerTypography}
          onClick={handleShowColumnMenu}
        >
          <FormattedMessage id="component.dataGrid.columns" />
        </Typography>
        <Typography
          ref={ref}
          variant="body3"
          className={classes.headerTypography}
          onClick={handleShowFilterPanel}
        >
          <FormattedMessage id="component.dataGrid.filters" />
        </Typography>
      </ColumnFilterSection> */}
    </ExtraHeaderContainer>
  )
}

export default ExtraHeader
