import { FunctionComponent } from 'react'
import { useStyles, Wrapper, HeaderWrapper } from './styled'
import { Typography } from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import Card from 'components/common/Card'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import TitleAmount from 'components/TitleAmount'
import { ReactComponent as MoneyIcon } from 'assets/money.svg'

interface Props {
  amount: number
}

const NextMonth: FunctionComponent<Props> = ({ amount }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const scheduledWithdrawalsText = intl.formatMessage({
    id: 'components.nextMonth.scheduledWithdrawals',
  })

  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant="body3">
          <FormattedMessage id="components.nextMonth.title" />
        </Typography>
        <Typography className={classes.date} variant="h4">
          {moment().add(1, 'month').format('MMMM[, ]YYYY')}
        </Typography>
      </HeaderWrapper>
      <Divider size={theme.spacing(2)} />
      <Card>
        <MoneyIcon className={classes.icon} />
        <TitleAmount
          titleColor="variant01"
          color="variant01"
          title={scheduledWithdrawalsText}
          amount={amount}
          colorLineStyleProps={{ height: '0px' }}
        />
      </Card>
    </Wrapper>
  )
}

export default NextMonth
