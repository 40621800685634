import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    width: '100%',
    height: '100%',
    borderBottom: `1px solid ${theme.buttonColor.stroke}`,
    gridAutoFlow: 'column',
    alignItems: 'stretch',
  },
  whiteText: {
    color: 'white !important',
    '& p': {
      color: 'white !important',
    },
  },
}))

export const PlanDescriptionItemWrapper = styled.div`
  padding: ${myTheme.spacing(2)};
  ${({ theme: Theme }) => myTheme.breakpoints.down('md')} {
    padding: ${myTheme.spacing(1)};
  }
`

export const TextAttributeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${myTheme.spacing(3)};
  padding: ${myTheme.spacing(3, 1)};
  p: {
    color: white !important;
  }
`
export const TableDetailsWrapper = styled.div`
  margin-top: ${myTheme.spacing(4)};
  ${({ theme: Theme }) => myTheme.breakpoints.down('md')} {
    margin-top: ${myTheme.spacing(1)};
  }
`
