import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { columnsTreasurer } from 'components/common/DataGrid/utils'
import { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { GET_DEPOSITS_REQUESTS, GET_DEPOSIT_RULES_DATES } from '../queries'
import { Wrapper } from './styled'
import { DataGridHeaderWrapper } from '../styled'
import ApproveDepositsModal from './ApproveDepositsModal'
import { Deposit } from 'types'
import { theme } from 'screens/App/theme'
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import { DepositRequestsTab } from 'global/types'

export interface GetDepositsRequestVariables {
  page?: number
  limit?: number
  orderBy?: string
  orderDirection?: GridSortDirection
  status?: DepositRequestsTab | null
  search?: string
}

export interface GetDepositsRequestData {
  depositsRequest: {
    depositsRequest: Deposit[]
    total: number
  }
}

interface DepositRulesDates {
  depositRulesDates: {
    createdAt: string
    receivedAt: string
    targetDate: string
  }
}

const TAB_STATUS = 'REQUESTED'

const RequestedTab: FunctionComponent = () => {
  const [selectedDeposit, setSelectedDeposit] = useState<undefined | Deposit>(undefined)
  const [isApproveDepositsModalOpen, setIsApproveDepositsModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDirection, setOrderDirection] = useState<GridSortDirection | undefined>(undefined)

  const { data, error, loading, refetch } = useQuery<
    GetDepositsRequestData,
    GetDepositsRequestVariables
  >(GET_DEPOSITS_REQUESTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      status: DepositRequestsTab[TAB_STATUS],
    },
  })

  const { data: depositRulesDates } = useQuery<DepositRulesDates, { investmentUuid: string }>(
    GET_DEPOSIT_RULES_DATES,
    {
      variables: {
        investmentUuid: selectedDeposit?.investment.uuid ?? '',
      },
      skip: !selectedDeposit,
    },
  )

  const handleClickApproveButtonInDepositRow = (rowId: string) => {
    setSelectedDeposit(data?.depositsRequest?.depositsRequest.find((row) => row.id === rowId))
    setIsApproveDepositsModalOpen(true)
  }

  const handleCloseCallbackApproveDepositsModal = () => {
    setIsApproveDepositsModalOpen(false)
    setSelectedDeposit(undefined)
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const newOrderBy = sortModel?.[0]?.field
    const newOrderDirection = sortModel?.[0]?.sort
    setPage(0)
    setOrderBy(newOrderBy)
    setOrderDirection(newOrderDirection)
    refetch({ page: 0, orderBy: newOrderBy, orderDirection: newOrderDirection })
  }

  const handleOnPageSizeChange = (newPageSize: number) => {
    setPage(0)
    setLimit(newPageSize)
    refetch({ page: 0, limit: newPageSize })
  }

  const handleOnPageChange = (newPage: number) => {
    setPage(newPage)
    refetch({ page: newPage })
  }

  const columns = columnsTreasurer(
    undefined,
    undefined,
    true,
    undefined,
    undefined,
    true,
    handleClickApproveButtonInDepositRow,
  )

  let deposits = data?.depositsRequest.depositsRequest ?? []
  let rowCount = data?.depositsRequest.total ?? 0
  if (error || loading) {
    deposits = []
    rowCount = 0
  }

  const HeaderWithAction = () => {
    return (
      <DataGridHeaderWrapper>
        <Typography variant="body3" color={theme.typographyColor.primaryText}>
          Requested deposits
        </Typography>
        <div />
      </DataGridHeaderWrapper>
    )
  }

  return (
    <Wrapper>
      <Typography variant="body2">
        <FormattedMessage id="screen.depositRequests.tab01.description" />
      </Typography>
      <DataGrid
        extraHeader={<HeaderWithAction />}
        columns={columns}
        rows={deposits}
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={limit}
        paginationMode="server"
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        emptyMessageId="screen.depositRequests.tab.empty"
        errorMessageId="screen.depositRequests.tab.error"
        hasError={Boolean(error)}
        isLoading={loading}
        autoHeight
        sortingMode="server"
        activeSorting
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel: orderBy && orderDirection ? [{ field: orderBy, sort: orderDirection }] : [],
          },
        }}
      />
      {selectedDeposit && (
        <ApproveDepositsModal
          modalOpen={isApproveDepositsModalOpen}
          handleCloseCallback={handleCloseCallbackApproveDepositsModal}
          deposit={selectedDeposit}
          defaultInputValueDate={depositRulesDates?.depositRulesDates.targetDate}
        />
      )}
    </Wrapper>
  )
}

export default RequestedTab
