import { useLazyQuery } from '@apollo/client'
import { Tab, Tabs as MuiTabs, Typography, useMediaQuery, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import SearchInput from 'components/common/Inputs/SearchInput'
import { DepositRequestsTab } from 'global/types'
import { useCallback, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import ActiveTab from './ActiveTab'
import RequestedTab, { GetDepositsRequestData, GetDepositsRequestVariables } from './RequestedTab'
import PendingTab from './PendingTab'
import { GET_DEPOSITS_REQUESTS } from './queries'
import { DepositTab, changeTab } from './reducer'
import {
  DepositRequestWrapper,
  HeaderWrapper,
  SearchBarWrapper,
  TabContentWrapper,
  TabsWrapper,
  useStyles,
} from './styled'
import _ from 'lodash'
import { columnsTreasurer } from 'components/common/DataGrid/utils'
import DataGrid from 'components/common/DataGrid'

const getAriaAndId = (tab: DepositRequestsTab) => {
  return {
    id: `deposits-tab-${tab}`,
    'aria-controls': `deposits-tabpanel-${tab}`,
  }
}

function DepositRequests() {
  const classes = useStyles()
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const selectedTab: DepositTab = useSelector((state: GlobalState) => state.depositRequests)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const intl = useIntl()

  const [getRequests, { loading, data, error }] = useLazyQuery<
    GetDepositsRequestData,
    GetDepositsRequestVariables
  >(GET_DEPOSITS_REQUESTS, {
    fetchPolicy: 'no-cache',
    variables: {
      status: null,
      limit: 9999,
    },
  })
  const depositsDebouncer = useCallback(_.debounce(getRequests, 1000), [getRequests])
  const columns = columnsTreasurer(true, undefined, true)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
  }

  const Tabs = {
    [DepositRequestsTab.REQUESTED]: <RequestedTab />,
    [DepositRequestsTab.PENDING]: <PendingTab />,
    [DepositRequestsTab.ACTIVE]: <ActiveTab />,
  }

  const onChangeSearchValue = (newSearch: string) => {
    if (newSearch.length < 1) return
    setSearch(newSearch)
    depositsDebouncer({
      variables: {
        search: newSearch,
      },
    })
  }

  let deposits = data?.depositsRequest.depositsRequest ?? []
  let rowCount = data?.depositsRequest.total ?? 0
  if (error || loading) {
    deposits = []
    rowCount = 0
  }

  return (
    <DepositRequestWrapper>
      <HeaderWrapper>
        <Grid
          container
          rowGap={5}
          alignItems={{ xs: 'initial', md: 'center' }}
          justifyContent={{ xs: 'initial', md: 'space-between' }}
          paddingBottom={{ xs: 2, md: 2.5 }}
        >
          <Grid item xs={12} md="auto">
            <Typography className={classes.white} sx={{ typography: { xs: 'h3', md: 'h2' } }}>
              <FormattedMessage id="screen.depositRequests.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchBarWrapper>
              <SearchInput
                fullWidth={!isBreakpointMdUp}
                value={search}
                onChangeValue={onChangeSearchValue}
              />
            </SearchBarWrapper>
          </Grid>
        </Grid>
      </HeaderWrapper>
      {search.length > 1 ? (
        <TabsWrapper className={classes.noTabs}>
          <DataGrid
            columns={columns}
            rows={deposits}
            rowCount={rowCount}
            emptyMessageId="screen.depositRequests.search.empty"
            errorMessageId="screen.depositRequests.tab.error"
            isLoading={loading}
            hasError={Boolean(error)}
          />
        </TabsWrapper>
      ) : (
        <TabsWrapper>
          <MuiTabs
            className={classes.tabs}
            value={selectedTab.index}
            onChange={handleChange}
            aria-label={intl.formatMessage({ id: 'screen.depositRequests.title' }) as string}
          >
            <Tab
              label={intl.formatMessage({ id: 'screen.depositRequests.tab01.tab' })}
              {...getAriaAndId(DepositRequestsTab.REQUESTED)}
            />
            <Tab
              label={intl.formatMessage({ id: 'screen.depositRequests.tab02.tab' })}
              {...getAriaAndId(DepositRequestsTab.PENDING)}
            />
            <Tab
              label={intl.formatMessage({ id: 'screen.depositRequests.tab03.tab' })}
              {...getAriaAndId(DepositRequestsTab.ACTIVE)}
            />
          </MuiTabs>
          <TabContentWrapper>{Tabs[selectedTab.status]}</TabContentWrapper>
        </TabsWrapper>
      )}
    </DepositRequestWrapper>
  )
}

export default DepositRequests
