import { Card, CardContent, Typography } from '@mui/material'
import { FunctionComponent, useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as VerifiedIcon } from 'assets/check_outline.svg'
import { CardContentContainer, VerifiedStatusContainer, useStyles } from './styled'
import { useAuth0 } from '@auth0/auth0-react'
import { ProfileContext } from 'hooks/ProfileContext'
import { Divider } from 'screens/InvestorDetail/InvestorOverview/styled'
import AddTinModal from 'components/common/AddTinModal'
import Snackbar from 'components/common/Snackbar'

const TIN_ADDED_SNACKBAR_LABEL_ID = 'screen.investorDetail.snackbar.added.tin'

const ProfileOverviewCard: FunctionComponent = () => {
  const { user } = useAuth0()
  const { profileData, refetchProfileData } = useContext(ProfileContext)
  const [openAddTinModal, setOpenAddTinModal] = useState(false)
  const [openAddedTinSnackbar, setOpenAddedTinSnackbar] = useState(false)
  const tin = profileData?.profile?.tin || ''
  const classes = useStyles({ tinExists: Boolean(tin) })

  const handleOpenAddTinModal = (open: boolean) => () => !tin && setOpenAddTinModal(open)
  const handleCloseAddedTinSnackbar = () => setOpenAddedTinSnackbar(false)

  const typeLabel = `screen.profile.overview.investorType.${
    profileData?.profile?.application?.levelName || 'default'
  }`

  const onSaveTin = () => {
    refetchProfileData()
    setOpenAddedTinSnackbar(true)
  }

  return (
    <Card elevation={0} square className={classes.card}>
      <CardContent>
        <CardContentContainer>
          <Typography variant="body3">
            <FormattedMessage id={typeLabel} />
          </Typography>
          <Typography variant="h2" color="white">
            {profileData?.profile?.application?.name}
          </Typography>
          {user?.email_verified && (
            <VerifiedStatusContainer isVerified>
              <VerifiedIcon className={classes.verifiedIcon} />
              <Typography variant="body2">
                <FormattedMessage id="screen.profile.overview.verified" />
              </Typography>
            </VerifiedStatusContainer>
          )}
          <Typography variant="body3" marginTop={1} marginBottom={2}>
            {user?.email}
          </Typography>
          <Divider size="1px" backgroundColor="rgba(255, 255, 255, 0.08)" />
          <Typography
            onClick={handleOpenAddTinModal(true)}
            className={classes.addTin}
            variant="body3"
            marginTop={2}
          >
            {tin ? 'TIN: ' + tin : 'Add TIN'}
          </Typography>
        </CardContentContainer>
      </CardContent>
      <AddTinModal
        onSave={onSaveTin}
        data={{ profileUuid: profileData?.profile?.uuid || '', tin }}
        open={openAddTinModal}
        handleCloseCallback={handleOpenAddTinModal(false)}
      />
      <Snackbar
        messageId={TIN_ADDED_SNACKBAR_LABEL_ID}
        open={openAddedTinSnackbar}
        onClose={handleCloseAddedTinSnackbar}
      />
    </Card>
  )
}

export default ProfileOverviewCard
