import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    fontFamily: 'Acumin Pro',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))
