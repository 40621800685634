import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import apiSlice from 'data/api'
import appSlice from 'screens/App/reducer'
import depositRequestsSlice from 'screens/DepositRequests/reducer'
import InvestmentDetailsSlice from 'screens/InvestmentDetails/reducer'
import withdrawalRequestsSlice from 'screens/WithdrawalRequests/reducer'
import agentMyInvestmentsSlice from 'screens/AgentInvestments/reducer'
import newInvestmentSlice from 'screens/NewInvestment/reducer'
import newDepositRequestSuccessAppBarSlice from 'components/Appbar/NewDepositRequestSuccessAppBar/reducer'
import investmentListSlice from 'screens/InvestmentList/reducer'
import newAgentSlice from 'screens/NewAgent/reducer'

const store = configureStore({
  reducer: {
    app: appSlice,
    depositRequests: depositRequestsSlice,
    investmentDetails: InvestmentDetailsSlice,
    withdrawalRequests: withdrawalRequestsSlice,
    newInvestment: newInvestmentSlice,
    agentMyInvestments: agentMyInvestmentsSlice,
    newDepositRequestSuccessAppBar: newDepositRequestSuccessAppBarSlice,
    investmentList: investmentListSlice,
    newAgent: newAgentSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})

setupListeners(store.dispatch)

export default store

export type GlobalState = ReturnType<typeof store.getState>
