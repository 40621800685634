import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { Button, Modal as MuiModal, Typography, TextField, InputAdornment } from '@mui/material'
import {
  ActionWrapper,
  CloseIconWrapper,
  ErrorContainer,
  getTextFieldSx,
  HeaderWrapper,
  ModalContentWrapper,
  RightSideWrapper,
} from './styled'
import { FormattedMessage } from 'react-intl'
import CloseIcon from 'assets/close_white.svg'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'

export const formatInputValue = (value: string, defaultValue: string) => {
  const regExp = new RegExp('^[0-9]+(.[0-9]{0,2}){0,1}$')
  if (regExp.test(value) && value.trim().length !== 0) return value
  return defaultValue
}

const getInitialBalance = (balance?: number) => {
  if (balance) return formatInputValue(String(balance || 0), '')
  return ''
}

const EndIcon: FunctionComponent = () => <img src={ArrowRightDisabled} alt="arrow_right_black" />

interface Props {
  open: boolean
  width?: string
  balance?: number
  error?: boolean
  loading?: boolean
  onEditBalance?: (balance: number) => void
  handleCloseCallback?: () => void
}

const EditBalanceModal: FunctionComponent<Props> = ({
  open,
  width,
  balance,
  error,
  loading,
  onEditBalance,
  handleCloseCallback,
}: Props) => {
  const [text, setText] = useState('')

  const handleClose = () => {
    setText(getInitialBalance(balance))
    if (handleCloseCallback) handleCloseCallback()
  }

  const handleUpdateBalance = () => {
    const newBalance = Number(text.replaceAll(',', ''))
    if (onEditBalance) onEditBalance(newBalance)
  }

  const handleChangeBalance = (e: ChangeEvent<HTMLInputElement>) => {
    const value = formatInputValue(e.target.value, e.target.value.length ? text : '')
    setText(value)
  }

  useEffect(() => {
    setText(getInitialBalance(balance))
  }, [balance])

  return (
    <MuiModal open={open} onClose={handleClose}>
      <ModalContentWrapper width={width}>
        <HeaderWrapper>
          <RightSideWrapper>
            <CloseIconWrapper src={CloseIcon} onClick={handleClose} />
          </RightSideWrapper>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            <FormattedMessage id="components.editBalanceModal.title" />
          </Typography>
        </HeaderWrapper>
        <TextField
          sx={getTextFieldSx(text)}
          label="Balance"
          fullWidth
          variant="outlined"
          value={text}
          onChange={handleChangeBalance}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="white">$</Typography>
              </InputAdornment>
            ),
          }}
        />
        {error && (
          <ErrorContainer>
            <FormattedMessage id="global.error.generalError" />
          </ErrorContainer>
        )}
        <ActionWrapper>
          <Button
            disabled={loading || !text.trim().length}
            variant="contained"
            endIcon={<EndIcon />}
            onClick={handleUpdateBalance}
          >
            <FormattedMessage id="components.editBalanceModal.action.button.label" />
          </Button>
        </ActionWrapper>
      </ModalContentWrapper>
    </MuiModal>
  )
}

export default EditBalanceModal
