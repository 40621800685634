import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

// TODO: ADD styles on the fly (TESTING Screen)
export const useStyles = makeStyles((theme: Theme) => ({}))

export const LabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
