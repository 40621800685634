import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
}))

export const DataGridWrapper = styled.div`
  width: 100%;
  background-color: ${theme.extraBackgrounds.paper02};
`

export const DataGridExtraHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ColumnFilterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${theme.spacing(3)};
`
