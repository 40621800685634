import { Typography, Card, CardContent, Skeleton } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useStyles } from './styled'
import { FormattedMessage } from 'react-intl'
import InvestmentDataGrid from 'components/InvestmentDataGrid'
import { GET_INVESTMENTS } from 'screens/Home/queries'
import { useQuery } from '@apollo/client'
import { Investment } from 'types'

interface Props {
  changeHasInvestments: (hasInvestments: boolean) => void
}

const InvestmentCard: FunctionComponent<Props> = ({ changeHasInvestments }: Props) => {
  const classes = useStyles()

  const { loading, data } = useQuery(GET_INVESTMENTS, {
    fetchPolicy: 'network-only',
  })
  const investments: Investment[] = data?.investments || []

  useEffect(() => {
    if (!loading) changeHasInvestments(investments.length > 0)
  }, [investments.length, loading])

  if (loading) {
    return (
      <Skeleton variant="rectangular" width="100%" height={600}>
        <CardContent className={classes.card}>
          <InvestmentDataGrid investments={investments} isLoading={loading} />
        </CardContent>
      </Skeleton>
    )
  }

  return (
    <Card elevation={0} square>
      <CardContent className={classes.card}>
        {investments.length > 0 ? (
          <InvestmentDataGrid investments={investments} isLoading={loading} />
        ) : (
          <Typography variant="body1">
            <FormattedMessage id="components.cards.investmentCard.empty" />
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default InvestmentCard
