import { FunctionComponent } from 'react'
import { Wrapper } from './styled'
import InvestmentCard from 'components/cards/InvestmentCard'
import { useLoading } from 'hooks/LoadingHook'

const MyInvestmentsTab: FunctionComponent = () => {
  const { stopLoading } = useLoading(true)

  const finishLoadInvestments = () => stopLoading()

  return (
    <Wrapper>
      <InvestmentCard changeHasInvestments={finishLoadInvestments} />
    </Wrapper>
  )
}

export default MyInvestmentsTab
