import { FunctionComponent, useState } from 'react'
import { useStyles, Wrapper, GridContainer } from './styled'
import { Withdrawal } from 'types'
import { columnsInvestmentWithdrawals } from 'components/common/DataGrid/utils'
import CancelWithdrawalModal from './CancelWithdrawalModal'
import DataGrid from 'components/common/DataGrid'

interface Props {
  withdrawals: Withdrawal[]
  investmentUuid: string | undefined
  refetchInvestment: () => void
}

const WithdrawalsTab: FunctionComponent<Props> = ({
  withdrawals,
  investmentUuid,
  refetchInvestment,
}: Props) => {
  const [cancelWithdrawalModalOpen, setCancelWithdrawalModalOpen] = useState(false)
  const [selectedWithdrawal, setSelectedWithdrawal] = useState<Withdrawal>()

  const classes = useStyles()

  const cancelRequest = (withdrawal: Withdrawal) => {
    setSelectedWithdrawal(withdrawal)
    handleOpenCancelWithdrawalModal()
  }

  const columns = columnsInvestmentWithdrawals(cancelRequest)

  const handleOpenCancelWithdrawalModal = () => setCancelWithdrawalModalOpen(true)

  const handleCloseCancelWithdrawalCallback = () => {
    refetchInvestment()
    setCancelWithdrawalModalOpen(false)
  }

  return (
    <Wrapper>
      <GridContainer>
        <DataGrid
          className={classes.dataGrid}
          columns={columns}
          rows={withdrawals}
          emptyMessageId="screen.depositRequests.search.empty"
          errorMessageId="screen.depositRequests.tab.error"
          initialState={{
            sorting: {
              sortModel: [{ field: 'approvedAt', sort: 'desc' }],
            },
            pagination: { page: 0, pageSize: 10 },
          }}
          autoHeight
          getRowId={(row) => row?.uuid}
          activeSorting={true}
          pagination
          replaceDefaultPropsFromDataGrid={{
            rowCount: undefined,
            page: undefined,
            pageSize: undefined,
          }}
        />
      </GridContainer>
      <CancelWithdrawalModal
        modalOpen={cancelWithdrawalModalOpen}
        handleCloseCallback={handleCloseCancelWithdrawalCallback}
        withdrawal={selectedWithdrawal}
        investmentUuid={investmentUuid}
      />
    </Wrapper>
  )
}

export default WithdrawalsTab
