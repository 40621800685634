import { Fragment, FunctionComponent, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Card from 'components/cards/DashboardCard'
import { FormattedNumber, FormatNumberOptions, FormattedMessage } from 'react-intl'
import EditBalanceModal from 'components/EditBalanceModal'
import useStyles, { FlexContainer } from '../styled'
import {
  BALANCE_SHEET_ASSET_CARD_TITLE_ID,
  GLOBAL_LABEL_EDIT_ID,
  LIABILITIES_CARD_TITLE_ID,
  GROSS_MARGIN_CUMMULATIVE_CARD_TITLE_ID,
  GROSS_MARGIN_THIS_MONTH_CARD_TITLE_ID,
  WITHDRAWALS_CARD_TITLE_ID,
  AGENTS_MARGINS_CARD_TITLE_ID,
  ADD_BALANCE_LABEL_ID,
  HYPHEN,
  DEPOSIT_CARD_TITLE_ID,
} from './constants'
import { useMutation } from '@apollo/client'
import { UPDATE_MONTHLY_BALANCE } from '../queries'
import Snackbar from 'components/common/Snackbar'
import { isSameMonthAsCurrent } from 'utils/DateUtils'
import { ReactComponent as ArrowRight } from 'assets/arrow_right_primary.svg'

interface Props {
  balanceSheetAsset: number | null
  selectedDate: string
  grossMargin: number | null
  grossMarginCummulative: number | null
  agentsMargin: number | null
  liabilities: {
    total: number
    noLockup: number
    lockup: number
    earnings: number
  } | null
  balance: {
    plaid: number
    wire: number
    total: number
  } | null
  withdrawalsTotalAmount: number | null
  agentsEarnings: number | null
  refetchQueries: () => void
}

const formattedNumberProps = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
} as FormatNumberOptions

const MonthlyDataDashboard: FunctionComponent<Props> = ({
  balanceSheetAsset,
  selectedDate,
  grossMargin,
  grossMarginCummulative,
  agentsMargin,
  liabilities,
  balance,
  withdrawalsTotalAmount,
  agentsEarnings,
  refetchQueries,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [updateMonthlyBalance, { loading: updatingBalance }] = useMutation(UPDATE_MONTHLY_BALANCE)
  const { total } = balance || {}
  const liabilitiesValue = typeof liabilities?.total === 'number' ? Math.abs(liabilities?.total) : 0
  const buttonLabelId =
    typeof balanceSheetAsset === 'number' ? GLOBAL_LABEL_EDIT_ID : ADD_BALANCE_LABEL_ID

  const handleRedirectToPartners = () => navigate('/partners')

  const handleOpenModal = (open: boolean) => () => setOpenModal(open)

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const handleEditBalance = async (newBalance: number) => {
    try {
      const response = await updateMonthlyBalance({
        variables: {
          newBalance,
          date: selectedDate,
        },
      })
      const isUpdated = response?.data?.updateMonthlyBalance

      if (isUpdated) {
        refetchQueries()
        setOpenModal(false)
      }
    } catch (error) {
      console.log('Error when updating balance', error)
      setOpenSnackbar(true)
    }
  }

  return (
    <Fragment>
      <FlexContainer flexDirection="column" alignItems="center" width="100%" gap="8px">
        <FlexContainer alignItems="center" width="100%" gap="8px">
          <Card
            width="50%"
            gap="8px"
            flexDirection="column"
            title={<FormattedMessage id={BALANCE_SHEET_ASSET_CARD_TITLE_ID} />}
          >
            <FlexContainer className={classes.miniCard}>
              {typeof balanceSheetAsset === 'number' && (
                <Typography variant="h3" color="white">
                  <FormattedNumber {...formattedNumberProps} value={balanceSheetAsset} />
                </Typography>
              )}
              <Button disableTouchRipple onClick={handleOpenModal(true)}>
                <FormattedMessage id={buttonLabelId} />
              </Button>
            </FlexContainer>
          </Card>
          <Card
            width="50%"
            gap="8px"
            title={<FormattedMessage id={LIABILITIES_CARD_TITLE_ID} />}
            flexDirection="column"
          >
            <FlexContainer className={classes.miniCard}>
              <Typography variant="h3" color="white">
                <FormattedNumber {...formattedNumberProps} value={liabilitiesValue} />
              </Typography>
              {isSameMonthAsCurrent(selectedDate) && (
                <Typography variant="caption" className={classes.estimatedLabel} color="white">
                  <FormattedMessage id="global.label.estimated" />
                </Typography>
              )}
            </FlexContainer>
          </Card>
        </FlexContainer>
        <FlexContainer alignItems="center" width="100%" gap="8px">
          <Card
            width="50%"
            gap="8px"
            flexDirection="column"
            title={<FormattedMessage id={GROSS_MARGIN_CUMMULATIVE_CARD_TITLE_ID} />}
          >
            <Typography variant="h3" color="white">
              {typeof grossMarginCummulative === 'number' ? (
                <FormattedNumber {...formattedNumberProps} value={grossMarginCummulative} />
              ) : (
                HYPHEN
              )}
            </Typography>
          </Card>
          <Card
            width="50%"
            gap="8px"
            title={<FormattedMessage id={GROSS_MARGIN_THIS_MONTH_CARD_TITLE_ID} />}
            flexDirection="column"
          >
            <Typography variant="h3" color="white">
              {typeof grossMargin === 'number' ? (
                <FormattedNumber {...formattedNumberProps} value={grossMargin} />
              ) : (
                HYPHEN
              )}
            </Typography>
          </Card>
        </FlexContainer>
        <FlexContainer alignItems="center" width="100%" gap="8px">
          <Card
            width="50%"
            gap="8px"
            title={<FormattedMessage id={WITHDRAWALS_CARD_TITLE_ID} />}
            flexDirection="column"
          >
            <Typography variant="h3" color="white">
              {typeof withdrawalsTotalAmount === 'number' ? (
                <FormattedNumber {...formattedNumberProps} value={withdrawalsTotalAmount} />
              ) : (
                HYPHEN
              )}
            </Typography>
          </Card>
          <Card
            width="50%"
            flexDirection="column"
            gap="8px"
            title={<FormattedMessage id={DEPOSIT_CARD_TITLE_ID} />}
          >
            <Typography variant="h3" color="white">
              {typeof total === 'number' ? (
                <FormattedNumber {...formattedNumberProps} value={total} />
              ) : (
                HYPHEN
              )}
            </Typography>
          </Card>
        </FlexContainer>
        <FlexContainer alignItems="center" width="100%" gap="8px">
          <Card width="100%" flexDirection="column">
            <Fragment>
              <FlexContainer flexDirection="row" justifyContent="space-between" alignItems="center">
                <FlexContainer flexDirection="column" gap="8px">
                  <Typography variant="body3">
                    <FormattedMessage id={AGENTS_MARGINS_CARD_TITLE_ID} />
                  </Typography>
                  <Typography variant="h3" color="white">
                    {typeof agentsEarnings === 'number' ? (
                      <FormattedNumber {...formattedNumberProps} value={agentsEarnings} />
                    ) : (
                      HYPHEN
                    )}
                  </Typography>
                </FlexContainer>
                <ArrowRight className={classes.icon} onClick={handleRedirectToPartners} />
              </FlexContainer>
            </Fragment>
          </Card>
        </FlexContainer>
      </FlexContainer>
      <Snackbar
        messageId="global.error.generalError"
        open={openSnackbar}
        onClose={handleCloseSnackbar}
      />
      <EditBalanceModal
        open={openModal}
        loading={updatingBalance}
        onEditBalance={handleEditBalance}
        handleCloseCallback={handleOpenModal(false)}
        balance={balanceSheetAsset || 0}
        width="100%"
      />
    </Fragment>
  )
}

export default MonthlyDataDashboard
