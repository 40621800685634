import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    backgroundColor: theme.extraBackgrounds.paper03,
    padding: 0,
    height: '20px',
    color: 'green',
  },
  chevronLeft: {
    marginRight: theme.spacing(0.5),
    transform: 'rotate(90deg)',
  },
  chevronRight: {
    '& path': {
      stroke: `${theme.buttonColor.disable}`,
    },
    marginLeft: theme.spacing(0.5),
    transform: 'rotate(270deg)',
  },
  chevron: {
    '& path': {
      stroke: `${theme.buttonColor.disable}`,
      fill: `${theme.buttonColor.disable}`,
    },
  },
  chevronDisabled: {
    '& path': {
      stroke: `${theme.buttonColor.disable}`,
      fill: `${theme.buttonColor.disable}`,
    },
  },
  button: {
    color: theme.typographyColor.primaryText,
  },
  buttonDisabled: {
    color: `${theme.buttonColor.disable}`,
  },
}))

export const WrapperStepper = styled.div`
  padding: ${theme.spacing(3, 2)};
  background-color: ${theme.extraBackgrounds.paper03};
  width: 100%;
`
