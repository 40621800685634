import { useEffect, useState, createContext, FunctionComponent, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER_PROFILE } from 'layouts/queries'
import { QUERY_ME } from 'router/queries'
import { Profile } from 'global/types'

type UserMe = {
  userId: string
  name: string
  lastName: string
  email: string
  roles: string[]
}

type Response = {
  profileData?: { profile: Profile }
  profileRoles: string[]
  dataQueryMe?: { me: UserMe }
  clearStore: () => void
  loading: boolean
  isInvestor: boolean
  isTreasurer: boolean
  isAgent: boolean
  isPartner: boolean
  error: unknown
  hasFinishedLoading: boolean
  refetchProfileData: () => void
}

export const ProfileContext = createContext({} as Response)

interface Props {
  children: JSX.Element
}

const AuthProfile: FunctionComponent<Props> = ({ children }: Props) => {
  const [profileRoles, setProfileRoles] = useState<string[]>([])
  const [isInvestor, setIsInvestor] = useState(false)
  const [isTreasurer, setIsTreasurer] = useState(false)
  const [isAgent, setIsAgent] = useState(false)
  const [isPartner, setIsPartner] = useState(false)

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: refetchProfileData,
  } = useQuery<{ profile: Profile }>(GET_USER_PROFILE)

  const {
    data: dataQueryMe,
    loading: meLoading,
    error: meError,
  } = useQuery<{ me: UserMe }>(QUERY_ME)

  const checkIsRole = (role: string): boolean => dataQueryMe?.me.roles.includes(role) || false

  useEffect(() => {
    if (profileData && profileData?.profile?.uuid)
      localStorage.setItem('profile', JSON.stringify(profileData?.profile))
  }, [profileData])

  useEffect(() => {
    if (dataQueryMe?.me?.roles) {
      const roles = dataQueryMe.me.roles
      setProfileRoles(roles)
      localStorage.setItem('roles', JSON.stringify(roles))
      setIsTreasurer(checkIsRole('Treasurer'))
      setIsInvestor(checkIsRole('Investor'))
      setIsAgent(checkIsRole('Agent'))
      setIsPartner(checkIsRole('Partner'))
    }
  }, [dataQueryMe])

  const clearStore = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  const hasFinishedLoading = useMemo(() => {
    const role = [isInvestor, isTreasurer, isAgent, isPartner].some(Boolean)
    const profile = Boolean(profileData)
    const me = Boolean(dataQueryMe)
    const error = Boolean(profileError || meError)
    return role && profile && me && !error
  }, [
    dataQueryMe,
    profileData,
    meLoading,
    profileLoading,
    meError,
    profileError,
    isInvestor,
    isTreasurer,
    isAgent,
    isPartner,
  ])

  const defaultContext = {
    profileData,
    profileRoles,
    dataQueryMe,
    clearStore,
    loading: profileLoading || meLoading,
    isInvestor,
    isTreasurer,
    isAgent,
    isPartner,
    error: profileError || meError,
    hasFinishedLoading,
    refetchProfileData,
  }

  return <ProfileContext.Provider value={defaultContext}>{children}</ProfileContext.Provider>
}

export default AuthProfile
