import SumsubWebSdk from '@sumsub/websdk-react'
import { useMutation } from '@apollo/client'
import { CREATE_ACCESS_TOKEN } from './queries'
import { FunctionComponent, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonWrapper, Container, ErrorMessageWrapper, useStyles } from './styled'
import Card from 'components/common/Card'
import { Button, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ReloadBlack from 'assets/reload_black.svg'
import { AnyEventName, AnyEventPayload } from '@sumsub/websdk/types/types'

type AccessToken = {
  user: {
    userId: string
  }
  sumsubToken: string
}

const APPLICATION_SUBMITTED_TYPE = 'idCheck.onApplicantSubmitted'
const PENDING = 'pending'
const tokenInitalState: AccessToken = {
  user: {
    userId: '',
  },
  sumsubToken: '',
}

const Sumsub: FunctionComponent = () => {
  const classes = useStyles()
  const goHome = useNavigate()
  const [createAccessToken] = useMutation(CREATE_ACCESS_TOKEN)
  const { user } = useAuth0() || {}
  const [token, setToken] = useState<AccessToken>(tokenInitalState)
  const [errorSumsub, setErrorSumsub] = useState(false)
  const [, userId] = user?.sub?.split('|') || []
  const { levelname } = useParams()
  const levelName = levelname

  const getToken = async (): Promise<void> => {
    try {
      const response = await createAccessToken({
        variables: {
          userId,
          levelName,
        },
      })
      const data = response?.data?.sumsubToken
      setToken(data)
    } catch (e: unknown) {
      console.error(e)
      setErrorSumsub(true)
    }
  }
  const { sumsubToken } = token

  useEffect(() => {
    getToken()
  }, [user])

  //TODO: Add expiration handler logic for token and promise type
  const handler = async (): Promise<any> => {
    console.error('Access Token has been expired')
  }

  const handleMessage = (type: AnyEventName, payload: AnyEventPayload) => {
    const reviewStatus =
      type === 'idCheck.applicantStatus' && 'reviewStatus' in payload
        ? payload?.reviewStatus
        : undefined

    if (type === APPLICATION_SUBMITTED_TYPE || reviewStatus === PENDING) {
      setTimeout(() => {
        goHome('/welcome/sumsub')
      }, 3000)
    }
  }

  const refreshPage = () => {
    location.reload()
  }

  return (
    <Container className={classes.container}>
      {sumsubToken ? (
        <SumsubWebSdk
          accessToken={sumsubToken}
          expirationHandler={handler}
          onMessage={handleMessage}
        />
      ) : (
        errorSumsub && (
          <ErrorMessageWrapper>
            <Card>
              <Typography variant="h1">
                <FormattedMessage id="screen.sumsub.errorMessageCard.title" />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id="screen.sumsub.errorMessageCard.subtitle" />
              </Typography>
              <ButtonWrapper>
                <Button
                  variant="contained"
                  endIcon={<img src={ReloadBlack} alt="reload" />}
                  onClick={refreshPage}
                >
                  <FormattedMessage id="screen.sumsub.errorMessageCard.RefreshButton" />
                </Button>
              </ButtonWrapper>
            </Card>
          </ErrorMessageWrapper>
        )
      )}
    </Container>
  )
}

export default Sumsub
