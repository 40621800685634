import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content',
    minHeight: 'calc(100vh - 80px)',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
}))

export const MainContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  width: 100%;
  color: ${theme.typographyColor.primaryText};
  gap: ${theme.spacing(5)};
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    gap: ${theme.spacing(2)};
    flex-direction: column;
    padding-bottom: ${theme.spacing(3)};
  }
`

export const OverviewContainer = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
`

export const InvestmentDetailContainer = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
`
export const TopTitleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 48px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing(3)};
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`

export const CopyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(5)};
  gap: ${theme.spacing(2)};
  background-color: #8f9caa29;
  p {
    color: ${theme.typographyColor.primaryText};
  }
`
