import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  typographySpacing: {
    marginTop: theme.spacing(2),
  },
  minimumTypographySpacing: {
    marginTop: theme.spacing(1),
  },
}))

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(6)};
`

export const BankInformationContainer = styled.div`
  margin-bottom: 40px;
  p {
    color: ${theme.typographyColor.secondaryText};
  }
`

export const MainContainer = styled.div`
  margin-top: ${theme.spacing(4)};
  background-color: ${theme.extraBackgrounds.paper02};
  min-height: 530px;
  padding: ${theme.spacing(4, 5, 3, 5)};
`

export const SecondDescriptionMainContainer = styled.div`
  margin-top: ${theme.spacing(2)};
`

export const Divider = styled.div`
  border: 1px dashed ${theme.typographyColor.secondaryText};
  margin: ${theme.spacing(3, 0, 5, 0)};
`

export default useStyles
