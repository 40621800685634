import React, { FunctionComponent, ReactNode } from 'react'
import { StyledLayout, useStyles } from './styled'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles()
  return <StyledLayout className={classes.mainLayout}>{props.children}</StyledLayout>
}

export default Layout
