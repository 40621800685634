import { FunctionComponent, ElementType } from 'react'

interface Props {
  component: FunctionComponent
  layout: ElementType
}

const PublicRoute = ({ component: Component, layout: Layout }: Props) => {
  return (
    <Layout>
      <Component />
    </Layout>
  )
}

export default PublicRoute
