import { FunctionComponent, useState, useEffect } from 'react'
import { Wrapper } from './styled'
import { useIntl } from 'react-intl'
import moment, { Moment } from 'moment'
import { useLazyQuery } from '@apollo/client'
import { GET_INVESTMENT_DETAILS_CHART_DATA_BY_INVESTOR } from '../queries'
import { Investment } from 'types'
import momentTz from 'moment-timezone'
import ChartCard from 'components/cards/ChartCard'

interface Props {
  investment: Investment
}

interface Period {
  name: string
  startDate: Moment | null
  endDate: Moment
}

interface TitleData {
  color: 'success' | 'error' | 'warning' | 'variant01'
  text: string
  amount: number
}

const LOWEST_DATE = moment('01-01-2000')

const OverviewTab: FunctionComponent<Props> = ({ investment }: Props) => {
  const currentEndOfMonth = moment().endOf('month')
  const intl = useIntl()
  const timezone = momentTz.tz.guess(true)

  const [selectedPeriod, setSelectedPeriod] = useState<Period>({
    name: 'all',
    startDate: LOWEST_DATE,
    endDate: currentEndOfMonth,
  })
  const [getInvestmentDetails, { data }] = useLazyQuery(
    GET_INVESTMENT_DETAILS_CHART_DATA_BY_INVESTOR,
    {
      variables: {
        investmentUuid: investment?.uuid,
        startDate: selectedPeriod?.startDate?.utc(),
        endDate: selectedPeriod?.endDate.utc(),
        profileUuid: investment?.profile.uuid,
        timezone,
      },
    },
  )

  useEffect(() => {
    if (investment?.uuid && selectedPeriod?.startDate && selectedPeriod?.endDate) {
      getInvestmentDetails()
    }
  }, [selectedPeriod, investment])

  const handleChangePeriod = (name: string, startDate: Moment | null, endDate: Moment) => {
    setSelectedPeriod({
      name: name,
      startDate: startDate,
      endDate: endDate,
    })
  }

  const chartCardTitles: TitleData[] = [
    {
      color: 'variant01',
      text: intl.formatMessage({ id: 'screen.investmentDetail.tab1.totalDepositsToDate' }),
      amount: data?.investmentDetailsChartByInvestor?.totalDeposits || 0,
    },
    {
      color: 'success',
      text: intl.formatMessage({ id: 'screen.investmentDetail.tab1.totalEarningsToDate' }),
      amount: data?.investmentDetailsChartByInvestor?.totalEarnings || 0,
    },
    {
      color: 'error',
      text: intl.formatMessage({ id: 'screen.investmentDetail.tab1.totalWithdrawalsToDate' }),
      amount: data?.investmentDetailsChartByInvestor?.totalWithdrawals || 0,
    },
  ]

  return (
    <Wrapper>
      <ChartCard
        chooseOptionsToShow={{
          showAll: true,
          showAYear: true,
          showCurrentMonth: true,
          showThreeMonths: false,
          showSixMonths: true,
        }}
        titles={chartCardTitles}
        multiTypeChartProps={{
          chartDataBar: data?.investmentDetailsChartByInvestor?.chartData?.datasets[0],
          chartDataLine: data?.investmentDetailsChartByInvestor?.chartData?.datasets[1],
          chartLabels: data?.investmentDetailsChartByInvestor?.chartData?.labels,
        }}
        selectedPeriod={selectedPeriod}
        onChangePeriod={handleChangePeriod}
      />
    </Wrapper>
  )
}

export default OverviewTab
