import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    backgroundColor: theme.extraBackgrounds.paper01,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 80px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 7),
    },
  },
  investmentsLoading: {
    color: theme.typographyColor.secondaryText,
    backgroundColor: theme.extraBackgrounds.paper02,
  },
}))

export const MainContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  color: ${theme.typographyColor.primaryText};
  width: 100%;
  gap: 16px;
  display: flex;
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    padding-bottom: ${theme.spacing(3)};
  }
`

export const OverviewContainer = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
`

export const DataGridListContainer = styled.div`
  ${({ theme: Theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme: Theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
`

export const Image = styled.img`
  width: 16px;
  height: 16px;
  position: relative;
  top: 4px;
  left: 4px;
`

export default useStyles
