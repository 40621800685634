import { gql } from '@apollo/client'

export const GET_WITHDRAWAL_REQUESTS = gql`
  query WithdrawalsRequest(
    $status: WithdrawalStatusInput = null
    $page: Int = 0
    $limit: Int = 10
    $orderBy: WithdrawalsRequestListOrderBy = withdrawalRequestedDate
    $orderDirection: OrderDirection = desc
    $search: String = null
  ) {
    withdrawalRequests(
      status: $status
      page: $page
      limit: $limit
      search: $search
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      total
      withdrawalsRequest {
        id
        fullName
        investment {
          uuid
          status
          isMonthlyWithdrawal
          profile {
            uuid
          }
          duration {
            endDate
            startDate
          }
          contract {
            id
            url
          }
          plan {
            uuid
            name
            suffixName
            description
            interestRatePerMonth
            minimumInitialInvestment
            principalLockupInMonths
            maturity
            withdrawalAllowed
            earlyCancellationPenalty
            scheduledWithdrawalAllowed
            earlyCancellationAllowed
          }
          transactions {
            ... on Deposit {
              uuid
              status
              stripeId
              amount
              acknowledgeAt
              approvedAt
            }
            ... on Earning {
              uuid
              amount
              approvedAt
            }
          }
        }
        withdrawal {
          uuid
          amount
          approvedAt
          deliveredAt
          canBeCancelable
          requestedAt
          status
          type
        }
      }
    }
  }
`

export const APPLY_WITHDRAWALS = gql`
  mutation CompleteWithdrawalRequest($withdrawalUuids: [String!]!, $approve: Boolean!) {
    completeWithdrawalRequest(withdrawalUuids: $withdrawalUuids, approve: $approve) {
      success
      message
      transactionUuid
    }
  }
`

export const GET_SCHEDULED_WITHDRAWALS = gql`
  query ScheduledWithdrawals($status: String!) {
    scheduledWithdrawals(status: $status) {
      profile {
        application {
          name
        }
      }
      plan {
        name
      }
      balance {
        total
      }
      scheduledWithdrawals {
        amount
        endDate
        startDate
        status
        uuid
      }
    }
  }
`
