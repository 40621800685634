import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import { ReactComponent as OffIcon } from 'assets/state_off.svg'
import { ReactComponent as OnIcon } from 'assets/state_on.svg'
import Card from 'components/common/Card'
import { Divider } from 'components/common/styled'
import { StepNumber } from 'global/types'
import { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { theme } from 'screens/App/theme'
import { formatMoneyWithSymbolAndTwoDecimals } from 'utils/MoneyFormat'
import { ButtonContainer, WithdrawalRow, SubtitleWrapper, useStyles } from './styled'
import { DepositFrequency, Investment } from 'types'
import moment from 'moment'
import TimeLeftInlineAlert from '../TimeLeftInlineAlert'
import ButtonWithArrowAnimation from 'components/common/ButtonWithArrowAnimation'
import SubtitleTimeRules from '../SubtitleTimeRules'

interface Props {
  onClickBackButton: (step: StepNumber) => void
  onClickConfirmButton: () => void
  onDisableContinueButton: (isDisabled: boolean) => void
  newDepositAmount: number
  investment?: Investment
  depositRulesDates?: {
    createdAt: string
    receivedAt: string
    targetDate: string
  }
}

const DepositConfirmationStepper: FunctionComponent<Props> = ({
  onClickBackButton,
  onClickConfirmButton,
  onDisableContinueButton,
  newDepositAmount,
  investment,
  depositRulesDates,
}) => {
  const classes = useStyles()
  const [isCheckedDepositScheduleCheckbox, setIsCheckedDepositScheduleCheckbox] = useState(false)
  const [isCheckedReferenceNumberCheckbox, setIsCheckedReferenceNumberCheckbox] = useState(false)

  const isContinueButtonDisabled =
    !isCheckedDepositScheduleCheckbox || !isCheckedReferenceNumberCheckbox

  const handleDepositScheduleCheckboxChange = () => {
    setIsCheckedDepositScheduleCheckbox((oldValue) => !oldValue)
  }

  const handleReferenceNumberCheckboxChange = () => {
    setIsCheckedReferenceNumberCheckbox((oldValue) => !oldValue)
  }

  const handleBackButton = () => {
    onClickBackButton(0)
  }

  useEffect(() => {
    onDisableContinueButton(isContinueButtonDisabled)
  }, [isCheckedDepositScheduleCheckbox, isCheckedReferenceNumberCheckbox])

  const balanceAfterDeposit = investment?.balance?.total
    ? investment.balance.total + newDepositAmount
    : newDepositAmount

  return (
    <Card className={classes.cardSpacing}>
      <Typography variant="h4">
        <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.subtitle" />
      </Typography>
      <SubtitleTimeRules
        depositFrequency={investment?.plan.depositFrequency || DepositFrequency.NEXT_MONTH}
        depositRulesDates={depositRulesDates}
      />
      <div>
        <WithdrawalRow>
          <Typography variant="body2">
            <FormattedMessage
              id="screen.newDepositRequest.depositConfirmationStepper.endingBalance"
              values={{ date: moment(investment?.balance?.cutoffDate).format('M/D/YYYY') }}
            />
          </Typography>
          <Typography variant="body2" component="span" color={theme.typographyColor.primaryText}>
            {formatMoneyWithSymbolAndTwoDecimals(investment?.balance?.total || 0)}
          </Typography>
        </WithdrawalRow>
        <WithdrawalRow grayBackground>
          <Typography variant="body2">
            <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.depositAmount" />
          </Typography>
          <Typography variant="body2" component="span" color={theme.typographyColor.primaryText}>
            {formatMoneyWithSymbolAndTwoDecimals(newDepositAmount)}
          </Typography>
        </WithdrawalRow>
        <WithdrawalRow>
          <Typography variant="body2">
            <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.balanceAfterDeposit" />
          </Typography>
          <Typography variant="h4">
            {formatMoneyWithSymbolAndTwoDecimals(balanceAfterDeposit)}
          </Typography>
        </WithdrawalRow>
      </div>
      <Divider size={theme.spacing(3)} />
      <TimeLeftInlineAlert
        createdBefore={depositRulesDates?.receivedAt}
        startGeneratingInterest={depositRulesDates?.targetDate}
      />
      <Divider size={theme.spacing(3)} />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckedDepositScheduleCheckbox}
              onChange={handleDepositScheduleCheckboxChange}
              icon={<OffIcon />}
              checkedIcon={<OnIcon />}
            />
          }
          value="start"
          label={
            <Typography variant="body3" color={theme.typographyColor.secondaryText}>
              <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.checkbox.text1" />
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckedReferenceNumberCheckbox}
              onChange={handleReferenceNumberCheckboxChange}
              icon={<OffIcon />}
              checkedIcon={<OnIcon />}
            />
          }
          value="start"
          label={
            <Typography variant="body3" color={theme.typographyColor.secondaryText}>
              <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.checkbox.text2" />
            </Typography>
          }
        />
      </div>
      <Divider size={theme.spacing(0)} />
      <ButtonContainer>
        <Button variant="outlined" onClick={handleBackButton}>
          <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.backButton" />
        </Button>
        <ButtonWithArrowAnimation
          variant="contained"
          disabled={isContinueButtonDisabled}
          onClick={onClickConfirmButton}
        >
          <FormattedMessage id="screen.newDepositRequest.depositConfirmationStepper.confirmButton" />
        </ButtonWithArrowAnimation>
      </ButtonContainer>
    </Card>
  )
}

export default DepositConfirmationStepper
