import { FormControl, InputLabel, Menu, Select, SelectChangeEvent } from '@mui/material'
import clsx from 'clsx'
import { FunctionComponent, ReactNode, ElementType } from 'react'
import { useStyles } from './styled'

interface Props {
  label?: string
  children?: ReactNode | ReactNode[]
  value: string
  variant?: 'date'
  onChange: (event: SelectChangeEvent) => void
  iconComponent?: ElementType
  renderValue?: (selected: string) => string
  MenuProps?: Parameters<typeof Select>[0]['MenuProps']
}

const SelectInput: FunctionComponent<Props> = ({
  label,
  children,
  value,
  onChange,
  renderValue,
  variant,
  iconComponent,
  MenuProps,
}: Props) => {
  const classes = useStyles()
  return (
    <FormControl
      className={clsx(classes.selectInput, variant === 'date' && classes.dateVariant)}
      fullWidth
    >
      <InputLabel id="select-label" className={classes.selectInput}>
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        id="simple-select"
        value={value}
        label={label}
        onChange={onChange}
        IconComponent={iconComponent}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectInput
