import { gql } from '@apollo/client'

export const SAVE_BANK_ACCOUNT = gql`
  mutation saveWireBankAccount(
    $country: String!
    $bankName: String!
    $countryKey: String!
    $accountNumber: String!
    $routingNumber: String!
    $preferredMethod: PreferredMethodInput
    $beneficiary: String
    $profileUuid: String!
    $swiftCode: String
  ) {
    saveWireBankAccount(
      country: $country
      bankName: $bankName
      countryKey: $countryKey
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      preferredMethod: $preferredMethod
      beneficiary: $beneficiary
      profileUuid: $profileUuid
      swiftCode: $swiftCode
    ) {
      uuid
      preferredMethod
    }
  }
`

export const GET_MOST_USED_COUNTRIES = gql`
  query getMostUsedCountries {
    mostUsedCountries
  }
`
