import { Typography } from '@mui/material'
import CustomDoughnutChart from 'components/CustomDoughnutChart'
import { FunctionComponent } from 'react'
import { FormatNumberOptions, FormattedMessage, FormattedNumber } from 'react-intl'
import { theme } from 'screens/App/theme'
import useStyles, { Dot, FlexContainer } from '../styled'
import RedChevronsIcon from 'assets/red_down.svg'
import GreenChevronsIcon from 'assets/green_up.svg'
import { Image } from '../styled'
import { isSameMonthAsCurrent } from 'utils/DateUtils'

enum PercentageDiffColors {
  UP = 'up',
  DOWN = 'down',
  NONE = 'none',
}

const { DOWN, NONE, UP } = PercentageDiffColors

const TITLE_LABEL_ID = 'components.activeInvestments.title'
const SUBTITLE_LABEL_ID = 'components.activeInvestments.difference.last.month'
const LIQUID_ROW_LABEL_ID = 'components.activeInvestments.row.description.liquid'
const LOCKUP_ROW_LABEL_ID = 'components.activeInvestments.row.description.lockup'
const EARNINGS_ROW_LABEL_ID = 'components.activeInvestments.row.description.earnings'

const formattedNumberProps = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
} as FormatNumberOptions

const { doughnutChartColors } = theme
const { blue: blueColor, pink: pinkColor, yellow: yellowColor } = doughnutChartColors

const differenceColors = {
  up: theme.palette.success.main,
  down: theme.palette.error.main,
  none: theme.typographyColor.primaryText,
}

interface Props {
  percentageFromLastMonth: number
  totalActiveInvestments: number
  liquid: number
  lockup: number
  earnings: number
  selectedDate: string
}

const getPercentageDiffColor = (percentage: number) => {
  if (percentage > 0) return differenceColors[UP]
  if (percentage < 0) return differenceColors[DOWN]
  return differenceColors[NONE]
}

const ActiveInvestments: FunctionComponent<Props> = ({
  percentageFromLastMonth,
  totalActiveInvestments,
  liquid,
  lockup,
  earnings,
  selectedDate,
}: Props) => {
  const classes = useStyles()
  const percentageDiffColor = getPercentageDiffColor(percentageFromLastMonth)
  const rows = [
    {
      dotColor: blueColor,
      label: <FormattedMessage id={LIQUID_ROW_LABEL_ID} />,
      value: liquid,
    },
    {
      dotColor: pinkColor,
      label: <FormattedMessage id={LOCKUP_ROW_LABEL_ID} />,
      value: lockup,
    },
    {
      dotColor: yellowColor,
      label: <FormattedMessage id={EARNINGS_ROW_LABEL_ID} />,
      value: earnings,
    },
  ]

  return (
    <FlexContainer
      width="100%"
      height="100%"
      border="1px solid rgba(255, 255, 255, 0.1)"
      backgroundColor={theme.palette.background.default}
      flexDirection="column"
      alignItems="center"
    >
      <FlexContainer width="100%" marginTop="32px" justifyContent="center">
        <Typography variant="body3">
          <FormattedMessage id={TITLE_LABEL_ID} />
        </Typography>
      </FlexContainer>
      <FlexContainer width="100%" marginTop="8px" justifyContent="center">
        <Typography variant="h1" color="white">
          <FormattedNumber {...formattedNumberProps} value={totalActiveInvestments} />
        </Typography>
      </FlexContainer>
      <FlexContainer width="100%" marginTop="8px" justifyContent="center">
        {percentageFromLastMonth > 0 && !isSameMonthAsCurrent(selectedDate) && (
          <Image src={GreenChevronsIcon} alt="green_up.svg" />
        )}
        {percentageFromLastMonth < 0 && !isSameMonthAsCurrent(selectedDate) && (
          <Image src={RedChevronsIcon} alt="red_down.svg" />
        )}
        {isSameMonthAsCurrent(selectedDate) && (
          <Typography variant="caption" className={classes.estimatedLabel} color="white">
            <FormattedMessage id="global.label.estimated" />
          </Typography>
        )}
        {!isSameMonthAsCurrent(selectedDate) && (
          <Typography variant="body3" color={percentageDiffColor}>
            <FormattedMessage id={SUBTITLE_LABEL_ID} values={{ value: percentageFromLastMonth }} />
          </Typography>
        )}
      </FlexContainer>
      <FlexContainer width="100%" marginTop="24px" justifyContent="center">
        <CustomDoughnutChart
          data={rows.map(({ value }) => value)}
          colors={[blueColor as string, pinkColor as string, yellowColor as string]}
          width="160px"
          height="160px"
        />
      </FlexContainer>
      <FlexContainer
        width="100%"
        marginTop="24px"
        padding="0px 16px 32px 16px"
        flexDirection="column"
      >
        {rows.map(({ dotColor, label, value }) => (
          <FlexContainer
            key={`${dotColor}-${label}`}
            width="100%"
            alignItems="center"
            gap="8px"
            padding="8px 16px 8px 16px"
            borderBottom="1px solid rgba(255, 255, 255, 0.1)"
          >
            <Dot backgroundColor={dotColor} />
            <Typography variant="body3" className={classes.rowLabel}>
              {label}
            </Typography>
            <FlexContainer width="100%" flexDirection="row-reverse">
              <Typography variant="body1" color="white">
                <FormattedNumber {...formattedNumberProps} value={value} />
              </Typography>
            </FlexContainer>
          </FlexContainer>
        ))}
      </FlexContainer>
    </FlexContainer>
  )
}

export default ActiveInvestments
