import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WithdrawalRequestsTab } from 'global/types'

export interface WithdrawalTab {
  status: WithdrawalRequestsTab
  index: number
}

const initialState = { status: WithdrawalRequestsTab.PENDING, index: 0 } as WithdrawalTab

const withdrawalsTabStates: WithdrawalTab[] = [
  { status: WithdrawalRequestsTab.PENDING, index: 0 },
  { status: WithdrawalRequestsTab.COMPLETED, index: 1 },
  { status: WithdrawalRequestsTab.ACTIVE_SCHEDULED, index: 2 },
  { status: WithdrawalRequestsTab.INACTIVE_SCHEDULED, index: 3 },
]

const withdrawalRequestsSlice = createSlice({
  name: 'withdrawalRequests',
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      const newTab = withdrawalsTabStates[action.payload]
      state.index = newTab.index
      state.status = newTab.status
    },
  },
})

export const { changeTab } = withdrawalRequestsSlice.actions
export default withdrawalRequestsSlice.reducer
