export const getFormattedData = (data?: {
  uuid?: string
  balanceSheetAsset: number | null
  date?: string
  grossMargin: number | null
  grossMarginCummulative: number | null
  liabilities: {
    lastMonthDiff: number
    total: number
    noLockup: number
    lockup: number
    earnings: number
  } | null
  balance: {
    plaid: number
    wire: number
    total: number
  } | null
  withdrawalsTotalAmount: number | null
  agentsEarnings: number | null
}) => {
  const {
    balanceSheetAsset,
    grossMargin,
    grossMarginCummulative,
    liabilities,
    balance,
    withdrawalsTotalAmount,
    agentsEarnings,
  } = data || {}

  return {
    balanceSheetAsset: balanceSheetAsset || null,
    grossMargin: grossMargin || null,
    grossMarginCummulative: grossMarginCummulative || null,
    liabilities: liabilities || null,
    balance: balance || null,
    withdrawalsTotalAmount: withdrawalsTotalAmount || null,
    agentsEarnings: agentsEarnings || null,
    noLockup: liabilities?.noLockup || null,
    percentageFromLastMonth: liabilities?.lastMonthDiff || null,
  }
}
