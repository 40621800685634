import { gql } from '@apollo/client'

export const GET_PROFILE_BY_UUID = gql`
  query ProfileByUuid($uuid: String!) {
    profileByUuid(uuid: $uuid) {
      uuid
      application {
        name
        status
        applicantId
      }
      tin
    }
  }
`

export const GET_REFERRAL_CODE = gql`
  query ReferralCode($profileUuid: uuid!) {
    referralCode(profileUuid: $profileUuid) {
      code
      uuid
      isUpdateBlocked
    }
  }
`

export const VALIDATE_REFERRAL_CODE = gql`
  mutation ValidateReferralCode($referralCode: String!, $profileUuid: uuid!) {
    validateReferralCode(referralCode: $referralCode, profileUuid: $profileUuid) {
      code
      uuid
    }
  }
`

export const CREATE_AGENT = gql`
  mutation CreateAgent(
    $referralCode: String!
    $profileUuid: uuid!
    $plansUuid: [uuid!]!
    $linkPlans: [LinkedPlanInput!]!
  ) {
    createAgent(
      referralCode: $referralCode
      profileUuid: $profileUuid
      plansUuid: $plansUuid
      linkPlans: $linkPlans
    ) {
      referralCode {
        code
        uuid
      }
      uuid
    }
  }
`

export const CREATE_PLAN = gql`
  mutation CreatePlan($profileUuid: uuid!, $plan: PlanInput!, $forUpdate: Boolean!) {
    createPlan(profileUuid: $profileUuid, plan: $plan, forUpdate: $forUpdate) {
      uuid
      name
      suffixName
      description
      shortDescription
      principalLockupInMonths
      maturity
      autoRenewal
      autoRenewalPeriodInMonths
      maxAutoRenewalPeriod
      renewalPeriodLockInMonths
      depositAllowed
      earlyWithdrawalAllowed
      scheduledWithdrawalAllowed
      withdrawalAllowed
      depositFrequency
      contractId
      memorandumId
      minimumInitialInvestment
      interestRatePerMonth
      maximumInvestment
      initialProcessingFee
      earlyWithdrawalPenalty
      earningPenalty
      deadlineDays
      deadlineTime
      includeWeekends
      interestType
      earlyCancellationAllowed
      earlyCancellationPenalty
      scheduledWithdrawalMandatory
      scheduledWithdrawalPeriodInMonths
      interestPeriodDescription
    }
  }
`

export const GET_PLANS_BY_AGENT = gql`
  query PlansByAgent($profileUuid: uuid!) {
    plansByAgent(profileUuid: $profileUuid) {
      uuid
      name
      suffixName
      description
      shortDescription
      principalLockupInMonths
      maturity
      autoRenewal
      autoRenewalPeriodInMonths
      maxAutoRenewalPeriod
      renewalPeriodLockInMonths
      depositAllowed
      earlyWithdrawalAllowed
      scheduledWithdrawalAllowed
      withdrawalAllowed
      depositFrequency
      contractId
      memorandumId
      minimumInitialInvestment
      interestRatePerMonth
      maximumInvestment
      initialProcessingFee
      earlyWithdrawalPenalty
      earningPenalty
      deadlineDays
      deadlineTime
      includeWeekends
      interestType
      earlyCancellationAllowed
      earlyCancellationPenalty
      scheduledWithdrawalMandatory
      scheduledWithdrawalPeriodInMonths
      interestPeriodDescription
      nextPlanUuid
      previousPlanUuid
    }
  }
`
