import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  disabledCheckbox: {
    '& svg': {
      '& rect': {
        stroke: theme.typographyColor.onDisabledSecondaryButtonText,
      },
    },
  },
}))
