import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles(() => ({
  italic: {
    fontStyle: 'italic',
  },
}))

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${theme.spacing(2)};
`

export const ContentContainer = styled.div`
  margin-top: ${theme.spacing(1)};
`

export const AddressInfoContainer = styled.div`
  margin-top: ${theme.spacing(3)};
  padding: ${theme.spacing(3)} ${0}px;
  border-top: 1px solid ${theme.buttonColor.stroke};
  border-bottom: 1px solid ${theme.buttonColor.stroke};
`

export default useStyles
