import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  topContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      marginTop: theme.spacing(1.25),
    },
  },
  responsiveFullWidth: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    height: '100%',
    width: '60px',
    '& path': {
      stroke: `${theme.typographyColor.secondaryText}`,
    },
  },
}))

interface ButtonProps {
  isSelected?: boolean
}

interface ChartContainerProps {
  width?: string
  height?: string
}

export interface LeftContainerProps {
  width?: string
}

export const Wrapper = styled.div`
  padding: ${theme.spacing(3)};
  background-color: ${theme.extraBackgrounds.paper02};
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(5)};
`
export const LeftContainer = styled.div<LeftContainerProps>`
  width: ${({ width }) => width || '40%'};
  display: flex;
  gap: ${theme.spacing(3)};
`

export const RightContainer = styled.div`
  gap: ${theme.spacing(2)};
`

export const ChartContainer = styled.div`
  width: ${({ width }: ChartContainerProps) => width || '100%'};
  height: ${({ height }: ChartContainerProps) => height || 'calc(100vh - 500px)'};
`

export const PeriodButton = styled.div<ButtonProps>`
  padding: ${theme.spacing(1)};
  color: ${(props) => props.isSelected && theme.palette.primary.main};
  background-color: ${(props) => props.isSelected && theme.extraBackgrounds.selectedItem};
  border: 1px solid #313a42;
  cursor: pointer;
`
