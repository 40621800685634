import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme } from 'screens/App/theme'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
  },
  planRows: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  white: {
    color: theme.typographyColor.primaryText,
  },
}))

export const MainContainer = styled.div`
  width: 100%;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Divider = styled.div<{ size: string }>`
  width: 100%;
  height: ${({ size }) => size || theme.spacing(3)};
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.typographyColor.secondaryText};
`

export const Row = styled.div`
  width: 100%;
`

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`

export const ContentContainer = styled.div`
  width: 100%;
  background-color: ${theme.extraBackgrounds.paper02};
`
