import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

interface RowProps {
  grayBackground?: boolean
  borderBottom?: boolean
}

export const useStyles = makeStyles((theme: Theme) => ({
  cardSpacing: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
  },
  textHighlight: {
    color: theme.typographyColor.primaryText,
  },
  textWarningHighlight: {
    color: theme.typographyColor.onActiveSecondaryButtonText,
  },
  body3: {
    color: theme.typographyColor.secondaryText,
  },
  investmentPlan: {
    paddingTop: theme.spacing(6),
  },
  moneyInput: {
    width: '100%',
  },
  moneyInputError: {
    color: theme.palette.error.main,
  },
  backIcon: {
    '& path': {
      stroke: theme.typographyColor.primaonActivePrimaryButtonTextryText,
    },
  },
}))

export const SubtitleWrapper = styled.p`
  margin-top: 0;
  margin-bottom: 32px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
  display: none;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: flex;
  }
`

export const WithdrawalRow = styled.div<RowProps>`
  width: 100%;
  padding: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.grayBackground && theme.extraBackgrounds.paper06};
  border-bottom: ${(props) => props.borderBottom && `1px solid ${theme.extraBackgrounds.paper06}`};
`
