import { Grid, Hidden, Typography, useMediaQuery, useTheme } from '@mui/material'
import StepperCard from 'components/Stepper'
import { StepNumber, StepperNewDepositRequest } from 'global/types'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Investment } from 'types'
import DepositAmountStepper from './DepositAmountStepper'
import DepositConfirmationStepper from './DepositConfirmationStepper'
import {
  CenterContainer,
  MainContainer,
  StepperContentContainer,
  TopTitleButtonContainer,
  useStyles,
} from './styled'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_DEPOSIT_REQUEST, GET_INVESTMENT } from './queries'
import Snackbar from 'components/common/Snackbar'
import ResponsiveStepperCard from 'components/Stepper/ResponsiveStepper'

const { NONE } = StepNumber
const { DEPOSIT_AMOUNT, DEPOSIT_CONFIRMATION } = StepperNewDepositRequest
interface CreateDepositRequestData {
  createDepositRequest: { uuid: string }
}

interface CreateDepositRequestVariables {
  investmentUuid: string
  amount: number
}

interface InvestmentRequestData {
  investment: Investment
  depositRulesDates: {
    createdAt: string
    receivedAt: string
    targetDate: string
  }
}

const NewDepositRequest = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { uuid } = useParams()
  const navigation = useNavigate()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))
  const [activeStep, setActiveStep] = useState<StepNumber>(StepNumber.NONE)
  const [investment, setInvestment] = useState<Investment>()
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false)
  const [isNextButtonResponsiveDisabled, setIsNextButtonResponsiveDisabled] = useState(true)
  const [newDepositAmount, setNewDepositAmount] = useState(0)

  const investmentSteps = [DEPOSIT_AMOUNT, DEPOSIT_CONFIRMATION]

  const { data } = useQuery<InvestmentRequestData, { uuid: string }>(GET_INVESTMENT, {
    variables: { uuid: uuid ?? '' },
  })

  const [mutationCreateDepositRequest] = useMutation<
    CreateDepositRequestData,
    CreateDepositRequestVariables
  >(CREATE_DEPOSIT_REQUEST)

  const handleDepositAmountStepperContinueButton = (stepNumber: StepNumber) => {
    setActiveStep(stepNumber)
  }

  const handleConfirmationStepperBackButton = (stepNumber: StepNumber) => {
    setActiveStep(stepNumber)
  }

  const handleConfirmationStepperConfirmButton = async () => {
    try {
      if (!uuid) throw new Error()
      const { data, errors } = await mutationCreateDepositRequest({
        variables: {
          investmentUuid: uuid,
          amount: newDepositAmount,
        },
      })
      if (!data || errors) throw new Error()
      navigation(`/new-deposit-request/success/${data.createDepositRequest.uuid}`)
    } catch (error) {
      setIsOpenSnackbar(true)
    }
  }

  const handleSnackbarClose = () => {
    setIsOpenSnackbar(false)
  }

  const handleClickResponsiveStepperCardSetActive = (value: StepNumber) => {
    setActiveStep(value)
  }

  useEffect(() => {
    if (data?.investment) setInvestment(data.investment)
  }, [data])

  return (
    <>
      <MainContainer>
        <Grid container className={classes.mainGrid} direction="column" alignItems="center">
          <CenterContainer className={classes.centerContainer}>
            <TopTitleButtonContainer>
              <Typography variant="h2">
                <FormattedMessage id="screen.DepositAmountStepper.stepper.title" />
              </Typography>
            </TopTitleButtonContainer>
            <StepperContentContainer>
              {hideInResponsive && (
                <StepperCard steps={investmentSteps} activeStep={activeStep} widthStepper="370px" />
              )}
              {activeStep === NONE ? (
                <DepositAmountStepper
                  isContinueButtonDisabled={isNextButtonResponsiveDisabled}
                  newDepositAmount={newDepositAmount}
                  onChangeNewDepositAmountInput={setNewDepositAmount}
                  onDisableContinueButton={setIsNextButtonResponsiveDisabled}
                  onClickContinueButton={handleDepositAmountStepperContinueButton}
                  investment={investment}
                  depositRulesDates={data?.depositRulesDates}
                />
              ) : (
                <DepositConfirmationStepper
                  newDepositAmount={newDepositAmount}
                  onDisableContinueButton={setIsNextButtonResponsiveDisabled}
                  onClickConfirmButton={handleConfirmationStepperConfirmButton}
                  onClickBackButton={handleConfirmationStepperBackButton}
                  investment={investment}
                  depositRulesDates={data?.depositRulesDates}
                />
              )}
            </StepperContentContainer>
          </CenterContainer>
        </Grid>
        <Hidden mdUp>
          <ResponsiveStepperCard
            activeStep={activeStep}
            disableNextButton={isNextButtonResponsiveDisabled}
            handleFinishAction={handleConfirmationStepperConfirmButton}
            steps={investmentSteps}
            setActiveStep={handleClickResponsiveStepperCardSetActive}
          />
        </Hidden>
      </MainContainer>
      <Snackbar
        open={isOpenSnackbar}
        onClose={handleSnackbarClose}
        messageId="screen.DepositAmountStepper.stepper.snackbar"
      />
    </>
  )
}

export default NewDepositRequest
