import { Typography, useTheme, useMediaQuery } from '@mui/material'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import StepOne from 'assets/uno.svg'
import StepTwo from 'assets/dos.svg'
import StepThree from 'assets/tres.svg'
import {
  StepCircle,
  StepDivider,
  StepItemBody,
  StepItemContainer,
  StepItemHeader,
  StepsWrapper,
  useStyles,
} from './styled'
import Dialog from 'components/common/Dialog'
import clsx from 'clsx'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
}
interface Step {
  stepIcon: string
  isHighlighted: boolean
  hasStepLine: boolean
  stepTitle: string
  stepDescription: string
}

const FundingWorksModal: FunctionComponent<Props> = ({ modalOpen, handleCloseCallback }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const steps: Step[] = [
    {
      stepIcon: StepOne,
      isHighlighted: true,
      hasStepLine: true,
      stepDescription: 'modal.howBankFundingWorks.step1.description',
      stepTitle: 'modal.howBankFundingWorks.step1.title',
    },
    {
      stepIcon: StepTwo,
      isHighlighted: false,
      hasStepLine: true,
      stepDescription: 'modal.howBankFundingWorks.step2.description',
      stepTitle: 'modal.howBankFundingWorks.step2.title',
    },
    {
      stepIcon: StepThree,
      isHighlighted: false,
      hasStepLine: false,
      stepDescription: 'modal.howBankFundingWorks.step3.description',
      stepTitle: 'modal.howBankFundingWorks.step3.title',
    },
  ]

  if (isResponsive)
    return (
      <Dialog
        dialogOpen={modalOpen}
        handleCloseCallback={handleCloseCallback}
        actionMessageId="components.button.close"
        handleActionButton={handleCloseCallback}
        titleMessageId="modal.howBankFundingWorks.title"
        fullScreen
      >
        <StepsWrapper className={classes.stepsWrapper}>
          {steps.map((step, index) => {
            return (
              <StepItemContainer key={index}>
                <StepItemHeader className={classes.stepItemHeader}>
                  <StepCircle className={clsx(step.isHighlighted && classes.stepActive)}>
                    <img src={step.stepIcon} />
                  </StepCircle>
                  <StepItemBody>
                    <Typography variant="body1">
                      <FormattedMessage id={step.stepTitle} />
                    </Typography>
                    <Typography variant="body3">
                      <FormattedMessage id={step.stepDescription} />
                    </Typography>
                  </StepItemBody>
                </StepItemHeader>
                <StepDivider
                  className={clsx(
                    step.isHighlighted && classes.stepActive,
                    !step.hasStepLine && classes.noBorder,
                    classes.stepDivider,
                  )}
                />
              </StepItemContainer>
            )
          })}
        </StepsWrapper>
        <Typography variant="body2" className={classes.bottomText}>
          <FormattedMessage id="modal.howBankFundingWorks.bottom.description" />
        </Typography>
      </Dialog>
    )

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      titleMessageId="modal.howBankFundingWorks.title"
      actionMessageId="modal.howBankFundingWorks.button"
      fullScreen
    >
      <StepsWrapper>
        {steps.map((step, index) => {
          return (
            <StepItemContainer key={index}>
              <StepItemHeader className={classes.stepItemHeader}>
                <StepCircle className={clsx(step.isHighlighted && classes.stepActive)}>
                  <img src={step.stepIcon} />
                </StepCircle>
                <StepDivider
                  className={clsx(
                    step.isHighlighted && classes.stepActive,
                    !step.hasStepLine && classes.noBorder,
                  )}
                />
              </StepItemHeader>
              <StepItemBody>
                <Typography variant="body1">
                  <FormattedMessage id={step.stepTitle} />
                </Typography>
                <Typography variant="body3">
                  <FormattedMessage id={step.stepDescription} />
                </Typography>
              </StepItemBody>
            </StepItemContainer>
          )
        })}
      </StepsWrapper>
      <Typography variant="body2" className={classes.bottomText}>
        <FormattedMessage id="modal.howBankFundingWorks.bottom.description" />
      </Typography>
    </Dialog>
  )
}

export default FundingWorksModal
