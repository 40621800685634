import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export interface ColorLineProps {
  width?: string
  height?: string
  color?: string
}

interface ContainerProps {
  width?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
  icon: {
    width: '11px',
    height: '11px',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
  },
}))

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => width || '100%'};
`

export const ColorLine = styled.div<ColorLineProps>`
  width: ${({ width }) => width || '12px'};
  height: ${({ height }) => height || '3px'};
  margin-bottom: ${theme.spacing(1)};
  background-color: ${(props) => props.color || theme.palette.error.main};
`
