import { gql } from '@apollo/client'

export const GET_PROFILE_BY_UUID = gql`
  query ProfileByUuid($uuid: String!) {
    profileByUuid(uuid: $uuid) {
      uuid
      application {
        name
        status
        applicantId
      }
      referralCode {
        code
        active
        isUpdateBlocked
      }
      tin
    }
  }
`

export const GET_INVESTMENTS_BY_INVESTOR = gql`
  query InvestmentsByInvestor($profileUuid: String!) {
    investmentsByInvestor(profileUuid: $profileUuid) {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
          createdAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
    }
  }
`

export const GET_ADDRESS_BY_INVESTOR = gql`
  query AddressByInvestor($profileUuid: String!) {
    addressByInvestor(profileUuid: $profileUuid) {
      addressOne
      addressTwo
      city
      country
      countryKey
      postalCode
      state
      uuid
    }
  }
`

export const GET_BANK_ACCOUNT_BY_INVESTOR = gql`
  query WireBankAccountByInvestor($profileUuid: String!) {
    wireBankAccountByInvestor(profileUuid: $profileUuid) {
      uuid
      country
      bankName
      countryKey
      accountNumber
      routingNumber
      preferredMethod
      beneficiary
      swiftCode
    }
  }
`
