import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    backgroundColor: theme.extraBackgrounds.paper02,
  },
}))

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`

export default useStyles
