import { FunctionComponent } from 'react'
import { useStyles, TotalAmount, RowWithBorder } from '../styled'
import { Deposit } from 'types'
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import _ from 'lodash'
import moment from 'moment'
import { getFormattedReferenceNumber } from 'utils/DataFormat'

interface Props {
  deposit: Deposit
}

const UndoDepositsModalContent: FunctionComponent<Props> = ({ deposit }: Props) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <div>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.referenceRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {getFormattedReferenceNumber(deposit.deposit?.reference || 0)}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.dateRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {moment(deposit.deposit.createdAt).format('MMM DD[,] YYYY')}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.investorRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {deposit.fullName}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.planRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {deposit.investment?.plan?.name}
        </Typography>
      </RowWithBorder>
      <RowWithBorder>
        <Typography variant="body1" className={classes.rowLeft}>
          <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.amountRow" />
        </Typography>
        <Typography variant="body1" className={classes.rowRight}>
          {intl.formatNumber(deposit.deposit?.amount, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </RowWithBorder>
      <Divider size={theme.spacing(4)} />
      <Typography variant="body1" className={classes.grayText}>
        <FormattedMessage id="screen.depositRequests.tab03.undoDepositModal.investorText" />
      </Typography>
    </div>
  )
}

export default UndoDepositsModalContent
