import { theme } from 'screens/App/theme'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  depositDateInputLabel: {
    color: theme.typographyColor.secondaryText,
  },
  selectInput: {
    paddingTop: '16.5px !important',
    paddingBottom: '16.5px !important',
  },
  checkboxContainer: {
    color: theme.typographyColor.secondaryText,
  },
}))

export const TextFieldContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin: ${theme.spacing(1)};
`
