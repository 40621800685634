import { useQuery } from '@apollo/client'
import DataGrid from 'components/common/DataGrid'
import { columnsTreasurerScheduledWithdrawals } from 'components/common/DataGrid/utils'
import { FunctionComponent } from 'react'
import { Balance, Plan, ScheduledWithdrawal, ScheduledWithdrawalStatus } from 'types'
import { GET_SCHEDULED_WITHDRAWALS } from '../queries'
import { Wrapper } from './styled'
import { Profile } from 'global/types'

export interface GetWithdrawalRequestsData {
  scheduledWithdrawals: Array<{
    profile: Profile
    scheduledWithdrawals: ScheduledWithdrawal[]
    plan: Partial<Plan>
    balance: Partial<Balance>
  }>
}

export interface GetWithdrawalRequestsVariables {
  status: string
}

interface CompletedTabProps {
  status: ScheduledWithdrawalStatus
}

const ScheduledWithdrawals: FunctionComponent<CompletedTabProps> = ({ status }) => {
  // TODO: Implement pagination

  const dataGridColumns = columnsTreasurerScheduledWithdrawals({
    showEndDate: status === ScheduledWithdrawalStatus.FINALIZED,
    showBalance: status === ScheduledWithdrawalStatus.ACTIVE,
  })

  const { data, error, loading } = useQuery<
    GetWithdrawalRequestsData,
    GetWithdrawalRequestsVariables
  >(GET_SCHEDULED_WITHDRAWALS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { status: status },
  })

  let scheduledWithdrawals = data?.scheduledWithdrawals ?? []
  let rowCount = data?.scheduledWithdrawals.length ?? 0

  if (error || loading) {
    scheduledWithdrawals = []
    rowCount = 0
  }

  const formattedScheduledWithdrawals = [] as Array<{
    uuid: string
    investorName: string
    planName: string
    startDate: string
    endDate?: string | null
    amount: number
    balance?: number
  }>

  scheduledWithdrawals.forEach((withdrawal) => {
    withdrawal.scheduledWithdrawals.forEach((scheduledWithdrawal) => {
      formattedScheduledWithdrawals.push({
        uuid: scheduledWithdrawal.uuid,
        investorName: withdrawal.profile.application.name,
        planName: withdrawal.plan.name || '',
        startDate: scheduledWithdrawal.startDate,
        endDate: scheduledWithdrawal.endDate,
        amount: scheduledWithdrawal.amount,
        balance: withdrawal.balance.total,
      })
    })
  })

  return (
    <Wrapper>
      <DataGrid
        columns={dataGridColumns}
        rows={formattedScheduledWithdrawals}
        rowCount={rowCount}
        emptyMessageId="screen.withdrawalRequests.tab.empty"
        errorMessageId="screen.withdrawalRequests.tab.error"
        getRowId={(row) => row?.uuid}
        hasError={Boolean(error)}
        isLoading={loading}
        autoHeight
      />
    </Wrapper>
  )
}

export default ScheduledWithdrawals
