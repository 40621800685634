import { AlertContext } from 'hooks/AlertContext'
import { FunctionComponent, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { WithdrawalRequest } from 'types'
import { Container, useStyles } from './styled'
import { useMutation } from '@apollo/client'
import { APPLY_WITHDRAWALS } from '../../queries'
import { changeTab } from 'screens/WithdrawalRequests/reducer'
import { map } from 'lodash'
import Modal from 'components/common/Modal'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import UndoWithdrawalRequestModalContent from './UndoWithdrawalRequestModalContent'
import { useLoading } from 'hooks/LoadingHook'
import Dialog from 'components/common/Dialog'

interface Props {
  modalOpen: boolean
  handleCloseCallback: () => void
  withdrawals: WithdrawalRequest[] | []
}

const UndoWithdrawalRequestModal: FunctionComponent<Props> = ({
  modalOpen = false,
  handleCloseCallback,
  withdrawals,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const { showSnackbar } = useContext(AlertContext)
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [undoWithdrawals] = useMutation(APPLY_WITHDRAWALS, {
    variables: {
      withdrawalUuids: map(withdrawals, 'withdrawal.uuid'),
      approve: false,
    },
  })
  const handleUndoWithdrawals = async () => {
    try {
      startLoading()
      const result = await undoWithdrawals()

      if (result) {
        showSnackbar({
          messageId: 'screen.withdrawalRequests.tab02.undoWithdrawalModal.undoSnackbar',
          messageValues: { withdrawals: withdrawals.length },
        })
        dispatch(changeTab(0))
      }
    } catch (e: unknown) {
      showSnackbar({
        messageId: 'screen.withdrawalRequests.tab02.undoWithdrawalModal.undoSnackbar.error',
      })
    } finally {
      stopLoading()
      handleCloseCallback()
    }
  }

  return (
    <Dialog
      dialogOpen={modalOpen}
      handleCloseCallback={handleCloseCallback}
      handleActionButton={handleUndoWithdrawals}
      actionMessageId="screen.withdrawalRequests.tab02.undoWithdrawalModal.action01"
      actionMessageValues={{ withdrawals: withdrawals.length }}
      titleMessage=""
      descriptionMessage=""
      fullScreen={isResponsive}
      disabledActionButton={isLoading}
    >
      <Container>
        <Typography variant="h2" className={classes.whiteText}>
          <FormattedMessage id="screen.withdrawalRequests.tab02.undoWithdrawalModal.title" />
        </Typography>
        <Divider size={theme.spacing(2)} />
        <Typography variant="body1" className={classes.description}>
          <FormattedMessage id="screen.withdrawalRequests.tab02.undoWithdrawalModal.description" />
        </Typography>
        <Divider size={theme.spacing(4)} />
        <UndoWithdrawalRequestModalContent withdrawals={withdrawals} />
      </Container>
    </Dialog>
  )
}

export default UndoWithdrawalRequestModal
