import { gql } from '@apollo/client'

export const SAVE_ADDRESS = gql`
  mutation saveAddress(
    $tin: Tin!
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $state: String!
    $postalCode: String!
    $countryKey: String!
    $country: String!
    $profileUuid: String!
  ) {
    saveAddress(
      tin: $tin
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      countryKey: $countryKey
      profileUuid: $profileUuid
    ) {
      uuid
    }
  }
`
