import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import React, { FunctionComponent, useState } from 'react'
import {
  LeftAppbar,
  LeftMenu,
  RightAppbar,
  UserContainer,
  UserImageBackground,
  UserInfoContainer,
  useStyles,
  BlueDotLogoIcon,
  GoBackIcon,
  TabSelectedAddon,
  TitleContainer,
  ReferBadgeContainer,
} from './styled'
import userLogo from 'assets/user.svg'
import logoutIcon from 'assets/log_out.svg'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FormattedMessage, useIntl } from 'react-intl'
import BrandLogoIcon from 'assets/brand.svg'
import ArrowIcon from 'assets/arrow_down_left.svg'
import InviteInvestorsModal from 'components/common/InviteInvestorsModal'
import BadgeStatus from 'components/BadgeStatus'
import { ReactComponent as PartnershipIcon } from 'assets/partnership.svg'
import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'

const getAppBarTitleId = (pathname: string) => {
  if (pathname.includes('home')) return 'global.appbar.menu.tab.myInvestments'
  if (pathname.includes('my-investors')) return 'global.appbar.menu.tab.myInvestors'
  return undefined
}

const PartnerAppbar: FunctionComponent = () => {
  const classes = useStyles()
  const { user, logout } = useAuth0()

  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()
  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState(false)

  const showReferBadge = location.pathname.includes('home')
  const showBlueDotLogo = location.pathname.includes('home')
  const showGoBackIcon = location.pathname.includes('my-investors')
  const titleLabelId = getAppBarTitleId(location.pathname)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    localStorage.clear()
    logout({
      returnTo: AUTH0_LOGIN_REDIRECT_URL,
    })
  }

  const handleNavigate = (url: string) => () => {
    navigate(url)
  }

  const handleOpenInviteInvestorsModal = () => setOpenModal(true)

  const handleCloseInviteInvestorsModal = () => setOpenModal(false)

  const goBackAction = handleNavigate('/home')

  return (
    <AppBar position="sticky" elevation={0} className={classes.toolbar}>
      <Toolbar>
        <LeftAppbar>
          <LeftMenu>
            {showBlueDotLogo && (
              <Link to="/home" className={classes.logoBlueDot}>
                <BlueDotLogoIcon src={BrandLogoIcon} alt="bluedot logo" />
              </Link>
            )}
            {showGoBackIcon && (
              <GoBackIcon
                onClick={goBackAction}
                style={{ transform: 'rotate(45deg)' }}
                src={ArrowIcon}
                alt="go back arrow icon"
              />
            )}
            {titleLabelId && hideInResponsive && (
              <TitleContainer onClick={handleNavigate('/home')}>
                <Typography color="white" variant="body3">
                  <FormattedMessage id={titleLabelId} />
                </Typography>
                {location?.pathname === '/home' && <TabSelectedAddon />}
              </TitleContainer>
            )}
          </LeftMenu>
        </LeftAppbar>
        <RightAppbar>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenu}
          >
            <UserContainer>
              {hideInResponsive && (
                <UserInfoContainer className={classes.textUserContainer}>
                  <Typography className={classes.emailLabel} variant="body1">
                    {user?.email}
                  </Typography>
                  <Typography className={classes.typeLabel} variant="caption">
                    <FormattedMessage id="global.appbar.menu.partner" />{' '}
                  </Typography>
                </UserInfoContainer>
              )}
              <UserImageBackground>
                <img className={classes.menuIcon} src={userLogo} alt="User icon" />
              </UserImageBackground>
            </UserContainer>
          </IconButton>
          {showReferBadge && (
            <ReferBadgeContainer className={classes.referContainerResponsive}>
              <BadgeStatus
                status={intl.formatMessage({ id: 'global.appbar.menu.agent.refer' })}
                color="warning"
                avatar={<PartnershipIcon />}
                isClickeable
                onClick={handleOpenInviteInvestorsModal}
              />
            </ReferBadgeContainer>
          )}
          <Menu
            elevation={0}
            className={classes.menu}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>
              <Typography variant="body2">
                <FormattedMessage id="global.appbar.menu.logout" />
                <img src={logoutIcon} />
              </Typography>
            </MenuItem>
          </Menu>
        </RightAppbar>
      </Toolbar>
      <InviteInvestorsModal open={openModal} handleClose={handleCloseInviteInvestorsModal} />
    </AppBar>
  )
}

export default PartnerAppbar
