import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'

export const getInvitationCode = (): string | undefined => {
  const location = window.location.pathname
  const [, code] = location.split('/invite/')
  return code || undefined
}

type RedirectParams = {
  screen_hint: string
  redirectUri: string
  referralCode?: string
}

export const getRedirectParams = (referralCode?: string) => {
  const screen_hint = referralCode ? 'signup' : 'login'
  const redirectUri = AUTH0_LOGIN_REDIRECT_URL
  const data: RedirectParams = { screen_hint, redirectUri }
  if (referralCode) {
    data.referralCode = referralCode
  }
  return data
}
