import { FunctionComponent } from 'react'
import { useStyles, Wrapper, GridContainer } from './styled'
import { Transaction } from 'types'
import { columnsInvestmentDeposits } from 'components/common/DataGrid/utils'
import DataGrid from 'components/common/DataGrid'

interface Props {
  deposits: Transaction[]
}

const DepositsTab: FunctionComponent<Props> = ({ deposits }: Props) => {
  const classes = useStyles()
  const columns = columnsInvestmentDeposits({})

  return (
    <Wrapper>
      <GridContainer>
        <DataGrid
          pagination
          className={classes.dataGrid}
          columns={columns}
          rows={deposits}
          emptyMessageId="screen.depositRequests.search.empty"
          errorMessageId="screen.depositRequests.tab.error"
          initialState={{
            sorting: {
              sortModel: [{ field: 'approvedAt', sort: 'desc' }],
            },
            pagination: { page: 0, pageSize: 10 },
          }}
          autoHeight
          getRowId={(row) => row?.uuid}
          activeSorting={true}
          replaceDefaultPropsFromDataGrid={{
            rowCount: undefined,
            page: undefined,
            pageSize: undefined,
          }}
        />
      </GridContainer>
    </Wrapper>
  )
}

export default DepositsTab
