import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  marginLeft: {
    marginLeft: '11px',
  },
  marginRight: {
    marginRight: '11px',
  },
  animation: {
    transition: 'margin .5s',
    '&:hover': {
      margin: '0px',
    },
    '&:hover .button__arrow-icon': {
      width: '24px',
    },
    '& .button__arrow-icon': {
      transition: 'width .5s',
      width: '13px',
      overflowX: 'hidden',
    },
  },
}))
