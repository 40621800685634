import { Box, Button, Skeleton, Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import BalanceChip from 'components/BalanceChip'
import Card from 'components/common/Card'
import moment from 'moment'
import { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InterestType, Investment } from 'types'
import { GetFormattedDate } from 'utils/DataFormat'
import { EndingBalanceContainer, useStyles } from './styled'
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg'
import { theme } from 'screens/App/theme'
import { generatePlanNameWithSuffix } from 'utils/GeneratePlanNameWithSuffix'

interface Props {
  investment?: Investment
  loading?: boolean
  isOpenInvestmentDetails: boolean
  onClickToggleInvestmentDetails: () => void
}

const InvestmentOverview: FunctionComponent<Props> = ({
  investment,
  loading = false,
  isOpenInvestmentDetails,
  onClickToggleInvestmentDetails,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const isBreakpointMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleClickViewDetails = () => {
    onClickToggleInvestmentDetails()
  }

  let labelViewDetails = 'screen.investmentDetail.viewDetails'
  let iconViewDetails = <ChevronDown className={classes.chevron} />
  if (isOpenInvestmentDetails) {
    labelViewDetails = 'screen.investmentDetail.hideDetails'
    iconViewDetails = (
      <ChevronDown className={classes.chevron} style={{ transform: 'rotate(180deg)' }} />
    )
  }

  if (loading) {
    return (
      <Card className={classes.overviewCard}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Card>
    )
  }

  return (
    <Card className={classes.overviewCard}>
      {!isBreakpointMdUp && (
        <Typography variant="h4" className={classes.white} paddingBottom={1}>
          <FormattedMessage id="screen.investmentDetail.detailsOfTheInvestment" />
        </Typography>
      )}
      <Typography variant="body1" className={clsx(classes.white, classes.largeBottomPadding)}>
        <FormattedMessage
          id="screen.investmentDetail.planOverviewTitle"
          values={{
            name: investment?.profile.application.name,
            planName: generatePlanNameWithSuffix(
              investment?.plan.name.toLowerCase(),
              investment?.plan.suffixName,
            ),
          }}
        />
      </Typography>
      <Typography variant="body3">
        <FormattedMessage
          id="screen.investmentDetail.endingBalance"
          values={{ date: moment(investment?.balance?.cutoffDate).format('M/D/YYYY') }}
        />
      </Typography>
      <EndingBalanceContainer>
        <Typography className={clsx(classes.white, classes.largeBottomPadding)} variant="h3">
          {intl.formatNumber(investment?.balance?.total || 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
        {investment?.balance &&
          investment?.balance?.earningPercentage !== 0 &&
          investment.plan.interestType !== InterestType.SIMPLE && (
            <BalanceChip percentage={Number(investment?.balance?.earningPercentage.toFixed(2))} />
          )}
      </EndingBalanceContainer>
      <Box sx={{ display: { xs: isOpenInvestmentDetails ? 'block' : 'none', md: 'block' } }}>
        <Typography variant="body3">
          <FormattedMessage id="screen.investmentDetail.investor" />
        </Typography>
        <Typography
          className={clsx(classes.white, classes.largeBottomPadding, classes.underline)}
          variant="body2"
        >
          {investment?.profile.application.name}
        </Typography>
        <Typography variant="body3">
          <FormattedMessage id="screen.investmentDetail.plan" />
        </Typography>
        <Typography
          className={clsx(classes.white, classes.largeBottomPadding, classes.underline)}
          variant="body2"
        >
          {investment?.plan.name}
        </Typography>
        <Typography variant="body3">
          <FormattedMessage id="screen.investmentDetail.currentRateLabel" />
        </Typography>
        <Typography className={clsx(classes.white, classes.largeBottomPadding)} variant="body2">
          <FormattedMessage
            id="screen.investmentDetail.currentRateValue"
            values={{ percentage: investment?.plan.interestRatePerMonth }}
          />
        </Typography>
        <Typography variant="body3">
          <FormattedMessage id="screen.investmentDetail.principalLockup" />
        </Typography>
        <Typography className={clsx(classes.white)} variant="body2">
          {GetFormattedDate(
            moment(investment?.duration.startDate)
              .add(investment?.plan?.principalLockupInMonths, 'months')
              .format() || moment().toString(),
          )}
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={handleClickViewDetails}
          endIcon={iconViewDetails}
        >
          <Typography variant="body2" component="span" color={theme.palette.primary.main}>
            <FormattedMessage id={labelViewDetails} />
          </Typography>
        </Button>
      </Box>
    </Card>
  )
}

export default InvestmentOverview
