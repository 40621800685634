import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  abaTypography: {
    width: 132,
  },
}))

export interface TableDetailsWrapperProps {
  marginTop?: string
}

export const TableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }: TableDetailsWrapperProps) => marginTop || theme.spacing(4)};
`

export default useStyles
