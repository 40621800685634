import { gql } from '@apollo/client'

export const SAVE_COMPANY_PROFILE = gql`
  mutation updateCompanyProfile(
    $name: String!
    $tin: Tin!
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $state: String!
    $postalCode: String!
    $countryKey: String!
    $country: String!
    $phone: String!
  ) {
    updateCompanyProfile(
      name: $name
      tin: $tin
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      countryKey: $countryKey
      phone: $phone
    ) {
      name
    }
  }
`
