import { Tooltip, Typography } from '@mui/material'
import { Divider } from 'components/common/styled'
import { Fragment, FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useStyles, Container, ColorLine, ColorLineProps } from './styled'
import { theme } from 'screens/App/theme'
import { colors } from './colors'
import infoGrayIcon from 'assets/info-gray.svg'

interface Props {
  color: 'success' | 'error' | 'warning' | 'variant01' | 'variant02' | 'variant03'
  title: string
  titleColor?: string
  amount?: number
  showInfo?: boolean
  width?: string
  tooltipText?: string
  colorLineStyleProps?: ColorLineProps
}

const TitleAmount: FunctionComponent<Props> = ({
  color,
  title,
  titleColor,
  amount,
  showInfo = false,
  width,
  colorLineStyleProps,
  tooltipText,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Container {...{ width }}>
      <ColorLine color={colors[color]} {...colorLineStyleProps} />
      <Typography variant="body3" color={titleColor}>
        {title}{' '}
        {showInfo && (
          <Tooltip title={tooltipText || ''}>
            <img src={infoGrayIcon} alt="info icon" className={classes.icon} />
          </Tooltip>
        )}
      </Typography>
      {typeof amount === 'number' && (
        <Fragment>
          <Divider size={theme.spacing(1)} />
          <Typography variant="h4" className={classes.white}>
            {intl.formatNumber(amount, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Fragment>
      )}
    </Container>
  )
}

export default TitleAmount
