import { ButtonBase } from '@mui/material'
import { useStyles } from './styled'

interface Props {
  onClick: () => void
  children: React.ReactNode
}
const DisabledButtonWrapperWithEvent = ({ onClick, children }: Props) => {
  const classes = useStyles()
  return (
    <ButtonBase
      className={classes.buttonWrapper}
      onClick={onClick}
      sx={{ cursor: 'default' }}
      disableRipple
      disableTouchRipple
    >
      {children}
    </ButtonBase>
  )
}

export default DisabledButtonWrapperWithEvent
