import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { theme as myTheme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(5),
    backgroundColor: theme.extraBackgrounds.paper03,
    gap: theme.spacing(1),
  },
  defaultTypography: {
    '& h1': {
      color: theme.typographyColor.primaryText,
    },
    '& p': {
      color: theme.typographyColor.secondaryText,
    },
  },
}))

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(3)};
  justify-content: flex-start;
  h4 {
    color: ${myTheme.typographyColor.primaryText};
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(1)};
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(1)};
`
