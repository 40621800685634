import { FunctionComponent } from 'react'
import { Skeleton } from '@mui/material'
import useStyles, { MainContainer } from './styled'

const ScreenSkeleton: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <MainContainer>
      <Skeleton className={classes.skeleton} variant="rectangular" width="60%" height={420} />
      <Skeleton className={classes.skeleton} variant="rectangular" width="40%" height={300} />
    </MainContainer>
  )
}

export default ScreenSkeleton
