import { Chip, IconButton } from '@mui/material'
import clsx from 'clsx'
import { FunctionComponent, ReactNode, ReactElement } from 'react'
import { BadgeWrapper, useStyles } from './styled'

export const BadgeStatusColor = {
  DEFAULT: 'default',
  WARNING: 'warning',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
} as const

export type BadgeStatusColorType = (typeof BadgeStatusColor)[keyof typeof BadgeStatusColor]

interface Props {
  status: string
  color?: BadgeStatusColorType
  isTableStatus?: boolean
  onDelete?: () => void
  onClick?: () => void
  rightIcon?: ReactNode
  disableRightIcon?: boolean
  avatar?: ReactElement
  isClickeable?: boolean
}

const BadgeStatus: FunctionComponent<Props> = ({
  status,
  color,
  isTableStatus = false,
  onDelete,
  rightIcon,
  disableRightIcon = true,
  avatar,
  onClick,
  isClickeable,
}: Props) => {
  const classes = useStyles()
  return (
    <BadgeWrapper
      className={clsx(
        classes.chips,
        isTableStatus && classes.largeBadge,
        isClickeable && classes.isClickeable,
      )}
      onClick={onClick}
    >
      <Chip
        label={status}
        color={color}
        onDelete={onDelete}
        deleteIcon={<IconButton disabled={disableRightIcon}>{rightIcon}</IconButton>}
        avatar={avatar}
      />
    </BadgeWrapper>
  )
}

export default BadgeStatus
