import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import Card from 'components/common/Card'
import { FormattedMessage } from 'react-intl'
import { Theme } from '@mui/material/styles'
import { DepositFrequency, InterestType, Plan } from 'types'
import { useStyles } from '../ReferralCode/styled'
import PlanForm from './PlanForm'
import { BackNextButtonWrapper, Row } from './styled'
import Modal from 'components/common/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { addPlan, nextStep, previousStep } from '../reducer'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_PLAN, GET_PLANS_BY_AGENT } from '../queries'
import { InvestmentPlan } from '../InvestmentPlan'
import { getArraysChunks } from 'utils/PlanChunks'
import { AlertContext } from 'hooks/AlertContext'

const defaultPlan = {
  name: '',
  suffixName: '',
  description: '',
  shortDescription: '',
  interestRatePerMonth: 0,
  principalLockupInMonths: 0,
  minimumInitialInvestment: 0,
  maturity: 0,
  autoRenewal: false,
  autoRenewalPeriodInMonths: 0,
  maxAutoRenewalPeriod: 0,
  renewalPeriodLockInMonths: 0,
  depositAllowed: false,
  earlyWithdrawalAllowed: false,
  earlyWithdrawalPenalty: 0,
  earningPenalty: 0,
  initialProcessingFee: 0,
  scheduledWithdrawalAllowed: false,
  withdrawalAllowed: false,
  depositFrequency: DepositFrequency.NEXT_MONDAY,
  earlyCancellationAllowed: false,
  earlyCancellationPenalty: 0,
  deadlineDays: 0,
  deadlineTime: '00:00',
  includeWeekends: false,
  interestType: InterestType.COMPOUND,
  scheduledWithdrawalMandatory: false,
  scheduledWithdrawalPeriodInMonths: 0,
  maximumInvestment: 0,
  contractId: '',
  memorandumId: '',
  interestPeriodDescription: '',
} as Plan

interface Props {
  profileUuid: string
}

const PlansEdit: FunctionComponent<Props> = ({ profileUuid }: Props) => {
  const plans = useSelector((state: GlobalState) => state.newAgent.plans)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { showSnackbar } = useContext(AlertContext)
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)
  const [forUpdatePlan, setForUpdatePlan] = useState<Plan | null>(null)

  const {
    data: dataPlans,
    loading: loadingPlans,
    error: errorPlans,
  } = useQuery<{ plansByAgent: Array<Plan & { __typename?: string }> }, { profileUuid: string }>(
    GET_PLANS_BY_AGENT,
    { variables: { profileUuid }, fetchPolicy: 'network-only' },
  )

  const [createPlan, { loading }] = useMutation<
    { createPlan: Plan & { __typename?: string } },
    { profileUuid: string; plan: Plan; forUpdate: boolean }
  >(CREATE_PLAN)

  const handleBack = () => {
    dispatch(previousStep())
  }

  const handleNext = () => {
    dispatch(nextStep())
  }

  const handleAddPlan = async (plan: Plan) => {
    try {
      const response = await createPlan({
        variables: { plan, profileUuid, forUpdate: Boolean(forUpdatePlan) },
      })
      const planCreated = response?.data?.createPlan
      if (!planCreated) return
      const copyPlan = { ...planCreated }
      delete copyPlan.__typename
      dispatch(addPlan(copyPlan))
      setForUpdatePlan(null)
      setOpenModal(false)
    } catch (error) {
      console.error(`Error creating plan: ${error}`)
      showSnackbar({ messageText: `Error ${forUpdatePlan ? 'updating' : 'creating'} the plan` })
    }
  }

  const onClickUpdatePlan = (plan: Plan) => {
    setForUpdatePlan(plan)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setForUpdatePlan(null)
  }

  useEffect(() => {
    if (dataPlans) {
      const plans = [] as Plan[]
      for (const plan of dataPlans.plansByAgent) {
        const copyPlan = { ...plan }
        if (copyPlan.__typename) delete copyPlan.__typename
        plans.push(copyPlan)
      }
      dispatch(addPlan(plans))
    }
  }, [dataPlans, loadingPlans, errorPlans])

  return (
    <Card className={classes.mainGridCard}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" paddingX={2}>
        <Typography variant="h4">
          <FormattedMessage id="screen.newAgent.configurePlans.title" />
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
          <Typography variant="button">
            <FormattedMessage id="screen.newAgent.configurePlans.button.appPlan" />
          </Typography>
        </Button>
      </Grid>
      {plans.length === 0 ? (
        <Typography variant="body2">
          <FormattedMessage id="screen.newAgent.configurePlans.empty.description" />
        </Typography>
      ) : (
        getArraysChunks<Plan>(plans, 3).map((chunk, index) => (
          <Row key={index} className={classes.planRows}>
            {chunk.map((plan) => (
              <InvestmentPlan
                key={plan.contractId}
                plan={plan}
                callToActionId="screen.newAgent.configurePlans.plan.card.callToAction"
                onClickCallToAction={onClickUpdatePlan}
              />
            ))}
          </Row>
        ))
      )}
      {!isMobile && (
        <BackNextButtonWrapper className={classes.continueButton}>
          <Button variant="outlined" onClick={handleBack}>
            <FormattedMessage id="screen.newAgent.configurePlans.button.back" />
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            className={classes.continueButton}
            disabled={!plans.length}
          >
            <FormattedMessage id="screen.newAgent.configurePlans.button.continue" />
          </Button>
        </BackNextButtonWrapper>
      )}
      {openModal && (
        <Modal
          modalOpen={openModal}
          handleCloseCallback={(_e, reason) => reason ?? handleCloseModal()}
          titleMessageId={
            forUpdatePlan
              ? 'screen.newAgent.configurePlans.update.modal.title'
              : 'screen.newAgent.configurePlans.create.modal.title'
          }
          descriptionMessageId={
            forUpdatePlan
              ? 'screen.newAgent.configurePlans.update.modal.description'
              : 'screen.newAgent.configurePlans.create.modal.description'
          }
        >
          <PlanForm
            plan={forUpdatePlan ?? defaultPlan}
            handleSave={handleAddPlan}
            loadingSave={loading}
            callToActionMessageId={
              forUpdatePlan
                ? 'screen.newAgent.configurePlans.update.modal.callToAction'
                : 'screen.newAgent.configurePlans.create.modal.callToAction'
            }
          />
        </Modal>
      )}
    </Card>
  )
}

export default PlansEdit
