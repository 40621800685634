import { gql } from '@apollo/client'

export const GET_NEXT_MONTH_ESTIMATED_EARNINGS = gql`
  query NextMonthEstimatedEarnings($investmentUuid: String!) {
    nextMonthEstimatedEarnings(investmentUuid: $investmentUuid)
  }
`

export const CREATE_SCHEDULED_WITHDRAWAL_REQUEST = gql`
  mutation CreateScheduledWithdrawal($investmentUuid: String!, $amount: Float!) {
    createScheduledWithdrawal(investmentUuid: $investmentUuid, amount: $amount) {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
        closedDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
      scheduledWithdrawals {
        uuid
        amount
        status
        startDate
        endDate
      }
    }
  }
`
