import { MobileStepper, Typography } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { WrapperStepper, useStyles } from './styled'
import Button from '@mui/material/Button'
import { ReactComponent as Chevron } from 'assets/not_filled_chevron_down.svg'
import clsx from 'clsx'

interface Props {
  steps: string[]
  setActiveStep: (value: number) => void
  activeStep: number
  disableNextButton: boolean
  handleFinishAction: () => void
}

const ResponsiveStepperCard: FunctionComponent<Props> = (props) => {
  const { steps, setActiveStep, activeStep, disableNextButton, handleFinishAction } = props
  const classes = useStyles()

  const [disableBack, setDisableBack] = useState<boolean>(true)

  const handleDisableBackButton = () => {
    // TODO: Handle validations for more steps
    if (activeStep === 0) {
      setDisableBack(true)
    } else {
      setDisableBack(false)
    }
  }

  useEffect(() => {
    handleDisableBackButton()
  }, [activeStep])

  const handleNextStep = () => {
    if (activeStep + 1 === steps.length) {
      handleFinishAction()
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  return (
    <WrapperStepper>
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        className={classes.stepper}
        backButton={
          <Button size="small" onClick={() => setActiveStep(activeStep - 1)} disabled={disableBack}>
            <Chevron
              className={clsx({
                [classes.chevronLeft]: true,
                [classes.chevronDisabled]: disableBack,
              })}
            />
            <Typography
              variant="body3"
              className={clsx({
                [classes.button]: !disableBack,
                [classes.buttonDisabled]: disableBack,
              })}
            >
              <FormattedMessage id="components.stepper.label.back" />
            </Typography>
          </Button>
        }
        nextButton={
          <Button size="small" onClick={handleNextStep} disabled={disableNextButton}>
            <Typography
              variant="body3"
              className={clsx({
                [classes.button]: !disableNextButton,
                [classes.buttonDisabled]: disableNextButton,
              })}
            >
              <FormattedMessage id={`components.stepper.label.next`} />
            </Typography>
            <Chevron
              className={clsx({
                [classes.chevronRight]: true,
                [classes.chevronDisabled]: disableNextButton,
              })}
            />
          </Button>
        }
      />
    </WrapperStepper>
  )
}

export default ResponsiveStepperCard
