import DataGrid from 'components/common/DataGrid'
import { columnsInvestorScheduledWithdrawals } from 'components/common/DataGrid/utils'
import React, { Fragment, FunctionComponent, useState } from 'react'
import { ScheduledWithdrawal } from 'types'
import FinalizeScheduledWithdrawalModal from './FinalizeScheduledWithdrawalModal'

interface Props {
  investmentUuid?: string
  refetchInvestment?: () => void
  scheduledWithdrawals?: ScheduledWithdrawal[]
}

const ScheduleWithdrawalsTab: FunctionComponent<Props> = ({
  scheduledWithdrawals = [],
  investmentUuid,
  refetchInvestment,
}: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedScheduledWithdrawal, setSelectedScheduledWithdrawal] =
    useState<ScheduledWithdrawal>()

  const cancelScheduledWithdrawal = (scheduledWithdrawal: ScheduledWithdrawal) => {
    setSelectedScheduledWithdrawal(scheduledWithdrawal)
    setOpenModal(true)
  }

  const handleCancelScheduledWithdrawal = async () => {
    setOpenModal(false)
    refetchInvestment?.()
  }

  const columns = columnsInvestorScheduledWithdrawals({ cancelAction: cancelScheduledWithdrawal })

  return (
    <Fragment>
      <DataGrid columns={columns} rows={scheduledWithdrawals} getRowId={(row) => row?.uuid} />
      <FinalizeScheduledWithdrawalModal
        handleCloseCallback={handleCancelScheduledWithdrawal}
        modalOpen={openModal}
        investmentUuid={investmentUuid}
        scheduledWithdrawal={selectedScheduledWithdrawal}
      />
    </Fragment>
  )
}

export default ScheduleWithdrawalsTab
