import Snackbar from 'components/common/Snackbar'
import { useReducer, createContext, FunctionComponent, ReactNode } from 'react'

export const SHOW_SNACKBAR = 'SHOW_SNACK_BAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

interface MessageProps {
  messageId?: string
  messageText?: string
  messageValues?: Record<string, unknown>
}

type InitialState = {
  openSnack: boolean
  messageOptions: MessageProps
}

type Action =
  | { type: typeof CLOSE_SNACKBAR }
  | {
      type: typeof SHOW_SNACKBAR
      messageOptions: MessageProps
    }

const initialState: InitialState = {
  openSnack: false,
  messageOptions: {
    messageId: 'screen.welcome.mainCard.title',
    messageText: '',
    messageValues: {},
  },
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        messageOptions: action.messageOptions,
        openSnack: true,
      }
    case CLOSE_SNACKBAR:
      return { ...state, openSnack: false }
    default:
      throw new Error('Unknown action')
  }
}

type Response = {
  openSnack: boolean
  messageOptions: MessageProps
  showSnackbar: (messageOptions: MessageProps) => void
  closeSnackbar: () => void
}

export const AlertContext = createContext({} as Response)

interface Props {
  children: ReactNode
}

const Alert: FunctionComponent<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const showSnackbar = (messageOptions: MessageProps) =>
    dispatch({ type: SHOW_SNACKBAR, messageOptions: messageOptions })

  const closeSnackbar = () => dispatch({ type: CLOSE_SNACKBAR })

  const { openSnack, messageOptions } = state

  const defaultContext = {
    ...state,
    showSnackbar,
    closeSnackbar,
  }

  return (
    <AlertContext.Provider value={defaultContext}>
      <Snackbar
        open={openSnack}
        messageId={messageOptions?.messageId}
        messageText={messageOptions?.messageText}
        messageValues={messageOptions?.messageValues}
        onClose={closeSnackbar}
      />
      {children}
    </AlertContext.Provider>
  )
}

export default Alert
