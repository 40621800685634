import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { theme } from 'screens/App/theme'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  logoBlueDot: {
    maxHeight: 48,
  },
}))

export const LogoWrapper = styled.div`
  align-self: flex-start;
`

export const TextWrapper = styled.div`
  margin-top: ${theme.spacing(6)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`

export const ButtonWrapper = styled.div`
  align-self: flex-start;
  width: auto;
  padding-top: ${theme.spacing(5)};
`

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: ${theme.spacing(11)};
`
