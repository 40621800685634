import { gql } from '@apollo/client'

export const GET_REFERRALS_COUNT_INVESTMENTS = gql`
  query ReferralsCountInvestmentsByUserId($userId: String!) {
    referralsCountInvestmentsByUserId(userId: $userId) {
      name
      activeInvestments
    }
  }
`
