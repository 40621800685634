import { gql } from '@apollo/client'

export const GET_INVESTMENT_DETAILS_CHART_DATA = gql`
  query InvestmentDetailsChart(
    $startDate: String!
    $endDate: String!
    $investmentUuid: String!
    $timezone: String
  ) {
    investmentDetailsChart(
      startDate: $startDate
      endDate: $endDate
      investmentUuid: $investmentUuid
      timezone: $timezone
    ) {
      totalDeposits
      totalEarnings
      totalWithdrawals
      chartData {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
    }
  }
`

export const GET_INVESTMENT_DETAILS_STATEMENTS = gql`
  query Statements($investmentUuid: String!, $monthDate: String!) {
    statements(investmentUuid: $investmentUuid, monthDate: $monthDate) {
      total
      statements {
        id
        transaction
        amount
        balance
        days
        approxEarnings
        cumulativeApproxEarnings
        date
      }
    }
  }
`

export const DOWNLOAD_STATEMENTS_PDF = gql`
  mutation DownloadStatementPdf($investmentUuid: String!, $monthDate: String!) {
    downloadStatementPdf(investmentUuid: $investmentUuid, monthDate: $monthDate)
  }
`

export const CANCEL_WITHDRAWAL_REQUEST = gql`
  mutation CancelWithdrawalRequest($withdrawalUuid: String!, $investmentUuid: String!) {
    cancelWithdrawalRequest(withdrawalUuid: $withdrawalUuid, investmentUuid: $investmentUuid) {
      uuid
    }
  }
`

export const CANCEL_DEPOSIT_REQUEST = gql`
  mutation CancelDepositRequest($depositUuid: String!, $investmentUuid: String!) {
    cancelDepositRequest(depositUuid: $depositUuid, investmentUuid: $investmentUuid) {
      uuid
    }
  }
`

export const CLOSE_INVESTMENT = gql`
  mutation CloseInvestment($investmentUuid: String!) {
    closeInvestment(investmentUuid: $investmentUuid) {
      uuid
    }
  }
`

export const FINALIZE_SCHEDULED_WITHDRAWAL = gql`
  mutation FinalizeScheduledWithdrawal(
    $scheduledWithdrawalUuid: String!
    $investmentUuid: String!
  ) {
    finalizeScheduledWithdrawal(
      scheduledWithdrawalUuid: $scheduledWithdrawalUuid
      investmentUuid: $investmentUuid
    ) {
      uuid
    }
  }
`

export const GET_INVESTMENT = gql`
  query Investment($uuid: String!) {
    investment(uuid: $uuid) {
      uuid
      status
      isMonthlyWithdrawal
      isInLockupPrincipal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
      }
      duration {
        startDate
        endDate
        closedDate
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
        depositAllowed
        interestPeriodDescription
        interestType
      }
      transactions {
        ... on Deposit {
          uuid
          status
          amount
          approvedAt
          createdAt
          reference
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
          type
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
      scheduledWithdrawals {
        uuid
        amount
        status
        startDate
        endDate
      }
    }
  }
`
