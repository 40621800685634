export const formatMoney = (amount: number) => {
  return new Intl.NumberFormat('en-US', { currency: 'USD' }).format(amount)
}

export const formatMoneyWithSymbolAndTwoDecimals = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(amount)
}

export const inputToNumber = (input: string) => {
  const formattedValue = input.replace(/[^0-9.]/g, '') || '0'
  return Number(formattedValue)
}
