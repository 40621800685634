import { FunctionComponent, useState } from 'react'
import {
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import Card from 'components/common/Card'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { CenterContainer, BackNextButtonWrapper, useStyles, Divider } from './style'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { Plan } from 'types'
import { addLinkedPlan } from '../reducer'

interface Props {
  nextStep: () => void
  previousStep: () => void
}

interface Channels {
  next?: Plan
  plan: Plan
}

const LinkPlans: FunctionComponent<Props> = ({ nextStep, previousStep }) => {
  const newAgentStore = useSelector((state: GlobalState) => state.newAgent)
  const { plans, linkedPlans } = newAgentStore
  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const [channels, setChannels] = useState<Channels[]>(
    plans.map((plan) => ({
      next: plans.find(
        (p) => p.uuid === linkedPlans.find((l) => l.planUuid === plan.uuid)?.nextPlanUuid,
      ),
      plan,
    })),
  )
  const dispatch = useDispatch()

  const handleNextPlan = (current: Plan, selectedUuid: string) => {
    const newChannels = channels.map((channel) => {
      if (channel.plan.uuid !== current.uuid) {
        return channel
      }

      return { ...channel, next: plans.find((p) => p.uuid === selectedUuid) }
    })
    dispatch(
      addLinkedPlan(
        newChannels.map((c) => ({ planUuid: c.plan.uuid, nextPlanUuid: c.next?.uuid })),
      ),
    )
    setChannels(newChannels)
  }

  return (
    <CenterContainer className={classes.centerContainer}>
      <Card className={classes.cardContainer}>
        <Typography variant="h4" className={classes.marginBottom}>
          Link plans
        </Typography>
        <Typography variant="body3" className={classes.body3}>
          Select the next and previous plans to link them, this will allow to upgrade or downgrade
          the plans according the balance of the investment.
        </Typography>
        {channels.map(({ plan, next }, index) => {
          const nonCurrentPlans = plans.filter((p) => {
            return (
              p.uuid !== plan.uuid && p.minimumInitialInvestment >= plan.minimumInitialInvestment
            )
          })
          const possibleUpgradePlan = nonCurrentPlans.filter(
            (p) => !linkedPlans.find((l) => l.planUuid !== plan.uuid && l.nextPlanUuid === p.uuid),
          )
          return (
            <>
              <Grid
                container
                key={index}
                spacing={2}
                xs={12}
                style={{
                  width: '100%',
                }}
              >
                <Grid item xs={4}>
                  <Typography variant="h5" className={classes.marginBottom}>
                    {plan.name}
                  </Typography>
                  <Typography variant="body3" display="block" className={classes.shortDescription}>
                    {plan.shortDescription}
                  </Typography>
                  <Typography variant="body3" display="block" className={classes.minimalAmount}>
                    <FormattedMessage id="screen.newAgent.stepper.linkPlans.plan.minimalAmount" />
                    <FormattedNumber
                      value={Number(plan.minimumInitialInvestment)}
                      style="currency"
                      currency="USD"
                      maximumFractionDigits={0}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl className={classes.formControl}>
                    <FormattedMessage id="screen.newAgent.stepper.linkPlans.dropdown.downgrade.label" />
                    <Typography variant="body3" className={classes.downgradePlan}>
                      {channels.find((c) => c.next?.uuid === plan.uuid)?.plan.name || ''}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      <FormattedMessage id="screen.newAgent.stepper.linkPlans.dropdown.upgrade.label" />
                    </InputLabel>
                    <Select
                      label="Upgrade plan"
                      fullWidth
                      variant="outlined"
                      name="upgradePlan"
                      value={next?.uuid || ''}
                      onChange={(e) => handleNextPlan(plan, e.target.value)}
                    >
                      <MenuItem value="">
                        <FormattedMessage id="screen.newAgent.stepper.linkPlans.dropdown.empty" />
                      </MenuItem>
                      {possibleUpgradePlan.map((p) => (
                        <MenuItem key={p.uuid} value={p.uuid}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider />
            </>
          )
        })}
        {!isResponsive && (
          <BackNextButtonWrapper>
            <Button variant="outlined" onClick={previousStep}>
              <FormattedMessage id="screen.newAgent.stepper.linkPlans.button.back" />
            </Button>
            <Button
              variant="contained"
              onClick={nextStep}
              endIcon={<img src={ArrowRightDisabled} alt="Arrow Right" />}
            >
              <FormattedMessage id="screen.newAgent.stepper.linkPlans.button.continue" />
            </Button>
          </BackNextButtonWrapper>
        )}
      </Card>
    </CenterContainer>
  )
}

export default LinkPlans
