import { forwardRef } from 'react'
import MuiCheckbox from '@mui/material/Checkbox'
import { ReactComponent as OffIcon } from 'assets/state_off.svg'
import { ReactComponent as OnIcon } from 'assets/state_on.svg'
import { useStyles } from './styled'
import clsx from 'clsx'

const Checkbox = forwardRef<HTMLButtonElement>((props, ref) => {
  const classes = useStyles()
  const myProps = props as HTMLButtonElement
  return (
    <MuiCheckbox
      ref={ref}
      icon={<OffIcon />}
      checkedIcon={<OnIcon />}
      {...props}
      className={clsx(myProps.disabled && classes.disabledCheckbox)}
    />
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
