export type Variables = {
  profileUuid?: string
  swiftCode?: string
  country: string
  countryKey: string
  accountNumber: string
  bankName: string
  routingNumber: string
  preferredMethod: string
  beneficiary: string
}

export type Data = {
  profileUuid: string
  bankAccount: {
    countryKey: string
    country: string
    bankName: string
    accountNumber: string
    routingNumber: string
    preferredMethod: string
    beneficiary: string
    swiftCode: string | null
  }
} | null

export const getTitleId = (data?: Data) => {
  if (data) return 'components.addBankAccountModal.title.edit'
  return 'components.addBankAccountModal.title.save'
}

export const getButtonLabelId = (loading: boolean, data?: Data) => {
  if (loading) return 'components.addBankAccountModal.buttonLabel.saving'
  if (data) return 'components.addBankAccountModal.buttonLabel.edit'
  return 'components.addBankAccountModal.buttonLabel.save'
}

export const getMostUsedCountriesOptions = (
  countries: { key: string; name: string }[],
  mostUsedCountries: string[],
) => {
  const options = []

  if (mostUsedCountries) {
    for (const country of mostUsedCountries) {
      const found = countries.find(({ key }) => key === country)
      if (found) options.push(found)
    }
  }

  return options
}
