import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from 'redux/store'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH0_PROVIDER_DOMAIN, AUTH0_PROVIDER_CLIENT_ID, SENTRY_DSN } from 'config/appConfig'
import AuthWrapper from 'hooks/AuthWrapper'
import AuthorizedApolloProvider from 'api/ApolloContext'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={AUTH0_PROVIDER_DOMAIN}
        clientId={AUTH0_PROVIDER_CLIENT_ID}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
        useRefreshTokens
        scope="openid profile email offline_access"
      >
        <AuthorizedApolloProvider>
          <AuthWrapper>
            <App />
          </AuthWrapper>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
