import { FunctionComponent, useState } from 'react'
import { Button, TextField, useTheme, useMediaQuery } from '@mui/material'
import { ActionWrapper, TextFieldContainer, getTextFieldSx, ErrorContainer } from './styled'
import { FormattedMessage } from 'react-intl'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useMutation } from '@apollo/client'
import { SAVE_TIN } from './queries'
import Dialog from '../Dialog'

type Data = {
  tin: string
  profileUuid: string
} | null

type Variables = {
  profileUuid?: string
  tin: string
}

const getButtonLabelId = (loading: boolean, data?: Data) => {
  if (loading) return 'components.addProfileModal.buttonLabel.saving'
  if (data) return 'components.addProfileModal.buttonLabel.edit'
  return 'components.addTinModal.buttonLabel.save'
}

const getTitleId = (data?: Data) => `components.addTinModal.title.${data?.tin ? 'edit' : 'save'}`

const EndIcon: FunctionComponent = () => <img src={ArrowRightDisabled} alt="arrow_right_black" />

interface Props {
  data?: Data
  open: boolean
  onSave?: () => void
  handleCloseCallback?: () => void
}

const AddTinModal: FunctionComponent<Props> = ({
  data,
  open,
  onSave,
  handleCloseCallback,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const { profileUuid, tin } = data || {}
  const [saveTin, { loading }] = useMutation(SAVE_TIN)
  const [error, setError] = useState(false)
  const titleId = getTitleId(data)
  const buttonLabelId = getButtonLabelId(loading, data)

  const handleClose = () => {
    setError(false)
    if (handleCloseCallback) handleCloseCallback()
  }

  return (
    <Dialog
      dialogOpen={open}
      handleCloseCallback={handleClose}
      fullScreen={isResponsive}
      titleMessageId={titleId}
    >
      {
        <Formik
          initialValues={{
            tin: tin || '',
          }}
          onSubmit={async function (values: FormikValues): Promise<void | Promise<unknown>> {
            try {
              const { tin } = values
              const variables: Variables = {
                profileUuid,
                tin,
              }

              await saveTin({ variables })

              if (onSave) onSave()
              handleClose()

              return
            } catch (error) {
              setError(true)
              console.log('Error when saving profile: ', error)
            }
          }}
        >
          {({ values, dirty, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextFieldContainer>
                <TextField
                  label="TIN"
                  fullWidth
                  variant="outlined"
                  name="tin"
                  onChange={handleChange}
                  value={values.tin}
                  sx={getTextFieldSx(values.tin)}
                />
              </TextFieldContainer>
              {error && (
                <ErrorContainer>
                  <FormattedMessage id="global.error.tinError" />
                </ErrorContainer>
              )}
              <ActionWrapper>
                <Button
                  disabled={!dirty || loading}
                  type="submit"
                  variant="contained"
                  fullWidth={isResponsive}
                  endIcon={<EndIcon />}
                >
                  <FormattedMessage id={buttonLabelId} />
                </Button>
              </ActionWrapper>
            </form>
          )}
        </Formik>
      }
    </Dialog>
  )
}

export default AddTinModal
