import { FunctionComponent, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { AUTH0_LOGIN_REDIRECT_URL } from 'config/appConfig'

const Login: FunctionComponent = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home')
    } else {
      loginWithRedirect({ redirectUri: AUTH0_LOGIN_REDIRECT_URL })
    }
  }, [isAuthenticated])

  if (isLoading) {
    return <div>Loading ...</div>
  }

  return <div />
}

export default Login
