import { OutlinedInput } from '@mui/material'
import React, { FunctionComponent } from 'react'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import { ReactComponent as ClearIcon } from 'assets/clear.svg'
import { useStyles } from './styled'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

interface Props {
  value: string
  onChangeValue: (newValue: string) => void
  labelMessageId?: string
  labelMessage?: string
  disabled?: boolean
  fullWidth?: boolean
}

const SearchInput: FunctionComponent<Props> = ({
  value,
  onChangeValue,
  labelMessageId = 'component.searchInput.defaultLabel',
  labelMessage,
  disabled = false,
  fullWidth = false,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const clearValue = () => {
    onChangeValue('')
  }

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(e.target.value.toString())
  }

  return (
    <OutlinedInput
      id="search"
      className={classes.searchInput}
      placeholder={labelMessage ? labelMessage : intl.formatMessage({ id: labelMessageId })}
      disabled={disabled}
      autoComplete="off"
      startAdornment={<SearchIcon className={classes.searchIcon} />}
      fullWidth={fullWidth}
      endAdornment={
        <ClearIcon
          onClick={clearValue}
          className={clsx(!value.length && classes.hidden, classes.clearIcon)}
        />
      }
      value={value}
      onChange={changeValue}
    />
  )
}

export default SearchInput
