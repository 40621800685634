import { Button, Typography } from '@mui/material'
import { FunctionComponent, ReactNode, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { BodyWrapper, ImageIcon, CardIconWrapper, OutlinedCardWrapper, useStyles } from './styled'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import DisabledButtonWrapperWithEvent from 'components/common/DisabledButtonWrapperWithEvent'
import { AlertContext } from 'hooks/AlertContext'

interface Props {
  title?: string
  subtitle?: string
  children?: ReactNode
  icon?: string
  buttonLabel?: string
  buttonDisabled?: boolean
  onClickDisabledButton?: () => void
  buttonAction?: () => void
}

const OutlinedCard: FunctionComponent<Props> = ({
  title,
  subtitle,
  children,
  icon,
  buttonLabel,
  buttonDisabled,
  onClickDisabledButton,
  buttonAction,
}: Props) => {
  const { showSnackbar } = useContext(AlertContext)
  const classes = useStyles()

  const onClickDisabledButtonDefault = () => {
    showSnackbar({
      messageText: 'No available',
    })
  }

  return (
    <OutlinedCardWrapper className={classes.internalCard}>
      {icon && (
        <CardIconWrapper className={classes.iconWrapper}>
          <ImageIcon src={icon} />
        </CardIconWrapper>
      )}
      <Typography className={classes.cardTitle} variant="h4">
        <FormattedMessage id={title} />
      </Typography>
      <Typography className={classes.cardSubtitle} variant="body2">
        <FormattedMessage id={subtitle} />
      </Typography>
      <BodyWrapper className={classes.bodyWrapper}>{children}</BodyWrapper>
      {buttonLabel && (
        <DisabledButtonWrapperWithEvent
          onClick={onClickDisabledButton || onClickDisabledButtonDefault}
        >
          <Button variant="outlined" onClick={buttonAction} disabled={buttonDisabled} fullWidth>
            <FormattedMessage id={buttonLabel} />
            <SvgThinArrow className={classes.buttonIcon} />
          </Button>
        </DisabledButtonWrapperWithEvent>
      )}
    </OutlinedCardWrapper>
  )
}

export default OutlinedCard
