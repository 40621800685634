import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  cardResponsive: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5),
    },
  },
  titleResponsive: {
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  cardsContainerResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Row = styled.div`
  width: 100%;
  margin-top: 40px;
`
