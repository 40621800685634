import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  verifiedIcon: {
    width: '16px',
    height: '16px',
  },
  card: {
    maxWidth: '400px',
    height: 'fit-content',
  },
  addTin: (props: { tinExists?: boolean }) => ({
    color: `${theme.palette.primary.main}!important`,
    cursor: props.tinExists ? 'pointer' : 'default',
  }),
}))

interface VerifiedStatusProps {
  isVerified?: boolean
}
export const VerifiedStatusContainer = styled.div`
  display: flex;
  align-items: center;
  path {
    fill: ${({ isVerified }: VerifiedStatusProps) =>
      isVerified ? theme.palette.success.main : theme.palette.warning.main};
  }
  p {
    color: ${({ isVerified }: VerifiedStatusProps) =>
      isVerified ? theme.palette.success.main : theme.palette.warning.main};
  }
  gap: ${theme.spacing(1)};
`

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
