import { Grid, Typography, useMediaQuery } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { columnsTreasurerAgents } from 'components/common/DataGrid/utils'
import {
  TabContentHeaderWrapper,
  TabContentWrapper,
  useStyles,
  MainDataGridWrapper,
} from 'layouts/Treasurer/styled'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { AGENTS_LIST } from './queries'
import { useNavigate } from 'react-router-dom'
import { GridRowParams } from '@mui/x-data-grid'
import { Theme } from '@mui/material/styles'
import { theme } from 'screens/App/theme'
import { AgentDetailsTab, Profile, ReferralCode } from 'global/types'
import { ObjectValues, Plan } from 'types'
import { BadgeStatusColorType } from 'components/BadgeStatus'

export const AgentsStatus = {
  CONFIGURED: 'CONFIGURED',
  ACTIVE: 'ACTIVE',
  NOT_CONFIGURED: 'NOT_CONFIGURED',
} as const

export type AgentsStatusType = ObjectValues<typeof AgentsStatus>

const AgentsStatusColor: Record<AgentsStatusType, BadgeStatusColorType> = {
  [AgentsStatus.CONFIGURED]: 'warning',
  [AgentsStatus.ACTIVE]: 'success',
  [AgentsStatus.NOT_CONFIGURED]: 'error',
}

const AgentsStatusText = {
  [AgentsStatus.CONFIGURED]: 'screen.agentList.status.configured',
  [AgentsStatus.ACTIVE]: 'screen.agentList.status.active',
  [AgentsStatus.NOT_CONFIGURED]: 'screen.agentList.status.notConfigured',
}

interface AgentResponse {
  profile: Profile
  plans: Array<Plan>
  referralCode: ReferralCode
}

function AgentList() {
  const classes = useStyles()
  const navigate = useNavigate()
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const { data, loading, error } = useQuery<{ agents: AgentResponse[] }>(AGENTS_LIST, {
    notifyOnNetworkStatusChange: true,
  })

  const onRowClick = (row: GridRowParams) => {
    navigate(`/investor/${row.id}?tab=${AgentDetailsTab.INVESTORS}`)
  }

  const columns = columnsTreasurerAgents({
    agentsStatusColor: AgentsStatusColor,
    agentsStatusText: AgentsStatusText,
  })

  return (
    <TabContentWrapper>
      <TabContentHeaderWrapper>
        <Grid
          container
          rowGap={theme.spacing(5)}
          alignItems={isBreakpointMdUp ? 'center' : 'initial'}
          justifyContent={isBreakpointMdUp ? 'space-between' : 'initial'}
        >
          <Grid item xs={12} md="auto">
            <Typography variant={isBreakpointMdUp ? 'h2' : 'h3'} className={classes.white}>
              <FormattedMessage id="screen.agentList.title" />
            </Typography>
          </Grid>
        </Grid>
      </TabContentHeaderWrapper>
      <MainDataGridWrapper>
        <DataGrid
          columns={columns}
          rows={data?.agents ?? []}
          checkboxDisabled
          isLoading={loading}
          hasError={Boolean(error)}
          getRowId={(row) => row?.profile?.uuid}
          autoHeight
          onRowClick={onRowClick}
          activeSorting={false}
        />
      </MainDataGridWrapper>
    </TabContentWrapper>
  )
}

export default AgentList
