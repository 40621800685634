import { useQuery } from '@apollo/client'
import { Plan } from 'types'
import { GET_PLANS_BY_AGENT } from './queries'
import { FunctionComponent } from 'react'
import { Grid, Typography } from '@mui/material'
import { InvestmentPlan } from 'screens/NewAgent/InvestmentPlan'
import { ContentContainer, EmptyContainer, Row, useStyles } from './styled'
import { getArraysChunks } from 'utils/PlanChunks'

interface Props {
  agentUuid: string
}

export const PlanTab: FunctionComponent<Props> = ({ agentUuid }: Props) => {
  const classes = useStyles()
  const { data, loading, error } = useQuery<
    { plansByAgent: Array<Plan & { __typename?: string }> },
    { profileUuid: string }
  >(GET_PLANS_BY_AGENT, { variables: { profileUuid: agentUuid }, skip: !agentUuid })

  const getNextPlanName = (planUuid?: string): string | undefined => {
    if (!planUuid) return undefined
    const nextPlan = data?.plansByAgent.find((linkedPlan) => linkedPlan.uuid === planUuid)
    if (!nextPlan) return undefined
    return `${nextPlan.name} ${nextPlan.suffixName ?? ''}`.trim()
  }

  if (loading) return <div>Loading...</div>

  if (error)
    return (
      <EmptyContainer>
        <Typography variant="body3" className={classes.white}>
          Error loading investment plans.
        </Typography>
      </EmptyContainer>
    )

  if (data?.plansByAgent.length === 0) {
    return (
      <EmptyContainer>
        <Typography variant="body3" className={classes.white}>
          There are no investment plans available for this agent.
        </Typography>
      </EmptyContainer>
    )
  }

  return (
    <ContentContainer>
      <Grid container padding={3}>
        {getArraysChunks(data?.plansByAgent || [], 3).map((plansChunk, index) => (
          <Row className={classes.planRows} key={`plan-chunk-${index}`}>
            {plansChunk.map((plan) => (
              <InvestmentPlan
                key={plan.uuid}
                plan={plan}
                nextPlanName={getNextPlanName(plan.nextPlanUuid)}
              />
            ))}
          </Row>
        ))}
      </Grid>
    </ContentContainer>
  )
}
