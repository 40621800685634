import { gql } from '@apollo/client'

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail {
      success
      message
    }
  }
`
