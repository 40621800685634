import { FunctionComponent, useContext } from 'react'
import { Button, Modal as MuiModal, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  HeaderWrapper,
  ModalContentWrapper,
  RightSideWrapper,
  CloseIconWrapper,
  CircularIconContainer,
  Divider,
  ButtonContainer,
  useStyles,
} from './styled'
import { FormattedMessage, useIntl } from 'react-intl'
import CloseIcon from 'assets/close_white.svg'
import { ReactComponent as PartnershipIcon } from 'assets/partnership.svg'
import { theme } from 'screens/App/theme'
import { useAuth0 } from '@auth0/auth0-react'
import { ReactComponent as RightArrowPrimary } from 'assets/arrow_right_primary.svg'
import { useNavigate } from 'react-router-dom'
import { AlertContext } from 'hooks/AlertContext'
import Dialog from '../Dialog'

interface Props {
  width?: string
  open: boolean
  handleClose: () => void
}

const InviteInvestorsModal: FunctionComponent<Props> = ({ width, open, handleClose }: Props) => {
  const { user } = useAuth0()
  const { showSnackbar } = useContext(AlertContext)

  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const classes = useStyles()
  const referralCode = user?.user_metadata?.referralCode
  const intl = useIntl()
  const fullUrl = intl.formatMessage(
    { id: 'app.component.inviteInvestorsModal.url.full' },
    { origin: window.location.origin, referralCode },
  )

  const handleGoToInvestors = () => {
    navigate('/my-investors')
    handleClose()
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(fullUrl)
    showSnackbar({
      messageId: 'app.component.inviteInvestorsModal.copyUrl',
    })
  }
  return (
    <Dialog
      dialogOpen={open}
      handleCloseCallback={handleClose}
      descriptionMessage=""
      className={isResponsive ? classes.bottomDialog : undefined}
    >
      <HeaderWrapper>
        <CircularIconContainer>
          <PartnershipIcon />
        </CircularIconContainer>
        <Typography variant="h2" component="h2">
          <FormattedMessage id="app.component.inviteInvestorsModal.title" />
        </Typography>
        <Typography color={theme.typographyColor.secondaryText} variant="body3">
          <FormattedMessage id="app.component.inviteInvestorsModal.paragraph" />
        </Typography>
        <Typography color={theme.typographyColor.secondaryText} variant="body3">
          <FormattedMessage id="app.component.inviteInvestorsModal.subtitle" />
        </Typography>
        <Typography variant="body1" color="white">
          {fullUrl}
        </Typography>
      </HeaderWrapper>
      <Button className={classes.copyButton} fullWidth variant="outlined" onClick={handleCopy}>
        <FormattedMessage id="app.component.inviteInvestorsModal.copyLink.button" />
      </Button>
      <Divider />
      <ButtonContainer>
        <Button className={classes.goToMyInvestorsButton} fullWidth onClick={handleGoToInvestors}>
          <FormattedMessage id="app.component.inviteInvestorsModal.goToMyInvestors.button" />
          <RightArrowPrimary />
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default InviteInvestorsModal
