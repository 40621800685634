import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { theme as myTheme } from '../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
    },
  },
  cardContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2),
    },
  },
  link: {
    color: `${theme.typographyColor.onActiveSecondaryButtonText} !important`,
    cursor: 'pointer',
  },
  planDescription: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
  },
  actionWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

export const CenterContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${myTheme.spacing(2)};
`

export const IconWrapper = styled.div`
  padding: ${myTheme.spacing(2)};
  background-color: rgba(146, 243, 140, 0.16);
  border-radius: 41px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${myTheme.spacing(3)};
  margin-bottom: ${myTheme.spacing(4)};
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px dashed rgba(255, 255, 255, 0.22);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.22);
  padding-top: ${myTheme.spacing(5)};
  padding-bottom: ${myTheme.spacing(5)};
`

export const ActionWrapper = styled.div`
  display: flex;
  padding-top: ${myTheme.spacing(4)};
  gap: ${myTheme.spacing(4)};
`

export const BankInformationContainer = styled.div`
  margin-bottom: 40px;
`

export const PlanDescriptionContainer = styled.div`
  border-top: 1px dashed rgba(255, 255, 255, 0.22);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.22);
  margin-top: ${myTheme.spacing(4)};
  padding-top: ${myTheme.spacing(5)};
  padding-bottom: ${myTheme.spacing(3)};
  display: flex;
  justify-content: space-between;
  gap: ${myTheme.spacing(4)};
`

export const PlanDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${myTheme.spacing(1)};
  flex-grow: 1;
`
