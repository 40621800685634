import { Button, Menu, MenuItem, Stack } from '@mui/material'
import { FunctionComponent, useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Chevron } from 'assets/not_filled_chevron_down.svg'
import DisabledButtonWrapperWithEvent from 'components/common/DisabledButtonWrapperWithEvent'
import { Investment } from 'types'
import { AlertContext } from 'hooks/AlertContext'
import { useStyles } from './styled'

interface Props {
  onClickRequestWithdrawal: () => void
  onClickScheduleWithdrawal: () => void
  investment?: Investment
  activeScheduleWithdrawal: boolean
}

export const WithdrawalsMenuContainer: FunctionComponent<Props> = ({
  investment,
  onClickRequestWithdrawal,
  onClickScheduleWithdrawal,
  activeScheduleWithdrawal,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { showSnackbar } = useContext(AlertContext)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickDisabledScheduleWithdrawal = () => {
    if (investment?.isInLockupPrincipal) {
      showSnackbar({
        messageId:
          'screen.investmentDetail.action01.newWithdrawal.scheduledWithdrawal.disabled.lockupPeriod.snackbar',
      })
      return
    }
    if (activeScheduleWithdrawal) {
      showSnackbar({
        messageId:
          'screen.investmentDetail.action01.newWithdrawal.scheduledWithdrawal.disabled.alreadyScheduledWithdrawal.snackbar',
      })
    }
  }

  const handleClickDisabledRegularWithdrawal = () => {
    if (!investment?.plan.withdrawalAllowed) {
      showSnackbar({
        messageId:
          'screen.investmentDetail.action01.newWithdrawal.regularWithdrawal.disabled.snackbar',
      })
    }
  }
  const handleClickRequestRegularWithdrawal = () => {
    onClickRequestWithdrawal()
    setAnchorEl(null)
  }

  const handleClickScheduleWithdrawal = () => {
    onClickScheduleWithdrawal()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={
          <Chevron className={open ? classes.dropdownIconActive : classes.dropdownIconInactive} />
        }
      >
        <FormattedMessage id="screen.investmentDetail.action01.newWithdrawal" />
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Stack className={classes.buttonWrapper}>
          <DisabledButtonWrapperWithEvent onClick={handleClickDisabledRegularWithdrawal}>
            <MenuItem
              onClick={handleClickRequestRegularWithdrawal}
              disableRipple
              disabled={!investment?.plan.withdrawalAllowed}
            >
              <FormattedMessage id="screen.investmentDetail.action01.newWithdrawal.regularWithdrawal" />
            </MenuItem>
          </DisabledButtonWrapperWithEvent>
          <DisabledButtonWrapperWithEvent onClick={handleClickDisabledScheduleWithdrawal}>
            <MenuItem
              onClick={handleClickScheduleWithdrawal}
              disableRipple
              disabled={activeScheduleWithdrawal || !investment?.plan.scheduledWithdrawalAllowed}
            >
              <FormattedMessage id="screen.investmentDetail.action01.newWithdrawal.scheduledWithdrawal" />
            </MenuItem>
          </DisabledButtonWrapperWithEvent>
        </Stack>
      </Menu>
    </div>
  )
}
