import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'

export const useStyles = makeStyles((theme: Theme) => ({
  alerts: {
    '& .MuiAlert-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(2)}`,
      },
    },
    '& .MuiAlert-message': {
      padding: `${theme.spacing(0.25)} 0`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.typographyColor.primaryText,
      width: '24px',
      height: '24px',
    },
  },
  subtitle: {
    color: theme.typographyColor.primaryText,
  },
  closeIcon: {
    '& .MuiButtonBase-root': {
      marginTop: theme.spacing(0.5),
    },
  },
  withRightButton: {
    '& .MuiAlert-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing(0.25)} 0`,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: theme.spacing(2),
      },
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  },
}))

export const Container = styled.div`
  width: 100%;
`
