import { gql } from '@apollo/client'

export const GET_ALL_PLANS = gql`
  query getAllPlans {
    plans {
      uuid
      name
      suffixName
      description
      shortDescription
      interestRatePerMonth
      minimumInitialInvestment
      maximumInvestment
      principalLockupInMonths
      maturity
      withdrawalAllowed
      earlyCancellationAllowed
      earlyCancellationPenalty
      scheduledWithdrawalAllowed
      initialProcessingFee
      interestPeriodDescription
    }
  }
`
