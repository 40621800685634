import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    '& .MuiInputBase-root': {
      padding: 0,
    },
    backgroundColor: theme.extraBackgrounds.paper04,
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(3, 2, 2, 2)} !important`,
    },
  },
  dateVariant: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(0)} !important`,
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0`,
    },
    '& .MuiOutlinedInput-root': {
      height: '24px',
    },
  },
}))
