import { FunctionComponent, ReactNode, forwardRef } from 'react'
import { Button, Dialog as MuiDialog, Typography, Slide } from '@mui/material'
import {
  useStyles,
  ActionWrapper,
  CloseIconWrapper,
  HeaderWrapper,
  DialogContentWrapper,
  RightSideWrapper,
} from './styled'
import { FormattedMessage } from 'react-intl'
import CloseIcon from 'assets/close_white.svg'
import { IntlValuesObject } from 'global/types'
import { SlideProps } from '@mui/material'
interface Props {
  dialogOpen: boolean
  titleMessage?: string
  titleMessageId?: string
  descriptionMessage?: string
  descriptionMessageId?: string
  actionMessageId?: string
  actionMessageValues?: IntlValuesObject
  handleCloseCallback: () => void
  handleActionButton?: () => void
  children?: React.ReactNode
  width?: string
  valuesTitle?: IntlValuesObject
  valuesDescription?: IntlValuesObject
  endIconActionButton?: ReactNode
  fullScreen?: boolean
  className?: string
  disabledActionButton?: boolean
}

const Transition = forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Dialog: FunctionComponent<Props> = ({
  dialogOpen,
  titleMessage,
  titleMessageId,
  descriptionMessage,
  descriptionMessageId,
  actionMessageId,
  actionMessageValues,
  handleCloseCallback,
  handleActionButton,
  children,
  width = 'auto',
  valuesTitle,
  valuesDescription,
  endIconActionButton,
  fullScreen,
  className,
  disabledActionButton = false,
}: Props) => {
  const classes = useStyles()

  const getFormattedMessage = (messageId?: string, message?: string, values?: IntlValuesObject) => {
    if (messageId) {
      return <FormattedMessage id={messageId} values={values} />
    }
    return message
  }

  return (
    <MuiDialog
      open={dialogOpen}
      onClose={handleCloseCallback}
      aria-labelledby="learn-more-dialog-title"
      aria-describedby="learn-more-dialog-description"
      fullScreen={fullScreen}
      className={className ?? classes.modalContainer}
      TransitionComponent={Transition}
      transitionDuration={300}
    >
      <DialogContentWrapper width={fullScreen ? '100%' : width} className={classes.dialogContent}>
        <HeaderWrapper>
          <RightSideWrapper>
            <CloseIconWrapper src={CloseIcon} onClick={handleCloseCallback} />
          </RightSideWrapper>
          <Typography id="dialog-dialog-title" variant="h2" component="h2">
            {getFormattedMessage(titleMessageId, titleMessage, valuesTitle)}
          </Typography>
          <Typography id="dialog-dialog-description" variant="body1">
            {getFormattedMessage(descriptionMessageId, descriptionMessage, valuesDescription)}
          </Typography>
        </HeaderWrapper>
        {children}
        {actionMessageId && handleActionButton && (
          <ActionWrapper className={classes.actionWrapper}>
            <Button
              variant="outlined"
              onClick={handleActionButton}
              endIcon={endIconActionButton}
              className={classes.actionWrapper}
              disabled={disabledActionButton}
            >
              <FormattedMessage id={actionMessageId} values={actionMessageValues} />
            </Button>
          </ActionWrapper>
        )}
      </DialogContentWrapper>
    </MuiDialog>
  )
}

export default Dialog
