import { useLazyQuery } from '@apollo/client'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import SearchInput from 'components/common/Inputs/SearchInput'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { GET_INVESTORS } from './queries'
import useStyles, {
  InvestorsRequestWrapper,
  HeaderWrapper,
  SearchBarWrapper,
  getComponentsProps,
} from './styled'
import _ from 'lodash'
import { columnsTreasurerInvestors } from 'components/common/DataGrid/utils'
import DataGrid from 'components/common/DataGrid'
import { useLoading } from 'hooks/LoadingHook'
import { useNavigate } from 'react-router-dom'
import { GridRowParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import CustomToolbar from './CustomToolbar'
import { Theme } from '@mui/material/styles'
import Snackbar from 'components/common/Snackbar'

type Investor = {
  __typename?: string
  uuid: string
  endingBalance: number
  accountType: string
  status: string
  name: string
}

const Investors: FunctionComponent = () => {
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const classes = useStyles()
  const navigate = useNavigate()
  const { isLoading: searchLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState<string | null>('createdAt')
  const [sortDirection, setSortDirection] = useState<GridSortDirection>('desc')
  const [investors, setInvestors] = useState<Investor[]>([])
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [getRequests, { loading, data, error }] = useLazyQuery(GET_INVESTORS, {
    variables: {
      name: '',
      page,
      limit: pageSize,
      orderBy: sortBy,
      orderDirection: sortDirection,
    },
  })
  const investorsDebouncer = useCallback(_.debounce(getRequests, 1000), [getRequests])
  const { columns, apiRef, errorMessage } = columnsTreasurerInvestors()
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
  const handleCloseErrorSnackbar = () => setOpenErrorSnackbar(false)
  useEffect(() => {
    if (errorMessage) setOpenErrorSnackbar(true)
  }, [errorMessage])
  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLSpanElement | null>(null)
  const componentsProps = getComponentsProps({
    panelAnchorEl,
    apiRef,
    setPanelAnchorEl,
  })

  const handleRowClick = (params: GridRowParams) => navigate(`/investor/${params.row.uuid}`)

  const onChangeSearchValue = (newSearch: string) => {
    if (newSearch.length > 1) {
      startLoading()
      investorsDebouncer({
        variables: {
          name: newSearch,
          page: 0,
          limit: pageSize,
          orderBy: sortBy,
          orderDirection: sortDirection,
        },
        fetchPolicy: 'no-cache',
      })
    } else {
      stopLoading()
      getRequests({
        variables: { name: '', page, limit: 10, orderBy: sortBy, orderDirection: sortDirection },
      })
    }
    setPage(0)
    setSearch(newSearch)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    startLoading()
    getRequests({
      fetchPolicy: 'no-cache',
      variables: {
        page: newPage,
        limit: pageSize,
        name: search,
        orderBy: sortBy,
        orderDirection: sortDirection,
      },
    })
  }

  const handleChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)
    setPage(0)
    startLoading()
    getRequests({
      fetchPolicy: 'no-cache',
      variables: {
        page: 0,
        limit: newPageSize,
        name: search,
        orderBy: sortBy,
        orderDirection: sortDirection,
      },
    })
  }

  const handleChangeSort = (sortModel: GridSortModel) => {
    const sortEvent = sortModel[0] || null
    if (sortEvent) {
      const { field, sort } = sortEvent
      setSortBy(field)
      setSortDirection(sort || 'asc')
      startLoading()
      getRequests({
        fetchPolicy: 'no-cache',
        variables: {
          page,
          limit: pageSize,
          name: search,
          orderBy: field,
          orderDirection: sort || 'asc',
        },
      })
    } else {
      setSortBy(null)
      setSortDirection('asc')
      startLoading()
      getRequests({
        fetchPolicy: 'no-cache',
        variables: {
          page,
          limit: pageSize,
          name: search,
          orderBy: null,
          orderDirection: null,
        },
      })
    }
  }
  useEffect(() => {
    getRequests()
  }, [])

  useEffect(() => {
    setInvestors(data?.investors?.investors || [])
    setTotal((prev) => data?.investors?.total || prev)
  }, [data])

  useEffect(() => {
    if (investors) stopLoading()
  }, [investors])

  return (
    <InvestorsRequestWrapper>
      <HeaderWrapper>
        <Grid
          container
          rowGap={5}
          alignItems={{ xs: 'initial', md: 'center' }}
          justifyContent={{ xs: 'initial', md: 'space-between' }}
          paddingBottom={{ xs: 2, md: 2.5 }}
        >
          <Grid item xs={12} md="auto">
            <Typography className={classes.white} sx={{ typography: { xs: 'h3', md: 'h2' } }}>
              <FormattedMessage id="screen.treasurer.investors.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchBarWrapper>
              <SearchInput
                fullWidth={!isBreakpointMdUp}
                value={search}
                onChangeValue={onChangeSearchValue}
              />
            </SearchBarWrapper>
          </Grid>
        </Grid>
      </HeaderWrapper>
      <Box marginTop={{ md: 1.5 }}>
        <DataGrid
          apiRef={apiRef}
          page={page}
          pageSize={pageSize}
          pagination
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
          columns={columns}
          rows={investors}
          emptyMessageId="screen.depositRequests.search.empty"
          errorMessageId="screen.depositRequests.tab.error"
          isLoading={searchLoading || loading}
          hasError={Boolean(error)}
          getRowId={(row) => row.uuid}
          autoHeight
          rowCount={total}
          paginationMode="server"
          onRowClick={handleRowClick}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={componentsProps}
          sortingMode="server"
          onSortModelChange={handleChangeSort}
          initialState={{
            sorting: {
              sortModel: sortBy ? [{ field: sortBy, sort: sortDirection }] : [],
            },
          }}
          activeSorting
        />
      </Box>
      <Snackbar
        messageId={`Error: ${errorMessage}`}
        open={openErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
      />
    </InvestorsRequestWrapper>
  )
}

export default Investors
