import { gql } from '@apollo/client'

export const AGENTS_LIST = gql`
  query Agents {
    agents {
      plans {
        uuid
      }
      profile {
        uuid
        application {
          name
          levelName
        }
      }
      referralCode {
        uuid
        code
        active
        hasReferrals
        isUpdateBlocked
        referralBalance
      }
    }
  }
`
