import { FunctionComponent } from 'react'
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useStyles, BalanceContainer, BalanceItem, ButtonWrapper, WithdrawalRow } from './styled'
import { Investment } from 'types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from 'components/common/styled'
import { StepsWithdrawalRequest } from 'global/types'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import moment from 'moment'
import Card from 'components/common/Card'
import { inputToNumber } from 'utils/MoneyFormat'

interface Props {
  investment: Investment
  amount: string
  maximumAmount: number
  handleChangeStep: (value: number) => void
  handleConfirmWithdrawal: () => void
}

const { AMOUNT_STEP } = StepsWithdrawalRequest

const WithdrawalConfirmation: FunctionComponent<Props> = ({
  investment,
  amount,
  maximumAmount,
  handleChangeStep,
  handleConfirmWithdrawal,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const formattedAmount = inputToNumber(amount)

  const handleBackStep = () => {
    handleChangeStep(AMOUNT_STEP)
  }

  return (
    <Card className={classes.mainGridCard}>
      <Typography variant="h4">
        <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.title" />
      </Typography>
      <Divider size={theme.spacing(1)} />
      <Typography variant="body3" className={classes.body3}>
        <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.description" />
      </Typography>
      <Divider size={theme.spacing(5)} />
      <BalanceContainer>
        <BalanceItem>
          <Typography variant="body3" className={classes.body3}>
            <FormattedMessage id="screen.withdrawalRequest.step2.currentInvestmentBalance" />
          </Typography>
          <Typography variant="h4">
            {intl.formatNumber(investment?.balance?.total || 0, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </BalanceItem>
        <BalanceItem>
          <Typography variant="body3" className={classes.body3}>
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.estimatedInterest" />
          </Typography>
          <Typography variant="h4">
            {intl.formatNumber(maximumAmount, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </BalanceItem>
      </BalanceContainer>
      <Divider size={theme.spacing(5)} />
      <WithdrawalRow grayBackground>
        <Typography variant="body2" className={classes.gray}>
          <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.amount" />
        </Typography>
        <Typography variant="body2">
          {intl.formatNumber(formattedAmount || 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </WithdrawalRow>
      <WithdrawalRow>
        <Typography variant="body2" className={classes.gray}>
          <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.totalToDeposit" />
        </Typography>
        <Typography variant="h4">
          {intl.formatNumber(formattedAmount || 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </WithdrawalRow>
      <Divider size={theme.spacing(5)} />
      <Typography variant="body3">
        <FormattedMessage
          id="screen.scheduleWithdrawalRequest.step02.withdrawalEstimatedDate"
          values={{
            startDate: moment().add(1, 'months').startOf('month').format('DD'),
            endDate: moment()
              .add(1, 'months')
              .startOf('month')
              .add(15, 'days')
              .format(
                `DD [${intl.formatMessage({
                  id: 'screen.scheduleWithdrawalRequest.step02.of',
                })}] MMMM[,] YYYY`,
              ),
          }}
        />
      </Typography>
      <Divider size={theme.spacing(2)} />
      {!isResponsive && (
        <ButtonWrapper>
          <Button variant="outlined" onClick={handleBackStep}>
            <FormattedMessage id="screen.withdrawalRequest.step2.back" />
          </Button>
          <Button
            variant="contained"
            endIcon={<SvgThinArrow className={classes.backIcon} />}
            onClick={handleConfirmWithdrawal}
          >
            <FormattedMessage id="screen.scheduleWithdrawalRequest.step02.confirmWithdrawal" />
          </Button>
        </ButtonWrapper>
      )}
    </Card>
  )
}

export default WithdrawalConfirmation
