import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { makeStyles } from '@mui/styles'

interface ModalContentWrapperProps {
  width?: string
}

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  countryInputLabel: {
    color: theme.typographyColor.secondaryText,
  },
}))

export const getTextFieldSx = (value: string | null) => ({
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: value && value.length > 0 ? 0 : 12,
    color: theme.typographyColor.secondaryText,
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    top: 0,
  },
})

export const ModalContentWrapper = styled.div<ModalContentWrapperProps>`
  width: ${(props) => props.width || '816px'};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: ${theme.spacing(5)};
  background-color: ${theme.extraBackgrounds.paper03};
  color: ${theme.typographyColor.secondaryText};
  outline: none;
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${theme.spacing(1)};
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  padding-top: ${theme.spacing(4)};
`

export const CloseIconWrapper = styled.img`
  cursor: pointer;
`

export const TextFieldContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin: ${theme.spacing(1, 0)};
`

export const ErrorContainer = styled.p`
  background-color: ${theme.extraBackgrounds.paperError};
  color: ${theme.palette.error.main};
  padding: 16px;
`

export default useStyles
