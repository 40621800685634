import { Button, Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import BadgeStatus, { BadgeStatusColorType } from 'components/BadgeStatus'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  InvestmentStatus,
  TransactionStatus,
  Transaction,
  ScheduledWithdrawal,
  Withdrawal,
  Investment,
} from 'types'
import { GetFormattedDate, getFormattedReferenceNumber, getSumsubUrl } from 'utils/DataFormat'
import { ReactComponent as HelpIcon } from 'assets/help.svg'
import { useStyles } from './styled'
import { startCase, toLower } from 'lodash'
import { theme } from 'screens/App/theme'
import ExternalLinkIcon from 'assets/external_link_blue.svg'
import { Image } from './styled'
import { MutableRefObject, useRef, useState } from 'react'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MouseEvent } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DOWNLOAD_1099_PDF, GET_ADDRESS_BY_INVESTOR } from './queries'
import { colorInvestmentStatus, colorTransactionStatus } from 'utils/StatusColors'
import { capitalizeFirstLetter } from 'utils/StringsFormats'
import { Profile, ReferralCode } from 'global/types'
import { AgentsStatus, AgentsStatusType } from 'screens/AgentList'
import { NEW_YORK_TIMEZONE } from 'utils/DateUtils'

export const columnsHomeInvestments = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'component.investmentDataGrid.columns.investmentName' }),
      width: 275,
      valueGetter: (params: GridCellParams) => {
        return intl.formatMessage(
          {
            id: 'component.investmentDataGrid.columns.investmentName.name',
          },
          {
            name: params?.row?.profile?.application?.name,
            planName: params?.row?.plan?.name,
          },
        )
      },
    },
    {
      field: 'endingBalance',
      headerName: intl.formatMessage({ id: 'component.investmentDataGrid.columns.endingBalance' }),
      width: 150,
      valueGetter: (params) => params?.row?.balance?.total || 0,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'plan',
      headerName: intl.formatMessage({ id: 'component.investmentDataGrid.columns.plan' }),
      width: 100,
      valueGetter: (params) => params?.row?.plan?.name,
    },
    {
      field: 'duration.startDate',
      width: 150,
      headerName: 'Start Date',
      valueGetter: (params: GridCellParams) => {
        return GetFormattedDate(params.row.duration.startDate)
      },
    },
    {
      field: 'duration.endDate',
      width: 150,
      headerName: 'End Date',
      valueGetter: (params: GridCellParams) => {
        return GetFormattedDate(params.row.duration.endDate)
      },
    },
    {
      field: 'statusBadge',
      headerName: intl.formatMessage({ id: 'component.investmentDataGrid.columns.statusBadge' }),
      width: 100,
      renderCell: (params: GridCellParams) => {
        const status: InvestmentStatus = params?.row?.status
        const color = colorInvestmentStatus[status]
        return <BadgeStatus status={params?.row?.status} color={color} />
      },
    },
  ]

  return columns
}

export const columnsTreasurerInvestments = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'profile_applicantName',
      headerName: intl.formatMessage({ id: 'screen.investmentList.dataGrid.investor' }),
      width: 275,
    },
    {
      field: 'profile_applicantLevel',
      headerName: intl.formatMessage({ id: 'screen.investmentList.dataGrid.applicatLevel' }),
      width: 150,
      valueGetter: (params) => {
        return intl.formatMessage({
          id: `screen.investmentList.dataGrid.accountType.${params?.row?.profile_applicantLevel}`,
        })
      },
    },
    {
      field: 'plan_name',
      headerName: intl.formatMessage({ id: 'screen.investmentList.dataGrid.plan' }),
      width: 100,
      valueGetter: (params) => params?.row?.plan_name,
    },
    {
      field: 'investment_balance',
      headerName: intl.formatMessage({ id: 'screen.investmentList.dataGrid.amount' }),
      width: 150,
      valueGetter: (params) => params?.row?.investment_balance || 0,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'investment_status',
      headerName: intl.formatMessage({ id: 'screen.investmentList.dataGrid.investmentStatus' }),
      width: 100,
      renderCell: (params: GridCellParams) => {
        const status: InvestmentStatus = params?.row?.investment_status
        const color = colorInvestmentStatus[status]
        return <BadgeStatus status={params?.row?.investment_status} color={color} />
      },
    },
    {
      field: 'investment_startDate',
      width: 150,
      headerName: 'Start Date',
      valueFormatter: (params) => GetFormattedDate(params.value),
    },
    {
      field: 'investment_endDate',
      width: 150,
      headerName: 'End Date',
      valueFormatter: (params) => GetFormattedDate(params.value),
    },
  ]

  return columns
}

export const columnsTreasurer = (
  searchColumns = false,
  approvedDate = false,
  hasReferenceColumn = false,
  hasUndoColumnAction = false,
  undoButtonCallback?: (rowId: string) => void,
  hasApproveColumnAction = false,
  approveButtonCallback?: (rowId: string) => void,
  hasEstimatedActiveDate = false,
  hasActivatedDate = false,
): GridColDef[] => {
  const intl = useIntl()

  let columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: intl.formatMessage({ id: 'screen.depositRequests.tab02.dataGrid.investor' }),
      width: 250,
    },
    {
      field: 'depositDate',
      headerName: intl.formatMessage({ id: 'screen.depositRequests.tab02.dataGrid.depositDate' }),
      width: 250,
      valueGetter: (params) => params?.row?.deposit?.createdAt,
      valueFormatter: (params) => moment(params?.value).format('MMM DD[,] YYYY'),
    },
    {
      field: 'investment',
      headerName: intl.formatMessage({ id: 'screen.depositRequests.tab02.dataGrid.plan' }),
      width: 250,
      valueFormatter: (params: { value: { plan: { name: string } } }) => params?.value?.plan?.name,
    },
    {
      field: 'transaction',
      headerName: intl.formatMessage({ id: 'screen.depositRequests.tab02.dataGrid.depositAmount' }),
      width: 250,
      valueGetter: (params) => params?.row?.deposit,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value?.amount || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
  ]

  if (searchColumns) {
    const statusColumn = {
      field: 'depositStatus',
      headerName: '',
      width: 250,
      renderCell: (params: GridCellParams) => (
        <BadgeStatus status={params.row.status} color="info" />
      ),
    }
    columns = [...columns.slice(0, 3), statusColumn, ...columns.slice(-1)]
  }
  if (approvedDate) {
    const approvedColumn = {
      field: 'transactionApprovedAt',
      headerName: 'Approved date',
      width: 250,
      valueGetter: (params: any) => params?.row?.deposit?.approvedAt,
      valueFormatter: (params: { value: moment.MomentInput }) =>
        moment(params?.value).format('MMM DD[,] YYYY'),
    }
    columns = [
      ...columns.slice(0, 2),
      approvedColumn,
      ...columns.slice(-2 - (searchColumns ? 1 : 0)),
    ]
  }
  if (hasEstimatedActiveDate) {
    const estimatedActiveDateColumn = {
      field: 'transactionApprovedAt',
      headerName: 'Estimated active date',
      width: 250,
      valueGetter: (params: any) => params?.row?.deposit?.approvedAt,
      valueFormatter: (params: { value: moment.MomentInput }) =>
        moment(params?.value).format('MMM DD[,] YYYY'),
    }

    columns = [
      ...columns.slice(0, columns.length - 1),
      estimatedActiveDateColumn,
      ...columns.slice(columns.length - 1, columns.length),
    ]
  }
  if (hasActivatedDate) {
    const estimatedActiveDateColumn = {
      field: 'transactionApprovedAt',
      headerName: 'Activated date',
      width: 250,
      valueGetter: (params: any) => params?.row?.deposit?.approvedAt,
      valueFormatter: (params: { value: moment.MomentInput }) =>
        moment(params?.value).format('MMM DD[,] YYYY'),
    }

    columns = [
      ...columns.slice(0, columns.length - 1),
      estimatedActiveDateColumn,
      ...columns.slice(columns.length - 1, columns.length),
    ]
  }
  if (hasReferenceColumn) {
    const referenceColumn = {
      field: 'reference',
      headerName: 'Reference',
      width: 250,
      valueGetter: (params: any) => getFormattedReferenceNumber(params.row.deposit.reference),
    }

    columns = [
      ...columns.slice(0, columns.length - 1),
      referenceColumn,
      ...columns.slice(columns.length - 1, columns.length),
    ]
  }
  if (hasUndoColumnAction && undoButtonCallback) {
    const undoColumn = {
      field: 'undo',
      headerName: '',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Button variant="outlined" onClick={() => undoButtonCallback(params?.row?.id)}>
          Undo
        </Button>
      ),
    }
    columns = [...columns, undoColumn]
  }
  if (hasApproveColumnAction && approveButtonCallback) {
    const approveColumn = {
      field: 'approve',
      headerName: '',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Button variant="outlined" onClick={() => approveButtonCallback(params?.row?.id)}>
          Approve
        </Button>
      ),
    }
    columns = [...columns, approveColumn]
  }

  return columns
}

export const columnsInvestmentStatements = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.date' }),
      width: 125,
      valueFormatter: (params: { value: { date: number } }) =>
        moment.tz(params?.value, NEW_YORK_TIMEZONE).format('MMM DD[,] YYYY'),
    },
    {
      field: 'transaction',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.transaction' }),
      width: 150,
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.amount' }),
      width: 125,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'balance',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.balance' }),
      width: 125,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'days',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.daysInt' }),
      width: 100,
    },
    {
      field: 'approxEarnings',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.tab2.dataGrid.approxEarnings',
      }),
      width: 125,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'cumulativeApproxEarnings',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.tab2.dataGrid.cumulativeApproxEarnings',
      }),
      width: 125,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
  ]

  return columns
}

export const columnsInvestmentSimpleInterestStatements = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.date' }),
      width: 125,
      valueFormatter: (params: { value: { date: number } }) =>
        momentTz.tz(params?.value, NEW_YORK_TIMEZONE).format('MMM DD[,] YYYY'),
    },
    {
      field: 'transaction',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.transaction' }),
      width: 250,
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab2.dataGrid.amount' }),
      width: 125,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
  ]

  return columns
}

export const columnsInvestmentDeposits = (data: {
  cancelAction?: (deposit: Transaction) => void
}): GridColDef[] => {
  const { cancelAction } = data
  const intl = useIntl()
  const classes = useStyles()

  const handleInfoIcon = () => {
    // TODO Info icon handler
  }

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab4.dataGrid.date' }),
      width: 175,
      valueGetter: (params) => params?.row?.createdAt,
      valueFormatter: (params: { value: { date: number } }) =>
        moment(params?.value).format('MMM DD[,] YYYY'),
    },
    {
      field: 'approvedAt',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab4.dataGrid.approvedAt' }),
      width: 175,
      valueFormatter: (params: { value: { date: number } }) =>
        params?.value ? moment(params?.value).format('MMM DD[,] YYYY') : '-',
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab4.dataGrid.amount' }),
      width: 175,
      valueFormatter: (params) => {
        return `${intl.formatNumber(params?.value * 1 || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`
      },
    },
    {
      field: 'status',
      headerName: '',
      width: 150,
      renderCell: (params: GridCellParams) => {
        const status: TransactionStatus = params?.value
        const color = colorTransactionStatus[status]
        return (
          <BadgeStatus
            status={params?.value}
            color={color}
            onDelete={handleInfoIcon}
            rightIcon={<HelpIcon className={classes.helpIcon} />}
          />
        )
      },
    },
  ]
  if (cancelAction) {
    columns.push({
      field: 'cancelAction',
      headerName: '',
      width: 150,
      valueGetter: (params) => params?.row,
      renderCell: (params: GridCellParams) => {
        return (
          params?.value?.status === 'PENDING' && (
            <Button variant="text" onClick={() => cancelAction(params?.value)}>
              {intl.formatMessage({
                id: 'screen.investmentDetail.tab3.dataGrid.button.cancelRequest',
              })}
            </Button>
          )
        )
      },
    })
  }
  return columns
}

export const columnsInvestmentWithdrawals = (
  cancelAction?: (withdrawal: Withdrawal) => void,
): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'requestedAt',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab5.dataGrid.date' }),
      width: 175,
      valueFormatter: (params: { value: { date: number } }) =>
        moment(params?.value).format('MMM DD[,] YYYY'),
    },
    {
      field: 'approvedAt',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab5.dataGrid.approveAt' }),
      width: 175,
      valueFormatter: (params: { value: { date: number } }) =>
        params?.value ? moment(params?.value).format('MMM DD[,] YYYY') : '-',
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab5.dataGrid.amount' }),
      width: 175,
      valueFormatter: (params) => {
        return `${intl.formatNumber(params?.value * 1 || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`
      },
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab5.dataGrid.status' }),
      width: 150,
      renderCell: (params: GridCellParams) => {
        const status: TransactionStatus = params?.value
        const color = colorTransactionStatus[status]
        return <BadgeStatus status={params?.value} color={color} />
      },
    },
    {
      field: 'type',
      headerName: intl.formatMessage({ id: 'screen.investmentDetail.tab5.dataGrid.type' }),
      width: 150,
      valueFormatter: (params) => {
        return capitalizeFirstLetter(params?.value as string)
      },
    },
  ]
  if (cancelAction) {
    columns.push({
      field: 'cancelAction',
      headerName: '',
      width: 150,
      valueGetter: (params) => params?.row,
      renderCell: (params: GridCellParams) => {
        return (
          params?.value?.status === 'PENDING' &&
          params?.value?.canBeCancelable && (
            <Button variant="text" onClick={() => cancelAction(params?.value)}>
              {intl.formatMessage({
                id: 'screen.investmentDetail.tab5.dataGrid.button.cancelRequest',
              })}
            </Button>
          )
        )
      },
    })
  }
  return columns
}

export const columnsWithdrawalRequestsTreasurer = (
  includeAppliedDate = false,
  searchColumns = false,
): GridColDef[] => {
  const intl = useIntl()
  let appliedDateColumn: GridColDef | undefined
  if (includeAppliedDate) {
    appliedDateColumn = {
      field: 'withdrawalDeliveredAt',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab01.dataGrid.appliedDate',
      }),
      width: 150,
      valueGetter: (params) => params?.row?.withdrawal?.deliveredAt,
      valueFormatter: (params) => moment(params?.value).format('MMM DD[,] YYYY'),
    }
  }
  let statusColumn: GridColDef | undefined
  if (searchColumns) {
    statusColumn = {
      field: 'withdrawalStatus',
      headerName: '',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <BadgeStatus status={params.row.withdrawal.status} color="info" />
      ),
    }
  }
  const columns: GridColDef[] = [
    {
      field: 'withdrawalRequestedDate',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab01.dataGrid.requestedDate',
      }),
      width: 150,
      valueGetter: (params) => params?.row?.withdrawal?.requestedAt,
      valueFormatter: (params) => moment(params?.value).format('MMM DD[,] YYYY'),
    },
    ...(includeAppliedDate && appliedDateColumn ? [appliedDateColumn] : []),
    {
      field: 'withdrawalType',
      headerName: intl.formatMessage({ id: 'screen.withdrawalRequests.tab01.dataGrid.type' }),
      width: 100,
      valueGetter: (params) => params?.row?.withdrawal?.type,
      valueFormatter: (params) => {
        return capitalizeFirstLetter(params?.value as string)
      },
    },
    {
      field: 'fullName',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab01.dataGrid.investor',
      }),
      width: 250,
      valueGetter: (params) => params?.row?.fullName,
    },
    ...(searchColumns && statusColumn ? [statusColumn] : []),
    {
      field: 'investmentPlan',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab01.dataGrid.plan',
      }),
      width: 120,
      valueGetter: (params) => params?.row?.investment?.plan?.name,
    },
    {
      field: 'withdrawalAmount',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab01.dataGrid.withdrawalAmount',
      }),
      width: 250,
      valueGetter: (params) => params?.row?.withdrawal?.amount,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
  ]
  return columns
}

export const columnsTreasurerInvestors = (): {
  columns: GridColDef[]
  apiRef: MutableRefObject<GridApiPro | null>
  errorMessage?: string
} => {
  const apiRef = useRef(null) as MutableRefObject<GridApiPro | null>
  const intl = useIntl()
  const [errorMessage, setErrorMessage] = useState<string>()

  const columns: GridColDef[] = [
    {
      field: 'uuid',
      hide: true,
      hideable: false,
      filterable: false,
    },
    {
      field: 'name',
      headerName: intl.formatMessage({
        id: 'component.investors.treasurer.dataGrid.columns.names',
      }),
      width: 400,
    },
    {
      field: 'accountType',
      headerName: intl.formatMessage({
        id: 'component.investors.treasurer.dataGrid.columns.accountType',
      }),
      width: 160,
    },
    {
      field: 'endingBalance',
      headerName: intl.formatMessage({ id: 'component.investmentDataGrid.columns.endingBalance' }),
      width: 160,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({
        id: 'component.investors.treasurer.dataGrid.columns.status',
      }),
      width: 160,
      renderCell: (params: GridCellParams) => {
        const status = params.row.status
        const badgeStatusColor: Record<string, string> = {
          ACTIVE: 'success',
          PENDING: 'warning',
          REJECTED: 'error',
          DECLINED: 'error',
        }
        const color = badgeStatusColor[status] as 'success' | 'warning' | 'error' | undefined

        return <BadgeStatus status={startCase(toLower(status))} color={color} />
      },
    },
    {
      field: 'createdAt',
      headerName: intl.formatMessage({
        id: 'component.investmentDataGrid.columns.applicationDate',
      }),
      valueFormatter: (params) => moment(params?.value).format('MMM DD[,] YYYY'),
      width: 160,
    },
    {
      field: 'applicantId',
      headerName: '',
      width: 160,
      sortable: false,
      renderCell: (params: GridCellParams & { api: GridApiPro }) => {
        const primaryColor = theme.palette.primary.main
        const applicantId = params.row.applicantId
        const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
          e.stopPropagation()
          window.open(getSumsubUrl(applicantId), '_blank')
        }
        apiRef.current = params.api

        return (
          <Typography
            onClick={handleClick}
            variant="caption"
            color={primaryColor}
            sx={{ cursor: 'pointer' }}
          >
            <FormattedMessage id="screen.investorDetail.overview.open.in.sumbsub" />
            <Image src={ExternalLinkIcon} alt="external_link_icon.svg" />
          </Typography>
        )
      },
    },
    {
      field: '1099intDoc',
      headerName: '',
      width: 160,
      sortable: false,
      renderCell: (params: GridCellParams & { api: GridApiPro }) => {
        const primaryColor = theme.palette.primary.main
        const [download1099Pdf] = useMutation(DOWNLOAD_1099_PDF)
        const { data: addressData } = useQuery(GET_ADDRESS_BY_INVESTOR, {
          variables: { profileUuid: params.row.uuid },
        })
        const handleClick = async (e: MouseEvent<HTMLSpanElement>) => {
          e.stopPropagation()
          try {
            const {
              data: { download1099Pdf: base64Pdf },
            } = await download1099Pdf({ variables: { profileUuid: params.row.uuid } })
            const byteCharacters = atob(base64Pdf)
            const byteArray = new Uint8Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteArray[i] = byteCharacters.charCodeAt(i)
            }
            const blob = new Blob([byteArray], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = '1099int.pdf'
            link.click()
            URL.revokeObjectURL(url)
          } catch (e: any) { // eslint-disable-line
            if (e.message) {
              setErrorMessage(e.message)
            }
          }
        }
        apiRef.current = params.api

        if (addressData?.addressByInvestor?.countryKey === 'US') {
          return (
            <Typography
              onClick={handleClick}
              variant="caption"
              color={primaryColor}
              sx={{ cursor: 'pointer' }}
            >
              <FormattedMessage id="screen.investorDetail.overview.open.1099int" />
              <Image src={ExternalLinkIcon} alt="external_link_icon.svg" />
            </Typography>
          )
        }
      },
    },
  ]

  return { columns, apiRef, errorMessage }
}

export const getPartnersScreenColumns = () => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'referralName',
      hideSortIcons: true,
      sortable: false,
      headerName: intl.formatMessage({ id: 'screen.partners.dataGrid.columns.names' }),
      flex: 1,
    },
    {
      field: 'invitedByName',
      hideSortIcons: true,
      sortable: false,
      headerName: intl.formatMessage({ id: 'screen.partners.dataGrid.columns.invitedBy' }),
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'netMonthlyEarnings',
      hideSortIcons: true,
      sortable: false,
      headerName: intl.formatMessage({ id: 'screen.partners.dataGrid.columns.earnings' }),
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
  ]

  return columns
}

export const columnsInvestorScheduledWithdrawals = (data: {
  cancelAction?: (scheduledWithdrawal: ScheduledWithdrawal) => void
}): GridColDef[] => {
  const { cancelAction } = data
  const intl = useIntl()
  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.scheduledWithdrawals.dataGrid.date',
      }),
      width: 175,
      valueGetter: (params) => params?.row?.startDate,
      valueFormatter: (params) => GetFormattedDate(params?.value),
    },
    {
      field: 'endDate',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.scheduledWithdrawals.dataGrid.endDate',
      }),
      width: 175,
      valueGetter: (params) => params?.row?.endDate,
      valueFormatter: (params) => (params?.value ? GetFormattedDate(params?.value) : '-'),
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.scheduledWithdrawals.dataGrid.amount',
      }),
      width: 175,
      valueGetter: (params) => params?.row?.amount,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({
        id: 'screen.investmentDetail.scheduledWithdrawals.dataGrid.status',
      }),
      width: 150,
      renderCell: (params: GridCellParams) => {
        const status: 'ACTIVE' | 'FINISHED' = params?.value
        const color = status === 'ACTIVE' ? 'success' : 'default'
        return <BadgeStatus status={params?.value} color={color} />
      },
    },
  ]

  if (cancelAction) {
    columns.push({
      field: 'cancelAction',
      headerName: '',
      width: 150,
      valueGetter: (params) => params?.row,
      renderCell: (params: GridCellParams) => {
        return (
          params?.value?.status === 'ACTIVE' && (
            <Button variant="text" onClick={() => cancelAction(params?.value)}>
              {intl.formatMessage({
                id: 'screen.investmentDetail.scheduledWithdrawals.dataGrid.button.finalize',
              })}
            </Button>
          )
        )
      },
    })
  }

  return columns
}

export const columnsTreasurerScheduledWithdrawals = (data: {
  showEndDate?: boolean
  showBalance?: boolean
}): GridColDef[] => {
  const { showEndDate = true, showBalance } = data
  const intl = useIntl()
  const balanceColumn = {
    field: 'balance',
    headerName: intl.formatMessage({
      id: 'screen.withdrawalRequests.tab03.dataGrid.balance',
    }),
    width: 175,
    valueFormatter: (params) =>
      `${intl.formatNumber(params?.value || 0, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      })}`,
  } as GridColDef
  const columns: GridColDef[] = [
    {
      field: 'investorName',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab03.dataGrid.investor',
      }),
      width: 250,
    },
    {
      field: 'planName',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab03.dataGrid.plan',
      }),
      width: 150,
    },
    ...(showBalance ? [balanceColumn] : []),
    {
      field: 'amount',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab03.dataGrid.amount',
      }),
      width: 175,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
    },
    {
      field: 'startDate',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab03.dataGrid.requestedDate',
      }),
      width: 175,
      valueFormatter: (params) => GetFormattedDate(params?.value),
    },
  ]

  if (showEndDate) {
    columns.push({
      field: 'endDate',
      headerName: intl.formatMessage({
        id: 'screen.withdrawalRequests.tab03.dataGrid.endDate',
      }),
      width: 175,
      valueFormatter: (params) => (params?.value ? GetFormattedDate(params?.value) : '-'),
    })
  }
  return columns
}

export const columnsTreasurerAgents = (data: {
  agentsStatusColor: Record<AgentsStatusType, BadgeStatusColorType>
  agentsStatusText: Record<AgentsStatusType, string>
}): GridColDef[] => {
  const intl = useIntl()
  const { agentsStatusColor, agentsStatusText } = data
  const columns: GridColDef[] = [
    {
      field: 'profileName',
      headerName: intl.formatMessage({ id: 'screen.agentList.dataGrid.columns.name' }),
      valueGetter: (params) => (params?.row?.profile as Profile)?.application.name,
      width: 250,
    },
    {
      field: 'referralCode',
      headerName: intl.formatMessage({ id: 'screen.agentList.dataGrid.columns.referralCode' }),
      valueGetter: (params) => (params?.row?.referralCode as ReferralCode)?.code,
      width: 250,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'screen.agentList.dataGrid.columns.status' }),
      valueGetter: (params) => {
        const referralCode = params?.row?.referralCode as ReferralCode
        if (!referralCode?.active) {
          return AgentsStatus.NOT_CONFIGURED
        }
        if (!referralCode?.isUpdateBlocked) {
          return AgentsStatus.CONFIGURED
        }
        return AgentsStatus.ACTIVE
      },
      renderCell: (params: GridCellParams) => {
        const status: AgentsStatusType = params?.value as AgentsStatusType
        return (
          <BadgeStatus
            status={intl.formatMessage({ id: agentsStatusText[status] })}
            color={agentsStatusColor[status]}
          />
        )
      },
      width: 120,
    },
    {
      field: 'referralsBalance',
      headerName: intl.formatMessage({ id: 'screen.agentList.dataGrid.columns.referralsBalance' }),
      valueGetter: (params) => (params.row.referralCode as ReferralCode)?.referralBalance,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
      width: 250,
    },
  ]

  return columns
}

export const columnInvestorsByAgents = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'profileName',
      headerName: intl.formatMessage({
        id: 'screen.investorsByAgent.dataGrid.columns.profile.name',
      }),
      valueGetter: (params) => {
        const profile = params?.row as Profile
        if (profile?.application) {
          return profile.application.name
        }
        return 'No profile configured'
      },
      width: 350,
    },
    {
      field: 'email',
      headerName: intl.formatMessage({
        id: 'screen.investorsByAgent.dataGrid.columns.profile.email',
      }),
      valueGetter: (params) => (params?.row as Profile)?.user?.email,
      width: 250,
    },
    {
      field: 'balance',
      headerName: intl.formatMessage({
        id: 'screen.investorsByAgent.dataGrid.columns.balance',
      }),
      valueGetter: (params) => (params.row as Profile).endingBalance,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
      width: 150,
    },
  ]
  return columns
}

export const columnsInvestmentsInvestorsByAgents = (): GridColDef[] => {
  const intl = useIntl()

  const columns: GridColDef[] = [
    {
      field: 'investorName',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.profile.name',
      }),
      valueGetter: (params) => {
        const investment = params?.row as Investment
        return investment.profile.application.name
      },
      width: 350,
    },
    {
      field: 'accountType',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.profile.accountType',
      }),
      valueGetter: (params) => (params?.row as Investment).profile.application.levelName,
      width: 250,
    },
    {
      field: 'plan',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.plan',
      }),
      valueGetter: (params) => (params?.row as Investment).plan?.name || 'TODO',
      width: 250,
    },
    {
      field: 'amount',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.amount',
      }),
      valueGetter: (params) => (params?.row as Investment).balance.total,
      valueFormatter: (params) =>
        `${intl.formatNumber(params?.value || 0, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}`,
      width: 150,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.status',
      }),
      valueGetter: (params) => (params.row as Investment).status,
      renderCell: (params: GridCellParams) => {
        const status: InvestmentStatus = params?.row?.status
        const color = colorInvestmentStatus[status]
        return <BadgeStatus status={params?.row?.status} color={color} />
      },
      width: 150,
    },
    {
      field: 'startDate',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.startDate',
      }),
      valueGetter: (params) => (params.row as Investment).duration.startDate,
      width: 150,
      valueFormatter: (params) => GetFormattedDate(params.value),
    },
    {
      field: 'endDate',
      headerName: intl.formatMessage({
        id: 'screen.investmentInvestorByAgent.dataGrid.columns.endDate',
      }),
      valueGetter: (params) => (params.row as Investment).duration.endDate,
      width: 150,
      valueFormatter: (params) => GetFormattedDate(params.value),
    },
  ]
  return columns
}
