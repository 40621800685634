import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  margin-top: ${theme.spacing(3)};
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
`
