import { Grid, useMediaQuery } from '@mui/material'
import { theme } from 'screens/App/theme'

interface Props {
  title: React.ReactNode
  value: React.ReactNode
  copyButton?: React.ReactNode
}

const BankInformationGridItem = ({ copyButton, title, value }: Props) => {
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const titleSxProperty = {
    pb: 1.5,
    ...(matchesUpMd && { pb: 0 }),
  }

  return (
    <Grid
      container
      item
      px={1}
      py={3}
      xs={12}
      borderBottom={1}
      borderColor={theme.buttonColor.stroke}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid xs={12} md="auto" item sx={titleSxProperty} style={{ flexGrow: '1' }}>
        {title}
      </Grid>
      <Grid
        container
        item
        md="auto"
        justifyContent="space-between"
        alignItems="center"
        style={{ flexBasis: matchesUpMd ? '304px' : '' }}
      >
        <Grid xs="auto" item style={{ maxWidth: matchesUpMd ? '304px' : '' }}>
          {value}
        </Grid>
        {copyButton}
      </Grid>
    </Grid>
  )
}

export default BankInformationGridItem
