import { gql } from '@apollo/client'

export const GET_PLANS_BY_AGENT = gql`
  query PlansByAgent($profileUuid: uuid!) {
    plansByAgent(profileUuid: $profileUuid) {
      uuid
      name
      suffixName
      description
      shortDescription
      principalLockupInMonths
      maturity
      autoRenewal
      autoRenewalPeriodInMonths
      maxAutoRenewalPeriod
      renewalPeriodLockInMonths
      depositAllowed
      earlyWithdrawalAllowed
      scheduledWithdrawalAllowed
      withdrawalAllowed
      depositFrequency
      contractId
      memorandumId
      minimumInitialInvestment
      interestRatePerMonth
      maximumInvestment
      initialProcessingFee
      earlyWithdrawalPenalty
      earningPenalty
      nextPlanUuid
    }
  }
`

export const GET_REFERRALS_BY_AGENT = gql`
  query ReferralsByAgent($agentUuid: uuid!) {
    referralsByAgent(agentUuid: $agentUuid) {
      uuid
      application {
        name
        levelName
      }
      user {
        email
        name
        userId
      }
      endingBalance
    }
  }
`

export const GET_INVESTORS_INVESTMENTS_BY_AGENT = gql`
  query InvestorsInvestmentsByAgent($agentUuid: uuid!) {
    investorsInvestmentsByAgent(agentUuid: $agentUuid) {
      balance {
        total
      }
      profile {
        application {
          name
          levelName
        }
      }
      uuid
      status
      duration {
        startDate
      }
      plan {
        name
      }
    }
  }
`
