import { FunctionComponent, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import {
  useStyles,
  IconWrapper,
  DashedDivider,
  DepositBankContainer,
  BankDataContainer,
} from './styled'
import { Investment, Transaction } from 'types'
import { Divider } from 'components/common/styled'
import { theme } from 'screens/App/theme'
import { ReactComponent as GreenCheck } from 'assets/green_check.svg'
import { ReactComponent as SvgThinArrow } from 'assets/arrow_right_thin.svg'
import moment from 'moment'
import Card from 'components/common/Card'

interface Props {
  investment: Investment
}

const WithdrawalRequested: FunctionComponent<Props> = ({ investment }: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const navigate = useNavigate()

  const [transaction, setTransaction] = useState<Transaction>()

  useEffect(() => {
    if (investment) {
      const transactions = investment?.transactions
      const withdrawalRequestTransaction = transactions[transactions.length - 1]
      setTransaction(withdrawalRequestTransaction)
    }
  }, [investment])

  const handleRedirectToMyInvestment = () => {
    navigate(`/investment-detail/${investment?.uuid}`)
  }

  return (
    <Card className={classes.mainGrid}>
      <IconWrapper>
        <GreenCheck className={classes.greenCheck} />
      </IconWrapper>
      <Divider size={theme.spacing(4)} />
      <Typography variant="body2" className={classes.gray}>
        <FormattedMessage id="screen.withdrawalRequest.step3.confirmationNumber" />:{' '}
        {transaction?.uuid}
      </Typography>
      <Divider size={theme.spacing(3)} />
      <Typography variant="h1">
        <FormattedMessage id="screen.withdrawalRequest.step3.withdrawalRequested" />
      </Typography>
      <Divider size={theme.spacing(1)} />
      <Typography variant="body1" className={classes.gray}>
        <FormattedMessage
          id="screen.withdrawalRequest.step3.withdrawalEstimatedHit"
          values={{ depositDate: moment().add(1, 'months').format('MMMM YYYY') }}
        />
      </Typography>
      <Divider size={theme.spacing(4)} />
      <DashedDivider />
      <Divider size={theme.spacing(5)} />
      <DepositBankContainer>
        <div>
          <Typography variant="body2" className={classes.gray}>
            <FormattedMessage id="screen.withdrawalRequest.step3.totalToDeposit" />
          </Typography>
          <Divider size={theme.spacing(1)} />
          <Typography variant="h2">
            {intl.formatNumber(transaction?.amount || 0, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        {investment?.profile?.bankAccounts.length > 0 && (
          <div>
            <Typography variant="body2" className={classes.gray}>
              <FormattedMessage id="screen.withdrawalRequest.step3.bank" />
            </Typography>
            <Divider size={theme.spacing(1)} />
            <BankDataContainer>
              <img
                className={classes.bankImage}
                src={investment?.profile?.bankAccounts[0]?.bank?.logo}
                alt="bank-logo"
              />
              <Typography variant="body2">
                {investment?.profile?.bankAccounts[0]?.bank?.name}
              </Typography>
            </BankDataContainer>
          </div>
        )}
      </DepositBankContainer>
      <Divider size={theme.spacing(3)} />
      <DashedDivider />
      <Divider size={theme.spacing(5)} />
      <Button
        variant="contained"
        endIcon={<SvgThinArrow className={classes.backIcon} />}
        onClick={handleRedirectToMyInvestment}
      >
        <FormattedMessage id="screen.withdrawalRequest.step3.backToMyInvestment" />
      </Button>
    </Card>
  )
}

export default WithdrawalRequested
