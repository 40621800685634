import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { ReactComponent as ArrowRightThinCustom } from 'assets/arrow_right_thin_custom.svg'
import { useStyles } from './styled'

type Props = {
  marginRight?: boolean
} & Parameters<typeof Button>[0]

const ButtonWithArrowAnimation: FC<Props> = ({ children, marginRight, ...props }) => {
  const classes = useStyles()
  const animationMargin = marginRight ? classes.marginRight : classes.marginLeft

  return (
    <Button
      className={`${classes.animation} ${animationMargin}`}
      sx={{ paddingTop: '12px' }}
      endIcon={
        <Box display="flex" alignItems="center" className="button__arrow-icon">
          <ArrowRightThinCustom style={{ flexShrink: '0' }} />
        </Box>
      }
      {...props}
    >
      <Box sx={{ paddingTop: '4px' }}>{children}</Box>
    </Button>
  )
}

export default ButtonWithArrowAnimation
