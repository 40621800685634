import { useQuery } from '@apollo/client'
import { Button, Typography } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { columnsWithdrawalRequestsTreasurer } from 'components/common/DataGrid/utils'
import { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { GET_WITHDRAWAL_REQUESTS } from '../queries'
import ApplyWithdrawalsModal from './ApplyWithdrawalsModal'
import { DataGridActionHeader, DataGridHeaderWrapper, useStyles, Wrapper } from './styled'
import { WithdrawalRequest } from 'types'
import { GetWithdrawalRequestsData, GetWithdrawalRequestsVariables } from '../CompletedTab'
import { GridSelectionModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'

const TAB_STATUS = 'PENDING'

const PendingTab: FunctionComponent = () => {
  const classes = useStyles()
  // TODO: This is for Modals
  const [applyWithdrawalModalOpen, setApplyWithdrawalModalOpen] = useState(false)
  const [selectedWithdrawalRequests, setSelectedWithdrawalRequests] = useState<WithdrawalRequest[]>(
    [],
  )
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDirection, setOrderDirection] = useState<GridSortDirection | undefined>(undefined)
  const [selectedRow, setSelectedRow] = useState<WithdrawalRequest>()

  const pendingColumns = columnsWithdrawalRequestsTreasurer()

  const { data, error, loading, refetch } = useQuery<
    GetWithdrawalRequestsData,
    GetWithdrawalRequestsVariables
  >(GET_WITHDRAWAL_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    variables: { status: TAB_STATUS },
    fetchPolicy: 'no-cache',
  })

  const onChangeSelectedGridModel = (idsSelected: GridSelectionModel) => {
    const newSelectedDeposits = data?.withdrawalRequests.withdrawalsRequest.filter((value) =>
      idsSelected.includes(value.id),
    )
    if (!newSelectedDeposits) return
    setSelectedWithdrawalRequests(newSelectedDeposits)
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const newOrderBy = sortModel?.[0]?.field
    const newOrderDirection = sortModel?.[0]?.sort
    setPage(0)
    setOrderBy(newOrderBy)
    setOrderDirection(newOrderDirection)
    setSelectedWithdrawalRequests([])
    refetch({ page: 0, orderBy: newOrderBy, orderDirection: newOrderDirection })
  }

  const handleOnPageSizeChange = (newPageSize: number) => {
    setPage(0)
    setLimit(newPageSize)
    setSelectedWithdrawalRequests([])
    refetch({ page: 0, limit: newPageSize })
  }

  const handleOnPageChange = (newPage: number) => {
    setPage(newPage)
    setSelectedWithdrawalRequests([])
    refetch({ page: newPage })
  }

  const handleOpenApplyModal = () => setApplyWithdrawalModalOpen(true)
  const handleCloseApplyCallback = () => setApplyWithdrawalModalOpen(false)

  const handleSelectedRow = (row: WithdrawalRequest) => {
    setSelectedRow(row)
    handleOpenApplyModal()
  }

  const handleCloseCallback = () => {
    setSelectedRow(undefined)
    handleCloseApplyCallback()
  }

  let withdrawals = data?.withdrawalRequests.withdrawalsRequest ?? []
  let rowCount = data?.withdrawalRequests.total ?? 0
  if (error || loading) {
    withdrawals = []
    rowCount = 0
  }

  const HeaderWithAction = () => {
    if (selectedWithdrawalRequests.length !== 0) {
      return (
        <DataGridHeaderWrapper>
          <Typography variant="body3" className={classes.white}>
            <FormattedMessage
              id="screen.withdrawalRequests.tab.selected"
              values={{ selected: selectedWithdrawalRequests.length }}
            />
          </Typography>
          <DataGridActionHeader>
            <Button variant="outlined" onClick={handleOpenApplyModal}>
              <FormattedMessage id="screen.withdrawalRequests.tab01.action1.apply" />
            </Button>
          </DataGridActionHeader>
        </DataGridHeaderWrapper>
      )
    }
    return null
  }

  return (
    <Wrapper>
      <ApplyWithdrawalsModal
        handleCloseCallback={handleCloseCallback}
        modalOpen={applyWithdrawalModalOpen}
        withdrawals={selectedRow ? [selectedRow] : selectedWithdrawalRequests}
      />
      <DataGrid
        extraHeader={<HeaderWithAction />}
        columns={pendingColumns}
        rows={withdrawals}
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={limit}
        paginationMode="server"
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        emptyMessageId="screen.withdrawalRequests.tab.empty"
        errorMessageId="screen.withdrawalRequests.tab.error"
        checkboxSelection
        hasError={Boolean(error)}
        isLoading={loading}
        onSelectionModelChange={onChangeSelectedGridModel}
        autoHeight
        onRowClick={(row) => handleSelectedRow(row?.row)}
        activeSorting
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel: orderBy && orderDirection ? [{ field: orderBy, sort: orderDirection }] : [],
          },
        }}
      />
    </Wrapper>
  )
}

export default PendingTab
