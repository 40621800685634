import { FunctionComponent, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Alert, ButtonContainer, HeaderWrapper, useStyles } from './styled'
import Dialog from 'components/common/Dialog'
import { ReactComponent as OffIcon } from 'assets/state_off.svg'
import { ReactComponent as OnIcon } from 'assets/state_on.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import { Transaction } from 'types'

interface Props {
  open: boolean
  handleClickFinish: () => void
  handleClose: () => void
  pendingWithdrawals?: Transaction[]
  loading?: boolean
}

const FinishInvestmentModal: FunctionComponent<Props> = ({
  open,
  handleClose,
  handleClickFinish,
  loading = false,
  pendingWithdrawals = [],
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const intl = useIntl()
  const [checked, setChecked] = useState(false)

  const handleCheck = () => {
    setChecked((prev) => !prev)
  }

  return (
    <Dialog
      dialogOpen={open}
      handleCloseCallback={handleClose}
      titleMessageId="screen.finishInvestment.modal.title"
      fullScreen={isResponsive}
    >
      <HeaderWrapper>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.finishInvestment.modal.agreement01" />
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.finishInvestment.modal.agreement02" />
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          <FormattedMessage id="screen.finishInvestment.modal.agreement03" />
        </Typography>
        {pendingWithdrawals.length > 0 && (
          <Alert>
            <Typography variant="body1" color={theme.typographyColor.secondaryText}>
              <FormattedMessage
                id="screen.finishInvestment.modal.pendingWithdrawals"
                values={{
                  withdrawals: pendingWithdrawals.length,
                  totalAmount: intl.formatNumber(
                    pendingWithdrawals.reduce((acc, item) => acc + item.amount, 0),
                    {
                      style: 'currency',
                      currency: 'USD',
                    },
                  ),
                }}
              />
            </Typography>
          </Alert>
        )}
      </HeaderWrapper>
      <FormControlLabel
        className={classes.checkboxContainer}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheck}
            icon={<OffIcon />}
            checkedIcon={<OnIcon />}
          />
        }
        value="start"
        label={
          <Typography variant="body3" color={theme.typographyColor.secondaryText}>
            <FormattedMessage id="screen.finishInvestment.modal.checkbox" />
          </Typography>
        }
      />
      <ButtonContainer>
        <Button className={classes.button} fullWidth variant="outlined" onClick={handleClose}>
          <FormattedMessage id="screen.finishInvestment.modal.button.cancel" />
        </Button>
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          onClick={handleClickFinish}
          disabled={!checked || loading}
        >
          <FormattedMessage id="screen.finishInvestment.modal.button.confirm" />
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default FinishInvestmentModal
