import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Divider = styled.div<{ size: string }>`
  width: 100%;
  height: ${({ size }) => size || theme.spacing(3)};
`
