import { Skeleton } from '@mui/material'
import { FunctionComponent } from 'react'
import { FlexContainer } from '../styled'
import { theme } from 'screens/App/theme'

const paperBackground = theme.extraBackgrounds.paper02

const ScreenSkeleton: FunctionComponent = () => {
  return (
    <FlexContainer width="100%" flexDirection="column">
      <FlexContainer width="100%" alignItems="center" justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          sx={{ backgroundColor: paperBackground }}
          width={120}
          height={40}
        />
        <Skeleton
          variant="rectangular"
          sx={{ backgroundColor: paperBackground }}
          width={195}
          height={52}
        />
      </FlexContainer>
      <FlexContainer marginTop="24px" width="100%">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={400}
          sx={{ backgroundColor: paperBackground }}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

export default ScreenSkeleton
