import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.typographyColor.primaryText,
  },
}))

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  margin-top: ${theme.spacing(3)};
`
