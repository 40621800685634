import { Fragment, FunctionComponent, useState } from 'react'
import { Grid, Skeleton } from '@mui/material'
import AddAddressModal from 'components/common/AddAddressModal'
import AddBankAccountModal from 'components/common/AddBankAccountModal'
import { useIntl } from 'react-intl'
import InvestorOverview from './InvestorOverview'
import useStyles, { DataGridListContainer, Image, MainContainer, OverviewContainer } from './styled'
import ExternalLinkIcon from 'assets/external_link_blue.svg'
import { useQuery } from '@apollo/client'
import {
  GET_ADDRESS_BY_INVESTOR,
  GET_BANK_ACCOUNT_BY_INVESTOR,
  GET_INVESTMENTS_BY_INVESTOR,
  GET_PROFILE_BY_UUID,
} from './queries'
import Appbar from 'components/Appbar'
import { useNavigate, useParams } from 'react-router-dom'
import Snackbar from 'components/common/Snackbar'
import InvestmentDataGrid from 'components/InvestmentDataGrid'
import { getProfileLabelId } from './utils'
import { Profile, ProfileVerificationStatus } from 'global/types'
import { getSumsubUrl } from 'utils/DataFormat'
import AgentDetailsTabs from './AgentDetailsTabs'

const APPROVED = ProfileVerificationStatus.approved
const OPEN_IN_SUMSUB_LABEL_ID = 'screen.investorDetail.overview.open.in.sumbsub'
const ADD_PROFILE_LABEL_ID = 'screen.investorDetail.overview.add.profile'
const ADD_BANK_ACCOUNT_LABEL_ID = 'screen.investorDetail.overview.add.bank.account'
const PROFILE_ADDED_SNACKBAR_LABEL_ID = 'screen.investorDetail.snackbar.added.profile'
const BANK_ACCOUNT_ADDED_SNACKBAR_LABEL_ID = 'screen.investorDetail.snackbar.added.bank.account'

const isAgent = (profile?: Profile) => Boolean(profile?.referralCode?.active)

const InvestorDetail: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const navigation = useNavigate()
  const profileUuid = useParams()?.uuid || ''
  const {
    data: profileByUuid,
    loading: loadingProfile,
    refetch: refetchProfileData,
  } = useQuery<{ profileByUuid: Profile }, { uuid: string }>(GET_PROFILE_BY_UUID, {
    variables: { uuid: profileUuid },
  })
  const { name: fullName, status, applicantId } = profileByUuid?.profileByUuid?.application || {}
  const isAgentRole = isAgent(profileByUuid?.profileByUuid)
  const { tin = '' } = profileByUuid?.profileByUuid || {}
  const {
    data: addressData,
    loading: loadingAddressData,
    refetch: refetchAddressData,
  } = useQuery(GET_ADDRESS_BY_INVESTOR, {
    variables: { profileUuid },
  })
  const {
    data: bankAccountData,
    loading: loadingBankAccountData,
    refetch: refetchBankAccountData,
  } = useQuery(GET_BANK_ACCOUNT_BY_INVESTOR, {
    variables: { profileUuid },
  })
  const { data: investments, loading: loadingInvestments } = useQuery(GET_INVESTMENTS_BY_INVESTOR, {
    variables: { profileUuid },
  })
  const { addressByInvestor: address } = addressData || {}
  const { wireBankAccountByInvestor: wireBankAccount } = bankAccountData || {}
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false)
  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false)
  const [openAddedAddressSnackbar, setOpenAddedAddressSnackbar] = useState(false)
  const [openAddedBankAccountSnackbar, setOpenAddedBankAccountSnackbar] = useState(false)

  const handleOpenAddAddressModal = (open: boolean) => () => setOpenAddAddressModal(open)
  const handleOpenAddBankAccountModal = (open: boolean) => () => setOpenAddBankAccountModal(open)
  const handleCloseAddedAddressSnackbar = () => setOpenAddedAddressSnackbar(false)
  const handleCloseAddedBankAccountSnackbar = () => setOpenAddedBankAccountSnackbar(false)
  const onSaveAddress = () => {
    refetchAddressData()
    refetchProfileData()
    setOpenAddedAddressSnackbar(true)
  }
  const onSaveBankAccount = () => {
    refetchBankAccountData()
    setOpenAddedBankAccountSnackbar(true)
  }

  const handleGoToSumsub = () => window.open(getSumsubUrl(applicantId), 'blank')

  const handleConfigureAsAgent = () => {
    navigation(`/configure-agent/${profileUuid}`)
  }

  const profileAddressLabelId = getProfileLabelId(ADD_PROFILE_LABEL_ID, address)
  const profileBankAccountLabelId = getProfileLabelId(ADD_BANK_ACCOUNT_LABEL_ID, wireBankAccount)

  const overviewItems = [
    {
      key: 'open-in-sumsub',
      label: intl.formatMessage({ id: OPEN_IN_SUMSUB_LABEL_ID }),
      icon: <Image src={ExternalLinkIcon} alt="external_link_blue.svg" />,
      onClick: handleGoToSumsub,
    },
    {
      key: 'add-bank-account',
      label: intl.formatMessage({ id: profileAddressLabelId }),
      onClick: handleOpenAddAddressModal(true),
    },
    {
      key: 'add-address',
      label: intl.formatMessage({ id: profileBankAccountLabelId }),
      onClick: handleOpenAddBankAccountModal(true),
    },
  ]

  const configureAgentItem = {
    key: 'configure-agent',
    label: profileByUuid?.profileByUuid?.referralCode?.active ? 'Update Agent' : 'Set as Agent',
    onClick: handleConfigureAsAgent,
  }

  if (!profileByUuid?.profileByUuid?.referralCode?.isUpdateBlocked) {
    overviewItems.push(configureAgentItem)
  }

  return (
    <Fragment>
      <Appbar fullName={fullName} />
      <Grid container direction="column" alignItems="center" className={classes.mainGrid}>
        <MainContainer>
          <OverviewContainer>
            <InvestorOverview
              isVerified={status === APPROVED}
              fullName={fullName}
              loading={loadingAddressData || loadingBankAccountData}
              overviewItems={overviewItems}
              isAgent={isAgentRole}
              referralCode={profileByUuid?.profileByUuid?.referralCode?.code || ''}
              canConfigureAsAgent={!profileByUuid?.profileByUuid?.referralCode?.isUpdateBlocked}
            />
          </OverviewContainer>
          <DataGridListContainer>
            {loadingProfile ? (
              <Grid className={classes.investmentsLoading}>
                <Skeleton variant="rectangular" width="100%" height={200} />
              </Grid>
            ) : isAgentRole ? (
              <AgentDetailsTabs
                investments={investments?.investmentsByInvestor || []}
                agentUuid={profileUuid}
              />
            ) : (
              <InvestmentDataGrid
                investments={investments?.investmentsByInvestor || []}
                isLoading={loadingInvestments}
              />
            )}
          </DataGridListContainer>
        </MainContainer>
        <AddAddressModal
          data={{ profileUuid, address, tin }}
          open={openAddAddressModal}
          onSave={onSaveAddress}
          handleCloseCallback={handleOpenAddAddressModal(false)}
        />
        <AddBankAccountModal
          onSave={onSaveBankAccount}
          data={{ profileUuid, bankAccount: wireBankAccount }}
          open={openAddBankAccountModal}
          handleCloseCallback={handleOpenAddBankAccountModal(false)}
        />
        <Snackbar
          messageId={PROFILE_ADDED_SNACKBAR_LABEL_ID}
          open={openAddedAddressSnackbar}
          onClose={handleCloseAddedAddressSnackbar}
        />
        <Snackbar
          messageId={BANK_ACCOUNT_ADDED_SNACKBAR_LABEL_ID}
          open={openAddedBankAccountSnackbar}
          onClose={handleCloseAddedBankAccountSnackbar}
        />
      </Grid>
    </Fragment>
  )
}

export default InvestorDetail
