import { Typography } from '@mui/material'
import { Divider } from 'components/common/styled'
import { sumBy } from 'lodash'
import { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'screens/App/theme'
import { WithdrawalRequest } from 'types'
import { TotalAmount, useStyles } from '../styled'

interface Props {
  withdrawals: WithdrawalRequest[] | []
}

const UndoWithdrawalRequestModalContent: FunctionComponent<Props> = ({ withdrawals }: Props) => {
  const classes = useStyles()
  const intl = useIntl()

  const totalCount = sumBy(withdrawals, (withdrawal) => withdrawal?.withdrawal?.amount)

  return (
    <div>
      <TotalAmount>
        <Typography variant="body3" className={classes.grayText}>
          <FormattedMessage id="screen.withdrawalRequests.tab02.undoWithdrawalModal.amountRow" />
        </Typography>
        <Divider size={theme.spacing(1)} />
        <Typography variant="h3" className={classes.whiteText}>
          {intl.formatNumber(totalCount, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          })}
        </Typography>
      </TotalAmount>
      <Divider size={theme.spacing(4)} />
      <Typography variant="body1" className={classes.grayText}>
        <FormattedMessage id="screen.withdrawalRequests.tab02.undoWithdrawalModal.investorText" />
      </Typography>
    </div>
  )
}

export default UndoWithdrawalRequestModalContent
