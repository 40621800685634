import DataGrid from 'components/common/DataGrid'
import { columnsInvestorScheduledWithdrawals } from 'components/common/DataGrid/utils'
import React, { FunctionComponent } from 'react'
import { ScheduledWithdrawal } from 'types'

interface Props {
  scheduledWithdrawals?: ScheduledWithdrawal[]
}

const ScheduleWithdrawalsTab: FunctionComponent<Props> = ({ scheduledWithdrawals = [] }: Props) => {
  const columns = columnsInvestorScheduledWithdrawals({})

  return <DataGrid columns={columns} rows={scheduledWithdrawals} getRowId={(row) => row?.uuid} />
}

export default ScheduleWithdrawalsTab
