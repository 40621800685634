import { createSlice } from '@reduxjs/toolkit'

const LOCAL_STATE_KEY = 'newDepositRequestSuccessAppBar'

interface NewDepositRequestSuccessAppBarState {
  profileName?: string
  planName?: string
}

const newDepositRequestSuccessLocalStorage = localStorage.getItem(LOCAL_STATE_KEY)

const defaultState: NewDepositRequestSuccessAppBarState = {
  profileName: undefined,
  planName: undefined,
}

const newDepositRequestSuccessAppBar = createSlice({
  name: 'newDepositRequestSuccessAppBar',
  initialState: newDepositRequestSuccessLocalStorage
    ? JSON.parse(newDepositRequestSuccessLocalStorage)
    : defaultState,
  reducers: {
    setDepositRequestSuccessAppBarStorage: (state, action) => {
      state.profileName = action.payload.profileName
      state.planName = action.payload.planName
      localStorage.setItem(LOCAL_STATE_KEY, JSON.stringify(state))
    },
  },
})

export const { setDepositRequestSuccessAppBarStorage } = newDepositRequestSuccessAppBar.actions

export default newDepositRequestSuccessAppBar.reducer
