import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  box: {
    position: 'relative',
  },
}))

interface TreasurerLayoutChildrenContentProps {
  height?: string
}

export const HomeLayoutChildrenContent = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`
export const TreasurerLayoutChildrenContent = styled.div<TreasurerLayoutChildrenContentProps>`
  height: ${({ height }) => height || 'calc(100vh - 80px)'};
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  display: flex;
  width: 100%;
`

export default useStyles
