import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InvestmentDetailsTab } from 'global/types'

export interface DetailTab {
  status: InvestmentDetailsTab
  index: number
}

const initialState = { status: InvestmentDetailsTab.OVERVIEW, index: 0 } as DetailTab

const detailsTabStates: DetailTab[] = [
  { status: InvestmentDetailsTab.OVERVIEW, index: 0 },
  { status: InvestmentDetailsTab.STATEMENTS, index: 1 },
  { status: InvestmentDetailsTab.ALL_ACTIVITY, index: 2 },
  { status: InvestmentDetailsTab.DEPOSITS, index: 3 },
  { status: InvestmentDetailsTab.WITHDRAWALS, index: 4 },
  { status: InvestmentDetailsTab.INTEREST_EARNED, index: 5 },
]

const investmentDetailsSlice = createSlice({
  name: 'investmentDetails',
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      const newTab = detailsTabStates[action.payload]
      state.index = newTab.index
      state.status = newTab.status
    },
  },
})

export const { changeTab } = investmentDetailsSlice.actions
export default investmentDetailsSlice.reducer
