import { Typography } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrapper } from './styled'
import { useQuery } from '@apollo/client'
import { GET_DEPOSITS_REQUESTS } from '../queries'
import { DataGridHeaderWrapper } from '../styled'
import { columnsTreasurer } from 'components/common/DataGrid/utils'
import { theme } from 'screens/App/theme'
import UndoDepositsModal from './UndoDepositsModal'
import { Deposit } from 'types'
import { GetDepositsRequestData, GetDepositsRequestVariables } from '../RequestedTab'
import { DepositRequestsTab } from 'global/types'
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'

const TAB_STATUS = 'PENDING'

const PendingTab: FunctionComponent = () => {
  const [selectedDeposit, setSelectedDeposit] = useState<undefined | Deposit>(undefined)
  const [isUndoDepositModalOpen, setIsUndoDepositModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDirection, setOrderDirection] = useState<GridSortDirection | undefined>(undefined)

  const { data, error, refetch, loading } = useQuery<
    GetDepositsRequestData,
    GetDepositsRequestVariables
  >(GET_DEPOSITS_REQUESTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      status: DepositRequestsTab[TAB_STATUS],
    },
  })

  const handleClickUndoButtonInDepositRow = (rowId: string) => {
    setIsUndoDepositModalOpen(true)
    setSelectedDeposit(data?.depositsRequest.depositsRequest.find((row) => row.id === rowId))
  }

  const handleClickCloseInUndoDepositModal = () => {
    setIsUndoDepositModalOpen(false)
    setSelectedDeposit(undefined)
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const newOrderBy = sortModel?.[0]?.field
    const newOrderDirection = sortModel?.[0]?.sort
    setPage(0)
    setOrderBy(newOrderBy)
    setOrderDirection(newOrderDirection)
    refetch({ page: 0, orderBy: newOrderBy, orderDirection: newOrderDirection })
  }

  const handleOnPageSizeChange = (newPageSize: number) => {
    setPage(0)
    setLimit(newPageSize)
    refetch({ page: 0, limit: newPageSize })
  }

  const handleOnPageChange = (newPage: number) => {
    setPage(newPage)
    refetch({ page: newPage })
  }

  const pendingColumns = columnsTreasurer(
    undefined,
    undefined,
    true,
    true,
    handleClickUndoButtonInDepositRow,
    undefined,
    undefined,
    true,
  )

  let deposits = data?.depositsRequest.depositsRequest ?? []
  let rowCount = data?.depositsRequest.total ?? 0
  if (error || loading) {
    deposits = []
    rowCount = 0
  }

  const HeaderWithAction = () => {
    return (
      <DataGridHeaderWrapper>
        <Typography variant="body3" color={theme.typographyColor.primaryText}>
          Pending deposits
        </Typography>
        <div />
      </DataGridHeaderWrapper>
    )
  }

  return (
    <Wrapper>
      <Typography variant="body2">
        <FormattedMessage id="screen.depositRequests.tab02.description" />
      </Typography>
      <DataGrid
        extraHeader={<HeaderWithAction />}
        columns={pendingColumns}
        rows={deposits}
        rowCount={rowCount}
        rowsPerPageOptions={[10]}
        pagination
        page={page}
        pageSize={limit}
        paginationMode="server"
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        emptyMessageId="screen.depositRequests.tab.empty"
        errorMessageId="screen.depositRequests.tab.error"
        hasError={Boolean(error)}
        isLoading={loading}
        autoHeight
        sortingMode="server"
        activeSorting
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel: orderBy && orderDirection ? [{ field: orderBy, sort: orderDirection }] : [],
          },
        }}
      />
      {selectedDeposit && (
        <UndoDepositsModal
          modalOpen={isUndoDepositModalOpen}
          handleCloseCallback={handleClickCloseInUndoDepositModal}
          deposit={selectedDeposit}
        />
      )}
    </Wrapper>
  )
}

export default PendingTab
