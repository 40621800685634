import { useEffect, useState, useContext } from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import {
  useStyles,
  CenterContainer,
  TopTitleButtonContainer,
  StyledAnchorTag,
  InnerAlertContainer,
} from './styled'
import { Typography } from '@mui/material'
import InvestmentCard from 'components/cards/InvestmentCard'
import { Divider } from 'components/common/styled'
import Alert from 'components/Alert'
import { Profile, ProfileVerificationStatus } from 'global/types'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_ADDRESS, GET_BANK_ACCOUNT, MARK_PROFILE_AS_SEEN } from './queries'
import { useLoading } from 'hooks/LoadingHook'
import { ProfileContext } from 'hooks/ProfileContext'
import ArrowRightPrimaryColor from 'assets/arrow_right_primary.svg'
import AddMissingInfoView from './AddMissingInfoView'
import { useQuery } from '@apollo/client'
import Dialog from 'components/common/Dialog'
import ButtonWithArrowAnimation from 'components/common/ButtonWithArrowAnimation'
import { useDispatch } from 'react-redux'
import { clearNewInvestment } from 'screens/NewInvestment/reducer'

function Home() {
  const classes = useStyles()
  const { profileData, dataQueryMe, isTreasurer } = useContext(ProfileContext)
  const [profile, setProfile] = useState<Profile | undefined>(profileData?.profile)

  const [openModal, setOpenModal] = useState(false)
  const [userStatus, setUserStatus] = useState(ProfileVerificationStatus.completed)
  const [markProfileAsSeen] = useMutation(MARK_PROFILE_AS_SEEN)
  const { data: addressData, loading: addressLoading } = useQuery(GET_ADDRESS)
  const { data: bankData, loading: bankLoading } = useQuery(GET_BANK_ACCOUNT)
  const navigate = useNavigate()
  const { isLoading, stopLoading } = useLoading(true)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const [showNewInvestmentAlert, setShowNewInvestmentAlert] = useState(
    params.get('showNewInvestment') || false,
  )
  const dispatch = useDispatch()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const [hasInvestments, setHasInvestments] = useState(false)

  const showAddBankAndAddressAlert =
    !addressLoading && !bankLoading && (!addressData?.address || !bankData?.wireBankAccount)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const finishLoadInvestments = (mhasInvestments: boolean) => {
    setHasInvestments(mhasInvestments)
    stopLoading()
  }

  useEffect(() => {
    if (isTreasurer) {
      navigate('/dashboard')
    }

    setProfile(profileData?.profile)
  }, [profileData, dataQueryMe])

  useEffect(() => {
    const checkUserStatus = () => {
      if (!profile) {
        navigate('/welcome')
        return
      }

      const status = profile?.application?.status || ''
      if (!status) {
        navigate('/welcome')
        return
      }

      if (status === 'APPROVED' && profile.application?.seenAt) {
        changeUserStatus('COMPLETED')
        updateProfileSeenAt()
      } else {
        changeUserStatus(status)
      }
    }

    checkUserStatus()
  }, [profile])

  const updateProfileSeenAt = async (): Promise<void> => {
    try {
      await markProfileAsSeen()
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const changeUserStatus = (status: string) => {
    switch (status) {
      case 'CREATED':
        setUserStatus(ProfileVerificationStatus.created)
        break
      case 'REJECTED':
        setUserStatus(ProfileVerificationStatus.rejected)
        break
      case 'APPROVED':
        setUserStatus(ProfileVerificationStatus.approved)
        break
      case 'COMPLETED':
        setUserStatus(ProfileVerificationStatus.completed)
        break
      case 'DECLINED':
        setUserStatus(ProfileVerificationStatus.declined)
        break
      default:
        setUserStatus(ProfileVerificationStatus.pending)
    }
  }

  if (!profile) {
    return null
  }

  const onClickErrorBannerButton = () => {
    if (profile?.application?.levelName === 'KYB') {
      navigate('/sumsub/basic-kyb-level')
    } else {
      navigate('/sumsub/basic-kyc-level')
    }
  }

  const onClickContactSupport = () => {
    window.open('mailto:support@bluedotcapitals.com', '_blank')
  }

  const onClickNewInvestmentButton = () => {
    dispatch(clearNewInvestment())
    navigate('/new-investment')
  }

  const isUserApproved =
    userStatus === ProfileVerificationStatus.approved ||
    userStatus === ProfileVerificationStatus.completed

  return (
    <Grid container className={classes.mainGrid} direction="column" alignItems="center">
      {userStatus === ProfileVerificationStatus.created && (
        <Alert
          variant="warning"
          title="screen.home.banner.unfinishedProfile.title"
          subtitle="screen.home.banner.unfinishedProfile.subtitle"
          withRightButton
          buttonLabel="screen.home.banner.unfinishedProfile.buttonLabel"
          buttonDisabled={false}
          buttonColor="warning"
          buttonAction={onClickErrorBannerButton}
        />
      )}
      {userStatus === ProfileVerificationStatus.pending && (
        <Alert
          variant="warning"
          title="screen.home.banner.profileInReview.title"
          subtitle="screen.home.banner.profileInReview.subtitle"
        />
      )}
      {userStatus === ProfileVerificationStatus.declined && (
        <Alert
          variant="error"
          title="screen.home.banner.profileWithError.title"
          subtitle="screen.home.banner.profileWithError.subtitle"
          withRightButton
          buttonLabel="screen.home.banner.profileWithError.buttonLabel"
          buttonDisabled={false}
          buttonAction={onClickErrorBannerButton}
        />
      )}
      {userStatus === ProfileVerificationStatus.rejected && (
        <Alert
          variant="error"
          title="screen.home.banner.profileRejected.title"
          subtitle="screen.home.banner.profileRejected.subtitle"
          withRightButton
          buttonLabel="screen.home.banner.profileRejected.buttonLabel"
          buttonDisabled={false}
          buttonAction={onClickContactSupport}
        />
      )}
      {userStatus === ProfileVerificationStatus.approved && (
        <Alert
          variant="success"
          title="screen.home.banner.profileAccepted.title"
          subtitle="screen.home.banner.profileAccepted.subtitle"
          onClose={() => {
            setUserStatus(ProfileVerificationStatus.completed)
            updateProfileSeenAt()
          }}
        />
      )}
      {showNewInvestmentAlert && (
        <Alert
          variant="success"
          title="screen.home.banner.newInvestment.title"
          subtitle="screen.home.banner.newInvestment.subtitle"
          onClose={() => {
            setShowNewInvestmentAlert(false)
          }}
        />
      )}
      <CenterContainer>
        {showAddBankAndAddressAlert && (
          <InnerAlertContainer>
            <Alert
              buttonClassName={classes.muiAlertButton}
              subtitleContainerClassName={classes.muiAlertSubtitle}
              subtitleClassName={classes.customAlertSubtitle}
              withRightButton
              rightButtonEndIcon={ArrowRightPrimaryColor}
              variant="warning"
              title="screen.home.banner.not.added.bankStatement.address.title"
              subtitle="screen.home.banner.not.added.bankStatement.address.subtitle"
              buttonAction={handleOpenModal}
              buttonLabel="components.button.seeDetails"
              buttonColor="primary"
              valuesSubtitle={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </InnerAlertContainer>
        )}
        <TopTitleButtonContainer>
          <Typography variant="h3">Investments</Typography>
          {isUserApproved && (
            <ButtonWithArrowAnimation onClick={onClickNewInvestmentButton} variant="contained">
              <FormattedMessage id="screen.welcome.personalInvestorCard.newInvestmentButton" />
            </ButtonWithArrowAnimation>
          )}
        </TopTitleButtonContainer>
        <Divider size={theme.spacing(3)} />
        <InvestmentCard changeHasInvestments={finishLoadInvestments} />
        <Divider size={theme.spacing(3)} />
        <Divider size={theme.spacing(3)} />
      </CenterContainer>
      <Dialog
        endIconActionButton={<img src={ArrowRightPrimaryColor} alt="arrow_right_blue" />}
        handleActionButton={handleCloseModal}
        dialogOpen={openModal}
        handleCloseCallback={handleCloseModal}
        descriptionMessageId="screen.home.bank.statement.address.modal.subtitle"
        actionMessageId="components.button.close"
        titleMessageId="screen.home.bank.statement.address.modal.title"
        fullScreen={isResponsive}
        valuesDescription={{
          a: (chunks) => (
            <StyledAnchorTag onClick={onClickContactSupport}>{chunks}</StyledAnchorTag>
          ),
        }}
      >
        <AddMissingInfoView />
      </Dialog>
    </Grid>
  )
}

export default Home
