import styled from '@emotion/styled'
import { theme } from 'screens/App/theme'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginTop: theme.spacing(-0.5),
    '& path': {
      stroke: `${theme.palette.primary.main}`,
    },
  },
  inputLabel: {
    color: `${theme.typographyColor.secondaryText} !important`,
  },
  dataGrid: {
    '& .MuiDataGrid-main': {
      paddingLeft: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
      paddingLeft: '0 !important',
    },
  },
}))

export const Wrapper = styled.div`
  padding: ${theme.spacing(4, 5, 4, 2)};
  background-color: ${theme.extraBackgrounds.paper02};
`

export const GridContainer = styled.div`
  width: 100%;
  height: auto;
`
