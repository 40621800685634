import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import ArrowLeft from 'assets/arrow_down_left.svg'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_INVESTMENT } from 'screens/FinishNewInvestment/queries'
import { Investment } from 'types'
import { Content, TitleWrapper, useStyles } from './styled'
import { theme } from 'screens/App/theme'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDepositRequestSuccessAppBarStorage } from '../NewDepositRequestSuccessAppBar/reducer'

const NewDepositRequestAppBar = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const { data } = useQuery<{ investment: Investment }>(GET_INVESTMENT, {
    variables: {
      uuid,
    },
    fetchPolicy: 'cache-only',
  })

  const [investment, setInvestment] = useState<Investment>()

  const handleBackHome = () => {
    navigate(`/investment-detail/${uuid}`)
  }

  useEffect(() => {
    const investment = data?.investment
    if (investment) {
      dispatch(
        setDepositRequestSuccessAppBarStorage({
          profileName: investment.profile.application.name,
          planName: investment.plan.name,
        }),
      )
      setInvestment(investment)
    }
  }, [data])

  return (
    <Content>
      <img
        src={ArrowLeft}
        alt="arrow_right"
        className={classes.backIcon}
        onClick={handleBackHome}
      />
      <TitleWrapper>
        <Typography color={theme.typographyColor.primaryText} variant="body2">
          <FormattedMessage id="global.appbar.menu.newDeposit.bar.title" />
        </Typography>
        <Typography color={theme.typographyColor.secondaryText} variant="caption">
          <FormattedMessage
            id="screen.investmentDetail.planOverviewTitle"
            values={{
              name: investment?.profile?.application?.name,
              planName: investment?.plan?.name.toLowerCase(),
            }}
          />
        </Typography>
      </TitleWrapper>
    </Content>
  )
}

export default NewDepositRequestAppBar
