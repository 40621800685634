import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from '../../App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  cardSpacing: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
  },
  textWarningHighlight: {
    color: theme.typographyColor.onActiveSecondaryButtonText,
  },
  body3: {
    color: theme.typographyColor.secondaryText,
  },
  investmentPlan: {
    paddingTop: theme.spacing(6),
  },
  moneyInput: {
    width: '100%',
  },
  moneyInputError: {
    color: theme.palette.error.main,
  },
  white: {
    color: '#FFF !important',
  },
  underline: {
    textDecoration: 'underline',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    '& path': {
      stroke: `${theme.extraBackgrounds.paper01}`,
    },
  },
}))

export const ButtonContainer = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: flex;
  }
`

export const EndingBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};
`

export const InvestmentStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    gap: ${theme.spacing(8)};
    flex-direction: row;
  }
`

export const InvestmentStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
