import { gql } from '@apollo/client'

export const GET_REFERRALS_INVESTMENTS = gql`
  query ReferralsInvestmentsByUserId {
    referralsInvestmentsByUserId {
      investments {
        balance {
          total
        }
        plan {
          name
        }
        uuid
        duration {
          startDate
          endDate
        }
        status
        profile {
          application {
            name
          }
        }
      }
    }
  }
`
