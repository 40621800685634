import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PublicRoute from './permissions/PublicRoute'
import PrivateRoute from './permissions/PrivateRoute'
import EmptyLayout from 'layouts/emptyLayout'
import HomeLayout from 'layouts/homeLayout'
import WelcomeLayout from 'layouts/WelcomeLayout'
import TreasurerLayout from 'layouts/treasurerLayout'
import Home from 'screens/Home'
import Login from 'screens/Login'
import SignUp from 'screens/SignUp'
import Welcome from 'screens/Welcome'
import Sumsub from 'screens/Sumsub'
import NewInvestment from 'screens/NewInvestment'
import NotFound from 'screens/NotFound'
import Lab from 'screens/Lab'
import FinishNewInvestment from 'screens/FinishNewInvestment'
import InvestmentDetails from 'screens/InvestmentDetails'
import WithdrawalRequest from 'screens/WithdrawalRequest'
import DepositRequests from 'screens/DepositRequests'
import WithdrawalRequests from 'screens/WithdrawalRequests'
import LoginCallback from 'screens/LoginCallback'
import Profile from 'screens/Profile'
import InvestmentList from 'screens/InvestmentList'
import InvestorDetail from 'screens/InvestorDetail'
import { useContext } from 'react'
import { ProfileContext } from 'hooks/ProfileContext'
import TreasurerInvestmentDetails from 'screens/TreasurerInvestmentDetails'
import Investors from 'screens/Investors'
import Dashboard from 'screens/Dashboard'
import AgentInvestments from 'screens/AgentInvestments'
import AgentInvestors from 'screens/AgentInvestors'
import Partners from 'screens/Partners'
import AgentLayout from 'layouts/AgentLayout'
import PartnerLayout from 'layouts/Partner'
import DepositRequestInitializated from 'screens/DepositRequestInitializated'
import NewDepositRequest from 'screens/NewDepositRequest'
import { useSessionInactivityLifetime } from './permissions/useSessionInactivityLifetime'
import NewInvestmentDraftHandler from 'screens/NewInvestment/NewInvestmentDraftHandler'
import * as Sentry from '@sentry/react'
import ScheduledWithdrawal from 'screens/ScheduledWithdrawals'
import NewAgent from 'screens/NewAgent'
import AgentList from 'screens/AgentList'

function Router() {
  const { profileData, hasFinishedLoading, isTreasurer, isInvestor, isAgent, isPartner } =
    useContext(ProfileContext)

  Sentry.setUser({ id: profileData?.profile?.uuid })
  useSessionInactivityLifetime()

  const TreasurerRoutes = () => {
    return (
      <>
        <Route
          path="/investment-detail/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={TreasurerInvestmentDetails} />}
        />
        <Route
          path="/deposit-requests"
          element={<PrivateRoute layout={TreasurerLayout} component={DepositRequests} />}
        />
        <Route
          path="/withdrawal-requests"
          element={<PrivateRoute layout={TreasurerLayout} component={WithdrawalRequests} />}
        />
        <Route
          path="/investments"
          element={<PrivateRoute layout={TreasurerLayout} component={InvestmentList} />}
        />
        <Route
          path="/investors"
          element={<PrivateRoute layout={TreasurerLayout} component={Investors} />}
        />
        <Route
          path="/investor/:uuid"
          element={<PrivateRoute layout={EmptyLayout} component={InvestorDetail} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute layout={TreasurerLayout} component={Dashboard} />}
        />
        <Route
          path="/partners"
          element={
            <PrivateRoute
              layout={({ children }) => (
                <TreasurerLayout showSideBar={false}>{children}</TreasurerLayout>
              )}
              component={Partners}
            />
          }
        />
        <Route
          path="/configure-agent/:profileUuid"
          element={
            <PrivateRoute
              layout={({ children }) => (
                <TreasurerLayout showSideBar={false}>{children}</TreasurerLayout>
              )}
              component={NewAgent}
            />
          }
        />
        <Route
          path="/agents"
          element={<PrivateRoute layout={TreasurerLayout} component={AgentList} />}
        />
        <Route path="/welcome" element={<Navigate to="/dashboard" />} />
      </>
    )
  }

  const AgentRoutes = () => {
    return (
      <>
        {!isTreasurer && (
          <Route
            path="/home"
            element={<PrivateRoute layout={AgentLayout} component={AgentInvestments} />}
          />
        )}
        <Route
          path="my-investors"
          element={<PrivateRoute layout={AgentLayout} component={AgentInvestors} />}
        />
      </>
    )
  }

  const PartnerRoutes = () => {
    return (
      <>
        <Route
          path="my-investors"
          element={<PrivateRoute layout={PartnerLayout} component={AgentInvestors} />}
        />
        {!isTreasurer && (
          <Route path="/home" element={<PrivateRoute layout={PartnerLayout} component={Home} />} />
        )}
      </>
    )
  }

  const InvestorRoutes = () => {
    return (
      <>
        <Route
          path="/sumsub/:levelname"
          element={<PrivateRoute layout={WelcomeLayout} component={Sumsub} />}
        />
        <Route
          path="/welcome"
          element={<PrivateRoute layout={WelcomeLayout} component={Welcome} />}
        />
        <Route
          path="/new-investment"
          element={<PrivateRoute layout={HomeLayout} component={NewInvestment} />}
        />
        <Route
          path="/new-investment/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={NewInvestmentDraftHandler} />}
        />
        <Route
          path="/finish-investment/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={FinishNewInvestment} />}
        />
        {isInvestor && (
          <Route
            path="/new-deposit-request/:uuid"
            element={<PrivateRoute layout={HomeLayout} component={NewDepositRequest} />}
          />
        )}
        <Route
          path="/new-deposit-request/success/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={DepositRequestInitializated} />}
        />
        {isInvestor && (
          <Route
            path="/investment-detail/:uuid"
            element={<PrivateRoute layout={HomeLayout} component={InvestmentDetails} />}
          />
        )}
        <Route
          path="/withdrawal-request/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={WithdrawalRequest} />}
        />
        <Route
          path="/welcome/:sumsub"
          element={<PrivateRoute layout={WelcomeLayout} component={Welcome} />}
        />
        <Route
          path="/new-schedule-withdrawal/:uuid"
          element={<PrivateRoute layout={HomeLayout} component={ScheduledWithdrawal} />}
        />
      </>
    )
  }

  const DefaultRoutes = () => {
    return (
      <>
        <Route
          path="/invite/:code"
          element={<PublicRoute layout={EmptyLayout} component={SignUp} />}
        />
        <Route path="/login" element={<PublicRoute layout={EmptyLayout} component={Login} />} />
        <Route path="/sign-up" element={<PublicRoute layout={EmptyLayout} component={SignUp} />} />
        <Route path="/lab" element={<PrivateRoute layout={HomeLayout} component={Lab} />} />
        <Route
          path="/callback"
          element={<PrivateRoute layout={EmptyLayout} component={LoginCallback} />}
        />
      </>
    )
  }

  const AllRolesRoutes = () => {
    return (
      <>
        {!isTreasurer && (
          <Route
            path="/profile"
            element={<PrivateRoute layout={HomeLayout} component={Profile} />}
          />
        )}
        <Route path="/home" element={<PrivateRoute layout={HomeLayout} component={Home} />} />
        <Route path="/" element={<PublicRoute layout={EmptyLayout} component={Login} />} />
        <Route path="*" element={<PublicRoute layout={EmptyLayout} component={NotFound} />} />
      </>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        {hasFinishedLoading && (
          <>
            {isTreasurer && TreasurerRoutes()}
            {isAgent && AgentRoutes()}
            {isPartner && PartnerRoutes()}
            {isInvestor && InvestorRoutes()}
            {AllRolesRoutes()}
          </>
        )}
        {DefaultRoutes()}
      </Routes>
    </BrowserRouter>
  )
}

export default Router
