import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { theme as myTheme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    borderBottom: `1px solid ${theme.buttonColor.stroke}`,
    gridAutoFlow: 'column',
    alignItems: 'stretch',
  },
  whiteText: {
    color: theme.typographyColor.primaryText,
  },
  grayText: {
    color: theme.typographyColor.secondaryText,
  },
  description: {
    color: theme.typographyColor.secondaryText,
    marginBottom: theme.spacing(4),
  },
}))

export const Container = styled.div`
  width: 100%;
  text-align: left;
`

export const TotalAmount = styled.div`
  width: 100%;
  height: 86px;
  padding: ${myTheme.spacing(2)};
  background-color: ${myTheme.extraBackgrounds.paper02};
`

export const AlertDate = styled.div`
  width: 100%;
  height: 51px;
  padding: ${myTheme.spacing(2)};
  background-color: rgba(204, 173, 50, 0.4);
`

export const TotalRequestedContainer = styled.div`
  width: 100%;
  height: 86px;
  padding: ${myTheme.spacing(2)};
  background-color: ${myTheme.extraBackgrounds.paper02};
`
