import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  defaultDataIdFromObject,
} from '@apollo/client'
import { FunctionComponent, createContext } from 'react'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { REACT_APP_BLUEDOT_GRAPHQL_URL } from '../config/appConfig'

export const ApolloContext = createContext({} as any) // eslint-disable-line

interface Props {
  children: JSX.Element
}

const AuthorizedApolloProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const httpLink = new HttpLink({ uri: REACT_APP_BLUEDOT_GRAPHQL_URL })

  const checkToken = async () => {
    const idTokenClaims = await getIdTokenClaims()
    if (idTokenClaims) return
    await getAccessTokenSilently({})
  }

  const authLink = setContext(async (_, { headers }) => {
    await checkToken()
    const localStorageKeys = Object.keys(localStorage) || []
    const auth0Key = localStorageKeys.find((_key) => _key.includes('@@auth0')) || ''
    const user = JSON.parse(localStorage.getItem(auth0Key) || '{}')
    const profile = JSON.parse(localStorage.getItem('profile') || '{}')

    try {
      return {
        headers: {
          ...headers,
          authorization: user?.body?.id_token || null,
          'profile-uuid': profile?.uuid || '',
        },
      }
    } catch (error) {
      console.error('Error retrieving authentication token:', error)
      return {
        headers: {
          ...headers,
        },
      }
    }
  })

  // Initialize Apollo Client
  const client = new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        if (responseObject.__typename && responseObject.uuid) {
          return `${responseObject.__typename}:${responseObject.uuid}`
        }
        return defaultDataIdFromObject(responseObject)
      },
    }),
    link: authLink.concat(httpLink),
  })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
