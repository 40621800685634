import ACUMINPROBlackItalic from './fontAssets/FontsFree-Net-Acumin-Pro-Black-Italic.ttf'
import ACUMINPROBlack from './fontAssets/FontsFree-Net-Acumin-Pro-Black.ttf'
import ACUMINPROBold from './fontAssets/FontsFree-Net-Acumin-Pro-Bold.ttf'
import ACUMINPROBookItalic from './fontAssets/FontsFree-Net-Acumin-Pro-Book-Italic.ttf'
import ACUMINPROBook from './fontAssets/FontsFree-Net-Acumin-Pro-Book.ttf'
import ACUMINPROExtraLight from './fontAssets/FontsFree-Net-Acumin-Pro-ExtraLight.ttf'
import ACUMINPROLight from './fontAssets/FontsFree-Net-Acumin-Pro-Light.ttf'
import ACUMINPROMedium from './fontAssets/FontsFree-Net-Acumin-Pro-Medium.ttf'
import ACUMINPROSemiBold from './fontAssets/FontsFree-Net-Acumin-Pro-Semibold.ttf'
import ACUMINPROThin from './fontAssets/FontsFree-Net-Acumin-Pro-Thin.ttf'
import ACUMINPROUltraBlack from './fontAssets/FontsFree-Net-Acumin-Pro-UltraBlack.ttf'

export const AcuminProBlackItalic = {
  fontFamily: 'AcuminProBlackItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROBlackItalic}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProBlack = {
  fontFamily: 'AcuminProBlack',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROBlack}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProBold = {
  fontFamily: 'AcuminProBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROBold}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProBookItalic = {
  fontFamily: 'AcuminProBookItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROBookItalic}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProBook = {
  fontFamily: 'AcuminProBook',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROBook}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProExtraLight = {
  fontFamily: 'AcuminProExtraLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROExtraLight}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProLight = {
  fontFamily: 'AcuminProLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROLight}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProMedium = {
  fontFamily: 'AcuminProMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROMedium}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProSemiBold = {
  fontFamily: 'AcuminProSemiBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROSemiBold}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProThin = {
  fontFamily: 'AcuminProThin',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROThin}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const AcuminProUltraBlack = {
  fontFamily: 'AcuminProUltraBlack',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${ACUMINPROUltraBlack}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties
