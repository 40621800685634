import { FunctionComponent } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

interface chartDataProps {
  data: number[]
  backgroundColor: string
  title: string
}

interface Props {
  line: chartDataProps
  bar: chartDataProps
  extraBar?: chartDataProps
  labels: string[]
}

const chartsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      grid: {
        drawOnChartArea: false,
      },
    },
  },
}

const MultitypeChart: FunctionComponent<Props> = (props: Props) => {
  const labels = props?.labels
  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: props?.line?.title,
        borderColor: props?.line?.backgroundColor,
        borderWidth: 2,
        tension: 0.5,
        pointRadius: 0,
        fill: true,
        data: props?.line?.data,
      },
      {
        type: 'bar' as const,
        label: props?.bar?.title,
        backgroundColor: props?.bar?.backgroundColor,
        data: props?.bar?.data,
        borderWidth: 0,
        categoryPercentage: 1,
        barPercentage: 1,
      },
      {
        type: 'bar' as const,
        label: props?.extraBar?.title,
        backgroundColor: props?.extraBar?.backgroundColor,
        data: props?.extraBar?.data,
        borderWidth: 0,
        categoryPercentage: 1,
        barPercentage: 1,
      },
    ],
  }
  return <Chart type="bar" data={data} options={chartsOptions} height={300} />
}
export default MultitypeChart
