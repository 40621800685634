import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from 'screens/App/theme'

export const useStyles = makeStyles((theme: Theme) => ({
  mainGridCard: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  },
  gray: {
    color: theme.typographyColor.secondaryText,
  },
  amountInput: {
    width: '100%',
  },
  bankImage: {
    height: '24px',
    width: '24px',
    borderRadius: '40px',
    marginRight: theme.spacing(1.5),
  },
  continueButton: {
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  body3: {
    color: theme.typographyColor.secondaryText,
    paddingBottom: theme.spacing(2),
  },
}))

export const TopTitleButtonContainer = styled.div`
  margin-bottom: ${theme.spacing(4)};
`

export const BalanceContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
`

export const BalanceItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(0.5)};
`

export const LockupPeriodAlert = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.typographyColor.primaryText} !important;
  background-color: ${theme.extraBackgrounds.paperError};
  padding: ${theme.spacing(2)};
`

export const DepositAcountItem = styled.div`
  width: 100%;
  height: 64px;
  position: relative;
  padding: ${theme.spacing(3, 2, 2, 2)};
  border: 1px solid ${theme.extraBackgrounds.paper06};
`

export const DepositAcountItemTitle = styled.div`
  position: absolute;
  top: -12px;
  left: 12px;
  padding: ${theme.spacing(0, 0.5)};
  background-color: ${theme.extraBackgrounds.paper02};
`

export const DepositAcountItemContent = styled.div`
  display: flex;
  position: relative;
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  padding-top: ${theme.spacing(5)};
  justify-content: right;
`
