import { gql } from '@apollo/client'

export const GET_MONTHLY_DATA = gql`
  query MonthlyDashboardData($date: String!) {
    monthlyDashboardData(date: $date) {
      balanceSheetAsset
      liabilities {
        total
        lockup
        earnings
        noLockup
        lastMonthDiff
      }
      date
      grossMargin
      grossMarginCummulative
      balance {
        wire
        plaid
        total
      }
      withdrawalsTotalAmount
      agentsEarnings
    }
  }
`

export const UPDATE_MONTHLY_BALANCE = gql`
  mutation UpdateMonthlyBalance($newBalance: Float!, $date: String!) {
    updateMonthlyBalance(newBalance: $newBalance, date: $date)
  }
`

export const GET_DASHBOARD_DATES = gql`
  query DashboardDates {
    dashboardDates {
      dashboardDates
    }
  }
`

export const GET_DASHBOARD_NEXT_MONTH_DATA = gql`
  query nextMonthDashboardData {
    nextMonthDashboardData {
      scheduledWithdrawals
    }
  }
`

export const GET_CHART_TAB_DATA = gql`
  query DashboardChartData($startDate: String!, $endDate: String!) {
    dashboardChartData(startDate: $startDate, endDate: $endDate) {
      balanceSheetAssets
      grossMargins
      labels
      liabilities
    }
  }
`
