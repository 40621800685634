import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  inlineAlert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly !important',
    padding: `${theme.spacing(2)} !important`,
    '& .MuiAlert-icon': {
      order: 2,
      marginRight: 0,
    },
    '& .MuiAlert-message': {
      order: 1,
      flex: 1,
      color: theme.typographyColor.primaryText,
    },
  },
  icon: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: `${theme.palette.primary.main}`,
    },
  },
  iconClickable: {
    cursor: 'pointer',
  },
}))
