import { FormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from '@mui/material'
import Money from 'assets/money.svg'
import clsx from 'clsx'
import Card from 'components/common/Card'
import { Divider } from 'components/common/styled'
import { StepNumber } from 'global/types'
import moment from 'moment'
import { FunctionComponent, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'screens/App/theme'
import { DepositFrequency, Investment } from 'types'
import { validateOnlyNumberInput } from 'utils/Validators'
import {
  ButtonContainer,
  EndingBalanceContainer,
  InvestmentStatContainer,
  InvestmentStatsContainer,
  useStyles,
} from './styled'
import { formatMoneyWithSymbolAndTwoDecimals } from 'utils/MoneyFormat'
import TimeLeftInlineAlert from '../TimeLeftInlineAlert'
import ButtonWithArrowAnimation from 'components/common/ButtonWithArrowAnimation'
import SubtitleTimeRules from '../SubtitleTimeRules'

const MINIMUM_DEPOSIT_AMOUNT = 100
const EMPTY_INPUT_VALUE = 0

interface Props {
  onClickContinueButton: (stepNumber: StepNumber) => void
  onDisableContinueButton: (isDisabled: boolean) => void
  onChangeNewDepositAmountInput: (newValue: number) => void
  isContinueButtonDisabled: boolean
  newDepositAmount: number
  investment?: Investment
  depositRulesDates?: {
    createdAt: string
    receivedAt: string
    targetDate: string
  }
}

const DepositAmountStepper: FunctionComponent<Props> = ({
  onClickContinueButton,
  investment,
  newDepositAmount,
  isContinueButtonDisabled,
  onChangeNewDepositAmountInput,
  onDisableContinueButton,
  depositRulesDates,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = validateOnlyNumberInput(event)
    const value = newAmount.replace(/\D/g, '') || '0'
    if (value.length > 13) return
    const formattedAmount = Number(value.replaceAll(',', ''))
    onChangeNewDepositAmountInput(formattedAmount)
  }

  useEffect(() => {
    onDisableContinueButton(true)
    if (newDepositAmount >= MINIMUM_DEPOSIT_AMOUNT) {
      onDisableContinueButton(false)
    }
  }, [newDepositAmount])

  let newDepositAmountFormatted = ''
  if (newDepositAmount > 0) {
    newDepositAmountFormatted = intl.formatNumber(newDepositAmount, { currency: 'USD' })
  }

  let isValidDepositAmountInput = false
  if (newDepositAmount === EMPTY_INPUT_VALUE || newDepositAmount >= MINIMUM_DEPOSIT_AMOUNT) {
    isValidDepositAmountInput = true
  }

  return (
    <Card className={classes.cardSpacing}>
      <Typography variant="h4">
        <FormattedMessage id="screen.DepositAmountStepper.subtitle" />
      </Typography>
      <SubtitleTimeRules
        depositFrequency={investment?.plan.depositFrequency || DepositFrequency.NEXT_MONTH}
        depositRulesDates={depositRulesDates}
      />

      <TimeLeftInlineAlert
        createdBefore={depositRulesDates?.receivedAt}
        startGeneratingInterest={depositRulesDates?.targetDate}
      />

      <Divider size={theme.spacing(3)} />

      <Typography variant="body1" className={clsx(classes.white)}>
        {investment ? (
          <FormattedMessage
            id="screen.investmentDetail.planOverviewTitle"
            values={{
              name: investment?.profile.application.name,
              planName: investment?.plan.name.toLowerCase(),
            }}
          />
        ) : (
          '-'
        )}
      </Typography>
      <Divider size={theme.spacing(1)} />
      <InvestmentStatsContainer>
        <InvestmentStatContainer>
          <Typography variant="body2">
            <FormattedMessage
              id="screen.investmentDetail.endingBalance"
              values={{
                date: investment ? moment(investment?.balance?.cutoffDate).format('M/D/YYYY') : '-',
              }}
            />
          </Typography>
          <EndingBalanceContainer>
            <Typography className={clsx(classes.white)} variant="h3">
              {investment ? formatMoneyWithSymbolAndTwoDecimals(investment?.balance?.total) : '$-'}
            </Typography>
          </EndingBalanceContainer>
        </InvestmentStatContainer>
        <InvestmentStatContainer>
          <Typography variant="body2">
            <FormattedMessage id="screen.investmentDetail.plan" />
          </Typography>
          <Typography className={clsx(classes.white)} variant="body2">
            {investment ? investment?.plan.name : '-'}
          </Typography>
        </InvestmentStatContainer>
        <InvestmentStatContainer>
          <Typography variant="body2">
            <FormattedMessage id="screen.investmentDetail.currentRateLabel" />
          </Typography>
          <Typography className={clsx(classes.white)} variant="body2">
            <FormattedMessage
              id="screen.investmentDetail.currentRateValue"
              values={{ percentage: investment?.plan.interestRatePerMonth }}
            />
          </Typography>
        </InvestmentStatContainer>
        <InvestmentStatContainer>
          <Typography variant="body2">
            <FormattedMessage id="screen.investmentDetail.principalLockup" />
          </Typography>
          <Typography className={clsx(classes.white)} variant="body2">
            {investment
              ? moment(
                  moment(investment?.duration.startDate)
                    .add(investment?.plan?.principalLockupInMonths, 'months')
                    .format() || moment().toString(),
                ).format('MMMM DD, YYYY')
              : '-'}
          </Typography>
        </InvestmentStatContainer>
      </InvestmentStatsContainer>

      <FormControl sx={{ mt: 4 }} error={!isValidDepositAmountInput}>
        {newDepositAmount > 0 && (
          <InputLabel htmlFor="outlined-adornment-amount">
            <FormattedMessage id="screen.DepositAmountStepper.inputLabel" />
          </InputLabel>
        )}
        <OutlinedInput
          className={classes.moneyInput}
          value={newDepositAmountFormatted}
          onChange={handleInputAmount}
          startAdornment={<img src={Money} alt="money" style={{ paddingRight: '8px' }} />}
          label={newDepositAmount > 0 ? 'Deposit Amount' : ''}
          autoFocus={true}
          placeholder="Deposit Amount"
        />
        <FormHelperText>
          <FormattedMessage id="screen.DepositAmountStepper.formHelper.text" />
        </FormHelperText>
      </FormControl>
      <Divider size={theme.spacing(3)} />
      <ButtonContainer>
        <ButtonWithArrowAnimation
          disabled={isContinueButtonDisabled}
          variant="contained"
          onClick={() => onClickContinueButton(StepNumber.FIRST)}
        >
          <FormattedMessage id="screen.withdrawalRequest.step1.continue" />
        </ButtonWithArrowAnimation>
      </ButtonContainer>
    </Card>
  )
}

export default DepositAmountStepper
