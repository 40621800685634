import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import {
  useStyles,
  MainContainer,
  CenterContainer,
  TopTitleButtonContainer,
  ContentCard,
  StepperContentContainer,
} from './styled'
import { StepNumber, StepperWithdrawalRequest, StepsWithdrawalRequest } from 'global/types'
import { Investment } from 'types'
import StepperCard from 'components/Stepper'
import WithdrawalAmount from './WithdrawalAmount'
import WithdrawalConfirmation from './WithdrawalConfirmation'
import WithdrawalRequested from './WithdrawalRequested'
import { useMutation, useQuery } from '@apollo/client'
import { GET_INVESTMENT } from 'screens/FinishNewInvestment/queries'
import { CREATE_WITHDRAWAL_REQUEST } from './queries'
import { AlertContext } from 'hooks/AlertContext'
import ResponsiveStepperCard from 'components/Stepper/ResponsiveStepper'
import { useLoading } from 'hooks/LoadingHook'

const { WITHDRAWAL_AMOUNT, WITHDRAWAL_CONFIRMATION } = StepperWithdrawalRequest

const { NONE, FIRST } = StepNumber
const { AMOUNT_STEP, CONFIRMATION_STEP, REQUESTED_STEP } = StepsWithdrawalRequest

const WithdrawalRequest = () => {
  const classes = useStyles()
  const { uuid } = useParams()
  const { showSnackbar } = useContext(AlertContext)

  const [activeStep, setActiveStep] = useState<number>(0)
  const [disableNextStep, setDisableNextStep] = useState<boolean>(true)
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('0')
  const [investmentFromWithdrawal, setInvestmentFromWithdrawal] = useState<Investment | null>()
  const theme = useTheme()
  const hideInResponsive = useMediaQuery(theme.breakpoints.up('md'))
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const withdrawalRequestSteps = [WITHDRAWAL_AMOUNT, WITHDRAWAL_CONFIRMATION]

  const { data } = useQuery(GET_INVESTMENT, {
    variables: {
      uuid,
    },
  })

  const [createWithdrawalRequest] = useMutation(CREATE_WITHDRAWAL_REQUEST, {
    variables: {
      amount: Number(withdrawalAmount.replaceAll(',', '')),
      investmentUuid: uuid,
    },
  })

  const handleCreateWithdrawalRequest = async () => {
    try {
      const response = await createWithdrawalRequest()
      if (response?.data?.createWithdrawalRequest) {
        setInvestmentFromWithdrawal(response?.data?.createWithdrawalRequest)
        setActiveStep(REQUESTED_STEP)
      }
    } catch (e: unknown) {
      setInvestmentFromWithdrawal(null)
      setActiveStep(REQUESTED_STEP)
      showSnackbar({
        messageId: 'global.snackbar.error.generalError',
      })
    }
  }

  const handleChangeWithdrawalAmount = (amount: string) => {
    setWithdrawalAmount(amount)
  }

  const changeActiveStep = async (step: number) => {
    if (activeStep === CONFIRMATION_STEP) {
      setActiveStep(step)
      setDisableNextStep(true)
    }
    setActiveStep(step)
  }

  useEffect(() => {
    if (activeStep === AMOUNT_STEP && withdrawalAmount !== '0') {
      setDisableNextStep(false)
    } else if (activeStep === CONFIRMATION_STEP) {
      setDisableNextStep(false)
    } else {
      setDisableNextStep(true)
    }

    if (activeStep === CONFIRMATION_STEP) {
      setDisableNextStep(false)
    }
  }, [activeStep, withdrawalAmount])

  return (
    <MainContainer>
      <Grid container className={classes.mainGrid} direction="column" alignItems="center">
        <CenterContainer className={classes.centerContainer}>
          <TopTitleButtonContainer>
            <Typography variant={hideInResponsive ? 'h2' : 'h3'}>
              <FormattedMessage id="screen.withdrawalRequest.title" />
            </Typography>
          </TopTitleButtonContainer>
          <StepperContentContainer>
            {hideInResponsive && (
              <StepperCard
                steps={withdrawalRequestSteps}
                activeStep={activeStep}
                widthStepper="423px"
              />
            )}
            {activeStep === AMOUNT_STEP && (
              <WithdrawalAmount
                investment={data?.investment}
                handleChangeWithdrawalAmount={handleChangeWithdrawalAmount}
                handleChangeStep={setActiveStep}
              />
            )}
            {activeStep === CONFIRMATION_STEP && (
              <WithdrawalConfirmation
                investment={data?.investment}
                amount={withdrawalAmount}
                handleChangeStep={setActiveStep}
                handleConfirmWithdrawal={handleCreateWithdrawalRequest}
              />
            )}
            {activeStep === REQUESTED_STEP && investmentFromWithdrawal && (
              <WithdrawalRequested investment={investmentFromWithdrawal} />
            )}
          </StepperContentContainer>
        </CenterContainer>
      </Grid>
      {isResponsive && (
        <ResponsiveStepperCard
          steps={withdrawalRequestSteps}
          activeStep={activeStep}
          setActiveStep={changeActiveStep}
          disableNextButton={disableNextStep}
          handleFinishAction={() => handleCreateWithdrawalRequest()}
        />
      )}
    </MainContainer>
  )
}

export default WithdrawalRequest
